import React, { useState, useContext } from "react";
import Section from "./Section";
import AutocompleteTextArea from "../AutocompleteTextArea";
import CopyButton from "../CopyButton";
import Spinner from "../Spinner";
import useAutoSave from "../hooks/useAutoSave";
import { DataContext } from "../../dataContext/DataContext";

export default function TextSection({
  noteId,
  content,
  placeholder,
  noteSlug,
  isLoading = false,
  macros,
  resultsKey,
  sectionProps,
  right,
}) {
  const { updateNote } = useContext(DataContext);
  const [innerLoading, setInnerLoading] = useState(false);
  const { handleChange } = useAutoSave(updateNote, 1000);

  return (
    <Section
      right={
        <>
          <CopyButton content={content} />
          {right}
        </>
      }
      {...sectionProps}
    >
      <div className="relative">
        <AutocompleteTextArea
          initialValue={content}
          placeholder={placeholder}
          options={macros}
          onUpdate={(updatedContent) =>
            handleChange({
              updated: { [resultsKey]: updatedContent },
            })
          }
        />
        {(isLoading || innerLoading) && (
          <div className="absolute top-2 right-2">
            <Spinner size="small" />
          </div>
        )}
      </div>
    </Section>
  );
}
