// import { User } from "@clerk/backend";
// import { UserResource } from "@clerk/types";

export const keysOf = (obj) => Object.keys(obj);

export const toTitleCase = (s) =>
  s
    .split(" ")
    .map((w) => (w.at(0) || "").toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");

// export function daysDifference(date?: Date | null): number {
//   if (!date) return 0;

//   const diffTime = Math.abs(new Date().getTime() - date.getTime());
//   return Math.trunc(diffTime / (1000 * 60 * 60 * 24));
// }

// /*
//  * Calculate the subscription start date. If the user has a createdAt date
//  * older than the period the subscriptions started working, we start counting
//  * days from the subscriptions start date. Otherwise, we start counting days
//  * from the user createdAt date.
//  */
// export function calculateTrialDaysLeft(
//   user: User | UserResource | null | undefined,
// ): number | null {
//   const subscriptionsStartDate = new Date(
//     process.env.NEXT_PUBLIC_SUBSCRIPTIONS_START_DATE || "2023-09-01",
//   );
//   const today = new Date();

//   if (today < subscriptionsStartDate || !user || !user.createdAt) return null;

//   const trialDays = Number.parseInt(
//     process.env.NEXT_PUBLIC_TRIAL_PERIOD_DAYS || "7",
//     10,
//   );

//   const trialStartedDate = user.publicMetadata.trialStartedDate
//     ? new Date(user.publicMetadata.trialStartedDate as string)
//     : new Date(user.createdAt);

//   const daysDiff = daysDifference(
//     trialStartedDate > subscriptionsStartDate
//       ? trialStartedDate
//       : subscriptionsStartDate,
//   );

//   return trialDays - daysDiff;
// }

// export const parsedPathNames: Record<string, string> = {
//   "/": "Home",
//   "/sign-in": "Sign In",
//   "/custom-phrases": "Custom Phrases",
//   "/data-model": "Data Model",
//   "/onboarding": "Onboarding",
//   "/subscription": "Subscription",
//   "/Success": "Success",
// };

// export const downloadFile = (url: string, filename: string) => {
//   const link = document.createElement("a");
//   link.target = "_blank";
//   link.download = filename;
//   link.href = url;
//   document.body.appendChild(link);
//   link.click();
// };

// export const deepEquals = (o1: any, o2: any) =>
//   o1 != undefined &&
//   o2 != undefined &&
//   !Object.keys(o1).some(
//     (key) => o1[key as keyof typeof o1] !== o2[key as keyof typeof o2],
//   );
