import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useAtom } from "jotai";
import classNames from "classnames";

export default function Section({
  title,
  actionButton,
  children,
  isReady = true,
  disabled = false,
  // showAtom,
  right,
  styled = true,
  patient = false,
}) {
  // let shown, setShown;

  // if (showAtom) {
  //   [shown, setShown] = useAtom(showAtom);
  // } else {
  //   shown = true;
  //   setShown = null;
  // }

  const [shown, setShown] = useState(!patient);
  const showAtom = true;

  return (
    <section className="relative">
      {(!isReady || disabled) && (
        <div className="absolute z-10 h-full w-full animate-pulse cursor-wait bg-white opacity-10"></div>
      )}
      <div className="relative z-10 flex h-fit items-end gap-4 py-2">
        <h3 className="text-xl">{title}</h3>
        {actionButton && isReady && (
          <button
            className="rounded-md bg-primary px-4 py-1 text-white hover:bg-primary-600 disabled:bg-gray-300"
            onClick={actionButton.onClick}
            disabled={actionButton.disabled}
          >
            {actionButton.label}
          </button>
        )}
        {showAtom ? (
          shown ? (
            <EyeIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => setShown && setShown(false)}
            />
          ) : (
            <EyeSlashIcon
              onClick={() => setShown && setShown(true)}
              className="h-6 w-6 cursor-pointer"
            />
          )
        ) : null}
        {right}
      </div>
      {shown ? (
        <div
          className={classNames(
            "rounded-lg bg-gray-100",
            styled && "space-y-4 p-3"
          )}
        >
          {isReady && children}
        </div>
      ) : null}
    </section>
  );
}
