// import { atom } from "jotai";
// import { CalculatorOption } from "../components/CDT/Calculator";
// import { CalculatorRecursiveSection } from "../components/CDT/RecursiveCalculator";

export const CDTSections = {
  HEART: "HEART Score for Major Cardiac Events",
  NIHSS: "NIH Stroke Scale/Score (NIHSS)",
};

// export type CDTSubsections = {
//   [CDTSections.HEART]: HeartCalcSubSections;
//   [CDTSections.NIHSS]: NIHSSCalcSubSections;
// };

export const HeartCalcSubSections = {
  HISTORY: "History",
  AGE: "Age",
  EKG: "EKG",
  RISK_FACTORS: "Risk Factors",
  INITIAL_TROPONIN: "Initial Troponin",
};

export const NIHSSCalcSubSections = {
  LEVEL_OF_CONSCIOUSNESS: "1A: Level of consciousness",
  ASK_MONTH_AND_AGE: "1B: Ask month and age",
  BLINK_EYES_AND_SQUEEZE_HANDS: "'Blink eyes' & 'squeeze hands'",
  HORIZONTAL_EXTRAOCULAR_MOVEMENTS: "2: Horizontal extraocular movements",
  VISUAL_FIELDS: "3: Visual fields",
  FACIAL_PALSY: "4: Facial palsy",
  LEFT_ARM_MOTOR_DRIFT: "5A: Left arm motor drift",
  RIGHT_ARM_MOTOR_DRIFT: "5B: Right arm motor drift",
  LEFT_LEG_MOTOR_DRIFT: "6A: Left leg motor drift",
  RIGHT_LEG_MOTOR_DRIFT: "6B: Right leg motor drift",
  LIMB_ATAXIA: "7: Limb ataxia",
  SENSATION: "8: Sensation",
  LANGUAGE_OR_APHASIA: "9: Language/aphasia",
  DYSARTHRIA: "10: Dysarthria",
  EXTINCTION_OR_INATTENTION: "11: Extinction/inattention",
};

export const isEnabledCopy = (pointResults, calculatorOptions) =>
  !Object.values(calculatorOptions).some(
    (value) => pointResults[value.name] === undefined
  );
// export const isValidName = (name: string | undefined): name is CdtName =>
//   !!name && z.nativeconst(CdtName).safeParse(name).success;

export const cdtSectionsData = {
  [CDTSections.HEART]: [
    {
      name: HeartCalcSubSections.HISTORY,
      options: [
        {
          id: 0,
          label: "Slightly suspicious",
          value: 0,
        },
        {
          id: 1,
          label: "Moderately suspicious",
          value: 1,
        },
        {
          id: 2,
          label: "Highly suspicious",
          value: 2,
        },
      ],
    },
    {
      name: HeartCalcSubSections.EKG,
      description:
        "1 point: No ST deviation but LBBB, LVH, repolarization changes (e.g. digoxin); 2 points: ST deviation not due to LBBB, LVH, or digoxin",
      options: [
        {
          id: 0,
          label: "Normal",
          value: 0,
        },
        {
          id: 1,
          label: "Non-specific repolarization disturbance",
          value: 1,
        },
        {
          id: 2,
          label: "Significant ST deviation",
          value: 2,
        },
      ],
    },
    {
      name: HeartCalcSubSections.AGE,
      options: [
        {
          id: 0,
          label: "<45",
          value: 0,
        },
        {
          id: 1,
          label: "45-64",
          value: 1,
        },
        {
          id: 2,
          label: "≥65",
          value: 2,
        },
      ],
    },
    {
      name: HeartCalcSubSections.RISK_FACTORS,
      description:
        "Risk factors: HTN, hypercholesterolemia, DM, obesity (BMI >30 kg/m²), smoking (current, or smoking cessation ≤3 mo), positive family history (parent or sibling with CVD before age 65); atherosclerotic disease: prior MI, PCI/CABG, CVA/TIA, or peripheral arterial disease",
      options: [
        {
          id: 0,
          label: "No known risk factors",
          value: 0,
        },
        {
          id: 1,
          label: "1-2 risk factors",
          value: 1,
        },
        {
          id: 2,
          label: "≥3 risk factors or history of atherosclerotic disease",
          value: 2,
        },
      ],
    },
    {
      name: HeartCalcSubSections.INITIAL_TROPONIN,
      description:
        "Use local, regular sensitivity troponin assays and corresponding cutoffs",
      options: [
        {
          id: 0,
          label: "≤normal limit",
          value: 0,
        },
        {
          id: 1,
          label: "1–3× normal limit",
          value: 1,
        },
        {
          id: 2,
          label: ">3× normal limit",
          value: 2,
        },
      ],
    },
  ],
  [CDTSections.NIHSS]: [
    {
      name: NIHSSCalcSubSections.LEVEL_OF_CONSCIOUSNESS,
      description: "May be assessed casually while taking history",
      options: [
        {
          id: 0,
          label: "Alert; keenly responsive",
          value: 0,
        },
        {
          id: 1,
          label: "Arouses to minor stimulation",
          value: 1,
        },
        {
          id: 3,
          label: "Requires repeated stimulation to arouse",
          value: 2,
        },
        {
          id: 4,
          label: "Movements to pain",
          value: 2,
        },
        {
          id: 5,
          label: "Postures or unresponsive",
          value: 3,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.ASK_MONTH_AND_AGE,
      options: [
        {
          id: 0,
          label: "Both questions right",
          value: 0,
        },
        {
          id: 1,
          label: "1 question right",
          value: 1,
        },
        {
          id: 2,
          label: "0 questions right",
          value: 2,
        },
        {
          id: 3,
          label: "Dysarthric/intubated/trauma/language barrier",
          value: 1,
        },
        {
          id: 4,
          label: "Aphasic",
          value: 2,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.BLINK_EYES_AND_SQUEEZE_HANDS,
      description: "Pantomime commands if communication barrier",
      options: [
        {
          id: 0,
          label: "Performs both tasks",
          value: 0,
        },
        {
          id: 1,
          label: "Performs 1 task",
          value: 1,
        },
        {
          id: 2,
          label: "Performs 0 tasks",
          value: 2,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.HORIZONTAL_EXTRAOCULAR_MOVEMENTS,
      description: "Only assess horizontal gaze",
      options: [
        {
          id: 0,
          label: "Normal",
          value: 0,
        },
        {
          id: 1,
          label: "Partial gaze palsy: can be overcome",
          value: 1,
        },
        {
          id: 2,
          label: "Partial gaze palsy: corrects with oculocephalic reflex",
          value: 1,
        },
        {
          id: 3,
          label: "Forced gaze palsy: cannot be overcome",
          value: 2,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.VISUAL_FIELDS,
      options: [
        {
          id: 0,
          label: "No visual loss",
          value: 0,
        },
        {
          id: 1,
          label: "Partial hemianopia",
          value: 1,
        },
        {
          id: 2,
          label: "Complete hemianopia",
          value: 2,
        },
        {
          id: 3,
          label: "Patient is bilaterally blind",
          value: 3,
        },
        {
          id: 4,
          label: "Bilateral hemianopia",
          value: 3,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.FACIAL_PALSY,
      description: "Use grimace if obtunded",
      options: [
        {
          id: 0,
          label: "Normal symmetry",
          value: 0,
        },
        {
          id: 1,
          label: "Minor paralysis (flat nasolabial fold, smile asymmetry)",
          value: 1,
        },
        {
          id: 2,
          label: "Partial paralysis (lower face)",
          value: 2,
        },
        {
          id: 3,
          label: "Unilateral complete paralysis (upper/lower face)",
          value: 3,
        },
        {
          id: 4,
          label: "Bilateral complete paralysis (upper/lower face)",
          value: 3,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.LEFT_ARM_MOTOR_DRIFT,
      description:
        "Count out loud and use your fingers to show the patient your count",
      options: [
        {
          id: 0,
          label: "No drift for 10 seconds",
          value: 0,
        },
        {
          id: 1,
          label: "Drift, but doesn't hit bed",
          value: 1,
        },
        {
          id: 2,
          label: "Drift, hits bed",
          value: 2,
        },
        {
          id: 3,
          label: "Some effort against gravity",
          value: 2,
        },
        {
          id: 4,
          label: "No effort against gravity",
          value: 3,
        },
        {
          id: 5,
          label: "No movement",
          value: 4,
        },
        {
          id: 6,
          label: "Amputation/joint fusion",
          value: 0,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.RIGHT_ARM_MOTOR_DRIFT,
      description:
        "Count out loud and use your fingers to show the patient your count",
      options: [
        {
          id: 0,
          label: "No drift for 10 seconds",
          value: 0,
        },
        {
          id: 1,
          label: "Drift, but doesn't hit bed",
          value: 1,
        },
        {
          id: 2,
          label: "Drift, hits bed",
          value: 2,
        },
        {
          id: 3,
          label: "Some effort against gravity",
          value: 2,
        },
        {
          id: 4,
          label: "No effort against gravity",
          value: 3,
        },
        {
          id: 5,
          label: "No movement",
          value: 4,
        },
        {
          id: 6,
          label: "Amputation/joint fusion",
          value: 0,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.LEFT_LEG_MOTOR_DRIFT,
      description:
        "Count out loud and use your fingers to show the patient your count",
      options: [
        {
          id: 0,
          label: "No drift for 5 seconds",
          value: 0,
        },
        {
          id: 1,
          label: "Drift, but doesn't hit bed",
          value: 1,
        },
        {
          id: 2,
          label: "Drift, hits bed",
          value: 2,
        },
        {
          id: 3,
          label: "Some effort against gravity",
          value: 2,
        },
        {
          id: 4,
          label: "No effort against gravity",
          value: 3,
        },
        {
          id: 5,
          label: "No movement",
          value: 4,
        },
        {
          id: 6,
          label: "Amputation/joint fusion",
          value: 0,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.RIGHT_LEG_MOTOR_DRIFT,
      description:
        "Count out loud and use your fingers to show the patient your count",
      options: [
        {
          id: 0,
          label: "No drift for 5 seconds",
          value: 0,
        },
        {
          id: 1,
          label: "Drift, but doesn't hit bed",
          value: 1,
        },
        {
          id: 2,
          label: "Drift, hits bed",
          value: 2,
        },
        {
          id: 3,
          label: "Some effort against gravity",
          value: 2,
        },
        {
          id: 4,
          label: "No effort against gravity",
          value: 3,
        },
        {
          id: 5,
          label: "No movement",
          value: 4,
        },
        {
          id: 6,
          label: "Amputation/joint fusion",
          value: 0,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.LIMB_ATAXIA,
      description: "FNF/heel-shin",
      options: [
        {
          id: 0,
          label: "No ataxia",
          value: 0,
        },
        {
          id: 1,
          label: "Ataxia in 1 Limb",
          value: 1,
        },
        {
          id: 2,
          label: "Ataxia in 2 Limbs",
          value: 2,
        },
        {
          id: 3,
          label: "Does not understand",
          value: 0,
        },
        {
          id: 4,
          label: "Paralyzed",
          value: 0,
        },
        {
          id: 5,
          label: "Amputation/joint fusion",
          value: 0,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.SENSATION,
      options: [
        {
          id: 0,
          label: "Normal; no sensory loss",
          value: 0,
        },
        {
          id: 1,
          label: "Mild-moderate loss: less sharp/more dull ",
          value: 1,
        },
        {
          id: 2,
          label: "Mild-moderate loss: can sense being touched ",
          value: 1,
        },
        {
          id: 3,
          label: "Complete loss: cannot sense being touched at all",
          value: 2,
        },
        {
          id: 4,
          label: "No response and quadriplegic",
          value: 2,
        },
        {
          id: 5,
          label: "Coma/unresponsive",
          value: 2,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.LANGUAGE_OR_APHASIA,
      description: "Describe the scene; name the items; read the sentences",
      options: [
        {
          id: 0,
          label: "Normal; no aphasia",
          value: 0,
        },
        {
          id: 1,
          label:
            "Mild-moderate aphasia: some obvious changes, without significant limitation",
          value: 1,
        },
        {
          id: 2,
          label:
            "Severe aphasia: fragmentary expression, inference needed, cannot identify materials",
          value: 2,
        },
        {
          id: 3,
          label: "Mute/global aphasia: no usable speech/auditory comprehension",
          value: 3,
        },
        {
          id: 4,
          label: "Coma/unresponsive",
          value: 3,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.DYSARTHRIA,
      description: "Read the words",
      options: [
        {
          id: 0,
          label: "Normal",
          value: 0,
        },
        {
          id: 1,
          label: "Mild-moderate dysarthria: slurring but can be understood",
          value: 1,
        },
        {
          id: 2,
          label:
            "Severe dysarthria: unintelligible slurring or out of proportion to dysphasia",
          value: 2,
        },
        {
          id: 3,
          label: "Mute/anarthric",
          value: 2,
        },
        {
          id: 4,
          label: "Intubated/unable to test",
          value: 0,
        },
      ],
    },
    {
      name: NIHSSCalcSubSections.EXTINCTION_OR_INATTENTION,
      options: [
        {
          id: 0,
          label: "No abnormality",
          value: 0,
        },
        {
          id: 1,
          label: "Visual/tactile/auditory/spatial/personal inattention",
          value: 1,
        },
        {
          id: 2,
          label: "Extinction to bilateral simultaneous stimulation",
          value: 1,
        },
        {
          id: 3,
          label: "Profound hemi-inattention (ex: does not recognize own hand)",
          value: 2,
        },
        {
          id: 4,
          label: "Extinction to >1 modality",
          value: 2,
        },
      ],
    },
  ],
};

export const CDTRecursiveSections = {
  PECARN: "PECARN Pediatric Head Injury/Trauma Algorithm",
};

export const cdtRecursiveSectionsData = {
  [CDTRecursiveSections.PECARN]: {
    name: "Age",
    options: [
      {
        id: 0,
        label: "<2 Years",
        section: {
          name: "GCS ≤14, palpable skull fracture or signs of AMS",
          description:
            "AMS: Agitation, somnolence, repetitive questioning, or slow response to verbal communication",
          options: [
            {
              id: 0,
              label: "No",
              section: {
                name: "Occipital, parietal or temporal scalp hematoma; history of LOC ≥5 sec; not acting normally per parent or severe mechanism of injury?",
                description:
                  "Severe mechanism: MVC with patient ejection, death of another passenger, rollover; pedestrian or bicyclist w/o helmet struck by motorized vehicle; fall from >0.9m or 3ft; head struck by high-impact object",
                options: [
                  {
                    id: 0,
                    label: "No",
                    result:
                      "PECARN recommends No CT; Risk of ciTBI <0.02%, “Exceedingly Low, generally lower than risk of CT-induced malignancies.",
                  },
                  {
                    id: 1,
                    label: "Yes",
                    result:
                      "PECARN recommends observation over imaging, depending on provider comfort; 0.9% risk of clinically important Traumatic Brain Injury.\n\nConsider the following when making imaging decisions: Physician experience, worsening signs/symptoms during observation period, age <3 months, parent preference, multiple vs. isolated findings: patients with certain isolated findings (i.e., no other findings suggestive of TBI), such as isolated LOC, isolated headache, isolated vomiting, and certain types of isolated scalp hematomas in infants >3 months have ciTBI risk substantially <1%.",
                  },
                ],
              },
            },
            {
              id: 1,
              label: "Yes",
              result:
                "PECARN recommends CT; 4.4% risk of clinically important Traumatic Brain Injury.",
            },
          ],
        },
      },
      {
        id: 1,
        label: "≥2 Years",
        section: {
          name: "GCS ≤14 or signs of basilar skull fracture or signs of AMS",
          description:
            "AMS: Agitation, somnolence, repetitive questioning, or slow response to verbal communication",
          options: [
            {
              id: 0,
              label: "No",
              section: {
                name: "History of LOC or history of vomiting or severe headache or severe mechanism of injury",
                description:
                  "Motor vehicle crash with patient ejection, death of another passenger, or rollover; pedestrian or bicyclist without helmet struck by a motorized vehicle; falls of more than 1.5m/5ft; head struck by a high-impact object",
                options: [
                  {
                    id: 0,
                    label: "No",
                    result:
                      "PECARN recommends No CT; Risk <0.05%, “Exceedingly Low, generally lower than risk of CT-induced malignancies.",
                  },
                  {
                    id: 1,
                    label: "Yes",
                    result:
                      "PECARN recommends observation over imaging, depending on provider comfort; 0.9% risk of clinically important Traumatic Brain Injury.\n\nConsider the following when making imaging decisions: Physician experience, worsening signs/symptoms during observation period, age <3 months, parent preference, multiple vs. isolated findings: patients with certain isolated findings (i.e., no other findings suggestive of TBI), such as isolated LOC, isolated headache, isolated vomiting, and certain types of isolated scalp hematomas in infants >3 months have ciTBI risk substantially <1%.",
                  },
                ],
              },
            },
            {
              id: 1,
              label: "Yes",
              result:
                "PECARN recommends CT; 4.3% risk of clinically important Traumatic Brain Injury.",
            },
          ],
        },
      },
    ],
  },
};

// export type CDTSectionScoreRanges = {
//   min: number;
//   max?: number;
//   description: string;
// }[];

export const scoreRanges = {
  [CDTSections.HEART]: [
    {
      min: 0,
      max: 3,
      description: "Low Score (0-3 points)\n\nRisk of MACE of 0.9-1.7%.",
    },
    {
      min: 4,
      max: 6,
      description:
        "Moderate Score (4-6 points)\n\nRisk of MACE of 12-16.6%.\n\nIf EKG is highly suspicious, many experts recommend further workup and admission even with a low HEART Score.",
    },
    {
      min: 7,
      description: "High Score (7-10 points)\n\nRisk of MACE of 50-65%.",
    },
  ],
  [CDTSections.NIHSS]: [
    {
      min: 0,
      description: "NIH Stroke Scale",
    },
  ],
};

export function calculatePointsResult(
  pointResult,
  calculatorOptions,
  cdtScoreRanges
) {
  let totalPoints = 0;

  for (const calculatorOption of calculatorOptions) {
    for (const option of calculatorOption.options) {
      if (option.id === pointResult[calculatorOption.name]) {
        totalPoints += option.value;
      }
    }
  }

  for (const rangeData of cdtScoreRanges) {
    const { min, max, description } = rangeData;
    if (totalPoints >= min && totalPoints <= (max ?? Infinity)) {
      return { totalPoints, description };
    }
  }

  return { totalPoints, description: "" };
}
