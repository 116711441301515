//import Head from "next/head";
import { SignIn } from "@clerk/clerk-react";
import { useEffect } from "react";

const SignInPage = () => {
  useEffect(() => {
    // analytics.reset();
  }, []);

  return (
    <>
      {/* <Head>
        <title>Time by DrH - Sign In</title>
      </Head> */}

      <div className="flex h-screen min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <SignIn
            // signUpFallbackRedirectUrl="/home"
            // fallbackRedirectUrl="/home"
            />
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/drhaydar_banner.png"
            alt="Dr. Haydar banner"
          />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
