import { useState } from "react";

export function useCopyToClipboard(content) {
  const [copied, setCopied] = useState(false);
  const copyHandler = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content);
    } else {
      console.log("Copying content to clipboard", content);
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  return { copyHandler, copied };
}
