import { ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import TextEditor from './TextEditor';
import { useCopyToClipboard } from '../hooks/useCopyFromClipBoard';
import { useDispatch, useSelector } from 'react-redux';
import { updateNoteDetails } from '../slices/noteDetialsSlice';
import DraftJsEditor from './NewTextArea';
import { getCopyContentFromValue } from '../utils';
import NewNoteDetailsContent from './newNoteDetailsContent/NewNoteDetailsContent';
import { enqueueSnackbar } from 'notistack';

const TextArealayout = React.memo(({id, excludedHeadings, handleExcludedHeadings,title, noteKey}) => {
  const [showContent, setShowContent] = React.useState(true);
  const noteDetails = useSelector(state => state.noteDetails.noteDetails)
  const { copyHandler } = useCopyToClipboard(noteDetails[noteKey] ? noteDetails[noteKey].reduce((prev,value) => prev + getCopyContentFromValue(value) + '\n','') : '');



  return (
    <Box border="1px solid #2E6FF3" borderRadius={2}>
        <Box sx={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} display="flex" justifyContent="space-between" alignItems="center" bgcolor="#2E6FF3" padding="5px 10px">
            <Typography color='white' variant="h6">{title}</Typography>
            <Box>
              <IconButton sx={{ color: 'white'}} onClick={()=> setShowContent((prev)=> !prev)}>
                {showContent ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            <IconButton onClick={()=>{
              copyHandler(noteDetails[noteKey])
              enqueueSnackbar({
                variant: 'success',
                message: "content has been copied"
              })
            }} sx={{ color: 'white'}}>
                <ContentCopy />
              </IconButton>
            </Box>
          </Box>
        {/* {
          showContent && <DraftJsEditor id={id} value={noteDetails[noteKey]}  handleChange={(value)=>dispatch(updateNoteDetails({key: noteKey, value }))} />
        } */}
        {
          showContent && <NewNoteDetailsContent excludedHeadings={excludedHeadings} handleExcludedHeadings={handleExcludedHeadings} id={id} noteDetailType={noteKey} />
        }
      </Box>
  )
});

export default TextArealayout