import { useSortable } from '@dnd-kit/sortable';
import { BlurOn, DragIndicator } from '@mui/icons-material';
import { Card, FormControlLabel } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import React from 'react'
import { enqueueSnackbar } from 'notistack';

const DragAbleControlOptionGroup = React.memo(({onChange, color, disabled,option, index, ControlComponent}) => {

  const { setNodeRef, transform, transition, attributes, listeners } = useSortable({
    id: option.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

        return (
            <Card style={style} sx={{ display: 'flex', gap: 0.5, alignItems: 'center',margin: '5px 0px', padding: '15px 10px'}} variant='outlined'>
            <FormControlLabel
            sx={{ flexGrow: 1}}
            key={index}
            onClick={()=>{
              if(option?.alwaysChecked){
                enqueueSnackbar({
                  variant: 'warning',
                  message: "This section will always be included"
                })
              }
            }}
            control={
              <ControlComponent
                disabled={disabled || option?.alwaysChecked}
                color={color}
                checked={option.checked || option?.alwaysChecked}
                onChange={(e) => onChange(e.target.checked, option, index)}
              />
            }
            label={option.item}
          />
          <BlurOn ref={setNodeRef} {...attributes} {...listeners} sx={{ cursor: 'grab', color: 'grey'}} />
          </Card>
        )
})

export default DragAbleControlOptionGroup