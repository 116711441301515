export const MIPS = {
  cc_database: [
    {
      cc: "abdominal distention / mass",
      desc: "Unspecified abdominal distension",
    },
    {
      cc: "abnormal ekg result",
      desc: "Abnormal electrocardiogram [ECG] [EKG]",
    },
    {
      cc: "altered mental status (ams)",
      desc: "Delirium due to known physiological condition",
    },
    {
      cc: "animal bite",
      desc: "Bitten by dog, initial encounter",
    },
    {
      cc: "abdominal pain",
      desc: "Unspecified abdominal pain",
    },
    {
      cc: "abnormal lab result",
      desc: "Other general symptoms and signs",
    },
    {
      cc: "abnormal xray result",
      desc: "Abnormal findings on diagnostic imaging of body structures",
    },
    {
      cc: "abrasion or scratch",
      desc: "Abrasion of other part of head, initial encounter",
    },
    {
      cc: "alcohol use, abuse, and dependence",
      desc: "Alcohol use, unspecified",
    },
    {
      cc: "anorexia",
      desc: "Anorexia",
    },
    {
      cc: "anxiety and panic attack",
      desc: "Anxiety disorder, unspecified",
    },
    {
      cc: "apnea",
      desc: "Apnea, not elsewhere classified",
    },
    {
      cc: "arm pain",
      desc: "Pain in unspecified forearm",
    },
    {
      cc: "asthma attack",
      desc: "Unspecified asthma, uncomplicated",
    },
    {
      cc: "back pain",
      desc: "Dorsalgia, unspecified",
    },
    {
      cc: "bacterial infection",
      desc: "Bacterial infection, unspecified",
    },
    {
      cc: "bladder symptoms",
      desc: "Unspecified symptoms and signs involving the genitourinary system",
    },
    {
      cc: "bleeding disorder",
      desc: "Coagulation defect, unspecified",
    },
    {
      cc: "blood in stool",
      desc: "Melena",
    },
    {
      cc: "blood in urine",
      desc: "Hematuria, unspecified",
    },
    {
      cc: "body odor",
      desc: "Other general symptoms and signs",
    },
    {
      cc: "bone pain",
      desc: "Pain in unspecified bone",
    },
    {
      cc: "breathing difficulty",
      desc: "Shortness of breath",
    },
    {
      cc: "bruise",
      desc: "Superficial injury of thorax, unspecified, initial encounter",
    },
    {
      cc: "burn",
      desc: "Burn, unspecified",
    },
    {
      cc: "chest pain",
      desc: "Chest pain, unspecified",
    },
    {
      cc: "chills",
      desc: "Chills (without fever)",
    },
    {
      cc: "cold symptoms",
      desc: "Acute nasopharyngitis (common cold)",
    },
    {
      cc: "constipation",
      desc: "Constipation, unspecified",
    },
    {
      cc: "dehydration",
      desc: "Dehydration",
    },
    {
      cc: "depression",
      desc: "Major depressive disorder, single episode, unspecified",
    },
    {
      cc: "diarrhea",
      desc: "Diarrhea, unspecified",
    },
    {
      cc: "difficulty swallowing",
      desc: "Dysphagia, unspecified",
    },
    {
      cc: "dry skin",
      desc: "Xerosis cutis",
    },
    {
      cc: "ear discharge",
      desc: "Otorrhea, unspecified ear",
    },
    {
      cc: "ear fullness",
      desc: "Sensation of ear fullness",
    },
    {
      cc: "ear infection",
      desc: "Otitis media, unspecified, unspecified ear",
    },
    {
      cc: "edema",
      desc: "Edema, unspecified",
    },
    {
      cc: "fainting",
      desc: "Syncope and collapse",
    },
    {
      cc: "fatigue",
      desc: "Other fatigue",
    },
    {
      cc: "fever",
      desc: "Fever, unspecified",
    },
    {
      cc: "flank pain",
      desc: "Unspecified abdominal pain",
    },
    {
      cc: "foot pain",
      desc: "Pain in foot",
    },
    {
      cc: "foreign body in eye",
      desc: "Foreign body in unspecified part of eye, unspecified eye, initial encounter",
    },
    {
      cc: "foreign body ingestion",
      desc: "Foreign body in alimentary tract, part unspecified",
    },
    {
      cc: "fracture",
      desc: "Unspecified fracture of unspecified lower leg, initial encounter for closed fracture",
    },
    {
      cc: "gastroenteritis",
      desc: "Noninfective gastroenteritis and colitis, unspecified",
    },
    {
      cc: "headache",
      desc: "Headache",
    },
    {
      cc: "heart palpitations",
      desc: "Palpitations",
    },
    {
      cc: "hemorrhoids",
      desc: "Unspecified hemorrhoids",
    },
    {
      cc: "hepatitis",
      desc: "Unspecified viral hepatitis",
    },
    {
      cc: "hiv/aids",
      desc: "Human immunodeficiency virus [HIV] disease",
    },
    {
      cc: "hypertension",
      desc: "Essential (primary) hypertension",
    },
    {
      cc: "elevated blood pressure",
      desc: "",
    },
    {
      cc: "hypothermia",
      desc: "Hypothermia, initial encounter",
    },
    {
      cc: "incontinence",
      desc: "Unspecified urinary incontinence",
    },
    {
      cc: "influenza",
      desc: "Influenza, virus not identified",
    },
    {
      cc: "insect bite",
      desc: "Insect bite (nonvenomous) of anus and rectum, initial encounter",
    },
    {
      cc: "insomnia",
      desc: "Insomnia not due to a substance or known physiological condition",
    },
    {
      cc: "itching",
      desc: "Pruritus, unspecified",
    },
    {
      cc: "jaundice",
      desc: "Unspecified jaundice",
    },
    {
      cc: "joint pain",
      desc: "Pain in unspecified joint",
    },
    {
      cc: "kidney stone",
      desc: "Calculus of kidney",
    },
    {
      cc: "laceration",
      desc: "Unspecified open wound of unspecified part of head, initial encounter",
    },
    {
      cc: "leg pain",
      desc: "Pain in right leg",
    },
    {
      cc: "loss of consciousness",
      desc: "Unspecified coma",
    },
    {
      cc: "low blood sugar",
      desc: "Hypoglycemia, unspecified",
    },
    {
      cc: "low blood pressure",
      desc: "Hypotension, unspecified",
    },
    {
      cc: "lung disease",
      desc: "Respiratory disorder, unspecified",
    },
    {
      cc: "tick bite",
      desc: "Lyme disease, unspecified",
    },
    {
      cc: "malaria",
      desc: "Unspecified malaria",
    },
    {
      cc: "measles",
      desc: "Measles without complication",
    },
    {
      cc: "menstrual problems",
      desc: "Dysmenorrhea, unspecified",
    },
    {
      cc: "migraine",
      desc: "Migraine, unspecified, not intractable, without status migrainosus",
    },
    {
      cc: "mononucleosis",
      desc: "Infectious mononucleosis, unspecified",
    },
    {
      cc: "muscle pain",
      desc: "Myalgia",
    },
    {
      cc: "nausea and vomiting",
      desc: "Nausea with vomiting, unspecified",
    },
    {
      cc: "neck pain",
      desc: "Cervicalgia",
    },
    {
      cc: "nervousness",
      desc: "Anxiety disorder, unspecified",
    },
    {
      cc: "nosebleed (epistaxis)",
      desc: "Epistaxis",
    },
    {
      cc: "numbness",
      desc: "Anesthesia of skin",
    },
    {
      cc: "obesity",
      desc: "Obesity, unspecified",
    },
    {
      cc: "pain, unspecified",
      desc: "Pain, unspecified",
    },
    {
      cc: "palpable mass",
      desc: "Localized swelling, mass and lump, unspecified",
    },
    {
      cc: "parasitic infection",
      desc: "Unspecified parasitic disease",
    },
    {
      cc: "pelvic pain",
      desc: "Pelvic and perineal pain",
    },
    {
      cc: "pneumonia",
      desc: "Pneumonia, unspecified organism",
    },
    {
      cc: "poisoning",
      desc: "Toxic effect of unspecified substance, accidental (unintentional), initial encounter",
    },
    {
      cc: "rash",
      desc: "Rash and other nonspecific skin eruption",
    },
    {
      cc: "respiratory infection",
      desc: "Other specified respiratory disorders",
    },
    {
      cc: "seizure",
      desc: "Unspecified convulsions",
    },
    {
      cc: "sexually transmitted infection (sti)",
      desc: "Unspecified sexually transmitted disease",
    },
    {
      cc: "shoulder pain",
      desc: "Pain in unspecified shoulder",
    },
    {
      cc: "sinusitis",
      desc: "Chronic sinusitis, unspecified",
    },
    {
      cc: "skin infection",
      desc: "Unspecified local infection of the skin and subcutaneous tissue",
    },
    {
      cc: "sleep disorder",
      desc: "Sleep disorder, unspecified",
    },
    {
      cc: "sore throat",
      desc: "Acute pharyngitis, unspecified",
    },
    {
      cc: "sprains and strains",
      desc: "Sprain of unspecified ligament of unspecified ankle, initial encounter",
    },
    {
      cc: "stomach pain",
      desc: "Acute abdomen",
    },
    {
      cc: "stroke",
      desc: "Cerebral infarction, unspecified",
    },
    {
      cc: "suicidal ideation",
      desc: "Suicidal ideation",
    },
    {
      cc: "swelling",
      desc: "Edema, unspecified",
    },
    {
      cc: "tinnitus",
      desc: "Tinnitus, unspecified ear",
    },
    {
      cc: "toothache",
      desc: "Other specified disorders of teeth and supporting structures",
    },
    {
      cc: "tuberculosis",
      desc: "Respiratory tuberculosis, unspecified, bacteriological or histological examination not done",
    },
    {
      cc: "urinary tract infection (uti)",
      desc: "Urinary tract infection, site not specified",
    },
    {
      cc: "vaginal bleeding",
      desc: "Abnormal uterine and vaginal bleeding, unspecified",
    },
    {
      cc: "vaginal discharge",
      desc: "Other specified noninflammatory disorders of vagina",
    },
    {
      cc: "vertigo",
      desc: "Dizziness and giddiness",
    },
    {
      cc: "viral infection",
      desc: "Viral infection, unspecified",
    },
    {
      cc: "vision changes",
      desc: "Unspecified visual disturbance",
    },
    {
      cc: "vision problems",
      desc: "Unspecified visual disturbance",
    },
    {
      cc: "vomiting",
      desc: "Vomiting, unspecified",
    },
    {
      cc: "weight gain",
      desc: "Abnormal weight gain",
    },
    {
      cc: "weight loss",
      desc: "Abnormal weight loss",
    },
    {
      cc: "wheezing",
      desc: "Wheezing",
    },
    {
      cc: "wrist pain",
      desc: "Pain in unspecified wrist",
    },
    {
      cc: "yeast infection",
      desc: "Candidiasis of vulva and vagina",
    },
    {
      cc: "yellow fever",
      desc: "Yellow fever, unspecified",
    },
    {
      cc: "zika virus",
      desc: "Zika virus disease",
    },
    {
      cc: "trauma",
      desc: "Injury, unspecified",
    },
    {
      cc: "pain during urination (dysuria)",
      desc: "Dysuria",
    },
    {
      cc: "high blood sugar",
      desc: "Type 2 diabetes mellitus with hyperglycemia",
    },
    {
      cc: "flu-like symptoms",
      desc: "Influenza, virus not identified",
    },
    {
      cc: "difficulty walking",
      desc: "Difficulty in walking",
    },
    {
      cc: "disorientation",
      desc: "Disorientation, unspecified",
    },
    {
      cc: "leg symptoms",
      desc: "Pain in unspecified leg",
    },
    {
      cc: "complications of surgical or medical procedures and treatments",
      desc: "Complication of procedure, unspecified, initial encounter",
    },
    {
      cc: "injuries due to falls or accidents",
      desc: "Unspecified fall, initial encounter",
    },
    {
      cc: "palpitations or irregular heartbeats",
      desc: "Palpitations",
    },
    {
      cc: "rashes and skin infections",
      desc: "Dermatitis, unspecified",
    },
    {
      cc: "eye irritation or redness",
      desc: "Unspecified disorders of eye and adnexa",
    },
    {
      cc: "abnormal bleeding",
      desc: "Coagulation defect, unspecified",
    },
    {
      cc: "asthma exacerbation",
      desc: "Asthma, unspecified, with (acute) exacerbation",
    },
    {
      cc: "appendicitis",
      desc: "Acute appendicitis, unspecified",
    },
    {
      cc: "panic attacks",
      desc: "Panic disorder [episodic paroxysmal anxiety]",
    },
    {
      cc: "gallbladder issues (cholecystitis)",
      desc: "Cholecystitis, unspecified",
    },
    {
      cc: "burns",
      desc: "Burn, unspecified",
    },
    {
      cc: "severe allergic reactions (anaphylaxis)",
      desc: "Anaphylactic shock, unspecified, initial encounter",
    },
    {
      cc: "testicular pain",
      desc: "Other specified disorders of male genital organs",
    },
    {
      cc: "foreign body",
      desc: "Foreign body in unspecified part of eye, unspecified eye, initial encounter",
    },
    {
      cc: "motor vehicle accident (mva)",
      desc: "Person injured in unspecified motor-vehicle accident, traffic",
    },
    {
      cc: "acute and unspecified renal failure",
      desc: "Acute kidney failure, unspecified",
    },
    {
      cc: "cerebral infarction",
      desc: "Cerebral infarction, unspecified",
    },
    {
      cc: "skin and subcutaneous tissue infection",
      desc: "Unspecified local infection of the skin and subcutaneous tissue",
    },
    {
      cc: "pulmonary embolism (pe)",
      desc: "Other pulmonary embolism without acute cor pulmonale",
    },
    {
      cc: "deep vein thrombosis (dvt)",
      desc: "Acute embolism and thrombosis of unspecified deep veins of right lower extremity",
    },
    {
      cc: "muscle weakness",
      desc: "Muscle weakness (generalized)",
    },
    {
      cc: "muscle cramps",
      desc: "Cramp and spasm",
    },
    {
      cc: "tremors",
      desc: "Tremor, unspecified",
    },
    {
      cc: "unexplained bruising",
      desc: "Spontaneous ecchymoses",
    },
    {
      cc: "changes in appetite",
      desc: "Anorexia",
    },
    {
      cc: "unintended weight gain",
      desc: "Obesity, unspecified",
    },
    {
      cc: "polydipsia (excessive thirst)",
      desc: "Polydipsia",
    },
    {
      cc: "polyuria (excessive urination)",
      desc: "Other polyuria",
    },
    {
      cc: "polyphagia (excessive hunger)",
      desc: "Polyphagia",
    },
    {
      cc: "nightmares",
      desc: "Nightmare disorder",
    },
    {
      cc: "sleep apnea",
      desc: "Sleep apnea, unspecified",
    },
    {
      cc: "snoring",
      desc: "Snoring",
    },
    {
      cc: "hair loss",
      desc: "Nonscarring hair loss, unspecified",
    },
    {
      cc: "excessive sweating",
      desc: "Generalized hyperhidrosis",
    },
    {
      cc: "hot flashes",
      desc: "Menopausal and female climacteric states",
    },
    {
      cc: "cold intolerance",
      desc: "Hypothyroidism, unspecified",
    },
    {
      cc: "heat intolerance",
      desc: "Hyperthyroidism, unspecified",
    },
    {
      cc: "irregular menstrual cycle",
      desc: "Irregular menstruation, unspecified",
    },
    {
      cc: "menorrhagia (heavy menstrual bleeding)",
      desc: "Heavy and/or prolonged menstrual periods",
    },
    {
      cc: "amenorrhea (absence of menstrual periods)",
      desc: "Secondary amenorrhea",
    },
    {
      cc: "dysmenorrhea (painful menstrual periods)",
      desc: "Dysmenorrhea, unspecified",
    },
    {
      cc: "menopausal symptoms",
      desc: "Menopausal and female climacteric states",
    },
    {
      cc: "erectile dysfunction",
      desc: "Male erectile dysfunction, unspecified",
    },
    {
      cc: "premature ejaculation",
      desc: "Premature ejaculation",
    },
    {
      cc: "infertility",
      desc: "Female infertility, unspecified",
    },
    {
      cc: "discharge from nipple",
      desc: "Galactorrhea, unspecified",
    },
    {
      cc: "breast lump",
      desc: "Unspecified lump in breast",
    },
    {
      cc: "breast pain",
      desc: "Mastodynia",
    },
    {
      cc: "changes in mole appearance",
      desc: "Other melanin hyperpigmentation",
    },
    {
      cc: "new skin lesions",
      desc: "Disorder of the skin and subcutaneous tissue, unspecified",
    },
    {
      cc: "nail changes",
      desc: "Nail disorder, unspecified",
    },
    {
      cc: "nose congestion",
      desc: "Other specified disorders of nose and nasal sinuses",
    },
    {
      cc: "sinus pain",
      desc: "Chronic sinusitis, unspecified",
    },
    {
      cc: "loss of smell",
      desc: "Anosmia",
    },
    {
      cc: "loss of taste",
      desc: "Other disturbances of smell and taste",
    },
    {
      cc: "black stools",
      desc: "Melena",
    },
    {
      cc: "clay-colored stools",
      desc: "Other feces abnormalities",
    },
    {
      cc: "hematemesis (vomiting blood)",
      desc: "Hematemesis",
    },
    {
      cc: "bloating",
      desc: "Abdominal distension (gaseous)",
    },
    {
      cc: "flatulence",
      desc: "Flatulence",
    },
    {
      cc: "halitosis (bad breath)",
      desc: "Halitosis",
    },
    {
      cc: "arthralgia (joint pain)",
      desc: "Pain in unspecified joint",
    },
    {
      cc: "myalgia (muscle pain)",
      desc: "Myalgia",
    },
    {
      cc: "tingling or numbness",
      desc: "Anesthesia of skin",
    },
    {
      cc: "restless leg syndrome",
      desc: "Restless legs syndrome",
    },
    {
      cc: "frequent infections",
      desc: "Asymptomatic human immunodeficiency virus [HIV] infection status",
    },
    {
      cc: "slow wound healing",
      desc: "Chronic skin ulcer, unspecified",
    },
    {
      cc: "enlarged spleen",
      desc: "Splenomegaly, unspecified",
    },
    {
      cc: "excessive salivation or drooling",
      desc: "Asphyxia",
    },
    {
      cc: "dry mouth",
      desc: "Xerostomia",
    },
    {
      cc: "unexplained weight gain",
      desc: "Obesity, unspecified",
    },
    {
      cc: "change in voice or hoarseness",
      desc: "Dysphonia",
    },
    {
      cc: "difficulty sleeping or insomnia",
      desc: "Insomnia not due to a substance or known physiological condition, unspecified",
    },
    {
      cc: "night sweats",
      desc: "Hyperhidrosis, unspecified",
    },
    {
      cc: "excessive daytime sleepiness",
      desc: "Hypersomnia, unspecified",
    },
    {
      cc: "changes in skin color",
      desc: "Disorder of pigmentation, unspecified",
    },
    {
      cc: "unusual or foul body odor",
      desc: "Personal history of sudden cardiac arrest",
    },
    {
      cc: "difficulty hearing or hearing loss",
      desc: "Unspecified hearing loss",
    },
    {
      cc: "ringing or buzzing in the ears",
      desc: "Tinnitus, bilateral",
    },
    {
      cc: "discharge from the ears",
      desc: "Other otalgia",
    },
    {
      cc: "change in vision or blurred vision",
      desc: "Other visual disturbances",
    },
    {
      cc: "sensitivity to light or photophobia",
      desc: "Photophobia, unspecified eye",
    },
    {
      cc: "difficulty focusing on close objects",
      desc: "Hypermetropia, right eye",
    },
    {
      cc: "flashes or floaters in vision",
      desc: "Other visual disturbances",
    },
    {
      cc: "excessive tearing or watery eyes",
      desc: "Unspecified lacrimal gland, bilateral",
    },
    {
      cc: "dry eyes",
      desc: "Dry eye syndrome, unspecified eye",
    },
    {
      cc: "discharge from the eyes",
      desc: "Unspecified conjunctivitis",
    },
    {
      cc: "nose congestion or stuffiness",
      desc: "Other specified disorders of nose and nasal sinuses",
    },
    {
      cc: "loss of sense of smell",
      desc: "Anosmia",
    },
    {
      cc: "metallic taste in the mouth",
      desc: "Other disturbances of smell and taste",
    },
    {
      cc: "difficulty swallowing or dysphagia",
      desc: "Dysphagia, unspecified",
    },
    {
      cc: "heartburn or acid reflux",
      desc: "Gastro-esophageal reflux disease without esophagitis",
    },
    {
      cc: "belching or burping",
      desc: "Flatulence",
    },
    {
      cc: "flatulence or passing gas",
      desc: "Flatulence",
    },
    {
      cc: "bloating or feeling of fullness",
      desc: "Abdominal distension (gaseous)",
    },
    {
      cc: "change in appetite or loss of appetite",
      desc: "Anorexia",
    },
    {
      cc: "thirst or excessive thirst",
      desc: "Syndrome of inappropriate secretion of antidiuretic hormone",
    },
    {
      cc: "swelling or lumps in the armpits",
      desc: "Localized swelling, mass and lump, right upper limb",
    },
    {
      cc: "swelling or lumps in the groin",
      desc: "Localized swelling, mass and lump, left lower limb",
    },
    {
      cc: "pain or discomfort during sexual intercourse",
      desc: "Dyspareunia",
    },
    {
      cc: "irregular menstrual periods",
      desc: "Irregular menstruation, unspecified",
    },
    {
      cc: "excessive menstrual bleeding",
      desc: "Excessive bleeding in the premenopausal period",
    },
    {
      cc: "absence of menstrual periods",
      desc: "Amenorrhea, unspecified",
    },
    {
      cc: "hot flashes or night sweats",
      desc: "Menopausal and female climacteric states",
    },
    {
      cc: "vaginal dryness",
      desc: "Postmenopausal bleeding",
    },
    {
      cc: "discharge from the penis",
      desc: "Balanoposthitis",
    },
    {
      cc: "erectile difficulties",
      desc: "Male erectile dysfunction, unspecified",
    },
    {
      cc: "premature or delayed ejaculation",
      desc: "Delayed ejaculation",
    },
    {
      cc: "reduced sexual desire or libido",
      desc: "Hypoactive sexual desire disorder",
    },
    {
      cc: "pain or discomfort in the testicles",
      desc: "Other specified disorders of male genital organs",
    },
    {
      cc: "nipple discharge",
      desc: "Galactorrhea, not associated with childbirth",
    },
    {
      cc: "breast lumps or masses",
      desc: "Unspecified lump in breast",
    },
    {
      cc: "change in the size or shape of the breasts",
      desc: "Mastodynia",
    },
    {
      cc: "skin rash",
      desc: "Urticaria, unspecified",
    },
    {
      cc: "itching or pruritus",
      desc: "Pruritus, unspecified",
    },
    {
      cc: "dry or scaly skin",
      desc: "Xerosis cutis",
    },
    {
      cc: "yellowing of the skin or eyes",
      desc: "Unspecified jaundice",
    },
    {
      cc: "bruising easily",
      desc: "Spontaneous ecchymoses",
    },
    {
      cc: "cuts or wounds that don't heal",
      desc: "Chronic skin ulcer, unspecified",
    },
    {
      cc: "shortness of breath with exertion",
      desc: "Shortness of breath",
    },
    {
      cc: "wheezing or noisy breathing",
      desc: "Wheezing",
    },
    {
      cc: "hemoptysis (coughing up blood)",
      desc: "Hemoptysis",
    },
    {
      cc: "palpitations or skipped beats",
      desc: "Palpitations",
    },
    {
      cc: "lower extremity edema",
      desc: "Localized edema",
    },
    {
      cc: "cold hands or feet",
      desc: "Other skin changes",
    },
    {
      cc: "abdominal cramping",
      desc: "Peripheral vascular disease, unspecified",
    },
    {
      cc: "shortness of breath at night",
      desc: "Shortness of breath",
    },
    {
      cc: "needing to sleep with more than one pillow",
      desc: "Shortness of breath",
    },
    {
      cc: "urinary frequency (polyuria)",
      desc: "Other polyuria",
    },
    {
      cc: "blood in the urine or hematuria",
      desc: "Hematuria, unspecified",
    },
    {
      cc: "painful urination or dysuria",
      desc: "Dysuria",
    },
    {
      cc: "frequent urinary tract infections",
      desc: "Urinary tract infection, site not specified",
    },
    {
      cc: "change in the color or consistency of the urine",
      desc: "Other abnormal findings in urine",
    },
    {
      cc: "increased thirst or hunger",
      desc: "Drug-induced hypoglycemia without coma",
    },
    {
      cc: "tingling or numbness in the hands or feet",
      desc: "Disorders of peripheral nerves",
    },
    {
      cc: "blurred vision or changes in vision",
      desc: "Other visual disturbances",
    },
    {
      cc: "fatigue or tiredness",
      desc: "Other fatigue",
    },
    {
      cc: "dry or itchy skin",
      desc: "Pruritus, unspecified",
    },
    {
      cc: "unexplained weight loss or gain",
      desc: "Abnormal weight loss",
    },
    {
      cc: "change in bowel habits or diarrhea",
      desc: "Diarrhea, unspecified",
    },
    {
      cc: "blood in the stool or black stools",
      desc: "Melena",
    },
    {
      cc: "difficulty swallowing or pain with swallowing",
      desc: "Dysphagia, unspecified",
    },
    {
      cc: "swelling in the abdomen",
      desc: "Edema, unspecified",
    },
    {
      cc: "easy bruising or bleeding",
      desc: "Coagulation defect, unspecified",
    },
    {
      cc: "persistent itching or pruritus",
      desc: "Pruritus, unspecified",
    },
    {
      cc: "bone pain or fractures",
      desc: "Pain in unspecified ankle and joints of unspecified foot",
    },
    {
      cc: "fatigue or weakness",
      desc: "Other fatigue",
    },
    {
      cc: "shortness of breath",
      desc: "Shortness of breath",
    },
    {
      cc: "night sweats or fevers",
      desc: "Fever presenting with conditions classified elsewhere",
    },
    {
      cc: "excessive hair growth (hirsutism)",
      desc: "Hirsutism",
    },
    {
      cc: "unusual moles or skin tags",
      desc: "Other hypertrophic disorders of the skin",
    },
    {
      cc: "change in shoe size",
      desc: "Stiffness of unspecified ankle and joints of unspecified foot, not elsewhere classified",
    },
    {
      cc: "difficulty with balance",
      desc: "Unsteadiness on feet",
    },
    {
      cc: "lockjaw or trismus",
      desc: "Temporomandibular joint disorders, unspecified",
    },
    {
      cc: "excessive drooling",
      desc: "Asphyxia",
    },
    {
      cc: "change in nail appearance",
      desc: "Nail disorder, unspecified",
    },
    {
      cc: "unexplained chills",
      desc: "Chills (without fever)",
    },
    {
      cc: "enlarged breasts in men",
      desc: "Hypertrophy of breast",
    },
    {
      cc: "chest tightness or pressure",
      desc: "Chest pain, unspecified",
    },
    {
      cc: "pain or cramping in the legs with walking",
      desc: "Peripheral vascular disease, unspecified",
    },
    {
      cc: "urgency to urinate",
      desc: "Urgency of urination",
    },
    {
      cc: "incontinence or loss of bladder control",
      desc: "Unspecified urinary incontinence",
    },
    {
      cc: "difficulty starting or stopping the flow of urine",
      desc: "Feeling of incomplete bladder emptying",
    },
    {
      cc: "dribbling after urination",
      desc: "Post-void dribbling",
    },
    {
      cc: "swelling of the gums",
      desc: "Other specified disorders of gingiva and edentulous alveolar ridge",
    },
    {
      cc: "bad breath or halitosis",
      desc: "Halitosis",
    },
    {
      cc: "difficulty with coordination",
      desc: "Ataxia, unspecified",
    },
    {
      cc: "slurred speech",
      desc: "Slurred speech",
    },
    {
      cc: "difficulty remembering recent events",
      desc: "Other amnesia",
    },
    {
      cc: "unusual or foul-smelling urine",
      desc: "Abnormal odor of urine",
    },
    {
      cc: "change in frequency of headaches",
      desc: "Other headache syndrome",
    },
    {
      cc: "unexplained muscle twitching",
      desc: "Fasciculation",
    },
    {
      cc: "difficulty raising arms above head",
      desc: "Pain in unspecified shoulder",
    },
    {
      cc: "change in handwriting",
      desc: "Parkinson's disease",
    },
    {
      cc: "difficulty buttoning shirts or tying shoes",
      desc: "Parkinson's disease",
    },
    {
      cc: "excessive or unusual snoring",
      desc: "Sleep apnea, unspecified",
    },
    {
      cc: "sudden onset of acne or skin changes",
      desc: "Acne vulgaris",
    },
    {
      cc: "change in hair texture or color",
      desc: "Hair color and hair shaft changes, unspecified",
    },
    {
      cc: "unusual thirst or hunger",
      desc: "Drug-induced hypoglycemia without coma",
    },
    {
      cc: "difficulty recognizing familiar faces",
      desc: "Mild cognitive impairment, so stated",
    },
    {
      cc: "sensation of something stuck in the throat",
      desc: "Chest pain, unspecified",
    },
    {
      cc: "unusual or persistent foot odor",
      desc: "Other general symptoms and signs",
    },
    {
      cc: "craving non-food items (pica)",
      desc: "Other eating disorders",
    },
    {
      cc: "excessive yawning",
      desc: "Yawning",
    },
    {
      cc: "sensation of crawling skin",
      desc: "Pruritus, unspecified",
    },
    {
      cc: "unusual or vivid dreams",
      desc: "Nightmare disorder",
    },
    {
      cc: "change in voice pitch",
      desc: "Dysphonia",
    },
    {
      cc: "sensation of spinning when stationary",
      desc: "Dizziness and giddiness",
    },
    {
      cc: "difficulty reading small print",
      desc: "Presbyopia, unspecified eye",
    },
    {
      cc: "sensitivity to sounds",
      desc: "Other tinnitus, unspecified ear",
    },
    {
      cc: "persistent hiccups",
      desc: "Hiccough",
    },
    {
      cc: "change in the shape or size of pupils",
      desc: "Anisocoria, unspecified eye",
    },
    {
      cc: "difficulty understanding spoken words",
      desc: "Aphasia",
    },
    {
      cc: "unusual cravings or aversions to food",
      desc: "Other specified eating disorders",
    },
    {
      cc: "sensation of fullness in the ears",
      desc: "Tinnitus, bilateral",
    },
    {
      cc: "unexplained change in height",
      desc: "Repeated falls",
    },
    {
      cc: "persistent or unusual body odor",
      desc: "Other general symptoms and signs",
    },
    {
      cc: "difficulty swallowing pills",
      desc: "Dysphagia, unspecified",
    },
    {
      cc: "sensation of warmth or heat in a limb",
      desc: "Paresthesia of skin",
    },
    {
      cc: "change in the pattern of moles",
      desc: "Melanocytic nevus, unspecified",
    },
    {
      cc: "unusual or persistent itching without a rash",
      desc: "Pruritus, unspecified",
    },
    {
      cc: "sensation of cold in a limb",
      desc: "Hypoesthesia of skin",
    },
    {
      cc: "difficulty understanding written words",
      desc: "Dyslexia and alexia",
    },
    {
      cc: "unusual sensitivity to cold temperatures",
      desc: "Chilblains, unspecified",
    },
    {
      cc: "persistent or unusual taste in the mouth",
      desc: "Other disturbances of smell and taste",
    },
    {
      cc: "unexplained change in weight without diet or exercise changes",
      desc: "Abnormal weight loss",
    },
    {
      cc: "sensation of pressure in the head",
      desc: "Headache",
    },
    {
      cc: "difficulty turning the head side to side",
      desc: "Cervicalgia",
    },
    {
      cc: "unusual or persistent itching of the eyes",
      desc: "Other acute conjunctivitis",
    },
    {
      cc: "sensation of grit or sand in the eyes",
      desc: "Other superficial keratitis without conjunctivitis, unspecified eye",
    },
    {
      cc: "difficulty focusing on distant objects",
      desc: "Hypermetropia, right eye",
    },
    {
      cc: "unusual or excessive tearing of the eyes",
      desc: "Epiphora, unspecified, bilateral",
    },
    {
      cc: "swelling or lumps in the neck",
      desc: "Localized swelling, mass and lump, head",
    },
    {
      cc: "mouth pain",
      desc: "Glossodynia",
    },
    {
      cc: "nose pain",
      desc: "Epistaxis",
    },
    {
      cc: "ear pain",
      desc: "Otalgia, left ear",
    },
    {
      cc: "eye pain",
      desc: "Pain in the eye",
    },
    {
      cc: "elbow pain",
      desc: "Pain in right elbow",
    },
    {
      cc: "hip pain",
      desc: "Pain in left hip",
    },
    {
      cc: "knee pain",
      desc: "Pain in left knee",
    },
    {
      cc: "hand pain",
      desc: "Pain in left hand",
    },
    {
      cc: "rib pain",
      desc: "Other specified soft tissue disorders",
    },
    {
      cc: "thumb pain",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "index finger pain",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "index finger injury",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "middle finger pain",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "middle finger injury",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "ring finger pain",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "little finger pain",
      desc: "Pain in left hand, initial encounter",
    },
    {
      cc: "big toe pain",
      desc: "Pain in left foot, not elsewhere classified",
    },
    {
      cc: "second toe pain",
      desc: "Pain in left foot, not elsewhere classified",
    },
    {
      cc: "third toe pain",
      desc: "Pain in left foot, not elsewhere classified",
    },
    {
      cc: "fourth toe pain",
      desc: "Pain in left foot, not elsewhere classified",
    },
    {
      cc: "little toe pain",
      desc: "Pain in left foot, not elsewhere classified",
    },
    {
      cc: "toe pain",
      desc: "Pain in right foot, not elsewhere classified",
    },
    {
      cc: "forearm pain",
      desc: "Pain in left forearm",
    },
    {
      cc: "wrist injury",
      desc: "Pain in left wrist",
    },
    {
      cc: "eyebrow pain",
      desc: "Pain in the eye",
    },
    {
      cc: "nares pain",
      desc: "Epistaxis",
    },
    {
      cc: "swelling/edema",
      desc: "Edema, unspecified",
    },
    {
      cc: "allergic reaction",
      desc: "Allergy, unspecified, initial encounter",
    },
    {
      cc: "sexually transmitted diease (std)",
      desc: "Unspecified sexually transmitted disease",
    },
    {
      cc: "elbow infection",
      desc: "",
    },
    {
      cc: "surgical drain dislodgement",
      desc: "",
    },
    {
      cc: "unwitnessed fall",
      desc: "",
    },
    {
      cc: "light headedness",
      desc: "",
    },
    {
      cc: "trauma to eye",
      desc: "",
    },
    {
      cc: "diverticulitis",
      desc: "",
    },
    {
      cc: "facial swelling",
      desc: "",
    },
    {
      cc: "facial pain",
      desc: "",
    },
    {
      cc: "asymptomatic hypertension",
      desc: "",
    },
    {
      cc: "hyperglycemia",
      desc: "Type 2 diabetes mellitus with hyperglycemia",
    },
    {
      cc: "hypoglycemia",
      desc: "Hypoglycemia, unspecified",
    },
    {
      cc: "gout",
      desc: "",
    },
    {
      cc: "joint infection",
      desc: "Pyogenic arthritis, unspecified",
    },
    {
      cc: "head injury",
      desc: "",
    },
    {
      cc: "tia",
      desc: "",
    },
    {
      cc: "tooth abscess",
      desc: "",
    },
    {
      cc: "ankle injury",
      desc: "",
    },
    {
      cc: "back injury",
      desc: "",
    },
    {
      cc: "back ache",
      desc: "",
    },
    {
      cc: "hand injury",
      desc: "Pain in hand",
    },
    {
      cc: "neck injury",
      desc: "",
    },
    {
      cc: "something in ear",
      desc: "",
    },
    {
      cc: "something in nose",
      desc: "",
    },
    {
      cc: "stepped on nail",
      desc: "",
    },
    {
      cc: "bug bite",
      desc: "",
    },
    {
      cc: "insect sting",
      desc: "",
    },
    {
      cc: "overdose",
      desc: "",
    },
    {
      cc: "self injury",
      desc: "",
    },
    {
      cc: "broken tooth",
      desc: "",
    },
    {
      cc: "difficulty speaking",
      desc: "",
    },
    {
      cc: "knee injury",
      desc: "",
    },
    {
      cc: "shoulder injury",
      desc: "",
    },
    {
      cc: "cardiac arrest",
      desc: "",
    },
    {
      cc: "hernia",
      desc: "",
    },
    {
      cc: "shoulder dislocation",
      desc: "",
    },
    {
      cc: "hip dislocation",
      desc: "",
    },
    {
      cc: "finger dislocation",
      desc: "",
    },
    {
      cc: "ovarian cyst",
      desc: "",
    },
    {
      cc: "pilonidal cyst",
      desc: "",
    },
    {
      cc: "skin abscess",
      desc: "",
    },
    {
      cc: "electrocution & electric shock",
      desc: "",
    },
    {
      cc: "chemical exposure",
      desc: "",
    },
    {
      cc: "carbon monoxide poisioning",
      desc: "",
    },
    {
      cc: "smoke exposure",
      desc: "",
    },
    {
      cc: "heat exhaustion",
      desc: "",
    },
    {
      cc: "heat stroke",
      desc: "",
    },
    {
      cc: "croup",
      desc: "",
    },
    {
      cc: "spider bite",
      desc: "",
    },
    {
      cc: "boil",
      desc: "",
    },
    {
      cc: "meningitis",
      desc: "",
    },
    {
      cc: "sudden vision loss",
      desc: "",
    },
    {
      cc: "concussion",
      desc: "",
    },
    {
      cc: "drug reaction",
      desc: "",
    },
    {
      cc: "pancreatitis",
      desc: "",
    },
    {
      cc: "alcohol intoxication",
      desc: "",
    },
    {
      cc: "alcohol withdrawal",
      desc: "",
    },
    {
      cc: "priapism",
      desc: "",
    },
    {
      cc: "testicle swelling",
      desc: "",
    },
    {
      cc: "tongue swelling",
      desc: "",
    },
    {
      cc: "rectal pain",
      desc: "",
    },
    {
      cc: "rectal bleeding",
      desc: "",
    },
    {
      cc: "high blood pressure",
      desc: "",
    },
    {
      cc: "manic",
      desc: "",
    },
    {
      cc: "pink eye",
      desc: "",
    },
    {
      cc: "dog bite",
      desc: "",
    },
    {
      cc: "cat bite",
      desc: "",
    },
    {
      cc: "sepsis",
      desc: "",
    },
    {
      cc: "aspiration",
      desc: "",
    },
    {
      cc: "dizzy",
      desc: "",
    },
    {
      cc: "dizziness",
      desc: "",
    },
    {
      cc: "fistula problem",
      desc: "",
    },
    {
      cc: "fistula bleeding",
      desc: "",
    },
    {
      cc: "assault",
      desc: "",
    },
    {
      cc: "altitude sickness",
      desc: "",
    },
    {
      cc: "tooth knocked out",
      desc: "",
    },
    {
      cc: "svt",
      desc: "",
    },
    {
      cc: "sickle cell pain",
      desc: "",
    },
    {
      cc: "sickle cell crisis",
      desc: "",
    },
    {
      cc: "memory loss",
      desc: "",
    },
    {
      cc: "c difficile",
      desc: "",
    },
    {
      cc: "copd",
      desc: "",
    },
    {
      cc: "snake bite",
      desc: "",
    },
    {
      cc: "low potassium",
      desc: "",
    },
    {
      cc: "picc line problem",
      desc: "",
    },
    {
      cc: "penile fracture",
      desc: "",
    },
    {
      cc: "atrial fibrillation (afib)",
      desc: "",
    },
    {
      cc: "vaginal itching",
      desc: "",
    },
    {
      cc: "fall",
      desc: "",
    },
    {
      cc: "ostomy problem",
      desc: "",
    },
    {
      cc: "ostomy bag leaking",
      desc: "",
    },
    {
      cc: "syncope",
      desc: "",
    },
    {
      cc: "pregnant",
      desc: "",
    },
    {
      cc: "pregnacy problem",
      desc: "",
    },
    {
      cc: "bleach in eye",
      desc: "",
    },
    {
      cc: "chemical in eye",
      desc: "",
    },
    {
      cc: "foley catheter malfunction",
      desc: "",
    },
    {
      cc: "foley catheter displacement",
      desc: "",
    },
    {
      cc: "miscarriage",
      desc: "",
    },
    {
      cc: "small bowel obstruction",
      desc: "",
    },
    {
      cc: "ear piercing",
      desc: "",
    },
    {
      cc: "tongue piercing",
      desc: "",
    },
    {
      cc: "body piercing",
      desc: "",
    },
    {
      cc: "urinary retention",
      desc: "",
    },
    {
      cc: "unable to pee",
      desc: "",
    },
    {
      cc: "labor pain",
      desc: "",
    },
    {
      cc: "gastroparesis",
      desc: "",
    },
    {
      cc: "opiate withdrawl",
      desc: "",
    },
    {
      cc: "glaucoma",
      desc: "",
    },
    {
      cc: "epilepsy",
      desc: "",
    },
    {
      cc: "liver failure",
      desc: "",
    },
    {
      cc: "ectopic pregnancy",
      desc: "",
    },
    {
      cc: "drowning",
      desc: "",
    },
    {
      cc: "dka",
      desc: "",
    },
    {
      cc: "abdominal pain in pregnancy",
      desc: "",
    },
    {
      cc: "low temperature",
      desc: "",
    },
    {
      cc: "nasal congestion",
      desc: "",
    },
    {
      cc: "fast heart rate",
      desc: "",
    },
    {
      cc: "tachycardia",
      desc: "",
    },
    {
      cc: "slow heart rate",
      desc: "",
    },
    {
      cc: "bradycardia",
      desc: "",
    },
    {
      cc: "suture removal",
      desc: "",
    },
    {
      cc: "oral bleeding",
      desc: "",
    },
    {
      cc: "tooth extraction",
      desc: "",
    },
    {
      cc: "unresponsive",
      desc: "",
    },
    {
      cc: "blood per mouth",
      desc: "",
    },
    {
      cc: "finger laceration",
      desc: "",
    },
    {
      cc: "hallucinations",
      desc: "",
    },
    {
      cc: "agitation",
      desc: "",
    },
    {
      cc: "medical clearance",
      desc: "",
    },
    {
      cc: "cough",
      desc: "",
    },
    {
      cc: "motorcycle accident",
      desc: "",
    },
    {
      cc: "chest pain after fall",
      desc: "",
    },
    {
      cc: "head trauma",
      desc: "",
    },
    {
      cc: "jellyfish sting",
      desc: "",
    },
    {
      cc: "non-syncopal fall",
      desc: "",
    },
    {
      cc: "encounter for management of wound vac",
      desc: "",
    },
    {
      cc: "self harm",
      desc: "",
    },
    {
      cc: "aggressive behavior of adult",
      desc: "",
    },
    {
      cc: "psychiatric evaluation",
      desc: "",
    },
    {
      cc: "pre-syncope ",
      desc: "",
    },
    {
      cc: "penile swelling",
      desc: "",
    },
    {
      cc: "std exposure",
      desc: "",
    },
    {
      cc: "near-drowning",
      desc: "",
    },
    {
      cc: "ascites",
      desc: "",
    },
    {
      cc: "thc overdose",
      desc: "",
    },
    {
      cc: "food bolus impaction",
      desc: "",
    },
    {
      cc: "scalp laceration",
      desc: "",
    },
    {
      cc: "positive blood culture",
      desc: "",
    },
    {
      cc: "ear wax",
      desc: "",
    },
    {
      cc: "impacted cerumen ear ",
      desc: "",
    },
    {
      cc: "prescription refill",
      desc: "",
    },
    {
      cc: "medication refill",
      desc: "",
    },
    {
      cc: "skin lesion",
      desc: "",
    },
    {
      cc: "post-op pain",
      desc: "",
    },
    {
      cc: "motor vechicle crash (mvc)",
      desc: "",
    },
    {
      cc: "mouth sores",
      desc: "",
    },
    {
      cc: "decreased fetal movement ",
      desc: "",
    },
    {
      cc: "vaginal bleeding in pregnancy",
      desc: "",
    },
    {
      cc: "cellulitis",
      desc: "",
    },
    {
      cc: "hypercapnia",
      desc: "",
    },
    {
      cc: "accidental overdose",
      desc: "",
    },
    {
      cc: "aicd discharge",
      desc: "",
    },
    {
      cc: "benadryl overdose",
      desc: "",
    },
    {
      cc: "febrile seizure",
      desc: "",
    },
    {
      cc: "oil of wintergreen overdose",
      desc: "",
    },
    {
      cc: "nausea, vomiting, and diarrhea",
      desc: "",
    },
    {
      cc: "generalized weakness ",
      desc: "",
    },
    {
      cc: "dyspnea",
      desc: "",
    },
    {
      cc: "confusion",
      desc: "",
    },
    {
      cc: "buttock irritation",
      desc: "",
    },
    {
      cc: "dysarthria",
      desc: "",
    },
    {
      cc: "foreign body in throat",
      desc: "",
    },
    {
      cc: "musculoskeletal pain",
      desc: "",
    },
    {
      cc: "positive for covid-19",
      desc: "",
    },
    {
      cc: "blood transfusion",
      desc: "",
    },
  ],
  ddx: {
    0: {
      "Chief Complaint": "Chest Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Chest Pain ": "R07.9",
        "Costochondritis ": "M94.0",
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Gastroesophageal reflux ": "K21.9",
        "Muscle strain ": "S29.91XA",
        "Pericarditis ": "I30.9",
        "Precordial catch syndrome ": "R07.2",
        "Anxiety ": "F41.9",
        "Rib fracture ": "S22.3XXA",
      },
    },
    1: {
      "Chief Complaint": "Chest Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Chest Pain ": "R07.9",
        "Acute myocardial infarction ": "I21.9",
        "Aortic dissection ": "I71.0",
        "Pulmonary embolism ": "I26.99",
        "Tension pneumothorax ": "J93.0",
        "Panic attack ": "F41.0",
        "Costochondritis ": "M94.0",
        "Gastroesophageal reflux ": "K21.9",
        "Pneumothorax ": "J93.9",
        "Angina pectoris ": "I20.9",
        "Esophageal rupture ": "K22.6",
        "Pericarditis ": "I30.9",
        "Heart failure ": "I50.9",
      },
    },
    2: {
      "Chief Complaint": "Chest Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Chest Pain ": "R07.9",
        "Acute myocardial infarction ": "I21.9",
        "Pulmonary embolism ": "I26.99",
        "Angina pectoris ": "I20.9",
        "Aortic dissection ": "I71.0",
        "Cardiac Tamponade ": "I31.9",
        "Costochondritis ": "M94.0",
        "Gastroesophageal reflux ": "K21.9",
        "Anxiety ": "F41.9",
        "Heart failure ": "I50.9",
        "Esophageal rupture ": "K22.6",
        "Pericarditis ": "I30.9",
        "Breast conditions ": "N64.4",
      },
    },
    3: {
      "Chief Complaint": "Chest Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Chest Pain ": "R07.9",
        "Acute myocardial infarction ": "I21.9",
        "Angina pectoris ": "I20.9",
        "Aortic dissection ": "I71.0",
        "Pulmonary embolism ": "I26.99",
        "Gastroesophageal reflux ": "K21.9",
        "Pneumonia ": "J18.9",
        "Costochondritis ": "M94.0",
        "Rib fracture ": "S22.3XXA",
        "Heart failure ": "I50.9",
      },
    },
    4: {
      "Chief Complaint": "Shortness of Breath",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Shortness of Breath ": "R06.02",
        "Asthma ": "J45.909",
        "Bronchiolitis ": "J21.9",
        "Pneumonia ": "J18.9",
        "Foreign body aspiration ": "T17.9",
        "Anemia ": "D64.9",
        "Cystic fibrosis ": "E84.9",
        "Allergic reaction ": "T78.40XA",
        "Heart failure ": "I50.9",
        "Anxiety ": "F41.9",
      },
    },
    5: {
      "Chief Complaint": "Shortness of Breath",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Shortness of Breath ": "R06.02",
        "Asthma ": "J45.909",
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Heart failure ": "I50.9",
        "Anxiety ": "F41.9",
        "Pneumothorax ": "J93.9",
        "Anemia ": "D64.9",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
      },
    },
    6: {
      "Chief Complaint": "Shortness of Breath",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Shortness of Breath ": "R06.02",
        "Asthma ": "J45.909",
        "Pulmonary embolism ": "I26.99",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "Anemia ": "D64.9",
        "COPD ": "J44.9",
        "Anxiety ": "F41.9",
        "GERD ": "K21.9",
        "Pregnancy-related changes ": "O99.89",
      },
    },
    7: {
      "Chief Complaint": "Shortness of Breath",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Shortness of Breath ": "R06.02",
        "Heart failure ": "I50.9",
        "COPD ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Chronic kidney disease ": "N18.9",
        "Anemia ": "D64.9",
        "Lung cancer ": "C34.90",
        "GERD ": "K21.9",
        "Cardiac ischemia ": "I20.9",
      },
    },
    8: {
      "Chief Complaint": "headache",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal meningitis ": "G00.2",
        "Intracranial hemorrhage ": "P52.9",
        "Hypoxic-ischemic encephalopathy ": "P91.6",
        "Hydrocephalus ": "P91.5",
        "Inborn metabolic disorder ": "E88.9",
      },
    },
    9: {
      "Chief Complaint": "headache",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Meningitis ": "G03.9",
        "Traumatic head injury ": "S09.90",
        "Hydrocephalus ": "G91.9",
        "Sinusitis ": "J32.9",
        "Teething ": "K00.1",
      },
    },
    10: {
      "Chief Complaint": "Headache",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Headache ": "R51",
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Sinusitis ": "J01.90",
        "Dehydration ": "E86.0",
        "Vision problems ": "H53.9",
        "Stress ": "F43.9",
        "Brain tumor ": "C71.9",
        "Medication overuse headache ": "G44.41",
        "Infection ": "A49.9",
        "meningitis) ": "G03.9",
      },
    },
    11: {
      "Chief Complaint": "Headache",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Headache ": "R51",
        "Subarachnoid hemorrhage (SAH) ": "I60.9",
        "Bacterial meningitis/encephalitis ": "G00.9",
        "Carbon monoxide poisoning ": "T58.91",
        "Tumor or mass ": "D49.9",
        "Subdural hematoma ": "S06.6X9A",
        "Epidural hematoma ": "S06.0X9A",
        "Acute angle glaucoma ": "H40.011",
        "Hypertensive emergency ": "I16.9",
        "Tension headache ": "G44.209",
        "Cluster headache ": "G44.009",
        "Migraine ": "G43.909",
        "Sinusitis ": "J01.90",
        "Hypertension ": "I10",
        "Caffeine withdrawal ": "F15.93",
        "Sleep disorders ": "G47.9",
        "Medication overuse headache ": "G44.41",
      },
    },
    12: {
      "Chief Complaint": "Headache",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Headache ": "R51",
        "Subarachnoid hemorrhage (SAH) ": "I60.9",
        "Bacterial meningitis/encephalitis ": "G00.9",
        "Carbon monoxide poisoning ": "T58.91",
        "Tumor or mass ": "D49.9",
        "Subdural hematoma ": "S06.6X9A",
        "Epidural hematoma ": "S06.0X9A",
        "Acute angle glaucoma ": "H40.011",
        "Hypertensive emergency ": "I16.9",
        "Tension headache ": "G44.209",
        "Migraine ": "G43.909",
        "Sinusitis ": "J01.90",
        "Hormonal changes ": "E34.9",
        "Menstrual cycle ": "N94.6",
        "Anemia ": "D64.9",
        "Stress ": "F43.9",
        "Sleep disorders ": "G47.9",
        "Medication overuse headache ": "G44.41",
      },
    },
    13: {
      "Chief Complaint": "Headache",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Headache ": "R51",
        "Subarachnoid hemorrhage (SAH) ": "I60.9",
        "Bacterial meningitis/encephalitis ": "G00.9",
        "Temporal arteritis ": "M31.6",
        "Carbon monoxide poisoning ": "T58.91",
        "Tumor or mass ": "D49.9",
        "Subdural hematoma ": "S06.6X9A",
        "Epidural hematoma ": "S06.0X9A",
        "Acute angle glaucoma ": "H40.011",
        "Hypertensive emergency ": "I16.9",
        "Tension headache ": "G44.209",
        "Migraine ": "G43.909",
        "Sinusitis ": "J01.90",
        "Hypertension ": "I10",
        "Medication side effects ": "T88.7",
        "Brain tumor ": "C71.9",
        "Glaucoma ": "H40.9",
        "Cervical spondylosis ": "M47.817",
      },
    },
    14: {
      "Chief Complaint": "Back Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Back Pain ": "M54.5",
        "Muscle strain ": "S39.012A",
        "Scoliosis ": "M41.9",
        "Kidney infection ": "N10",
        "Lordosis ": "M40.5",
        "Discitis ": "M46.40",
        "Sports injury ": "T14.8",
        "Juvenile rheumatoid arthritis ": "M08.3",
        "Osteomyelitis ": "M86.9",
        "Growth pains ": "R62.0",
      },
    },
    15: {
      "Chief Complaint": "Back Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Back Pain ": "M54.5",
        "Muscle strain ": "S39.012A",
        "Herniated disc ": "M51.26",
        "Sciatica ": "M54.30",
        "Spinal stenosis ": "M48.06",
        "Osteoarthritis ": "M19.90",
        "Ankylosing spondylitis ": "M45.0",
        "Prostatitis ": "N41.9",
        "Pancreatitis ": "K85.9",
        "Renal colic/urolithiasis ": "N20.0",
        "Cauda Equina Syndrome ": "G83.4",
        "Epidural abscess ": "G06.0",
        "Tumor or mass ": "D49.9",
        "Fracture ": "S32.9",
      },
    },
    16: {
      "Chief Complaint": "Back Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Back Pain ": "M54.5",
        "Muscle strain ": "S39.012A",
        "Herniated disc ": "M51.26",
        "Sciatica ": "M54.30",
        "Osteoarthritis ": "M19.90",
        "Pregnancy-related back pain ": "O26.89",
        "Fibromyalgia ": "M79.7",
        "Endometriosis ": "N80.9",
        "Spinal stenosis ": "M48.06",
        "Abdominal pathology ": "K76.9",
        "Zoster ": "B02.9",
        "Renal colic/urolithiasis ": "N20.0",
        "Cauda Equina Syndrome ": "G83.4",
        "Epidural abscess ": "G06.0",
        "Tumor or mass ": "D49.9",
      },
    },
    17: {
      "Chief Complaint": "Back Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Back Pain ": "M54.5",
        "Muscle strain ": "S39.012A",
        "Spinal stenosis ": "M48.06",
        "Osteoarthritis ": "M19.90",
        "Compression fracture ": "M80.08XA",
        "Sciatica ": "M54.30",
        "Osteoporosis ": "M81.0",
        "Lumbar spondylosis ": "M47.816",
        "Degenerative disc disease ": "M51.36",
        "Renal colic/urolithiasis ": "N20.0",
        "Abdominal Aortic Aneurysm ": "I71.4",
        "Aortic Dissection ": "I71.0",
        "Tumor or mass ": "D49.9",
        "Fracture ": "S32.9",
        "Pyelonephritis ": "N10",
        "Abdominal pathology ": "K76.9",
        "Zoster ": "B02.9",
      },
    },
    18: {
      "Chief Complaint": "nausea",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal sepsis ": "P36.9",
        "Gastroesophageal reflux ": "P78.83",
        "Metabolic disorder ": "E88.9",
        "Intestinal obstruction ": "P76.9",
        "Infection ": "P39.9",
      },
    },
    19: {
      "Chief Complaint": "nausea",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Gastroesophageal reflux ": "K21.0",
        "Intestinal obstruction ": "K56.609",
        "Teething ": "K00.1",
        "Ear infection ": "H66.90",
      },
    },
    20: {
      "Chief Complaint": "Nausea",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nausea ": "R11.0",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Motion sickness ": "T75.3",
        "Migraine ": "G43.909",
        "Appendicitis ": "K35.80",
        "Gastritis ": "K29.70",
        "Urinary tract infection ": "N39.0",
        "Medication side effect ": "T88.7",
        "Stress ": "F43.9",
      },
    },
    21: {
      "Chief Complaint": "Nausea",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Nausea ": "R11.0",
        "Gastroenteritis ": "K52.9",
        "Peptic ulcer ": "K27.9",
        "Gastritis ": "K29.70",
        "Pancreatitis ": "K85.9",
        "Hepatitis ": "B15.9",
        "Medication side effect ": "T88.7",
        "Alcohol use ": "F10.20",
        "Kidney stones ": "N20.0",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
      },
    },
    22: {
      "Chief Complaint": "Nausea",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Nausea ": "R11.0",
        "Pregnancy ": "O21.0",
        "Gastroenteritis ": "K52.9",
        "Peptic ulcer ": "K27.9",
        "Gastritis ": "K29.70",
        "Urinary tract infection ": "N39.0",
        "Migraine ": "G43.909",
        "Medication side effect ": "T88.7",
        "Gallstones ": "K80.20",
        "GERD ": "K21.9",
      },
    },
    23: {
      "Chief Complaint": "Nausea",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Nausea ": "R11.0",
        "Gastroenteritis ": "K52.9",
        "Gastritis ": "K29.70",
        "Peptic ulcer ": "K27.9",
        "GERD ": "K21.9",
        "Medication side effect ": "T88.7",
        "Chronic kidney disease ": "N18.9",
        "Pancreatitis ": "K85.9",
        "Gallstones ": "K80.20",
        "Heart failure ": "I50.9",
      },
    },
    24: {
      "Chief Complaint": "Trauma",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Trauma ": "T14.90",
        "Birth trauma ": "P15.9",
        "Fracture ": "S02.0XXA",
        "Head injury ": "S09.90XA",
        "Soft tissue injury ": "S00.83XA",
        "Bruising ": "S00.83XA",
        "Accidental falls ": "W01.0XXA",
        "Child abuse ": "T74.12XA",
        "Hypoxic-ischemic encephalopathy ": "P91.60",
        "Intracranial hemorrhage ": "P52.9",
      },
    },
    25: {
      "Chief Complaint": "Trauma",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Trauma ": "T14.90",
        "Accidental falls ": "W01.0XXA",
        "Head injury ": "S09.90XA",
        "Fracture ": "S02.0XXA",
        "Soft tissue injury ": "S00.83XA",
        "Bruising ": "S00.83XA",
        "Child abuse ": "T74.12XA",
        "Foreign body aspiration ": "T17.9",
        "Burn ": "T20.20XA",
        "Drowning ": "W65.9XXA",
      },
    },
    26: {
      "Chief Complaint": "Trauma",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Trauma ": "T14.90",
        "Accidental falls ": "W01.0XXA",
        "Sports injuries ": "Y93.6",
        "Fracture ": "S42.001A",
        "Head injury ": "S09.90XA",
        "Soft tissue injury ": "S00.83XA",
        "Bicycle accidents ": "V10.5XXA",
        "Motor vehicle accidents ": "V89.2",
        "Playground injuries ": "Y93.3",
        "Animal bites ": "W54.0XXA",
      },
    },
    27: {
      "Chief Complaint": "Trauma",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Trauma ": "T14.90",
        "Motor vehicle accidents ": "V89.2",
        "Workplace injuries ": "Y92.9",
        "Sports injuries ": "Y93.6",
        "Assault ": "X96",
        "Falls ": "W10.9XXA",
        "Fracture ": "S42.001A",
        "Head injury ": "S09.90XA",
        "Stab wounds ": "X99.0XXA",
        "Gunshot wounds ": "W32.0XXA",
      },
    },
    28: {
      "Chief Complaint": "Trauma",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Trauma ": "T14.90",
        "Falls ": "W10.9XXA",
        "Motor vehicle accidents ": "V89.2",
        "Domestic violence ": "T74.11XA",
        "Sports injuries ": "Y93.6",
        "Fracture ": "S42.001A",
        "Head injury ": "S09.90XA",
        "Burn ": "T20.20XA",
        "Animal bites ": "W54.0XXA",
        "Accidental poisoning ": "T39.0X1A",
      },
    },
    29: {
      "Chief Complaint": "Trauma",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Trauma ": "T14.90",
        "Falls ": "W19.0XXA",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Motor vehicle accidents ": "V89.2",
        "Head injury ": "S09.90XA",
        "Soft tissue injury ": "S00.83XA",
        "Pedestrian accidents ": "V01.9XXA",
        "Burn ": "T20.20XA",
        "Elder abuse ": "T74.21XA",
        "Accidental poisoning ": "T39.0X1A",
      },
    },
    30: {
      "Chief Complaint": "Cough",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Cough ": "R05",
        "Respiratory distress syndrome ": "P22.0",
        "Congenital heart disease ": "Q24.9",
        "Tracheoesophageal fistula ": "Q39.1",
        "Cystic fibrosis ": "E84.9",
        "Bronchopulmonary dysplasia ": "P27.1",
        "Aspiration ": "P24.9",
        "Gastroesophageal reflux ": "K21.9",
        "Infection ": "A49.9",
        "pneumonia) ": "J18.9",
        "Airway malformation ": "Q31.8",
      },
    },
    31: {
      "Chief Complaint": "Cough",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Cough ": "R05",
        "Bronchiolitis ": "J21.9",
        "Croup ": "J05.0",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Gastroesophageal reflux ": "K21.9",
        "Foreign body aspiration ": "T17.9",
        "Cystic fibrosis ": "E84.9",
        "Allergic rhinitis ": "J30.9",
        "Congenital heart disease ": "Q24.9",
      },
    },
    32: {
      "Chief Complaint": "Cough",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cough ": "R05",
        "Asthma ": "J45.909",
        "Upper respiratory infection ": "J06.9",
        "Pneumonia ": "J18.9",
        "Allergic rhinitis ": "J30.9",
        "Croup ": "J05.0",
        "Gastroesophageal reflux ": "K21.9",
        "Sinusitis ": "J01.90",
        "Foreign body aspiration ": "T17.9",
        "Pertussis ": "A37.9",
      },
    },
    33: {
      "Chief Complaint": "Cough",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Cough ": "R05",
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Asthma ": "J45.909",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
        "Upper respiratory infection ": "J06.9",
        "Smoking-related cough ": "J41.0",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "Tuberculosis ": "A15.9",
        "Lung cancer ": "C34.90",
      },
    },
    34: {
      "Chief Complaint": "Cough",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Cough ": "R05",
        "Asthma ": "J45.909",
        "Upper respiratory infection ": "J06.9",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Pneumonia ": "J18.9",
        "Allergic rhinitis ": "J30.9",
        "Heart failure ": "I50.9",
        "Tuberculosis ": "A15.9",
        "Lung cancer ": "C34.90",
      },
    },
    35: {
      "Chief Complaint": "Cough",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cough ": "R05",
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Heart failure ": "I50.9",
        "Pneumonia ": "J18.9",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
        "Lung cancer ": "C34.90",
        "Asthma ": "J45.909",
        "Tuberculosis ": "A15.9",
        "Pulmonary fibrosis ": "J84.1",
        "Chronic bronchitis ": "J42",
      },
    },
    36: {
      "Chief Complaint": "pain during urination (dysuria)",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dysuria ": "R30.0",
        "Urinary tract infection ": "N39.0",
        "Vulvovaginitis ": "N76.0",
        "Urethritis ": "N34.1",
        "Bladder stones ": "N21.0",
        "Pinworm infection ": "B80",
        "Labial adhesions ": "N90.4",
        "Soap irritation ": "L23.7",
        "Constipation ": "K59.00",
        "Dehydration ": "E86.0",
      },
    },
    37: {
      "Chief Complaint": "pain during urination (dysuria)",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Dysuria ": "R30.0",
        "Urinary tract infection ": "N39.0",
        "Prostatitis ": "N41.9",
        "Urethritis ": "N34.1",
        "Bladder stones ": "N21.0",
        "Sexually transmitted infections ": "A64",
        "Kidney stones ": "N20.0",
        "Bladder cancer ": "C67.9",
        "Benign prostatic hyperplasia ": "N40.1",
        "Diabetes mellitus ": "E11.9",
      },
    },
    38: {
      "Chief Complaint": "pain during urination (dysuria)",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dysuria ": "R30.0",
        "Urinary tract infection ": "N39.0",
        "Cystitis ": "N30.0",
        "Urethritis ": "N34.1",
        "Sexually transmitted infections ": "A64",
        "Vaginitis ": "N76.0",
        "Kidney stones ": "N20.0",
        "Pregnancy ": "O26.89",
        "Interstitial cystitis ": "N30.10",
        "Diabetes mellitus ": "E11.9",
      },
    },
    39: {
      "Chief Complaint": "pain during urination (dysuria)",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dysuria ": "R30.0",
        "Urinary tract infection ": "N39.0",
        "Bladder stones ": "N21.0",
        "Urethritis ": "N34.1",
        "Kidney stones ": "N20.0",
        "Benign prostatic hyperplasia ": "N40.1",
        "Bladder cancer ": "C67.9",
        "Diabetes mellitus ": "E11.9",
        "Prostatitis ": "N41.9",
        "Urinary incontinence ": "N39.3",
      },
    },
    40: {
      "Chief Complaint": "Fall",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Fall ": "W19.XXXA",
        "Birth trauma ": "P15.9",
        "Fracture ": "S02.0XXA",
        "Head injury ": "S09.90XA",
        "Soft tissue injury ": "S00.83XA",
        "Bruising ": "S00.83XA",
        "Accidental falls ": "W01.0XXA",
        "Child abuse ": "T74.12XA",
        "Hypoxic-ischemic encephalopathy ": "P91.60",
        "Intracranial hemorrhage ": "P52.9",
      },
    },
    41: {
      "Chief Complaint": "Fall",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Fall ": "W19.XXXA",
        "Accidental falls ": "W01.0XXA",
        "Head injury ": "S09.90XA",
        "Fracture ": "S02.0XXA",
        "Soft tissue injury ": "S00.83XA",
        "Bruising ": "S00.83XA",
        "Child abuse ": "T74.12XA",
        "Foreign body aspiration ": "T17.9",
        "Burn ": "T20.20XA",
        "Drowning ": "W65.9XXA",
      },
    },
    42: {
      "Chief Complaint": "Fall",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fall ": "W19.XXXA",
        "Accidental falls ": "W01.0XXA",
        "Sports injuries ": "Y93.6",
        "Fracture ": "S42.001A",
        "Head injury ": "S09.90XA",
        "Soft tissue injury ": "S00.83XA",
        "Bicycle accidents ": "V10.5XXA",
        "Motor vehicle accidents ": "V89.2",
        "Playground injuries ": "Y93.3",
        "Animal bites ": "W54.0XXA",
      },
    },
    43: {
      "Chief Complaint": "Fall",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Fall ": "W19.XXXA",
        "Motor vehicle accidents ": "V89.2",
        "Workplace injuries ": "Y92.9",
        "Sports injuries ": "Y93.6",
        "Assault ": "X96",
        "Falls ": "W10.9XXA",
        "Fracture ": "S42.001A",
        "Head injury ": "S09.90XA",
        "Stab wounds ": "X99.0XXA",
        "Gunshot wounds ": "W32.0XXA",
      },
    },
    44: {
      "Chief Complaint": "Fall",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Fall ": "W19.XXXA",
        "Falls ": "W10.9XXA",
        "Motor vehicle accidents ": "V89.2",
        "Domestic violence ": "T74.11XA",
        "Sports injuries ": "Y93.6",
        "Fracture ": "S42.001A",
        "Head injury ": "S09.90XA",
        "Burn ": "T20.20XA",
        "Animal bites ": "W54.0XXA",
        "Accidental poisoning ": "T39.0X1A",
      },
    },
    45: {
      "Chief Complaint": "Fall",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fall ": "W19.XXXA",
        "Falls ": "W19.0XXA",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Motor vehicle accidents ": "V89.2",
        "Head injury ": "S09.90XA",
        "Soft tissue injury ": "S00.83XA",
        "Pedestrian accidents ": "V01.9XXA",
        "Burn ": "T20.20XA",
        "Elder abuse ": "T74.21XA",
        "Accidental poisoning ": "T39.0X1A",
      },
    },
    46: {
      "Chief Complaint": "anxiety",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal withdrawal syndrome ": "P96.1",
        "Hypocalcemia ": "P71.6",
        "Hypoglycemia ": "P70.4",
        "Birth asphyxia ": "P21.9",
        "Neonatal jaundice ": "P59.9",
      },
    },
    47: {
      "Chief Complaint": "anxiety",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Separation anxiety ": "F93.0",
        "Reactive attachment disorder ": "F94.1",
        "Developmental delay ": "F88",
        "Autism spectrum disorder ": "F84.0",
        "Feeding disorder ": "F98.29",
      },
    },
    48: {
      "Chief Complaint": "anxiety",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Generalized anxiety disorder ": "F41.1",
        "Social anxiety disorder ": "F40.10",
        "Specific phobia ": "F40.8",
        "Panic disorder ": "F41.0",
        "Post-traumatic stress disorder ": "F43.10",
      },
    },
    49: {
      "Chief Complaint": "anxiety",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Generalized anxiety disorder ": "F41.1",
        "Panic disorder ": "F41.0",
        "Obsessive-compulsive disorder ": "F42",
        "Social anxiety disorder ": "F40.10",
        "Post-traumatic stress disorder ": "F43.10",
      },
    },
    50: {
      "Chief Complaint": "anxiety",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Generalized anxiety disorder ": "F41.1",
        "Panic disorder ": "F41.0",
        "Post-traumatic stress disorder ": "F43.10",
        "Obsessive-compulsive disorder ": "F42",
        "Premenstrual dysphoric disorder ": "N94.3",
      },
    },
    51: {
      "Chief Complaint": "anxiety",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Generalized anxiety disorder ": "F41.1",
        "Adjustment disorder with anxiety ": "F43.22",
        "Depression with anxious distress ": "F32.8",
        "Dementia with behavioral disturbance ": "F03.91",
        "Delirium due to a general medical condition ": "F05",
      },
    },
    52: {
      "Chief Complaint": "Flu-like Symptoms",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Flu-like Symptoms ": "R50.9",
        "Influenza ": "J09.X2",
        "Gastroenteritis ": "K52.9",
        "Mononucleosis ": "B27.9",
        "Strep throat ": "J02.0",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Allergic rhinitis ": "J30.9",
        "Sinusitis ": "J01.90",
        "Urinary tract infection ": "N39.0",
      },
    },
    53: {
      "Chief Complaint": "Flu-like Symptoms",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Flu-like Symptoms ": "R50.9",
        "Influenza ": "J09.X2",
        "Gastroenteritis ": "K52.9",
        "HIV/AIDS ": "B20",
        "Tuberculosis ": "A15.9",
        "Hepatitis ": "B15.9",
        "Pneumonia ": "J18.9",
        "Lyme disease ": "A69.2",
        "Malaria ": "B50.9",
        "Mononucleosis ": "B27.9",
      },
    },
    54: {
      "Chief Complaint": "Flu-like Symptoms",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Flu-like Symptoms ": "R50.9",
        "Influenza ": "J09.X2",
        "Gastroenteritis ": "K52.9",
        "Urinary tract infection ": "N39.0",
        "Pregnancy ": "O26.89",
        "Mononucleosis ": "B27.9",
        "Tuberculosis ": "A15.9",
        "Pneumonia ": "J18.9",
        "Anemia ": "D64.9",
        "Thyroid disorders ": "E07.9",
      },
    },
    55: {
      "Chief Complaint": "flu-like symptoms",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Influenza ": "J10.1",
        "Pneumonia ": "J18.9",
        "COVID-19 ": "U07.1",
        "Acute bronchitis ": "J20.9",
        "Chronic bronchitis ": "J42",
      },
    },
    56: {
      "Chief Complaint": "ankle pain",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital talipes equinovarus ": "Q66.0",
        "Developmental dysplasia of the hip ": "Q65.9",
        "Fracture of ankle ": "S82.9XXA",
        "Infection ": "R68.0",
        "Congenital anomalies ": "Q89.9",
      },
    },
    57: {
      "Chief Complaint": "ankle pain",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Tarsal coalition ": "Q66.89",
        "Laceration ": "S61.419",
        "Fracture ": "S82.9XXA",
        "Infection ": "R68.0",
        "Sprain ": "S93.4XXA",
      },
    },
    58: {
      "Chief Complaint": "ankle pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Ankle sprain ": "S93.4",
        "Juvenile arthritis ": "M08.9",
        "Osgood-Schlatter disease ": "M92.5",
        "Fracture ": "S82.6",
        "Tendinitis ": "M76.7",
      },
    },
    59: {
      "Chief Complaint": "ankle pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Ankle sprain ": "S93.4",
        "Gout ": "M10.07",
        "Fracture ": "S82.6",
        "Achilles tendinitis ": "M76.6",
        "Osteoarthritis ": "M19.07",
      },
    },
    60: {
      "Chief Complaint": "ankle pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ankle sprain ": "S93.4",
        "Fracture ": "S82.6",
        "Achilles tendinitis ": "M76.6",
        "Osteoarthritis ": "M19.07",
        "Rheumatoid arthritis ": "M06.07",
      },
    },
    61: {
      "Chief Complaint": "ankle pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.07",
        "Fracture ": "S82.6",
        "Gout ": "M10.07",
        "Ankle sprain ": "S93.4",
        "Tendinitis ": "M76.7",
      },
    },
    62: {
      "Chief Complaint": "knee pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Osgood-Schlatter disease ": "M92.5",
        "Juvenile arthritis ": "M08.9",
        "Patellar tendinitis ": "M76.5",
        "Meniscal tear ": "S83.2",
        "Fracture ": "S82.4",
      },
    },
    63: {
      "Chief Complaint": "knee pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Meniscal tear ": "S83.2",
        "Patellar tendinitis ": "M76.5",
        "Osteoarthritis ": "M17.9",
        "ACL tear ": "S83.5",
        "Bursitis ": "M70.2",
      },
    },
    64: {
      "Chief Complaint": "knee pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Osteoarthritis ": "M17.9",
        "Meniscal tear ": "S83.2",
        "Patellar tendinitis ": "M76.5",
        "Rheumatoid arthritis ": "M06.9",
        "Bursitis ": "M70.2",
      },
    },
    65: {
      "Chief Complaint": "knee pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M17.9",
        "Meniscal tear ": "S83.2",
        "Bursitis ": "M70.2",
        "Gout ": "M10.06",
        "Rheumatoid arthritis ": "M06.9",
      },
    },
    66: {
      "Chief Complaint": "difficulty walking",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Developmental delay ": "R62.50",
        "Cerebral palsy ": "G80.9",
        "Muscular dystrophy ": "G71.0",
        "Bone dysplasia ": "Q78.9",
        "Tarsal coalition ": "Q66.89",
      },
    },
    67: {
      "Chief Complaint": "difficulty walking",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Developmental delay ": "F88",
        "Muscular dystrophy ": "G71.0",
        "Cerebral palsy ": "G80.9",
        "Legg-Calv\u00e9-Perthes disease ": "M91.1",
        "Juvenile arthritis ": "M08.9",
      },
    },
    68: {
      "Chief Complaint": "difficulty walking",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Sciatica ": "M54.3",
        "Lumbar disc herniation ": "M51.2",
        "Osteoarthritis ": "M17.9",
        "Peripheral neuropathy ": "G62.9",
        "Stroke ": "I63.9",
      },
    },
    69: {
      "Chief Complaint": "difficulty walking",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Sciatica ": "M54.3",
        "Lumbar disc herniation ": "M51.2",
        "Osteoarthritis ": "M17.9",
        "Multiple sclerosis ": "G35",
        "Rheumatoid arthritis ": "M06.9",
      },
    },
    70: {
      "Chief Complaint": "difficulty walking",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M17.9",
        "Hip fracture ": "S72.0",
        "Stroke ": "I63.9",
        "Parkinson's disease ": "G20",
        "Peripheral neuropathy ": "G62.9",
      },
    },
    71: {
      "Chief Complaint": "sore throat",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral pharyngitis ": "J02.9",
        "Strep throat ": "J02.0",
        "Tonsillitis ": "J03.9",
        "Mononucleosis ": "B27.90",
        "Croup ": "J05.0",
      },
    },
    72: {
      "Chief Complaint": "sore throat",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Viral pharyngitis ": "J02.9",
        "Strep throat ": "J02.0",
        "Tonsillitis ": "J03.9",
        "GERD ": "K21.9",
        "Laryngitis ": "J04.0",
      },
    },
    73: {
      "Chief Complaint": "sore throat",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Viral pharyngitis ": "J02.9",
        "Strep throat ": "J02.0",
        "Tonsillitis ": "J03.9",
        "GERD ": "K21.9",
        "Thyroiditis ": "E06.9",
      },
    },
    74: {
      "Chief Complaint": "sore throat",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "GERD ": "K21.9",
        "Viral pharyngitis ": "J02.9",
        "Strep throat ": "J02.0",
        "Esophageal cancer ": "C15.9",
        "Laryngeal cancer ": "C32.9",
      },
    },
    75: {
      "Chief Complaint": "palpitations",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Supraventricular tachycardia ": "I47.1",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Anemia ": "D64.9",
        "Arrhythmia ": "I49.9",
      },
    },
    76: {
      "Chief Complaint": "palpitations",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
        "Ventricular tachycardia ": "I47.2",
      },
    },
    77: {
      "Chief Complaint": "palpitations",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Anxiety ": "F41.9",
        "Atrial fibrillation ": "I48.91",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
        "Anemia ": "D64.9",
      },
    },
    78: {
      "Chief Complaint": "palpitations",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Heart failure ": "I50.9",
        "Ischemic heart disease ": "I25.9",
        "Ventricular tachycardia ": "I47.2",
        "Hyperthyroidism ": "E05.90",
      },
    },
    79: {
      "Chief Complaint": "syncope",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Seizure ": "G40.909",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
      },
    },
    80: {
      "Chief Complaint": "syncope",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Cardiac arrhythmia ": "I49.9",
        "Ischemic heart disease ": "I25.9",
        "Seizure ": "G40.909",
      },
    },
    81: {
      "Chief Complaint": "syncope",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Anemia ": "D64.9",
        "Hypoglycemia ": "E16.2",
        "Seizure ": "G40.909",
        "Orthostatic hypotension ": "I95.1",
      },
    },
    82: {
      "Chief Complaint": "syncope",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cardiac arrhythmia ": "I49.9",
        "Orthostatic hypotension ": "I95.1",
        "Carotid sinus hypersensitivity ": "G90.01",
        "Ischemic heart disease ": "I25.9",
        "Vasovagal syncope ": "R55",
      },
    },
    83: {
      "Chief Complaint": "gi bleeding/rectal bleed",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.0",
        "Meckel's diverticulum ": "Q43.0",
        "Intussusception ": "K56.1",
        "Infectious colitis ": "A09.9",
        "Juvenile polyps ": "D12.3",
      },
    },
    84: {
      "Chief Complaint": "gi bleeding/rectal bleed",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Hemorrhoids ": "I84.9",
        "Anal fissure ": "K60.0",
        "Colorectal cancer ": "C18.9",
        "Gastric ulcer ": "K25.9",
        "Diverticulitis ": "K57.90",
      },
    },
    85: {
      "Chief Complaint": "gi bleeding/rectal bleed",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Hemorrhoids ": "I84.9",
        "Anal fissure ": "K60.0",
        "Diverticulitis ": "K57.90",
        "Gastric ulcer ": "K25.9",
        "Colorectal cancer ": "C18.9",
      },
    },
    86: {
      "Chief Complaint": "gi bleeding/rectal bleed",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Colorectal cancer ": "C18.9",
        "Diverticulitis ": "K57.90",
        "Gastric ulcer ": "K25.9",
        "Hemorrhoids ": "I84.9",
        "Ischemic colitis ": "K55.0",
      },
    },
    87: {
      "Chief Complaint": "stroke",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Arterial ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Migraine ": "G43.909",
        "Seizure ": "G40.909",
        "Brain tumor ": "C71.9",
      },
    },
    88: {
      "Chief Complaint": "stroke",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Transient ischemic attack ": "G45.9",
        "Brain tumor ": "C71.9",
        "Migraine ": "G43.909",
      },
    },
    89: {
      "Chief Complaint": "stroke",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Migraine ": "G43.909",
        "Transient ischemic attack ": "G45.9",
        "Brain tumor ": "C71.9",
      },
    },
    90: {
      "Chief Complaint": "stroke",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Transient ischemic attack ": "G45.9",
        "Brain tumor ": "C71.9",
        "Subdural hematoma ": "I62.9",
      },
    },
    91: {
      "Chief Complaint": "dental pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Dental abscess ": "K04.7",
        "Gingivitis ": "K05.1",
        "Tooth eruption ": "K00.6",
      },
    },
    92: {
      "Chief Complaint": "dental pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Dental caries ": "K02.9",
        "Periodontitis ": "K05.3",
        "Dental abscess ": "K04.7",
        "Pulpitis ": "K04.0",
        "Impacted tooth ": "K01.1",
      },
    },
    93: {
      "Chief Complaint": "dental pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dental caries ": "K02.9",
        "Dental abscess ": "K04.7",
        "Periodontitis ": "K05.3",
        "Pulpitis ": "K04.0",
        "Tooth fracture ": "S02.5XXA",
      },
    },
    94: {
      "Chief Complaint": "dental pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dental caries ": "K02.9",
        "Periodontitis ": "K05.3",
        "Dental abscess ": "K04.7",
        "Tooth loss ": "K08.1",
        "Denture-related stomatitis ": "K12.2",
      },
    },
    95: {
      "Chief Complaint": "diarrhea",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Rotavirus ": "A08.0",
        "Lactose intolerance ": "E73.9",
        "Antibiotic-associated diarrhea ": "A04.7",
        "Food poisoning ": "A05.9",
      },
    },
    96: {
      "Chief Complaint": "diarrhea",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Irritable bowel syndrome ": "K58.9",
        "Crohn's disease ": "K50.90",
        "Ulcerative colitis ": "K51.90",
        "Celiac disease ": "K90.0",
      },
    },
    97: {
      "Chief Complaint": "diarrhea",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Irritable bowel syndrome ": "K58.9",
        "Celiac disease ": "K90.0",
        "Crohn's disease ": "K50.90",
        "Ulcerative colitis ": "K51.90",
      },
    },
    98: {
      "Chief Complaint": "diarrhea",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Diverticulitis ": "K57.90",
        "Medication side effect ": "Y57.9",
        "Clostridium difficile infection ": "A04.7",
        "Irritable bowel syndrome ": "K58.9",
      },
    },
    99: {
      "Chief Complaint": "insect sting",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Local reaction to insect bite ": "T63.4XXA",
        "Anaphylaxis ": "T78.2XXA",
        "Cellulitis from insect bite ": "L03.90",
        "Lyme disease ": "A69.20",
        "Spider bite ": "T63.3XXA",
      },
    },
    100: {
      "Chief Complaint": "insect sting",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Local reaction to insect bite ": "T63.4XXA",
        "Anaphylaxis ": "T78.2XXA",
        "Cellulitis from insect bite ": "L03.90",
        "Lyme disease ": "A69.20",
        "Spider bite ": "T63.3XXA",
      },
    },
    101: {
      "Chief Complaint": "insect sting",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Local reaction to insect bite ": "T63.4XXA",
        "Anaphylaxis ": "T78.2XXA",
        "Cellulitis from insect bite ": "L03.90",
        "Lyme disease ": "A69.20",
        "Spider bite ": "T63.3XXA",
      },
    },
    102: {
      "Chief Complaint": "insect sting",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Local reaction to insect bite ": "T63.4XXA",
        "Anaphylaxis ": "T78.2XXA",
        "Cellulitis from insect bite ": "L03.90",
        "Lyme disease ": "A69.20",
        "Spider bite ": "T63.3XXA",
      },
    },
    103: {
      "Chief Complaint": "flank pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pyelonephritis ": "N10",
        "Kidney stone ": "N20.0",
        "Musculoskeletal pain ": "M79.1",
        "Costochondritis ": "M94.0",
        "Pneumonia ": "J18.9",
        "UTI ": "N39.0",
      },
    },
    104: {
      "Chief Complaint": "flank pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Kidney stone ": "N20.0",
        "Pyelonephritis ": "N10",
        "Musculoskeletal pain ": "M79.1",
        "Renal cell carcinoma ": "C64.9",
        "Pneumonia ": "J18.9",
        "UTI ": "N39.0",
      },
    },
    105: {
      "Chief Complaint": "flank pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Pyelonephritis ": "N10",
        "Kidney stone ": "N20.0",
        "Musculoskeletal pain ": "M79.1",
        "Ovarian cyst rupture ": "N83.2",
        "Pneumonia ": "J18.9",
        "UTI ": "N39.0",
      },
    },
    106: {
      "Chief Complaint": "flank pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Kidney stone ": "N20.0",
        "Pyelonephritis ": "N10",
        "Renal cell carcinoma ": "C64.9",
        "Musculoskeletal pain ": "M79.1",
        "Pneumonia ": "J18.9",
        "UTI ": "N39.0",
      },
    },
    107: {
      "Chief Complaint": "allergic reaction",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2XXA",
        "Urticaria ": "L50.9",
        "Contact dermatitis ": "L25.9",
        "Food allergy ": "T78.1XXA",
        "Drug reaction ": "T88.7XXA",
      },
    },
    108: {
      "Chief Complaint": "allergic reaction",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Anaphylaxis ": "T78.2XXA",
        "Urticaria ": "L50.9",
        "Contact dermatitis ": "L25.9",
        "Drug reaction ": "T88.7XXA",
        "Food allergy ": "T78.1XXA",
      },
    },
    109: {
      "Chief Complaint": "allergic reaction",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Anaphylaxis ": "T78.2XXA",
        "Urticaria ": "L50.9",
        "Contact dermatitis ": "L25.9",
        "Drug reaction ": "T88.7XXA",
        "Food allergy ": "T78.1XXA",
      },
    },
    110: {
      "Chief Complaint": "allergic reaction",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2XXA",
        "Urticaria ": "L50.9",
        "Drug reaction ": "T88.7XXA",
        "Contact dermatitis ": "L25.9",
        "Food allergy ": "T78.1XXA",
      },
    },
    111: {
      "Chief Complaint": "vaginal bleeding",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Precocious puberty ": "E30.8",
        "Foreign body ": "T19.9XXA",
        "Trauma ": "T14.90",
        "Vaginal infection ": "N76.0",
        "Tumor ": "D28.9",
      },
    },
    112: {
      "Chief Complaint": "vaginal bleeding",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Menstrual irregularities ": "N92.6",
        "Pregnancy complications ": "O20.8",
        "Cervical polyp ": "N84.1",
        "Endometrial cancer ": "C54.1",
        "Uterine fibroids ": "D25.9",
      },
    },
    113: {
      "Chief Complaint": "vaginal bleeding",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Endometrial cancer ": "C54.1",
        "Vaginal atrophy ": "N95.2",
        "Uterine polyps ": "N84.0",
        "Cervical cancer ": "C53.9",
        "Vaginal trauma ": "T14.90",
      },
    },
    114: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Accidental ingestion ": "T51.0X1A",
        "Alcohol poisoning ": "T51.0X2A",
        "Metabolic acidosis ": "E87.2",
        "Hypoglycemia ": "E16.2",
        "Head trauma ": "S09.90XA",
      },
    },
    115: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Acute alcohol intoxication ": "F10.129",
        "Alcohol withdrawal ": "F10.239",
        "Alcohol hepatitis ": "K70.1",
        "Pancreatitis ": "K85.9",
        "Trauma ": "T14.90",
      },
    },
    116: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Acute alcohol intoxication ": "F10.129",
        "Alcohol withdrawal ": "F10.239",
        "Alcohol hepatitis ": "K70.1",
        "Pancreatitis ": "K85.9",
        "Trauma ": "T14.90",
      },
    },
    117: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Acute alcohol intoxication ": "F10.129",
        "Alcohol withdrawal ": "F10.239",
        "Alcohol hepatitis ": "K70.1",
        "Fall due to intoxication ": "W18.2XXA",
        "Medication interaction ": "T50.995A",
      },
    },
    118: {
      "Chief Complaint": "leg symptoms",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Growing pains ": "R29.89",
        "Osgood-Schlatter disease ": "M92.52",
        "Juvenile idiopathic arthritis ": "M08.3",
        "Legg-Calv\u00e9-Perthes disease ": "M91.1",
        "Fracture ": "S82.90XA",
      },
    },
    119: {
      "Chief Complaint": "leg symptoms",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Deep vein thrombosis ": "I82.409",
        "Peripheral artery disease ": "I70.209",
        "Sciatica ": "M54.30",
        "Muscle strain ": "M62.830",
        "Cellulitis ": "L03.115",
      },
    },
    120: {
      "Chief Complaint": "leg symptoms",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Deep vein thrombosis ": "I82.409",
        "Varicose veins ": "I83.90",
        "Sciatica ": "M54.30",
        "Peripheral artery disease ": "I70.209",
        "Muscle strain ": "M62.830",
      },
    },
    121: {
      "Chief Complaint": "leg symptoms",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Deep vein thrombosis ": "I82.409",
        "Peripheral artery disease ": "I70.209",
        "Osteoarthritis ": "M17.9",
        "Cellulitis ": "L03.115",
        "Venous insufficiency ": "I87.2",
      },
    },
    122: {
      "Chief Complaint": "hypertension",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Essential hypertension ": "I10",
        "Renal artery stenosis ": "I15.0",
        "Coarctation of the aorta ": "Q25.1",
        "Renal disease ": "N26.9",
        "Endocrine disorders ": "E27.9",
      },
    },
    123: {
      "Chief Complaint": "hypertension",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Essential hypertension ": "I10",
        "Secondary hypertension ": "I15.9",
        "Renal disease ": "N26.9",
        "Adrenal tumor ": "D35.0",
        "Sleep apnea ": "G47.30",
      },
    },
    124: {
      "Chief Complaint": "hypertension",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Essential hypertension ": "I10",
        "Pregnancy-induced hypertension ": "O13",
        "Renal disease ": "N26.9",
        "Thyroid disorders ": "E07.9",
        "Secondary hypertension ": "I15.9",
      },
    },
    125: {
      "Chief Complaint": "hypertension",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Essential hypertension ": "I10",
        "Atherosclerosis ": "I70.90",
        "Renal artery stenosis ": "I15.0",
        "Medication-induced hypertension ": "T88.7XXA",
      },
    },
    126: {
      "Chief Complaint": "fainting",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Dehydration ": "E86.0",
        "Arrhythmia ": "I49.9",
        "Seizure ": "G40.909",
        "Hyperventilation ": "R06.4",
      },
    },
    127: {
      "Chief Complaint": "fainting",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Cardiac arrhythmia ": "I49.9",
        "Orthostatic hypotension ": "I95.1",
        "Carotid sinus hypersensitivity ": "G90.01",
        "Anemia ": "D64.9",
      },
    },
    128: {
      "Chief Complaint": "fainting",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Anemia ": "D64.9",
        "Arrhythmia ": "I49.9",
        "Hypoglycemia ": "E16.2",
        "Pregnancy ": "O99.89",
      },
    },
    129: {
      "Chief Complaint": "fainting",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Cardiac arrhythmia ": "I49.9",
        "Medication side effect ": "T88.7XXA",
        "Carotid sinus hypersensitivity ": "G90.01",
      },
    },
    130: {
      "Chief Complaint": "complications",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Postoperative infection ": "T81.4XXA",
        "Hemorrhage ": "T81.01XA",
        "Adverse drug reaction ": "T88.7XXA",
        "Foreign body left in body ": "T81.5XXA",
        "Anesthetic complication ": "T88.59XA",
      },
    },
    131: {
      "Chief Complaint": "complications",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Surgical wound infection ": "T81.4XXA",
        "Hemorrhage ": "T81.01XA",
        "Adverse drug reaction ": "T88.7XXA",
        "Postoperative thrombosis ": "T81.71XA",
        "Anesthetic complication ": "T88.59XA",
      },
    },
    132: {
      "Chief Complaint": "complications",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Postoperative infection ": "T81.4XXA",
        "Hemorrhage ": "T81.01XA",
        "Adverse drug reaction ": "T88.7XXA",
        "Complication of implanted device ": "T85.9XXA",
        "Anesthetic complication ": "T88.59XA",
      },
    },
    133: {
      "Chief Complaint": "complications",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Postoperative infection ": "T81.4XXA",
        "Hemorrhage ": "T81.01XA",
        "Adverse drug reaction ": "T88.7XXA",
        "Postoperative thrombosis ": "T81.71XA",
        "Complication of implanted device ": "T85.9XXA",
      },
    },
    134: {
      "Chief Complaint": "seizures",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal seizures ": "P90",
        "Hypoxic-ischemic encephalopathy ": "P91.6",
        "Metabolic disorders ": "E70-E88",
        "Intracranial hemorrhage ": "P52.9",
        "Infections ": "P35-P39",
      },
    },
    135: {
      "Chief Complaint": "seizures",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Febrile seizures ": "R56.0",
        "Epilepsy ": "G40.909",
        "Infections ": "A87.9",
        "Traumatic brain injury ": "S06.9X9A",
        "Metabolic disorders ": "E70-E88",
      },
    },
    136: {
      "Chief Complaint": "seizures",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Epilepsy ": "G40.909",
        "Febrile seizures ": "R56.0",
        "Traumatic brain injury ": "S06.9X9A",
        "CNS infections ": "G00-G09",
        "Tumors ": "D33.9",
      },
    },
    137: {
      "Chief Complaint": "seizures",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Epilepsy ": "G40.909",
        "Traumatic brain injury ": "S06.9X9A",
        "Stroke ": "I63.9",
        "Alcohol withdrawal ": "F10.239",
        "Tumors ": "D43.9",
      },
    },
    138: {
      "Chief Complaint": "seizures",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Epilepsy ": "G40.909",
        "Traumatic brain injury ": "S06.9X9A",
        "Stroke ": "I63.9",
        "Alcohol withdrawal ": "F10.239",
        "Tumors ": "D43.9",
      },
    },
    139: {
      "Chief Complaint": "general weakness",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal sepsis ": "P36.9",
        "Congenital metabolic disorders ": "E70-E88",
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal jaundice ": "P59.9",
        "Birth asphyxia ": "P21.9",
      },
    },
    140: {
      "Chief Complaint": "general weakness",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Viral infections ": "B34.9",
        "Malnutrition ": "E46",
        "Congenital heart disease ": "Q24.9",
        "Anemia ": "D64.9",
        "Metabolic disorders ": "E70-E88",
      },
    },
    141: {
      "Chief Complaint": "general weakness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral infections ": "B34.9",
        "Anemia ": "D64.9",
        "Asthma ": "J45.909",
        "Type 1 diabetes ": "E10.9",
        "Malnutrition ": "E46",
      },
    },
    142: {
      "Chief Complaint": "general weakness",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Chronic kidney disease ": "N18.9",
        "Depression ": "F32.9",
        "Diabetes mellitus ": "E11.9",
      },
    },
    143: {
      "Chief Complaint": "general weakness",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Pregnancy ": "Z33.1",
        "Depression ": "F32.9",
        "Diabetes mellitus ": "E11.9",
      },
    },
    144: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.9XXA",
        "Internal injury ": "S36.9XXA",
        "Laceration ": "S01.9XXA",
      },
    },
    145: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.9XXA",
        "Internal injury ": "S36.9XXA",
        "Laceration ": "S01.9XXA",
      },
    },
    146: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.9XXA",
        "Internal injury ": "S36.9XXA",
        "Laceration ": "S01.9XXA",
      },
    },
    147: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Whiplash ": "S13.4XXA",
        "Fracture ": "S42.9XXA",
        "Concussion ": "S06.0X0A",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
      },
    },
    148: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Whiplash ": "S13.4XXA",
        "Fracture ": "S42.9XXA",
        "Concussion ": "S06.0X0A",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
      },
    },
    149: {
      "Chief Complaint": "injuries due to falls",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
        "Head injury ": "S09.90XA",
        "Sprain ": "S93.4XXA",
      },
    },
    150: {
      "Chief Complaint": "injuries due to falls",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
        "Head injury ": "S09.90XA",
        "Sprain ": "S93.4XXA",
      },
    },
    151: {
      "Chief Complaint": "injuries due to falls",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
        "Head injury ": "S09.90XA",
        "Sprain ": "S93.4XXA",
      },
    },
    152: {
      "Chief Complaint": "injuries due to falls",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Hip fracture ": "S72.0XXA",
        "Wrist fracture ": "S52.5XXA",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
        "Sprain ": "S93.4XXA",
      },
    },
    153: {
      "Chief Complaint": "injuries due to falls",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Hip fracture ": "S72.0XXA",
        "Wrist fracture ": "S52.5XXA",
        "Contusion ": "S00.9XXA",
        "Laceration ": "S01.9XXA",
        "Sprain ": "S93.4XXA",
      },
    },
    154: {
      "Chief Complaint": "general weakness",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal sepsis ": "P36.9",
        "Hypoglycemia ": "P70.4",
        "Inborn errors of metabolism ": "E88.9",
        "Congenital heart disease ": "Q24.9",
        "Anemia ": "P61.9",
      },
    },
    155: {
      "Chief Complaint": "general weakness",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Viral infection ": "B97.89",
        "Failure to thrive ": "R62.51",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    156: {
      "Chief Complaint": "general weakness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral infection ": "B97.89",
        "Anemia ": "D64.9",
        "Asthma ": "J45.909",
        "Diabetes ": "E10.9",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    157: {
      "Chief Complaint": "general weakness",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Anemia ": "D64.9",
        "Diabetes ": "E11.9",
        "Hypothyroidism ": "E03.9",
        "Chronic kidney disease ": "N18.9",
        "Depression ": "F32.9",
      },
    },
    158: {
      "Chief Complaint": "general weakness",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Diabetes ": "E11.9",
        "Depression ": "F32.9",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    159: {
      "Chief Complaint": "general weakness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Chronic kidney disease ": "N18.9",
        "Hypothyroidism ": "E03.9",
        "Cardiovascular disease ": "I25.9",
        "Diabetes ": "E11.9",
      },
    },
    160: {
      "Chief Complaint": "upper respiratory tract infections",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital pneumonia ": "P23.9",
        "Neonatal aspiration syndrome ": "P24.9",
        "Viral infection ": "B97.9",
        "Bacterial infection ": "A49.9",
        "Fungal infection ": "B49",
      },
    },
    161: {
      "Chief Complaint": "upper respiratory tract infections",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Bronchiolitis ": "J21.9",
        "Croup ": "J05.0",
        "Pneumonia ": "J18.9",
        "Tonsillitis ": "J03.90",
        "Pharyngitis ": "J02.9",
      },
    },
    162: {
      "Chief Complaint": "upper respiratory tract infections",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pharyngitis ": "J02.9",
        "Tonsillitis ": "J03.90",
        "Sinusitis ": "J01.90",
        "Bronchitis ": "J20.9",
        "Influenza ": "J10.1",
      },
    },
    163: {
      "Chief Complaint": "upper respiratory tract infections",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Influenza ": "J10.1",
        "Bronchitis ": "J40",
        "Pneumonia ": "J18.9",
        "Sinusitis ": "J01.90",
        "Pharyngitis ": "J02.9",
      },
    },
    164: {
      "Chief Complaint": "upper respiratory tract infections",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Influenza ": "J10.1",
        "Sinusitis ": "J01.90",
        "Bronchitis ": "J40",
        "Pharyngitis ": "J02.9",
        "Pneumonia ": "J18.9",
      },
    },
    165: {
      "Chief Complaint": "upper respiratory tract infections",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Pneumonia ": "J18.9",
        "Influenza ": "J10.1",
        "Bronchitis ": "J40",
        "Sinusitis ": "J01.90",
        "Pharyngitis ": "J02.9",
      },
    },
    166: {
      "Chief Complaint": "palpitations",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital heart disease ": "Q24.9",
        "Arrhythmia ": "I49.9",
        "Electrolyte imbalance ": "E87.8",
        "Neonatal sepsis ": "P36.9",
        "Anemia ": "P61.9",
      },
    },
    167: {
      "Chief Complaint": "palpitations",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Congenital heart disease ": "Q24.9",
        "Arrhythmia ": "I49.9",
        "Electrolyte imbalance ": "E87.8",
        "Fever ": "R50.9",
        "Anemia ": "D64.9",
      },
    },
    168: {
      "Chief Complaint": "palpitations",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Arrhythmia ": "I49.9",
        "Fever ": "R50.9",
        "Anemia ": "D64.9",
        "Anxiety ": "F41.9",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    169: {
      "Chief Complaint": "palpitations",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Arrhythmia ": "I49.9",
        "Ischemic heart disease ": "I25.9",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    170: {
      "Chief Complaint": "palpitations",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Arrhythmia ": "I49.9",
        "Anxiety ": "F41.9",
        "Anemia ": "D64.9",
        "Hyperthyroidism ": "E05.90",
        "Pregnancy-related ": "O99.419",
      },
    },
    171: {
      "Chief Complaint": "palpitations",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Arrhythmia ": "I49.9",
        "Ischemic heart disease ": "I25.9",
        "Heart failure ": "I50.9",
        "Electrolyte imbalance ": "E87.8",
        "Anemia ": "D64.9",
      },
    },
    172: {
      "Chief Complaint": "Skin Rash",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Diaper rash ": "L22",
        "Erythema toxicum ": "L53.9",
        "Neonatal acne ": "L70.4",
        "Birthmarks ": "Q82.5",
        "Congenital ichthyosis ": "Q80.9",
        "Seborrheic dermatitis ": "L21.0",
        "Candidiasis ": "B37.9",
        "Infections ": "B99. 9",
        "herpes) ": "B00.9",
        "Allergic reaction ": "T78.40XA",
      },
    },
    173: {
      "Chief Complaint": "Skin Rash",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Atopic dermatitis ": "L20.9",
        "Diaper rash ": "L22",
        "Viral exanthems ": "B09",
        "Seborrheic dermatitis ": "L21.0",
        "Impetigo ": "L01.0",
        "Scabies ": "B86",
        "Candidiasis ": "B37.9",
        "Allergic reaction ": "T78.40XA",
        "Eczema ": "L30.9",
      },
    },
    174: {
      "Chief Complaint": "Skin Rash",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Atopic dermatitis ": "L20.9",
        "Impetigo ": "L01.0",
        "Urticaria ": "L50.9",
        "Scabies ": "B86",
        "Viral exanthems ": "B09",
        "Allergic reaction ": "T78.40XA",
        "Eczema ": "L30.9",
        "Psoriasis ": "L40.9",
        "Insect bites ": "S00.83XA",
      },
    },
    175: {
      "Chief Complaint": "Skin Rash",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Skin Rash ": "R21",
        "Contact dermatitis ": "L23.9",
        "Psoriasis ": "L40.9",
        "Eczema ": "L30.9",
        "Drug reaction ": "L27.0",
        "Urticaria ": "L50.9",
        "Fungal infections ": "B35.9",
        "Scabies ": "B86",
        "Seborrheic dermatitis ": "L21.9",
        "Lyme disease ": "A69.2",
      },
    },
    176: {
      "Chief Complaint": "Skin Rash",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Skin Rash ": "R21",
        "Contact dermatitis ": "L23.9",
        "Eczema ": "L30.9",
        "Psoriasis ": "L40.9",
        "Drug reaction ": "L27.0",
        "Urticaria ": "L50.9",
        "Autoimmune disorders ": "D89.9",
        "lupus) ": "M32.9",
        "Scabies ": "B86",
        "Fungal infections ": "B35.9",
        "Pregnancy-related rash ": "O99.7",
      },
    },
    177: {
      "Chief Complaint": "Skin Rash",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Drug reaction ": "L27.0",
        "Psoriasis ": "L40.9",
        "Eczema ": "L30.9",
        "Seborrheic dermatitis ": "L21.9",
        "Urticaria ": "L50.9",
        "Fungal infections ": "B35.9",
        "Actinic keratosis ": "L57.0",
        "Stasis dermatitis ": "I83.1",
        "Autoimmune disorders ": "M32.9",
      },
    },
    178: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Aspiration of meconium ": "P24.00",
        "Aspiration of milk and regurgitated food ": "P24.01",
        "Foreign body in nasal cavity ": "T17.0XXA",
        "Foreign body in pharynx ": "T17.2XXA",
        "Foreign body in stomach ": "T18.2XXA",
      },
    },
    179: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Foreign body in esophagus ": "T18.1XXA",
        "Foreign body in stomach ": "T18.2XXA",
        "Foreign body in small intestine ": "T18.3XXA",
        "Aspiration of food ": "P24.01",
        "Foreign body in nasal cavity ": "T17.0XXA",
      },
    },
    180: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Foreign body in esophagus ": "T18.1XXA",
        "Foreign body in stomach ": "T18.2XXA",
        "Foreign body in ear ": "T16.XXXA",
        "Foreign body in nasal cavity ": "T17.0XXA",
        "Foreign body in pharynx ": "T17.2XXA",
      },
    },
    181: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Foreign body in esophagus ": "T18.1XXA",
        "Foreign body in stomach ": "T18.2XXA",
        "Foreign body in respiratory tract ": "T17.9XXA",
        "Foreign body in ear ": "T16.XXXA",
        "Foreign body in nose ": "T17.0XXA",
      },
    },
    182: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Foreign body in esophagus ": "T18.1XXA",
        "Foreign body in stomach ": "T18.2XXA",
        "Foreign body in respiratory tract ": "T17.9XXA",
        "Foreign body in ear ": "T16.XXXA",
        "Foreign body in nose ": "T17.0XXA",
      },
    },
    183: {
      "Chief Complaint": "ear pain",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "External otitis ": "H60.9",
        "Foreign body in ear ": "T16.XXXA",
        "Congenital malformation of ear ": "Q16.9",
        "Eustachian tube dysfunction ": "H69.8",
      },
    },
    184: {
      "Chief Complaint": "ear pain",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "External otitis ": "H60.9",
        "Foreign body in ear ": "T16.XXXA",
        "Teething ": "K00.1",
        "Eustachian tube dysfunction ": "H69.8",
      },
    },
    185: {
      "Chief Complaint": "ear pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "External otitis ": "H60.9",
        "Foreign body in ear ": "T16.XXXA",
        "Sinusitis ": "J32.9",
        "Eustachian tube dysfunction ": "H69.8",
      },
    },
    186: {
      "Chief Complaint": "ear pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "External otitis ": "H60.9",
        "Eustachian tube dysfunction ": "H69.8",
        "Temporomandibular joint disorder ": "M26.60",
        "Foreign body in ear ": "T16.XXXA",
      },
    },
    187: {
      "Chief Complaint": "ear pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "External otitis ": "H60.9",
        "Eustachian tube dysfunction ": "H69.8",
        "Cerumen impaction ": "H61.2",
        "Foreign body in ear ": "T16.XXXA",
      },
    },
    188: {
      "Chief Complaint": "influenza (flu)",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal sepsis ": "P36.9",
        "Respiratory syncytial virus pneumonia ": "J12.1",
        "Congenital pneumonia ": "P23.9",
        "Bronchiolitis ": "J21.9",
        "Congenital viral infection ": "P35.9",
      },
    },
    189: {
      "Chief Complaint": "influenza (flu)",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Respiratory syncytial virus pneumonia ": "J12.1",
        "Bronchiolitis ": "J21.9",
        "Viral pneumonia ": "J12.89",
        "Croup ": "J05.0",
        "Otitis media ": "H66.90",
      },
    },
    190: {
      "Chief Complaint": "influenza (flu)",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral pneumonia ": "J12.89",
        "Streptococcal pharyngitis ": "J02.0",
        "Otitis media ": "H66.90",
        "Sinusitis ": "J32.9",
        "Asthma exacerbation ": "J45.901",
      },
    },
    191: {
      "Chief Complaint": "influenza (flu)",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Viral pneumonia ": "J12.89",
        "Bacterial pneumonia ": "J15.9",
        "Chronic bronchitis ": "J42",
        "Asthma exacerbation ": "J45.901",
        "COPD exacerbation ": "J44.1",
      },
    },
    192: {
      "Chief Complaint": "influenza (flu)",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Viral pneumonia ": "J12.89",
        "Bacterial pneumonia ": "J15.9",
        "COPD exacerbation ": "J44.1",
        "Congestive heart failure exacerbation ": "I50.9",
        "Asthma exacerbation ": "J45.901",
      },
    },
    193: {
      "Chief Complaint": "eye irritation or redness",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal conjunctivitis ": "P39.1",
        "Congenital glaucoma ": "Q15.0",
        "Congenital cataract ": "Q12.0",
        "Subconjunctival hemorrhage ": "H11.3",
        "Dacryostenosis ": "Q10.5",
      },
    },
    194: {
      "Chief Complaint": "eye irritation or redness",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Bacterial conjunctivitis ": "H10.01",
        "Allergic conjunctivitis ": "H10.1",
        "Dacryocystitis ": "H04.30",
        "Subconjunctival hemorrhage ": "H11.3",
        "Viral conjunctivitis ": "B30.1",
      },
    },
    195: {
      "Chief Complaint": "eye irritation or redness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Bacterial conjunctivitis ": "H10.01",
        "Allergic conjunctivitis ": "H10.1",
        "Viral conjunctivitis ": "B30.1",
        "Foreign body in eye ": "T15.91XA",
        "Blepharitis ": "H01.00",
      },
    },
    196: {
      "Chief Complaint": "eye irritation or redness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Bacterial conjunctivitis ": "H10.01",
        "Allergic conjunctivitis ": "H10.1",
        "Dry eye syndrome ": "H04.12",
        "Uveitis ": "H20.9",
        "Glaucoma ": "H40.9",
      },
    },
    197: {
      "Chief Complaint": "eye irritation or redness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dry eye syndrome ": "H04.12",
        "Age-related macular degeneration ": "H35.31",
        "Glaucoma ": "H40.9",
        "Cataract ": "H25.9",
        "Bacterial conjunctivitis ": "H10.01",
      },
    },
    198: {
      "Chief Complaint": "toothache",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Teething ": "K00.1",
        "Congenital oral cyst ": "Q38.4",
        "Neonatal candidiasis ": "P37.5",
        "Congenital malformation of tooth ": "Q78.2",
        "Neonatal oral thrush ": "B37.9",
      },
    },
    199: {
      "Chief Complaint": "toothache",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Teething ": "K00.1",
        "Early childhood caries ": "K02.51",
        "Dental abscess ": "K04.7",
        "Gingivitis ": "K05.1",
        "Oral thrush ": "B37.9",
      },
    },
    200: {
      "Chief Complaint": "toothache",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dental cavity ": "K02.9",
        "Dental abscess ": "K04.7",
        "Gingivitis ": "K05.1",
        "Pulpitis ": "K04.0",
        "Orthodontic pain ": "M26.89",
      },
    },
    201: {
      "Chief Complaint": "toothache",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Dental cavity ": "K02.9",
        "Dental abscess ": "K04.7",
        "Periodontitis ": "K05.3",
        "Impacted tooth ": "K01.1",
        "Temporomandibular joint disorder ": "M26.60",
      },
    },
    202: {
      "Chief Complaint": "toothache",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dental cavity ": "K02.9",
        "Dental abscess ": "K04.7",
        "Periodontitis ": "K05.3",
        "Tooth erosion ": "K03.2",
        "Denture-related stomatitis ": "K12.1",
      },
    },
    203: {
      "Chief Complaint": "joint pain",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital hip dysplasia ": "Q65.9",
        "Neonatal septic arthritis ": "P39.4",
        "Congenital limb deformity ": "Q74.9",
        "Osteogenesis imperfecta ": "Q78.0",
        "Congenital muscular dystrophy ": "G71.2",
      },
    },
    204: {
      "Chief Complaint": "joint pain",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Juvenile idiopathic arthritis ": "M08.3",
        "Septic arthritis ": "M00.9",
        "Transient synovitis ": "M13.89",
        "Osteomyelitis ": "M86.9",
        "Fracture ": "S42.9XXA",
      },
    },
    205: {
      "Chief Complaint": "joint pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Juvenile idiopathic arthritis ": "M08.3",
        "Growing pains ": "M79.8",
        "Osgood-Schlatter disease ": "M92.5",
        "Reactive arthritis ": "M02.9",
        "Lyme disease ": "A69.20",
      },
    },
    206: {
      "Chief Complaint": "joint pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M15.9",
        "Rheumatoid arthritis ": "M06.9",
        "Gout ": "M10.9",
        "Lupus ": "M32.9",
        "Bursitis ": "M71.9",
      },
    },
    207: {
      "Chief Complaint": "joint pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M15.9",
        "Rheumatoid arthritis ": "M06.9",
        "Polymyalgia rheumatica ": "M35.3",
        "Gout ": "M10.9",
        "Osteoporosis with fracture ": "M81.0",
      },
    },
    208: {
      "Chief Complaint": "abnormal bleeding",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Hemorrhagic disease of newborn ": "P53",
        "Birth trauma ": "P10.9",
        "Congenital coagulation defect ": "D68.9",
        "Neonatal thrombocytopenia ": "P61.4",
        "Neonatal hemorrhage ": "P50.9",
      },
    },
    209: {
      "Chief Complaint": "abnormal bleeding",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Hemophilia ": "D66",
        "Vitamin K deficiency ": "E56.1",
        "Immune thrombocytopenic purpura ": "D69.3",
        "Disseminated intravascular coagulation ": "D65",
        "Congenital coagulation defect ": "D68.9",
      },
    },
    210: {
      "Chief Complaint": "abnormal bleeding",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hemophilia ": "D66",
        "Immune thrombocytopenic purpura ": "D69.3",
        "Leukemia ": "C91.9",
        "Von Willebrand's disease ": "D68.0",
        "Platelet function disorder ": "D69.1",
      },
    },
    211: {
      "Chief Complaint": "abnormal bleeding",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Menorrhagia ": "N92.0",
        "Uterine fibroids ": "D25.9",
        "Endometriosis ": "N80.9",
        "Von Willebrand's disease ": "D68.0",
        "Platelet function disorder ": "D69.1",
      },
    },
    212: {
      "Chief Complaint": "abnormal bleeding",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Hemophilia ": "D66",
        "Peptic ulcer ": "K27.9",
        "Gastric cancer ": "C16.9",
        "Colorectal cancer ": "C18.9",
        "Von Willebrand's disease ": "D68.0",
      },
    },
    213: {
      "Chief Complaint": "abnormal bleeding",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gastric ulcer ": "K25.9",
        "Colorectal cancer ": "C18.9",
        "Gastric cancer ": "C16.9",
        "Hemophilia ": "D66",
        "Chronic kidney disease ": "N18.9",
      },
    },
    214: {
      "Chief Complaint": "constipation",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Hirschsprung disease ": "Q43.1",
        "Meconium plug syndrome ": "P76.0",
        "Cystic fibrosis ": "E84.9",
        "Hypothyroidism ": "E03.9",
        "Anorectal malformation ": "Q42.3",
      },
    },
    215: {
      "Chief Complaint": "constipation",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Hirschsprung disease ": "Q43.1",
        "Cow's milk protein allergy ": "K52.21",
        "Cystic fibrosis ": "E84.9",
        "Dehydration ": "E86.0",
        "Botulism ": "A05.1",
      },
    },
    216: {
      "Chief Complaint": "constipation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Functional constipation ": "F98.1",
        "Hirschsprung disease ": "Q43.1",
        "Hypothyroidism ": "E03.9",
        "Celiac disease ": "K90.0",
        "Cow's milk protein allergy ": "K52.21",
      },
    },
    217: {
      "Chief Complaint": "constipation",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Irritable bowel syndrome ": "K58.9",
        "Opioid-induced constipation ": "K59.03",
        "Hypothyroidism ": "E03.9",
        "Colon cancer ": "C18.9",
        "Diabetes mellitus ": "E11.9",
      },
    },
    218: {
      "Chief Complaint": "constipation",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Medication-induced constipation ": "T50.905A",
        "Parkinson's disease ": "G20",
        "Hypothyroidism ": "E03.9",
        "Diverticular disease ": "K57.90",
        "Colon cancer ": "C18.9",
      },
    },
    219: {
      "Chief Complaint": "asthma exacerbation",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Bronchiolitis ": "J21.9",
        "Congenital heart disease ": "Q24.9",
        "Cystic fibrosis ": "E84.9",
        "Foreign body aspiration ": "T17.908A",
        "Gastroesophageal reflux ": "K21.9",
      },
    },
    220: {
      "Chief Complaint": "asthma exacerbation",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Bronchiolitis ": "J21.9",
        "Cystic fibrosis ": "E84.9",
        "Foreign body aspiration ": "T17.908A",
        "Viral pneumonia ": "J12.9",
        "Congenital heart disease ": "Q24.9",
      },
    },
    221: {
      "Chief Complaint": "asthma exacerbation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Foreign body aspiration ": "T17.908A",
        "Allergic rhinitis ": "J30.9",
        "Cystic fibrosis ": "E84.9",
      },
    },
    222: {
      "Chief Complaint": "asthma exacerbation",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    223: {
      "Chief Complaint": "asthma exacerbation",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Heart failure ": "I50.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
      },
    },
    224: {
      "Chief Complaint": "appendicitis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Necrotizing enterocolitis ": "P77.9",
        "Intestinal obstruction ": "Q41.1",
        "Hirschsprung disease ": "Q43.1",
        "Meconium ileus ": "P76.0",
        "Volvulus ": "K56.2",
      },
    },
    225: {
      "Chief Complaint": "appendicitis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Intussusception ": "K56.1",
        "Gastroenteritis ": "K52.9",
        "Hirschsprung disease ": "Q43.1",
        "Volvulus ": "K56.2",
        "Meckel's diverticulum ": "Q43.0",
      },
    },
    226: {
      "Chief Complaint": "appendicitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Mesenteric adenitis ": "I88.8",
        "Gastroenteritis ": "K52.9",
        "Meckel's diverticulum ": "Q43.0",
        "Ovarian torsion ": "N83.0",
        "Testicular torsion ": "N44",
      },
    },
    227: {
      "Chief Complaint": "appendicitis",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ectopic pregnancy ": "O00.9",
        "Ovarian cyst ": "N83.20",
        "Diverticulitis ": "K57.90",
        "Urinary tract infection ": "N39.0",
        "Gastroenteritis ": "K52.9",
      },
    },
    228: {
      "Chief Complaint": "appendicitis",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "appendicitis ": "K35.80",
        "Gastroenteritis ": "K52.9",
        "Diverticulitis ": "K57.90",
        "Urinary tract infection ": "N39.0",
      },
    },
    229: {
      "Chief Complaint": "appendicitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Diverticulitis ": "K57.90",
        "Colorectal cancer ": "C18.9",
        "Gastroenteritis ": "K52.9",
        "Ischemic colitis ": "K55.0",
        "Urinary tract infection ": "N39.0",
      },
    },
    230: {
      "Chief Complaint": "migraine/headache",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Meningitis ": "G03.9",
        "Hydrocephalus ": "Q03.9",
        "Intracranial hemorrhage ": "P52.9",
        "Hypoxic-ischemic encephalopathy ": "P91.6",
        "Inborn metabolic disorder ": "E88.9",
      },
    },
    231: {
      "Chief Complaint": "migraine/headache",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Meningitis ": "G03.9",
        "Hydrocephalus ": "Q03.9",
        "Traumatic brain injury ": "S06.9X9",
        "Sinusitis ": "J32.9",
        "Teething ": "K00.1",
      },
    },
    232: {
      "Chief Complaint": "migraine/headache",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Sinusitis ": "J32.9",
        "Brain tumor ": "C71.9",
        "Meningitis ": "G03.9",
      },
    },
    233: {
      "Chief Complaint": "migraine/headache",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Cluster headache ": "G44.029",
        "Sinusitis ": "J32.9",
        "Temporal arteritis ": "M31.6",
      },
    },
    234: {
      "Chief Complaint": "migraine/headache",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Temporal arteritis ": "M31.6",
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Brain tumor ": "C71.9",
        "Normal pressure hydrocephalus ": "G91.2",
      },
    },
    235: {
      "Chief Complaint": "panic attacks",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Generalized anxiety disorder ": "F41.1",
        "Separation anxiety ": "F93.0",
        "Specific phobia ": "F40.9",
        "Social anxiety disorder ": "F40.10",
        "Obsessive-compulsive disorder ": "F42",
      },
    },
    236: {
      "Chief Complaint": "panic attacks",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Panic disorder ": "F41.0",
        "Generalized anxiety disorder ": "F41.1",
        "Social anxiety disorder ": "F40.10",
        "Specific phobia ": "F40.9",
        "Major depressive disorder ": "F32.9",
      },
    },
    237: {
      "Chief Complaint": "panic attacks",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Panic disorder ": "F41.0",
        "Generalized anxiety disorder ": "F41.1",
        "Major depressive disorder ": "F32.9",
        "Adjustment disorder ": "F43.20",
        "Dementia ": "F03.90",
      },
    },
    238: {
      "Chief Complaint": "cholecystitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gastroenteritis ": "K52.9",
        "Appendicitis ": "K35.9",
        "Pancreatitis ": "K85.9",
        "Hepatitis ": "K75.9",
        "Peptic ulcer ": "K27.9",
      },
    },
    239: {
      "Chief Complaint": "cholecystitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Acute cholecystitis ": "K81.0",
        "Biliary colic ": "K80.0",
        "Pancreatitis ": "K85.9",
        "Peptic ulcer ": "K27.9",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    240: {
      "Chief Complaint": "cholecystitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Acute cholecystitis ": "K81.0",
        "Biliary colic ": "K80.0",
        "Pancreatitis ": "K85.9",
        "Peptic ulcer ": "K27.9",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    241: {
      "Chief Complaint": "Eye Pain",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Eye Pain ": "H57.1",
        "Conjunctivitis ": "H10.9",
        "Corneal abrasion ": "S05.0",
        "Glaucoma ": "H40.9",
        "Uveitis ": "H20.9",
        "Orbital cellulitis ": "H05.01",
        "Dacryocystitis ": "H04.30",
        "Birth trauma ": "P15.9",
        "Foreign body ": "T15.00",
        "Herpes simplex infection ": "B00.50",
      },
    },
    242: {
      "Chief Complaint": "Eye Pain",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Eye Pain ": "H57.1",
        "Conjunctivitis ": "H10.9",
        "Corneal abrasion ": "S05.0",
        "Glaucoma ": "H40.9",
        "Uveitis ": "H20.9",
        "Orbital cellulitis ": "H05.01",
        "Dacryocystitis ": "H04.30",
        "Foreign body ": "T15.00",
        "Allergic reaction ": "T78.40XA",
        "Blocked tear duct ": "Q10.5",
      },
    },
    243: {
      "Chief Complaint": "Eye Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Eye Pain ": "H57.1",
        "Conjunctivitis ": "H10.9",
        "Corneal abrasion ": "S05.0",
        "Uveitis ": "H20.9",
        "Orbital cellulitis ": "H05.01",
        "Foreign body ": "T15.00",
        "Allergic reaction ": "T78.40XA",
        "Keratitis ": "H16.9",
        "Blepharitis ": "H01.00",
        "Glaucoma ": "H40.9",
      },
    },
    244: {
      "Chief Complaint": "Eye Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Eye Pain ": "H57.1",
        "Corneal abrasion ": "S05.0",
        "Conjunctivitis ": "H10.9",
        "Uveitis ": "H20.9",
        "Glaucoma ": "H40.9",
        "Foreign body ": "T15.00",
        "Keratitis ": "H16.9",
        "Scleritis ": "H15.00",
        "Optic neuritis ": "H46",
        "Dry eye syndrome ": "H04.12",
      },
    },
    245: {
      "Chief Complaint": "Eye Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Eye Pain ": "H57.1",
        "Conjunctivitis ": "H10.9",
        "Corneal abrasion ": "S05.0",
        "Uveitis ": "H20.9",
        "Glaucoma ": "H40.9",
        "Foreign body ": "T15.00",
        "Keratitis ": "H16.9",
        "Scleritis ": "H15.00",
        "Dry eye syndrome ": "H04.12",
        "Contact lens irritation ": "H18.83",
      },
    },
    246: {
      "Chief Complaint": "Eye Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Eye Pain ": "H57.1",
        "Glaucoma ": "H40.9",
        "Cataract ": "H25.9",
        "Age-related macular degeneration ": "H35.30",
        "Corneal abrasion ": "S05.0",
        "Dry eye syndrome ": "H04.12",
        "Temporal arteritis [GCA] ": "M31.6",
        "Uveitis ": "H20.9",
        "Scleritis ": "H15.00",
        "Diabetic retinopathy ": "E14.3",
      },
    },
    247: {
      "Chief Complaint": "burns",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T20-T25",
        "Chemical burn ": "T26-T28",
        "Sunburn ": "L55.9",
        "Friction burn ": "abrasion",
        "Electrical burn ": "T20-T25",
      },
    },
    248: {
      "Chief Complaint": "burns",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T20-T25",
        "Chemical burn ": "T26-T28",
        "Sunburn ": "L55.9",
        "Friction burn ": "abrasion",
        "Electrical burn ": "T20-T25",
      },
    },
    249: {
      "Chief Complaint": "burns",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T20-T25",
        "Chemical burn ": "T26-T28",
        "Sunburn ": "L55.9",
        "Friction burn ": "abrasion",
        "Electrical burn ": "T20-T25",
      },
    },
    250: {
      "Chief Complaint": "burns",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T20-T25",
        "Chemical burn ": "T26-T28",
        "Sunburn ": "L55.9",
        "Friction burn ": "abrasion",
        "Electrical burn ": "T20-T25",
      },
    },
    251: {
      "Chief Complaint": "burns",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T20-T25",
        "Chemical burn ": "T26-T28",
        "Sunburn ": "L55.9",
        "Friction burn ": "abrasion",
        "Electrical burn ": "T20-T25",
      },
    },
    252: {
      "Chief Complaint": "cellulitis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Impetigo ": "L01.0",
        "Neonatal acne ": "L70.4",
        "Erythema toxicum neonatorum ": "L53.8",
        "Herpes simplex ": "B00.9",
      },
    },
    253: {
      "Chief Complaint": "cellulitis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Impetigo ": "L01.0",
        "Atopic dermatitis ": "L20.9",
        "Diaper rash ": "L22",
        "Candidiasis ": "B37.9",
      },
    },
    254: {
      "Chief Complaint": "cellulitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Impetigo ": "L01.0",
        "Eczema ": "L30.9",
        "Ringworm ": "B35.9",
        "Scabies ": "B86",
      },
    },
    255: {
      "Chief Complaint": "cellulitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Abscess ": "L02.91",
        "Erysipelas ": "A46",
        "Venous stasis dermatitis ": "I87.2",
        "Lymphangitis ": "L03.3",
      },
    },
    256: {
      "Chief Complaint": "cellulitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Abscess ": "L02.91",
        "Erysipelas ": "A46",
        "Venous stasis dermatitis ": "I87.2",
        "Lymphangitis ": "L03.3",
      },
    },
    257: {
      "Chief Complaint": "anaphylaxis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
        "Urticaria ": "L50.9",
        "Food allergy ": "T78.1",
        "Drug reaction ": "T88.7",
      },
    },
    258: {
      "Chief Complaint": "anaphylaxis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
        "Urticaria ": "L50.9",
        "Food allergy ": "T78.1",
        "Drug reaction ": "T88.7",
      },
    },
    259: {
      "Chief Complaint": "anaphylaxis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
        "Urticaria ": "L50.9",
        "Food allergy ": "T78.1",
        "Drug reaction ": "T88.7",
      },
    },
    260: {
      "Chief Complaint": "anaphylaxis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
      },
    },
    261: {
      "Chief Complaint": "big toe pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Ingrown toenail ": "L60.0",
        "Juvenile gout ": "M10.071",
        "Osteomyelitis ": "M86.671",
      },
    },
    262: {
      "Chief Complaint": "big toe pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Gout ": "M10.071",
        "Ingrown toenail ": "L60.0",
        "Osteoarthritis ": "M19.071",
        "Bunion ": "M20.11",
        "Fracture ": "S92.301A",
      },
    },
    263: {
      "Chief Complaint": "big toe pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gout ": "M10.071",
        "Osteoarthritis ": "M19.071",
        "Ingrown toenail ": "L60.0",
        "Bunion ": "M20.11",
        "Peripheral neuropathy ": "G63.2",
      },
    },
    264: {
      "Chief Complaint": "swelling/edema",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Cellulitis ": "L03.90",
        "Nephrotic syndrome ": "N04.9",
        "Trauma 5. Insect bite ": "S00.279A",
      },
    },
    265: {
      "Chief Complaint": "swelling/edema",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "Chronic venous insufficiency ": "I87.2",
        "Lymphedema ": "I89.0",
        "Cellulitis ": "L03.90",
        "Deep vein thrombosis ": "I82.90",
      },
    },
    266: {
      "Chief Complaint": "swelling/edema",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "Chronic venous insufficiency ": "I87.2",
        "Lymphedema ": "I89.0",
        "Kidney disease ": "N19",
      },
    },
    267: {
      "Chief Complaint": "allergic reaction",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
        "Urticaria ": "L50.9",
        "Asthma exacerbation ": "J45.901",
        "Contact dermatitis ": "L23.9",
        "Food allergy ": "T78.1",
      },
    },
    268: {
      "Chief Complaint": "allergic reaction",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
        "Urticaria ": "L50.9",
        "Allergic rhinitis ": "J30.9",
        "Contact dermatitis ": "L23.9",
        "Drug reaction ": "T88.7",
      },
    },
    269: {
      "Chief Complaint": "allergic reaction",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Anaphylaxis ": "T78.2",
        "Urticaria ": "L50.9",
        "Drug reaction ": "T88.7",
        "Contact dermatitis ": "L23.9",
        "Asthma exacerbation ": "J45.901",
      },
    },
    270: {
      "Chief Complaint": "forearm pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fracture ": "S52.502A",
        "Sprain ": "S53.402A",
        "Overuse injury 4. Juvenile arthritis ": "M08.821",
      },
    },
    271: {
      "Chief Complaint": "forearm pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S52.502A",
        "Tendinitis ": "M77.121",
        "Sprain ": "S53.402A",
        "Nerve entrapment ": "G56.22",
      },
    },
    272: {
      "Chief Complaint": "forearm pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fracture ": "S52.502A",
        "Osteoarthritis ": "M19.022",
        "Tendinitis ": "M77.121",
        "Nerve entrapment ": "G56.22",
        "Sprain ": "S53.402A",
      },
    },
    273: {
      "Chief Complaint": "wrist pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.502A",
        "Fracture ": "S62.502A",
        "Juvenile arthritis ": "M08.832",
        "Ganglion cyst ": "M67.432",
      },
    },
    274: {
      "Chief Complaint": "wrist pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Carpal tunnel syndrome ": "G56.02",
        "Sprain ": "S63.502A",
        "Fracture ": "S62.502A",
        "Tendinitis ": "M65.822",
        "Ganglion cyst ": "M67.432",
      },
    },
    275: {
      "Chief Complaint": "wrist pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.032",
        "Carpal tunnel syndrome ": "G56.02",
        "Fracture ": "S62.502A",
        "Sprain ": "S63.502A",
        "Tendinitis ": "M65.822",
      },
    },
    276: {
      "Chief Complaint": "bacterial infection",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal sepsis ": "P36.9",
        "Congenital pneumonia ": "P23.9",
        "Neonatal meningitis ": "G00.2",
        "Omphalitis of the newborn ": "P38",
        "Neonatal urinary tract infection ": "P39.3",
      },
    },
    277: {
      "Chief Complaint": "bacterial infection",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.9",
        "Pneumonia ": "J18.9",
        "Bacterial meningitis ": "G00.9",
        "Urinary tract infection ": "N39.0",
        "Cellulitis ": "L03.9",
      },
    },
    278: {
      "Chief Complaint": "bacterial infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Streptococcal pharyngitis ": "J02.0",
        "Otitis media ": "H66.9",
        "Pneumonia ": "J18.9",
        "Bacterial meningitis ": "G00.9",
        "Impetigo ": "L01.0",
      },
    },
    279: {
      "Chief Complaint": "bacterial infection",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Tuberculosis ": "A15.9",
        "Cellulitis ": "L03.9",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Bacterial meningitis ": "G00.9",
      },
    },
    280: {
      "Chief Complaint": "bacterial infection",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Bacterial vaginosis ": "N76.0",
        "Tuberculosis ": "A15.9",
        "Pneumonia ": "J18.9",
        "Cellulitis ": "L03.9",
      },
    },
    281: {
      "Chief Complaint": "bacterial infection",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Pneumonia ": "J18.9",
        "Cellulitis ": "L03.9",
        "Tuberculosis ": "A15.9",
        "Bacterial meningitis ": "G00.9",
        "Urinary tract infection ": "N39.0",
      },
    },
    282: {
      "Chief Complaint": "bacterial infection",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Cellulitis ": "L03.9",
        "Tuberculosis ": "A15.9",
        "Bacterial meningitis ": "G00.9",
      },
    },
    283: {
      "Chief Complaint": "bleeding disorder",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hemophilia ": "D66",
        "Von Willebrand disease ": "D68.0",
        "Thrombocytopenia ": "D69.6",
        "Disseminated intravascular coagulation ": "D65",
        "Vitamin K deficiency ": "E56.1",
      },
    },
    284: {
      "Chief Complaint": "bleeding disorder",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hemophilia ": "D66",
        "Von Willebrand disease ": "D68.0",
        "Thrombocytopenia ": "D69.6",
        "Liver disease ": "K76.9",
        "Vitamin K deficiency ": "E56.1",
      },
    },
    285: {
      "Chief Complaint": "bleeding disorder",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hemophilia ": "D66",
        "Von Willebrand disease ": "D68.0",
        "Thrombocytopenia ": "D69.6",
        "Liver disease ": "K76.9",
        "Anticoagulant therapy complication ": "T45.515A",
      },
    },
    286: {
      "Chief Complaint": "blood in stool",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.0",
        "Meckel's diverticulum ": "Q43.0",
        "Intussusception ": "K56.1",
        "Juvenile polyps ": "D12.3",
        "Infectious colitis ": "A09.9",
      },
    },
    287: {
      "Chief Complaint": "blood in stool",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hemorrhoids ": "I84.9",
        "Anal fissure ": "K60.0",
        "Colorectal cancer ": "C18.9",
        "Gastric ulcer ": "K25.9",
        "Diverticulitis ": "K57.90",
      },
    },
    288: {
      "Chief Complaint": "blood in stool",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Colorectal cancer ": "C18.9",
        "Hemorrhoids ": "I84.9",
        "Diverticulitis ": "K57.90",
        "Gastric ulcer ": "K25.9",
        "Ischemic colitis ": "K55.0",
      },
    },
    289: {
      "Chief Complaint": "blood in urine",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Kidney stones ": "N20.0",
        "Glomerulonephritis ": "N05.9",
        "Trauma ": "S37.89XA",
        "Hemolytic uremic syndrome ": "D59.3",
      },
    },
    290: {
      "Chief Complaint": "blood in urine",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Kidney stones ": "N20.0",
        "Bladder cancer ": "C67.9",
        "Prostatitis ": "N41.9",
        "Urinary tract infection ": "N39.0",
        "Kidney injury ": "N17.9",
      },
    },
    291: {
      "Chief Complaint": "blood in urine",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Kidney stones ": "N20.0",
        "Bladder cancer ": "C67.9",
        "Kidney infection ": "N10",
        "Kidney injury ": "N17.9",
      },
    },
    292: {
      "Chief Complaint": "blood in urine",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Bladder cancer ": "C67.9",
        "Kidney stones ": "N20.0",
        "Urinary tract infection ": "N39.0",
        "Kidney injury ": "N17.9",
        "Benign prostatic hyperplasia ": "N40.0",
      },
    },
    293: {
      "Chief Complaint": "body odor",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Poor hygiene ": "R68.89",
        "Hyperhidrosis ": "R61",
        "Metabolic disorders ": "E88.9",
        "Infections ": "B99.9",
        "Puberty ": "E30.0",
      },
    },
    294: {
      "Chief Complaint": "body odor",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hyperhidrosis ": "R61",
        "Poor hygiene ": "R68.89",
        "Metabolic disorders ": "E88.9",
        "Hormonal changes ": "E89.9",
        "Diet ": "R63.8",
      },
    },
    295: {
      "Chief Complaint": "body odor",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Poor hygiene ": "R68.89",
        "Hyperhidrosis ": "R61",
        "Infections ": "B99.9",
        "Metabolic disorders ": "E88.9",
        "Medication side effect ": "T88.7XXA",
      },
    },
    296: {
      "Chief Complaint": "bone pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Growing pains ": "R29.89",
        "Osteomyelitis ": "M86.9",
        "Juvenile arthritis ": "M08.9",
        "Fracture ": "S82.9XXA",
        "Bone tumor ": "C40.9",
      },
    },
    297: {
      "Chief Complaint": "bone pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Osteoporosis ": "M81.0",
        "Bone metastases ": "C79.51",
        "Fracture ": "S82.9XXA",
        "Osteomyelitis ": "M86.9",
        "Arthritis ": "M15.9",
      },
    },
    298: {
      "Chief Complaint": "bone pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoporosis ": "M81.0",
        "Arthritis ": "M15.9",
        "Bone metastases ": "C79.51",
        "Osteoarthritis ": "M19.90",
        "Fracture ": "S82.9XXA",
      },
    },
    299: {
      "Chief Complaint": "breathing difficulty",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Foreign body aspiration ": "T17.908A",
        "Croup ": "J05.0",
        "Bronchiolitis ": "J21.9",
      },
    },
    300: {
      "Chief Complaint": "breathing difficulty",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "COPD exacerbation ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Heart failure ": "I50.9",
        "Asthma ": "J45.909",
      },
    },
    301: {
      "Chief Complaint": "breathing difficulty",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "COPD exacerbation ": "J44.9",
        "Pulmonary embolism ": "I26.99",
        "Heart failure ": "I50.9",
      },
    },
    302: {
      "Chief Complaint": "breathing difficulty",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "COPD exacerbation ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Asthma ": "J45.909",
      },
    },
    303: {
      "Chief Complaint": "bruise",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Accidental injury ": "T14.90",
        "Non-accidental trauma ": "T74.12XA",
        "Hemophilia ": "D66",
        "Idiopathic thrombocytopenic purpura ": "D69.3",
        "Vitamin K deficiency ": "E56.1",
      },
    },
    304: {
      "Chief Complaint": "bruise",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Accidental injury ": "T14.90",
        "Blood thinners ": "T45.515A",
        "Hemophilia ": "D66",
        "Vitamin K deficiency ": "E56.1",
        "Aging skin ": "L57.9",
      },
    },
    305: {
      "Chief Complaint": "bruise",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Accidental injury ": "T14.90",
        "Aging skin ": "L57.9",
        "Blood thinners ": "T45.515A",
        "Vitamin K deficiency ": "E56.1",
        "Hemophilia ": "D66",
      },
    },
    306: {
      "Chief Complaint": "burn",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T31.0",
        "Chemical burn ": "T26.50XA",
        "Sunburn ": "L55.9",
        "Electrical burn ": "T20.9XXA",
        "Scald ": "T31.0",
      },
    },
    307: {
      "Chief Complaint": "burn",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T31.0",
        "Chemical burn ": "T26.50XA",
        "Sunburn ": "L55.9",
        "Electrical burn ": "T20.9XXA",
        "Friction burn ": "T23.301A",
      },
    },
    308: {
      "Chief Complaint": "burn",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Thermal burn ": "T31.0",
        "Sunburn ": "L55.9",
        "Chemical burn ": "T26.50XA",
        "Electrical burn ": "T20.9XXA",
        "Scald ": "T31.0",
      },
    },
    309: {
      "Chief Complaint": "chills",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral infection ": "B34.9",
        "Pneumonia ": "J18.9",
        "Malaria ": "B50.9",
        "Sepsis ": "A41.9",
        "Influenza ": "J11.1",
      },
    },
    310: {
      "Chief Complaint": "chills",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Influenza ": "J11.1",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Malaria ": "B50.9",
        "Sepsis ": "A41.9",
      },
    },
    311: {
      "Chief Complaint": "chills",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Pneumonia ": "J18.9",
        "Sepsis ": "A41.9",
        "Urinary tract infection ": "N39.0",
        "Influenza ": "J11.1",
        "Malaria ": "B50.9",
      },
    },
    312: {
      "Chief Complaint": "cold symptoms",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Common cold ": "J00",
        "Influenza ": "J11.1",
        "Allergic rhinitis ": "J30.9",
        "Sinusitis ": "J32.9",
        "Bronchiolitis ": "J21.9",
      },
    },
    313: {
      "Chief Complaint": "cold symptoms",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Common cold ": "J00",
        "Influenza ": "J11.1",
        "Allergic rhinitis ": "J30.9",
        "Sinusitis ": "J32.9",
        "Pharyngitis ": "J02.9",
      },
    },
    314: {
      "Chief Complaint": "cold symptoms",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Influenza ": "J11.1",
        "Common cold ": "J00",
        "Sinusitis ": "J32.9",
        "Pharyngitis ": "J02.9",
        "Bronchitis ": "J40",
      },
    },
    315: {
      "Chief Complaint": "dehydration",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Acute gastroenteritis ": "A09",
        "Diabetes mellitus ": "E10.9",
        "Heat exhaustion ": "T67.3XXA",
        "Cystic fibrosis ": "E84.9",
        "Renal tubular acidosis ": "N25.89",
      },
    },
    316: {
      "Chief Complaint": "dehydration",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Acute gastroenteritis ": "A09",
        "Diabetes mellitus ": "E10.9",
        "Heat exhaustion ": "T67.3XXA",
        "Chronic kidney disease ": "N18.9",
        "Diabetes insipidus ": "E23.2",
      },
    },
    317: {
      "Chief Complaint": "dehydration",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Acute gastroenteritis ": "A09",
        "Chronic kidney disease ": "N18.9",
        "Diabetes mellitus ": "E10.9",
        "Medication side effect ": "T88.7XXA",
        "Heat exhaustion ": "T67.3XXA",
      },
    },
    318: {
      "Chief Complaint": "depression",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Major depressive disorder ": "F32.9",
        "Adjustment disorder ": "F43.20",
        "Bipolar disorder ": "F31.9",
        "Dysthymia ": "F34.1",
        "Substance-induced mood disorder ": "F19.94",
      },
    },
    319: {
      "Chief Complaint": "depression",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Major depressive disorder ": "F32.9",
        "Bipolar disorder ": "F31.9",
        "Dysthymia ": "F34.1",
        "Adjustment disorder ": "F43.20",
        "Substance-induced mood disorder ": "F19.94",
      },
    },
    320: {
      "Chief Complaint": "depression",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Major depressive disorder ": "F32.9",
        "Dysthymia ": "F34.1",
        "Adjustment disorder ": "F43.20",
        "Bipolar disorder ": "F31.9",
        "Depression due to medical condition ": "F06.31",
      },
    },
    321: {
      "Chief Complaint": "dysphagia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "GERD ": "K21.9",
        "Cleft palate ": "Q35.9",
        "Tonsillitis ": "J03.90",
        "Foreign body ingestion ": "T18.9XXA",
        "Esophageal stricture ": "K22.2",
      },
    },
    322: {
      "Chief Complaint": "dysphagia",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "GERD ": "K21.9",
        "Esophageal cancer ": "C15.9",
        "Stroke ": "I63.9",
        "Esophageal stricture ": "K22.2",
        "Achalasia ": "K22.0",
      },
    },
    323: {
      "Chief Complaint": "dysphagia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Stroke ": "I63.9",
        "Esophageal cancer ": "C15.9",
        "GERD ": "K21.9",
        "Esophageal stricture ": "K22.2",
        "Parkinson's disease ": "G20",
      },
    },
    324: {
      "Chief Complaint": "dry skin",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Atopic dermatitis ": "L20.9",
        "Contact dermatitis ": "L25.9",
        "Psoriasis ": "L40.9",
        "Ichthyosis ": "Q80.9",
        "Seborrheic dermatitis ": "L21.9",
      },
    },
    325: {
      "Chief Complaint": "dry skin",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Atopic dermatitis ": "L20.9",
        "Psoriasis ": "L40.9",
        "Contact dermatitis ": "L25.9",
        "Xerosis cutis ": "L85.3",
        "Hypothyroidism ": "E03.9",
      },
    },
    326: {
      "Chief Complaint": "dry skin",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Xerosis cutis ": "L85.3",
        "Atopic dermatitis ": "L20.9",
        "Psoriasis ": "L40.9",
        "Contact dermatitis ": "L25.9",
        "Hypothyroidism ": "E03.9",
      },
    },
    327: {
      "Chief Complaint": "ear discharge",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "Otitis externa ": "H60.9",
        "Foreign body in ear ": "T16",
        "Cholesteatoma ": "H71.90",
        "Ear injury ": "S09.93XA",
      },
    },
    328: {
      "Chief Complaint": "ear discharge",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Otitis externa ": "H60.9",
        "Otitis media ": "H66.90",
        "Cholesteatoma ": "H71.90",
        "Eczema of ear ": "L20.8",
        "Fungal ear infection ": "B36.9",
      },
    },
    329: {
      "Chief Complaint": "ear discharge",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Otitis externa ": "H60.9",
        "Otitis media ": "H66.90",
        "Cholesteatoma ": "H71.90",
        "Eczema of ear ": "L20.8",
        "Tumor of ear ": "C44.329",
      },
    },
    330: {
      "Chief Complaint": "ear fullness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Otitis media ": "H66.90",
        "Eustachian tube dysfunction ": "H69.8",
        "Foreign body in ear ": "T16",
        "Earwax impaction ": "H61.2",
        "Sinusitis ": "J32.9",
      },
    },
    331: {
      "Chief Complaint": "ear fullness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Eustachian tube dysfunction ": "H69.8",
        "Otitis media ": "H66.90",
        "Meniere's disease ": "H81.0",
        "Earwax impaction ": "H61.2",
        "Sinusitis ": "J32.9",
      },
    },
    332: {
      "Chief Complaint": "ear fullness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Eustachian tube dysfunction ": "H69.8",
        "Otitis media ": "H66.90",
        "Meniere's disease ": "H81.0",
        "Earwax impaction ": "H61.2",
        "Tumor of ear ": "C44.329",
      },
    },
    333: {
      "Chief Complaint": "ear infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Acute otitis media ": "H66.001",
        "Otitis externa ": "H60.9",
        "Chronic otitis media ": "H66.3",
        "Mastoiditis ": "H70.9",
        "Eustachian tube dysfunction ": "H69.8",
      },
    },
    334: {
      "Chief Complaint": "ear infection",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Otitis externa ": "H60.9",
        "Acute otitis media ": "H66.001",
        "Chronic otitis media ": "H66.3",
        "Eustachian tube dysfunction ": "H69.8",
        "Cholesteatoma ": "H71.90",
      },
    },
    335: {
      "Chief Complaint": "ear infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Otitis externa ": "H60.9",
        "Chronic otitis media ": "H66.3",
        "Acute otitis media ": "H66.001",
        "Cholesteatoma ": "H71.90",
        "Mastoiditis ": "H70.9",
      },
    },
    336: {
      "Chief Complaint": "edema",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nephrotic syndrome ": "N04.9",
        "Allergic reaction ": "T78.40XA",
        "Heart failure ": "I50.9",
        "Malnutrition ": "E46",
        "Liver disease ": "K76.9",
      },
    },
    337: {
      "Chief Complaint": "edema",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "Chronic kidney disease ": "N18.9",
        "Cirrhosis ": "K74.60",
        "Venous insufficiency ": "I87.2",
        "Lymphedema ": "I89.0",
      },
    },
    338: {
      "Chief Complaint": "edema",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "Chronic kidney disease ": "N18.9",
        "Venous insufficiency ": "I87.2",
        "Cirrhosis ": "K74.60",
        "Lymphedema ": "I89.0",
      },
    },
    339: {
      "Chief Complaint": "fainting",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Seizure ": "R56.9",
        "Hypoglycemia ": "E16.2",
        "Anemia ": "D64.9",
        "Arrhythmia ": "I49.9",
      },
    },
    340: {
      "Chief Complaint": "fainting",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Cardiac arrhythmia ": "I49.9",
        "Orthostatic hypotension ": "I95.1",
        "Seizure ": "R56.9",
        "Hypoglycemia ": "E16.2",
      },
    },
    341: {
      "Chief Complaint": "fainting",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cardiac arrhythmia ": "I49.9",
        "Vasovagal syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Carotid sinus hypersensitivity ": "G90.01",
        "Seizure ": "R56.9",
      },
    },
    342: {
      "Chief Complaint": "fatigue",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Mononucleosis ": "B27.90",
        "Hypothyroidism ": "E03.9",
        "Depression ": "F32.9",
        "Sleep disorders ": "G47.9",
      },
    },
    343: {
      "Chief Complaint": "fatigue",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Depression ": "F32.9",
        "Sleep apnea ": "G47.30",
        "Chronic fatigue syndrome ": "R53.82",
      },
    },
    344: {
      "Chief Complaint": "fatigue",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Heart failure ": "I50.9",
        "Depression ": "F32.9",
        "Sleep disorders ": "G47.9",
      },
    },
    345: {
      "Chief Complaint": "Fever",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral infection ": "B34.9",
        "Bacterial infection ": "A49.9",
        "Otitis media ": "H66.90",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Meningitis ": "G03.9",
        "Gastroenteritis ": "K52.9",
        "Influenza ": "J09.X2",
        "Roseola ": "B05.9",
        "Kawasaki disease ": "M30.3",
      },
    },
    346: {
      "Chief Complaint": "Fever",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Upper respiratory infection ": "J06.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Tuberculosis ": "A15.9",
        "HIV/AIDS ": "B20",
        "Hepatitis ": "B15.9",
        "Malaria ": "B50.9",
        "Lyme disease ": "A69.2",
        "Gastroenteritis ": "K52.9",
        "Influenza ": "J09.X2",
      },
    },
    347: {
      "Chief Complaint": "Fever",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Upper respiratory infection ": "J06.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Tuberculosis ": "A15.9",
        "HIV/AIDS ": "B20",
        "Hepatitis ": "B15.9",
        "Malaria ": "B50.9",
        "Lyme disease ": "A69.2",
        "Gastroenteritis ": "K52.9",
        "Pelvic inflammatory disease ": "N73.9",
      },
    },
    348: {
      "Chief Complaint": "Fever",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Influenza ": "J09.X2",
        "Tuberculosis ": "A15.9",
        "Cellulitis ": "L03.90",
        "Diverticulitis ": "K57.90",
        "Cholangitis ": "K83.0",
        "Endocarditis ": "I33.0",
        "Colorectal cancer ": "C18.9",
        "Gastroenteritis ": "K52.9",
      },
    },
    349: {
      "Chief Complaint": "flank pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pyelonephritis ": "N10",
        "Kidney stone ": "N20.0",
        "Musculoskeletal pain ": "M79.1",
        "Urinary tract infection ": "N39.0",
        "Hydronephrosis ": "N13.30",
      },
    },
    350: {
      "Chief Complaint": "flank pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Kidney stone ": "N20.0",
        "Pyelonephritis ": "N10",
        "Musculoskeletal pain ": "M79.1",
        "Renal cyst ": "N28.1",
        "Hydronephrosis ": "N13.30",
      },
    },
    351: {
      "Chief Complaint": "flank pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Kidney stone ": "N20.0",
        "Pyelonephritis ": "N10",
        "Aortic aneurysm ": "I71.9",
        "Musculoskeletal pain ": "M79.1",
        "Renal cyst ": "N28.1",
      },
    },
    352: {
      "Chief Complaint": "foot pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Plantar fasciitis ": "M72.2",
        "Sprain ": "S93.401A",
        "Flat foot ": "M21.4",
        "Osteochondritis ": "M92.52",
        "Foreign body ": "Z18.9",
      },
    },
    353: {
      "Chief Complaint": "foot pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Plantar fasciitis ": "M72.2",
        "Gout ": "M10.9",
        "Sprain ": "S93.401A",
        "Fracture ": "S92.909A",
        "Bunions ": "M20.1",
      },
    },
    354: {
      "Chief Complaint": "foot pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Plantar fasciitis ": "M72.2",
        "Gout ": "M10.9",
        "Osteoarthritis ": "M19.90",
        "Neuropathy ": "E11.40",
        "Fracture ": "S92.909A",
      },
    },
    355: {
      "Chief Complaint": "foreign body in eye",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0",
        "Conjunctivitis ": "H10.9",
        "Corneal ulcer ": "H16.009",
        "Chemical burn ": "T26.00XA",
        "Blepharitis ": "H01.00",
      },
    },
    356: {
      "Chief Complaint": "foreign body in eye",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0",
        "Corneal foreign body ": "S05.1",
        "Conjunctivitis ": "H10.9",
        "Corneal ulcer ": "H16.009",
        "Chemical burn ": "T26.00XA",
      },
    },
    357: {
      "Chief Complaint": "foreign body in eye",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0",
        "Corneal foreign body ": "S05.1",
        "Conjunctivitis ": "H10.9",
        "Cataract ": "H25.9",
        "Glaucoma ": "H40.9",
      },
    },
    358: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Aspiration ": "J69.0",
        "Esophageal obstruction ": "T18.198A",
        "Gastritis ": "K29.70",
        "Bowel obstruction ": "K56.609",
        "Perforation ": "K25.1",
      },
    },
    359: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Esophageal obstruction ": "T18.198A",
        "Bowel obstruction ": "K56.609",
        "Gastritis ": "K29.70",
        "Perforation ": "K25.1",
        "Aspiration ": "J69.0",
      },
    },
    360: {
      "Chief Complaint": "foreign body ingestion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Esophageal obstruction ": "T18.198A",
        "Bowel obstruction ": "K56.609",
        "Gastritis ": "K29.70",
        "Perforation ": "K25.1",
        "Aspiration ": "J69.0",
      },
    },
    361: {
      "Chief Complaint": "fracture",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Greenstick fracture ": "S52.512A",
        "Growth plate fracture ": "M93.90",
        "Contusion ": "S00.81XA",
        "Sprain ": "S93.401A",
        "Dislocation ": "S93.0",
      },
    },
    362: {
      "Chief Complaint": "fracture",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Compression fracture ": "S22.0XXA",
        "Stress fracture ": "M48.4",
        "Contusion ": "S00.81XA",
        "Sprain ": "S93.401A",
        "Dislocation ": "S93.0",
      },
    },
    363: {
      "Chief Complaint": "fracture",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hip fracture ": "S72.0XXA",
        "Compression fracture ": "S22.0XXA",
        "Osteoporotic fracture ": "M80.08XA",
        "Contusion ": "S00.81XA",
        "Sprain ": "S93.401A",
      },
    },
    364: {
      "Chief Complaint": "gastroenteritis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral gastroenteritis ": "A08.4",
        "Bacterial gastroenteritis ": "A04.9",
        "Food poisoning ": "A05.9",
        "Appendicitis ": "K35.80",
        "Intussusception ": "K56.1",
      },
    },
    365: {
      "Chief Complaint": "gastroenteritis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Viral gastroenteritis ": "A08.4",
        "Bacterial gastroenteritis ": "A04.9",
        "Food poisoning ": "A05.9",
        "IBS ": "K58.9",
        "Gastritis ": "K29.70",
      },
    },
    366: {
      "Chief Complaint": "gastroenteritis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Viral gastroenteritis ": "A08.4",
        "Bacterial gastroenteritis ": "A04.9",
        "Diverticulitis ": "K57.90",
        "Gastritis ": "K29.70",
        "IBS ": "K58.9",
      },
    },
    367: {
      "Chief Complaint": "heart palpitations",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Supraventricular tachycardia ": "I47.1",
        "Anxiety ": "F41.9",
        "Anemia ": "D64.9",
        "Electrolyte imbalance ": "E87.8",
        "Hyperthyroidism ": "E05.90",
      },
    },
    368: {
      "Chief Complaint": "heart palpitations",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Supraventricular tachycardia ": "I47.1",
        "Anxiety ": "F41.9",
        "Electrolyte imbalance ": "E87.8",
        "Hyperthyroidism ": "E05.90",
      },
    },
    369: {
      "Chief Complaint": "heart palpitations",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Heart failure ": "I50.9",
        "Ischemic heart disease ": "I25.9",
        "Electrolyte imbalance ": "E87.8",
        "Valvular heart disease ": "I34.9",
      },
    },
    370: {
      "Chief Complaint": "hemorrhoids",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.0",
        "Constipation ": "K59.00",
        "Anal abscess ": "K61.9",
        "Perianal dermatitis ": "L30.4",
        "Pinworm infection ": "B80",
      },
    },
    371: {
      "Chief Complaint": "hemorrhoids",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Internal hemorrhoids ": "I84.0",
        "External hemorrhoids ": "I84.4",
        "Anal fissure ": "K60.0",
        "Constipation ": "K59.00",
        "Anal abscess ": "K61.9",
      },
    },
    372: {
      "Chief Complaint": "hemorrhoids",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Internal hemorrhoids ": "I84.0",
        "External hemorrhoids ": "I84.4",
        "Anal fissure ": "K60.0",
        "Colorectal cancer ": "C18.9",
        "Diverticulitis ": "K57.90",
      },
    },
    373: {
      "Chief Complaint": "hepatitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hepatitis A ": "B15.9",
        "Hepatitis B ": "B16.9",
        "Hepatitis C ": "B17.10",
        "Autoimmune hepatitis ": "K75.4",
        "Drug-induced hepatitis ": "K71.0",
      },
    },
    374: {
      "Chief Complaint": "hepatitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hepatitis C ": "B17.10",
        "Hepatitis B ": "B16.9",
        "Alcoholic hepatitis ": "K70.1",
        "Hepatitis A ": "B15.9",
        "Drug-induced hepatitis ": "K71.0",
      },
    },
    375: {
      "Chief Complaint": "hepatitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hepatitis C ": "B17.10",
        "Hepatitis B ": "B16.9",
        "Alcoholic hepatitis ": "K70.1",
        "Cirrhosis ": "K74.60",
        "Liver cancer ": "C22.9",
      },
    },
    376: {
      "Chief Complaint": "hiv/aids",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "HIV disease ": "B20",
        "Opportunistic infections ": "B20",
        "Tuberculosis ": "A15.9",
        "Pneumocystis pneumonia ": "B59",
        "Candidiasis ": "B37.9",
      },
    },
    377: {
      "Chief Complaint": "hiv/aids",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "HIV disease ": "B20",
        "Opportunistic infections ": "B20",
        "Tuberculosis ": "A15.9",
        "Kaposi's sarcoma ": "C46.9",
        "Lymphoma ": "C81.90",
      },
    },
    378: {
      "Chief Complaint": "hiv/aids",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "HIV disease ": "B20",
        "Opportunistic infections ": "B20",
        "Tuberculosis ": "A15.9",
        "Kaposi's sarcoma ": "C46.9",
        "Lymphoma ": "C81.90",
      },
    },
    379: {
      "Chief Complaint": "Elevated Blood Pressure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Elevated Blood Pressure ": "R03.0",
        "Essential hypertension ": "I10",
        "Secondary hypertension ": "I15.9",
        "Kidney disease ": "N18.9",
        "Endocrine disorders ": "E00-E35",
        "Obesity ": "E66.9",
        "Medication side effect ": "T88.7",
        "Coarctation of the aorta ": "Q25.1",
        "Sleep apnea ": "G47.33",
        "Stress ": "F43.9",
      },
    },
    380: {
      "Chief Complaint": "Elevated Blood Pressure",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Elevated Blood Pressure ": "R03.0",
        "Essential hypertension ": "I10",
        "Secondary hypertension ": "I15.9",
        "Kidney disease ": "N18.9",
        "Obesity ": "E66.9",
        "Alcohol use ": "F10.20",
        "Sleep apnea ": "G47.33",
        "Thyroid disorders ": "E07.9",
        "Adrenal gland disorders ": "E27.9",
        "Stress ": "F43.9",
      },
    },
    381: {
      "Chief Complaint": "Elevated Blood Pressure",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Elevated Blood Pressure ": "R03.0",
        "Essential hypertension ": "I10",
        "Pregnancy-induced hypertension ": "O13.9",
        "Secondary hypertension ": "I15.9",
        "Kidney disease ": "N18.9",
        "Obesity ": "E66.9",
        "Thyroid disorders ": "E07.9",
        "Oral contraceptive use ": "Z79.4",
        "Sleep apnea ": "G47.33",
        "Stress ": "F43.9",
      },
    },
    382: {
      "Chief Complaint": "Elevated Blood Pressure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elevated Blood Pressure ": "R03.0",
        "Essential hypertension ": "I10",
        "Secondary hypertension ": "I15.9",
        "Kidney disease ": "N18.9",
        "Atherosclerosis ": "I70.9",
        "Diabetes mellitus ": "E11.9",
        "Thyroid disorders ": "E07.9",
        "Medication side effects ": "T88.7",
        "Sleep apnea ": "G47.33",
        "Chronic pain ": "G89.29",
      },
    },
    383: {
      "Chief Complaint": "hypothermia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Accidental hypothermia ": "T68",
        "Exposure to excessive cold ": "X31",
        "Malnutrition ": "E46",
        "Hypothyroidism ": "E03.9",
        "Sepsis ": "A41.9",
      },
    },
    384: {
      "Chief Complaint": "hypothermia",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Accidental hypothermia ": "T68",
        "Exposure to excessive cold ": "X31",
        "Alcohol intoxication ": "F10.92",
        "Hypothyroidism ": "E03.9",
        "Sepsis ": "A41.9",
      },
    },
    385: {
      "Chief Complaint": "hypothermia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Accidental hypothermia ": "T68",
        "Exposure to excessive cold ": "X31",
        "Hypothyroidism ": "E03.9",
        "Malnutrition ": "E46",
        "Sepsis ": "A41.9",
      },
    },
    386: {
      "Chief Complaint": "incontinence",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Overactive bladder ": "N33.9",
        "Constipation ": "K59.00",
        "Neurogenic bladder ": "N31.9",
        "Stress incontinence ": "N39.3",
      },
    },
    387: {
      "Chief Complaint": "incontinence",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Stress incontinence ": "N39.3",
        "Urge incontinence ": "N39.41",
        "Mixed incontinence ": "N39.46",
        "Overactive bladder ": "N33.9",
        "UTI ": "N39.0",
      },
    },
    388: {
      "Chief Complaint": "incontinence",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.0",
        "Overactive bladder ": "N33.9",
        "Neurogenic bladder ": "N31.9",
        "UTI ": "N39.0",
        "Prostate cancer ": "C61",
      },
    },
    389: {
      "Chief Complaint": "incontinence",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Overactive bladder ": "N33.9",
        "Stress incontinence ": "N39.3",
        "Neurogenic bladder ": "N31.9",
        "UTI ": "N39.0",
        "Dementia ": "G30.9",
      },
    },
    390: {
      "Chief Complaint": "insomnia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Primary insomnia ": "G47.00",
        "Sleep apnea ": "G47.30",
        "Restless leg syndrome ": "G25.81",
        "Anxiety ": "F41.9",
        "Depression ": "F32.9",
      },
    },
    391: {
      "Chief Complaint": "insomnia",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Primary insomnia ": "G47.00",
        "Sleep apnea ": "G47.30",
        "Restless leg syndrome ": "G25.81",
        "Anxiety ": "F41.9",
        "Depression ": "F32.9",
      },
    },
    392: {
      "Chief Complaint": "insomnia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Primary insomnia ": "G47.00",
        "Sleep apnea ": "G47.30",
        "Restless leg syndrome ": "G25.81",
        "Medication-induced insomnia ": "Y45.9",
        "Depression ": "F32.9",
      },
    },
    393: {
      "Chief Complaint": "itching",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Atopic dermatitis ": "L20.9",
        "Contact dermatitis ": "L25.9",
        "Scabies ": "B86",
        "Urticaria ": "L50.9",
        "Dry skin ": "L85.3",
      },
    },
    394: {
      "Chief Complaint": "itching",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Atopic dermatitis ": "L20.9",
        "Contact dermatitis ": "L25.9",
        "Psoriasis ": "L40.9",
        "Urticaria ": "L50.9",
        "Fungal infection ": "B35.9",
      },
    },
    395: {
      "Chief Complaint": "itching",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atopic dermatitis ": "L20.9",
        "Contact dermatitis ": "L25.9",
        "Dry skin ": "L85.3",
        "Psoriasis ": "L40.9",
        "Lichen planus ": "L43.9",
      },
    },
    396: {
      "Chief Complaint": "jaundice",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Neonatal jaundice ": "P59.9",
        "Biliary atresia ": "Q44.2",
        "Hemolytic anemia ": "D58.9",
        "Hepatitis ": "K75.9",
        "Gilbert's syndrome ": "E80.4",
      },
    },
    397: {
      "Chief Complaint": "jaundice",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hepatitis ": "K75.9",
        "Alcoholic liver disease ": "K70.9",
        "Cholelithiasis ": "K80.20",
        "Hemolytic anemia ": "D58.9",
        "Cirrhosis ": "K74.60",
      },
    },
    398: {
      "Chief Complaint": "jaundice",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hepatitis ": "K75.9",
        "Cirrhosis ": "K74.60",
        "Cholelithiasis ": "K80.20",
        "Pancreatic cancer ": "C25.9",
        "Hemolytic anemia ": "D58.9",
      },
    },
    399: {
      "Chief Complaint": "joint pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Juvenile idiopathic arthritis ": "M08.9",
        "Growing pains ": "R29.89",
        "Reactive arthritis ": "M02.9",
        "Osgood-Schlatter disease ": "M92.52",
        "Lyme disease ": "A69.20",
      },
    },
    400: {
      "Chief Complaint": "joint pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M15.9",
        "Rheumatoid arthritis ": "M06.9",
        "Polymyalgia rheumatica ": "M35.3",
        "Gout ": "M10.9",
        "Bursitis ": "M71.9",
      },
    },
    401: {
      "Chief Complaint": "kidney stone",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nephrolithiasis ": "N20.0",
        "Hydronephrosis ": "N13.30",
        "UTI ": "N39.0",
        "Pyelonephritis ": "N10",
        "Hypercalciuria ": "E83.51",
      },
    },
    402: {
      "Chief Complaint": "kidney stone",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Nephrolithiasis ": "N20.0",
        "Hydronephrosis ": "N13.30",
        "UTI ": "N39.0",
        "Pyelonephritis ": "N10",
        "Hyperparathyroidism ": "E21.0",
      },
    },
    403: {
      "Chief Complaint": "kidney stone",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Nephrolithiasis ": "N20.0",
        "Hydronephrosis ": "N13.30",
        "UTI ": "N39.0",
        "Pyelonephritis ": "N10",
        "Hyperparathyroidism ": "E21.0",
      },
    },
    404: {
      "Chief Complaint": "laceration",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Unspecified open wound ": "S61.909A",
        "Animal bite ": "W54.0XXA",
        "Foreign body ": "Z18.9",
        "Contusion ": "S00.81XA",
        "Abrasion ": "S00.81XA",
      },
    },
    405: {
      "Chief Complaint": "laceration",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Unspecified open wound ": "S61.909A",
        "Animal bite ": "W54.0XXA",
        "Foreign body ": "Z18.9",
        "Contusion ": "S00.81XA",
        "Abrasion ": "S00.81XA",
      },
    },
    406: {
      "Chief Complaint": "laceration",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Unspecified open wound ": "S61.909A",
        "Animal bite ": "W54.0XXA",
        "Foreign body ": "Z18.9",
        "Contusion ": "S00.81XA",
        "Abrasion ": "S00.81XA",
      },
    },
    407: {
      "Chief Complaint": "leg pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Growing pains ": "R29.89",
        "Osgood-Schlatter disease ": "M92.52",
        "Juvenile idiopathic arthritis ": "M08.9",
        "Fracture ": "S82.9XXA",
        "Shin splints ": "M79.89",
      },
    },
    408: {
      "Chief Complaint": "leg pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Deep vein thrombosis ": "I82.90",
        "Sciatica ": "M54.30",
        "Peripheral artery disease ": "I70.209",
        "Muscle strain ": "M62.830",
        "Shin splints ": "M79.89",
      },
    },
    409: {
      "Chief Complaint": "leg pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Deep vein thrombosis ": "I82.90",
        "Sciatica ": "M54.30",
        "Peripheral artery disease ": "I70.209",
        "Osteoarthritis ": "M15.9",
        "Muscle strain ": "M62.830",
      },
    },
    410: {
      "Chief Complaint": "loss of consciousness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Syncope ": "R55",
        "Seizure ": "R56.9",
        "Hypoglycemia ": "E16.2",
        "Head injury ": "S09.90XA",
        "Breath-holding spell ": "R68.13",
      },
    },
    411: {
      "Chief Complaint": "loss of consciousness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Syncope ": "R55",
        "Seizure ": "R56.9",
        "Hypoglycemia ": "E16.2",
        "Cardiac arrhythmia ": "I49.9",
        "Drug overdose ": "T50.901A",
      },
    },
    412: {
      "Chief Complaint": "loss of consciousness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Syncope ": "R55",
        "Seizure ": "R56.9",
        "Hypoglycemia ": "E16.2",
        "Cardiac arrhythmia ": "I49.9",
        "Stroke ": "I63.9",
      },
    },
    413: {
      "Chief Complaint": "low blood sugar",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hypoglycemia ": "E16.2",
        "Diabetic hypoglycemia ": "E13.649",
        "Adrenal insufficiency ": "E27.1",
        "Insulin overdose ": "T38.3X6A",
        "Starvation ": "E40",
      },
    },
    414: {
      "Chief Complaint": "low blood sugar",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hypoglycemia ": "E16.2",
        "Diabetic hypoglycemia ": "E13.649",
        "Insulinoma ": "D13.7",
        "Insulin overdose ": "T38.3X6A",
        "Alcohol-induced hypoglycemia ": "E16.0",
      },
    },
    415: {
      "Chief Complaint": "low blood sugar",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hypoglycemia ": "E16.2",
        "Diabetic hypoglycemia ": "E13.649",
        "Insulinoma ": "D13.7",
        "Insulin overdose ": "T38.3X6A",
        "Adrenal insufficiency ": "E27.1",
      },
    },
    416: {
      "Chief Complaint": "Low Blood Pressure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Low Blood Pressure ": "I95.9",
        "Infection ": "A49.9",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Septic shock ": "R57.2",
        "Heart conditions ": "I51.9",
        "Endocrine disorders ": "E00-E35",
        "Medication side effect ": "T88.7",
        "Nutritional deficiencies ": "E63.9",
        "Vasovagal syncope ": "R55",
      },
    },
    417: {
      "Chief Complaint": "Low Blood Pressure",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Low Blood Pressure ": "I95.9",
        "Infection ": "A49.9",
        "Dehydration ": "E86.0",
        "Heart failure ": "I50.9",
        "Septic shock ": "R57.2",
        "Anemia ": "D64.9",
        "Medication side effect ": "T88.7",
        "Vasovagal syncope ": "R55",
        "Endocrine disorders ": "E00-E35",
        "Alcohol use ": "F10.20",
        "Autonomic neuropathy ": "G90.9",
      },
    },
    418: {
      "Chief Complaint": "Low Blood Pressure",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Low Blood Pressure ": "I95.9",
        "Infection ": "A49.9",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Pregnancy ": "O26.89",
        "Medication side effect ": "T88.7",
        "Vasovagal syncope ": "R55",
        "Endocrine disorders ": "E00-E35",
        "Heart conditions ": "I51.9",
        "Septic shock ": "R57.2",
        "Eating disorders ": "F50.9",
      },
    },
    419: {
      "Chief Complaint": "Low Blood Pressure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Low Blood Pressure ": "I95.9",
        "Infection ": "A49.9",
        "Dehydration ": "E86.0",
        "Heart failure ": "I50.9",
        "Medication side effect ": "T88.7",
        "Anemia ": "D64.9",
        "Orthostatic hypotension ": "I95. 1",
        "Septic shock ": "R57.2",
      },
    },
    420: {
      "Chief Complaint": "lung disease",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Cystic fibrosis ": "E84.9",
        "Bronchiolitis ": "J21.9",
        "Tuberculosis ": "A15.9",
      },
    },
    421: {
      "Chief Complaint": "lung disease",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Lung cancer ": "C34.90",
      },
    },
    422: {
      "Chief Complaint": "lung disease",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Lung cancer ": "C34.90",
        "Interstitial lung disease ": "J84.9",
      },
    },
    423: {
      "Chief Complaint": "tick bite",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Lyme disease ": "A69.20",
        "Viral exanthem ": "B09",
        "Rocky Mountain spotted fever ": "A77.0",
        "Erythema migrans ": "A69.22",
        "Juvenile idiopathic arthritis ": "M08.9",
      },
    },
    424: {
      "Chief Complaint": "tick bite",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Lyme disease ": "A69.20",
        "Erythema migrans ": "A69.22",
        "Rocky Mountain spotted fever ": "A77.0",
        "Rheumatoid arthritis ": "M06.9",
        "Fibromyalgia ": "M79.7",
      },
    },
    425: {
      "Chief Complaint": "tick bite",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Lyme disease ": "A69.20",
        "Erythema migrans ": "A69.22",
        "Rocky Mountain spotted fever ": "A77.0",
        "Rheumatoid arthritis ": "M06.9",
        "Polymyalgia rheumatica ": "M35.3",
      },
    },
    426: {
      "Chief Complaint": "malaria",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Malaria ": "B50.9",
        "Dengue fever ": "A90",
        "Typhoid fever ": "A01.0",
        "Viral fever ": "B34.9",
        "Sepsis ": "A41.9",
      },
    },
    427: {
      "Chief Complaint": "malaria",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Malaria ": "B50.9",
        "Dengue fever ": "A90",
        "Typhoid fever ": "A01.0",
        "Viral fever ": "B34.9",
        "Yellow fever ": "A95.9",
      },
    },
    428: {
      "Chief Complaint": "malaria",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Malaria ": "B50.9",
        "Dengue fever ": "A90",
        "Typhoid fever ": "A01.0",
        "Viral fever ": "B34.9",
        "Yellow fever ": "A95.9",
      },
    },
    429: {
      "Chief Complaint": "measles",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Measles ": "B05.9",
        "Rubella ": "B06.9",
        "Viral exanthem ": "B09",
        "Scarlet fever ": "A38.9",
        "Roseola ": "B08.1",
      },
    },
    430: {
      "Chief Complaint": "measles",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Measles ": "B05.9",
        "Rubella ": "B06.9",
        "Viral exanthem ": "B09",
        "Drug rash ": "L27.0",
        "Allergic reaction ": "T78.40XA",
      },
    },
    431: {
      "Chief Complaint": "measles",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Measles ": "B05.9",
        "Rubella ": "B06.9",
        "Viral exanthem ": "B09",
        "Drug rash ": "L27.0",
        "Allergic reaction ": "T78.40XA",
      },
    },
    432: {
      "Chief Complaint": "menstrual problems",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Dysmenorrhea ": "N94.6",
        "Menorrhagia ": "N92.0",
        "Polycystic ovary syndrome ": "E28.2",
        "Ovarian cyst ": "N83.20",
        "Endometriosis ": "N80.9",
      },
    },
    433: {
      "Chief Complaint": "menstrual problems",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dysmenorrhea ": "N94.6",
        "Menorrhagia ": "N92.0",
        "Polycystic ovary syndrome ": "E28.2",
        "Ovarian cyst ": "N83.20",
        "Endometriosis ": "N80.9",
      },
    },
    434: {
      "Chief Complaint": "menstrual problems",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Postmenopausal bleeding ": "N95.0",
        "Uterine fibroids ": "D25.9",
        "Endometrial cancer ": "C54.1",
        "Ovarian cancer ": "C56.9",
        "Vaginal atrophy ": "N95.2",
      },
    },
    435: {
      "Chief Complaint": "migraine",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Cluster headache ": "G44.029",
        "Sinusitis ": "J32.9",
        "Intracranial hypertension ": "G93.2",
      },
    },
    436: {
      "Chief Complaint": "migraine",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Cluster headache ": "G44.029",
        "Sinusitis ": "J32.9",
        "Brain tumor ": "C71.9",
      },
    },
    437: {
      "Chief Complaint": "migraine",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Migraine ": "G43.909",
        "Tension-type headache ": "G44.209",
        "Temporal arteritis ": "M31.6",
        "Brain tumor ": "C71.9",
        "Normal pressure hydrocephalus ": "G91.2",
      },
    },
    438: {
      "Chief Complaint": "mononucleosis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Infectious mononucleosis ": "B27.90",
        "Streptococcal pharyngitis ": "J02.0",
        "Tonsillitis ": "J03.90",
        "Viral pharyngitis ": "J02.9",
        "Influenza ": "J11.1",
      },
    },
    439: {
      "Chief Complaint": "mononucleosis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Infectious mononucleosis ": "B27.90",
        "Streptococcal pharyngitis ": "J02.0",
        "Tonsillitis ": "J03.90",
        "Viral pharyngitis ": "J02.9",
        "Influenza ": "J11.1",
      },
    },
    440: {
      "Chief Complaint": "mononucleosis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Infectious mononucleosis ": "B27.90",
        "Streptococcal pharyngitis ": "J02.0",
        "Tonsillitis ": "J03.90",
        "Viral pharyngitis ": "J02.9",
        "Influenza ": "J11.1",
      },
    },
    441: {
      "Chief Complaint": "muscle pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Myalgia ": "M79.1",
        "Viral myositis ": "M60.89",
        "Strain ": "S39.012A",
        "Growing pains ": "M79.8",
        "Influenza ": "J11.1",
      },
    },
    442: {
      "Chief Complaint": "muscle pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Myalgia ": "M79.1",
        "Fibromyalgia ": "M79.7",
        "Strain ": "S39.012A",
        "Polymyalgia rheumatica ": "M35.3",
        "Influenza ": "J11.1",
      },
    },
    443: {
      "Chief Complaint": "muscle pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Myalgia ": "M79.1",
        "Polymyalgia rheumatica ": "M35.3",
        "Fibromyalgia ": "M79.7",
        "Strain ": "S39.012A",
        "Osteoarthritis ": "M19.90",
      },
    },
    444: {
      "Chief Complaint": "nervousness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anxiety disorder ": "F41.9",
        "ADHD ": "F90.9",
        "Panic disorder ": "F41.0",
        "Hyperthyroidism ": "E05.90",
        "Adjustment disorder ": "F43.20",
      },
    },
    445: {
      "Chief Complaint": "nervousness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Anxiety disorder ": "F41.9",
        "Panic disorder ": "F41.0",
        "Hyperthyroidism ": "E05.90",
        "Caffeine intoxication ": "F15.929",
        "Adjustment disorder ": "F43.20",
      },
    },
    446: {
      "Chief Complaint": "nervousness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Anxiety disorder ": "F41.9",
        "Dementia ": "F03.90",
        "Hyperthyroidism ": "E05.90",
        "Depression ": "F32.9",
        "Medication side effect ": "T88.7XXA",
      },
    },
    447: {
      "Chief Complaint": "numbness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Peripheral neuropathy ": "G62.9",
        "Hyperventilation 3. Migraine ": "G43.909",
        "Vitamin B12 deficiency ": "D51.9",
        "Anxiety ": "F41.9",
      },
    },
    448: {
      "Chief Complaint": "numbness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Peripheral neuropathy ": "G62.9",
        "Multiple sclerosis ": "G35",
        "Stroke ": "I63.9",
        "Vitamin B12 deficiency ": "D51.9",
        "Carpal tunnel syndrome ": "G56.00",
      },
    },
    449: {
      "Chief Complaint": "numbness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Peripheral neuropathy ": "G62.9",
        "Stroke ": "I63.9",
        "Vitamin B12 deficiency ": "D51.9",
        "Diabetic neuropathy ": "E11.40",
        "Spinal stenosis ": "M48.06",
      },
    },
    450: {
      "Chief Complaint": "obesity",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Obesity ": "E66.9",
        "Hypothyroidism ": "E03.9",
        "Cushing's syndrome ": "E24.9",
      },
    },
    451: {
      "Chief Complaint": "obesity",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Obesity ": "E66.9",
        "Hypothyroidism ": "E03.9",
        "Polycystic ovary syndrome ": "E28.2",
        "Cushing's syndrome ": "E24.9",
      },
    },
    452: {
      "Chief Complaint": "obesity",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Obesity ": "E66.9",
        "Hypothyroidism ": "E03.9",
        "Medication-induced weight gain 4. Sedentary lifestyle 5. Metabolic syndrome ":
          "E88.81",
      },
    },
    453: {
      "Chief Complaint": "palpable mass",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cyst 2. Hemangioma ": "D18.01",
        "Lymphadenopathy ": "R59.0",
        "Lipoma ": "D17.9",
      },
    },
    454: {
      "Chief Complaint": "palpable mass",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Lipoma ": "D17.9",
        "Cyst 3. Fibroadenoma ": "D24",
        "Lymphadenopathy ": "R59.0",
      },
    },
    455: {
      "Chief Complaint": "palpable mass",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Lipoma ": "D17.9",
        "Cyst 3. Carcinoma ": "C80.1",
        "Lymphadenopathy ": "R59.0",
      },
    },
    456: {
      "Chief Complaint": "parasitic infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pinworms ": "B80",
        "Giardiasis ": "A07.1",
        "Malaria ": "B50.9",
        "Tapeworm ": "B68.9",
        "Lice infestation ": "B85.0",
      },
    },
    457: {
      "Chief Complaint": "parasitic infection",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Giardiasis ": "A07.1",
        "Malaria ": "B50.9",
        "Tapeworm ": "B68.9",
        "Trichomoniasis ": "A59.0",
        "Lice infestation ": "B85.0",
      },
    },
    458: {
      "Chief Complaint": "parasitic infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Giardiasis ": "A07.1",
        "Malaria ": "B50.9",
        "Tapeworm ": "B68.9",
        "Lice infestation ": "B85.0",
        "Pinworms ": "B80",
      },
    },
    459: {
      "Chief Complaint": "pelvic pain",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Ovarian cyst ": "N83.2",
        "Mittelschmerz 3. Endometriosis ": "N80.9",
        "Pelvic inflammatory disease ": "N73.9",
        "Urinary tract infection ": "N39.0",
      },
    },
    460: {
      "Chief Complaint": "pelvic pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ovarian cyst ": "N83.2",
        "Endometriosis ": "N80.9",
        "Pelvic inflammatory disease ": "N73.9",
        "Ectopic pregnancy ": "O00.9",
        "Uterine fibroids ": "D25.9",
      },
    },
    461: {
      "Chief Complaint": "pelvic pain",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Ovarian cyst ": "N83.2",
        "Uterine fibroids ": "D25.9",
        "Endometrial cancer ": "C54.1",
        "Diverticulitis ": "K57.9",
        "Urinary tract infection ": "N39.0",
      },
    },
    462: {
      "Chief Complaint": "pneumonia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Bacterial pneumonia ": "J15.9",
        "Viral pneumonia ": "J12.9",
        "Mycoplasma pneumonia ": "J15.7",
        "Aspiration pneumonia ": "J69.0",
        "Bronchiolitis ": "J21.9",
      },
    },
    463: {
      "Chief Complaint": "pneumonia",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Bacterial pneumonia ": "J15.9",
        "Viral pneumonia ": "J12.9",
        "Aspiration pneumonia ": "J69.0",
        "Pneumocystis pneumonia ": "B59",
        "Tuberculosis ": "A15.9",
      },
    },
    464: {
      "Chief Complaint": "pneumonia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Bacterial pneumonia ": "J15.9",
        "Aspiration pneumonia ": "J69.0",
        "Influenza with pneumonia ": "J10.0",
        "Pneumocystis pneumonia ": "B59",
      },
    },
    465: {
      "Chief Complaint": "poisoning",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Medication overdose ": "T50.901A",
        "Household cleaner ingestion 3. Plant ingestion 4. Carbon monoxide poisoning ":
          "T58.01XA",
        "Alcohol poisoning ": "T51.0X1A",
      },
    },
    466: {
      "Chief Complaint": "poisoning",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Medication overdose ": "T50.901A",
        "Alcohol poisoning ": "T51.0X1A",
        "Recreational drug overdose 4. Carbon monoxide poisoning ": "T58.01XA",
        "Pesticide poisoning ": "T60.9X1A",
      },
    },
    467: {
      "Chief Complaint": "poisoning",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Medication overdose ": "T50.901A",
        "Carbon monoxide poisoning ": "T58.01XA",
        "Household cleaner ingestion 4. Plant ingestion 5. Alcohol poisoning ":
          "T51.0X1A",
      },
    },
    468: {
      "Chief Complaint": "respiratory infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Bronchiolitis ": "J21.9",
        "Pneumonia ": "J18.9",
        "Croup ": "J05.0",
        "Tuberculosis ": "A15.9",
        "Whooping cough ": "A37.9",
      },
    },
    469: {
      "Chief Complaint": "respiratory infection",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Pneumonia ": "J18.9",
        "Tuberculosis ": "A15.9",
        "Bronchitis ": "J40",
        "Influenza ": "J10.1",
        "COVID-19 ": "U07.1",
      },
    },
    470: {
      "Chief Complaint": "respiratory infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Pneumonia ": "J18.9",
        "Influenza ": "J10.1",
        "Tuberculosis ": "A15.9",
        "COVID-19 ": "U07.1",
        "Chronic bronchitis ": "J42",
      },
    },
    471: {
      "Chief Complaint": "seizure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Febrile seizure ": "R56.0",
        "Epilepsy ": "G40.909",
        "Electrolyte imbalance 4. Meningitis ": "G03.9",
        "Brain tumor ": "C71.9",
      },
    },
    472: {
      "Chief Complaint": "seizure",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Epilepsy ": "G40.909",
        "Alcohol withdrawal ": "F10.239",
        "Brain tumor ": "C71.9",
        "Stroke ": "I63.9",
      },
    },
    473: {
      "Chief Complaint": "seizure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Epilepsy ": "G40.909",
        "Stroke ": "I63.9",
        "Brain tumor ": "C71.9",
        "Alzheimer's disease ": "G30.9",
      },
    },
    474: {
      "Chief Complaint": "sti",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Herpes simplex ": "A60.9",
        "Chlamydia ": "A56.9",
        "Gonorrhea ": "A54.9",
        "HPV ": "B97.7",
        "Syphilis ": "A53.9",
      },
    },
    475: {
      "Chief Complaint": "sti",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "HIV ": "B20",
        "Herpes simplex ": "A60.9",
        "Chlamydia ": "A56.9",
        "Gonorrhea ": "A54.9",
        "HPV ": "B97.7",
      },
    },
    476: {
      "Chief Complaint": "sti",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Herpes simplex ": "A60.9",
        "Chlamydia ": "A56.9",
        "Gonorrhea ": "A54.9",
        "Syphilis ": "A53.9",
        "HIV ": "B20",
      },
    },
    477: {
      "Chief Complaint": "std",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Herpes simplex ": "A60.9",
        "Chlamydia ": "A56.9",
        "Gonorrhea ": "A54.9",
        "HPV ": "B97.7",
        "Syphilis ": "A53.9",
      },
    },
    478: {
      "Chief Complaint": "std",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "HIV ": "B20",
        "Herpes simplex ": "A60.9",
        "Chlamydia ": "A56.9",
        "Gonorrhea ": "A54.9",
        "HPV ": "B97.7",
      },
    },
    479: {
      "Chief Complaint": "std",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Herpes simplex ": "A60.9",
        "Chlamydia ": "A56.9",
        "Gonorrhea ": "A54.9",
        "Syphilis ": "A53.9",
        "HIV ": "B20",
      },
    },
    480: {
      "Chief Complaint": "shoulder pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S43.409A",
        "Fracture ": "S42.9XXA",
        "Tendinitis ": "M75.90",
        "Dislocation ": "S43.009A",
        "Rotator cuff tear ": "M75.100",
      },
    },
    481: {
      "Chief Complaint": "shoulder pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Rotator cuff tear ": "M75.100",
        "Tendinitis ": "M75.90",
        "Arthritis ": "M19.91",
        "Fracture ": "S42.9XXA",
        "Bursitis ": "M75.80",
      },
    },
    482: {
      "Chief Complaint": "shoulder pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Arthritis ": "M19.91",
        "Rotator cuff tear ": "M75.100",
        "Fracture ": "S42.9XXA",
        "Tendinitis ": "M75.90",
        "Bursitis ": "M75.80",
      },
    },
    483: {
      "Chief Complaint": "sinusitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Acute sinusitis ": "J01.90",
        "Allergic rhinitis ": "J30.9",
        "Nasal polyps ": "J33.9",
        "Common cold ": "J00",
        "Influenza ": "J10.1",
      },
    },
    484: {
      "Chief Complaint": "sinusitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Acute sinusitis ": "J01.90",
        "Chronic sinusitis ": "J32.9",
        "Allergic rhinitis ": "J30.9",
        "Nasal polyps ": "J33.9",
        "Influenza ": "J10.1",
      },
    },
    485: {
      "Chief Complaint": "sinusitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Chronic sinusitis ": "J32.9",
        "Acute sinusitis ": "J01.90",
        "Allergic rhinitis ": "J30.9",
        "Nasal polyps ": "J33.9",
        "Influenza ": "J10.1",
      },
    },
    486: {
      "Chief Complaint": "skin infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Impetigo ": "L01.00",
        "Cellulitis ": "L03.90",
        "Ringworm ": "B35.9",
        "Scabies ": "B86",
        "Herpes simplex ": "B00.9",
      },
    },
    487: {
      "Chief Complaint": "skin infection",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Abscess ": "L02.91",
        "Herpes zoster ": "B02.9",
        "Fungal infection ": "B35.9",
        "MRSA skin infection ": "A49.02",
      },
    },
    488: {
      "Chief Complaint": "skin infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Abscess ": "L02.91",
        "Herpes zoster ": "B02.9",
        "Fungal infection ": "B35.9",
        "MRSA skin infection ": "A49.02",
      },
    },
    489: {
      "Chief Complaint": "sleep disorder",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Insomnia ": "G47.00",
        "Sleep apnea ": "G47.30",
        "Restless legs syndrome ": "G25.81",
        "Sleepwalking ": "F51.3",
        "Nightmares ": "F51.5",
      },
    },
    490: {
      "Chief Complaint": "sleep disorder",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Insomnia ": "G47.00",
        "Sleep apnea ": "G47.30",
        "Narcolepsy ": "G47.419",
        "Restless legs syndrome ": "G25.81",
        "Circadian rhythm sleep disorder ": "G47.20",
      },
    },
    491: {
      "Chief Complaint": "sleep disorder",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Insomnia ": "G47.00",
        "Sleep apnea ": "G47.30",
        "Restless legs syndrome ": "G25.81",
        "Circadian rhythm sleep disorder ": "G47.20",
        "REM sleep behavior disorder ": "G47.52",
      },
    },
    492: {
      "Chief Complaint": "sprains and strains",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Ankle sprain ": "S93.4",
        "Wrist sprain ": "S63.5",
        "Knee sprain ": "S83.5",
        "Elbow sprain ": "S53.4",
        "Muscle strain ": "S29.0",
      },
    },
    493: {
      "Chief Complaint": "sprains and strains",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Ankle sprain ": "S93.4",
        "Back strain ": "S39.0",
        "Knee sprain ": "S83.5",
        "Neck strain ": "S13.4",
        "Wrist sprain ": "S63.5",
      },
    },
    494: {
      "Chief Complaint": "sprains and strains",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Ankle sprain ": "S93.4",
        "Back strain ": "S39.0",
        "Knee sprain ": "S83.5",
        "Hip strain ": "S73.1",
        "Wrist sprain ": "S63.5",
      },
    },
    495: {
      "Chief Complaint": "stomach pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Constipation ": "K59.00",
        "Gastritis ": "K29.70",
        "Appendicitis ": "K35.80",
        "Lactose intolerance ": "E73.9",
      },
    },
    496: {
      "Chief Complaint": "stomach pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Gastritis ": "K29.70",
        "Peptic ulcer ": "K27.9",
        "Gallstones ": "K80.20",
        "GERD ": "K21.9",
        "Pancreatitis ": "K85.9",
      },
    },
    497: {
      "Chief Complaint": "stomach pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gastritis ": "K29.70",
        "Peptic ulcer ": "K27.9",
        "Gallstones ": "K80.20",
        "Diverticulitis ": "K57.30",
        "GERD ": "K21.9",
      },
    },
    498: {
      "Chief Complaint": "sunburn",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "First-degree sunburn ": "L55.0",
        "Second-degree sunburn ": "L55.1",
        "Heat rash ": "L74.0",
        "Allergic reaction to sun ": "L56.0",
        "Photosensitivity ": "L56.3",
      },
    },
    499: {
      "Chief Complaint": "sunburn",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "First-degree sunburn ": "L55.0",
        "Second-degree sunburn ": "L55.1",
        "Solar keratosis ": "L57.0",
        "Allergic reaction to sun ": "L56.0",
        "Photosensitivity ": "L56.3",
      },
    },
    500: {
      "Chief Complaint": "sunburn",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "First-degree sunburn ": "L55.0",
        "Second-degree sunburn ": "L55.1",
        "Solar keratosis ": "L57.0",
        "Basal cell carcinoma due to sun exposure ": "C44.91",
        "Photosensitivity ": "L56.3",
      },
    },
    501: {
      "Chief Complaint": "tinnitus",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Ear infection ": "H66.90",
        "Foreign body in ear ": "T16",
        "Wax impaction ": "H61.2",
        "Noise exposure ": "H83.3",
        "Sinusitis ": "J32.9",
      },
    },
    502: {
      "Chief Complaint": "tinnitus",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Noise-induced hearing loss ": "H91.8",
        "Wax impaction ": "H61.2",
        "Otosclerosis ": "H80.9",
        "Meniere's disease ": "H81.0",
        "Medication side effect ": "T88.7",
      },
    },
    503: {
      "Chief Complaint": "tinnitus",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Presbycusis ": "H91.1",
        "Noise-induced hearing loss ": "H91.8",
        "Wax impaction ": "H61.2",
        "Otosclerosis ": "H80.9",
        "Meniere's disease ": "H81.0",
      },
    },
    504: {
      "Chief Complaint": "toothache",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Gingivitis ": "K05.1",
        "Tooth eruption ": "K00.6",
        "Dental abscess ": "K04.7",
      },
    },
    505: {
      "Chief Complaint": "toothache",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Periodontitis ": "K05.3",
        "Impacted tooth ": "K01.1",
        "Dental abscess ": "K04.7",
      },
    },
    506: {
      "Chief Complaint": "toothache",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Periodontitis ": "K05.3",
        "Tooth loss due to decay ": "K08.1",
        "Dental abscess ": "K04.7",
      },
    },
    507: {
      "Chief Complaint": "trauma to eye",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0",
        "Foreign body in eye ": "T15.91",
        "Conjunctival hemorrhage ": "H11.3",
        "Chemical burn of eye ": "T26.9",
        "Orbital fracture ": "S02.3",
      },
    },
    508: {
      "Chief Complaint": "trauma to eye",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0",
        "Foreign body in eye ": "T15.91",
        "Hyphema ": "H21.0",
        "Chemical burn of eye ": "T26.9",
        "Orbital fracture ": "S02.3",
      },
    },
    509: {
      "Chief Complaint": "trauma to eye",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0",
        "Foreign body in eye ": "T15.91",
        "Hyphema ": "H21.0",
        "Chemical burn of eye ": "T26.9",
        "Retinal detachment due to trauma ": "H33.4",
      },
    },
    510: {
      "Chief Complaint": "tremors",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Essential tremor ": "G25.0",
        "Drug-induced tremor ": "G25.1",
        "Cerebral palsy ": "G80.9",
        "Hyperthyroidism ": "E05.90",
        "Anxiety ": "F41.9",
      },
    },
    511: {
      "Chief Complaint": "tremors",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Essential tremor ": "G25.0",
        "Parkinson's disease ": "G20",
        "Drug-induced tremor ": "G25.1",
        "Multiple sclerosis ": "G35",
        "Hyperthyroidism ": "E05.90",
      },
    },
    512: {
      "Chief Complaint": "tremors",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Parkinson's disease ": "G20",
        "Essential tremor ": "G25.0",
        "Drug-induced tremor ": "G25.1",
        "Cerebrovascular disease ": "I67.9",
        "Dementia with Lewy bodies ": "G31.83",
      },
    },
    513: {
      "Chief Complaint": "vertigo",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Benign paroxysmal positional vertigo ": "BPPV",
        "Labyrinthitis ": "H83.01",
        "Migraine ": "G43.909",
        "Motion sickness ": "T75.3XXA",
        "Ear infection ": "H66.90",
      },
    },
    514: {
      "Chief Complaint": "vertigo",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Benign paroxysmal positional vertigo ": "BPPV",
        "Meniere's disease ": "H81.01",
        "Vestibular neuritis ": "H81.12",
        "Labyrinthitis ": "H83.01",
        "Migraine ": "G43.909",
      },
    },
    515: {
      "Chief Complaint": "vertigo",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Benign paroxysmal positional vertigo ": "BPPV",
        "Meniere's disease ": "H81.01",
        "Vestibular neuritis ": "H81.12",
        "Labyrinthitis ": "H83.01",
        "Age-related dizziness and vertigo ": "R42",
      },
    },
    516: {
      "Chief Complaint": "wheezing",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Bronchiolitis ": "J21.9",
        "Foreign body aspiration ": "T17.908A",
        "Allergic reaction ": "T78.40XA",
        "Croup ": "J05.0",
      },
    },
    517: {
      "Chief Complaint": "wheezing",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Chronic obstructive pulmonary disease ": "COPD",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "Pulmonary embolism ": "I26.99",
      },
    },
    518: {
      "Chief Complaint": "wheezing",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Chronic obstructive pulmonary disease ": "COPD",
        "Heart failure ": "I50.9",
        "Pulmonary embolism ": "I26.99",
        "Bronchitis ": "J40",
      },
    },
    519: {
      "Chief Complaint": "vaginal discharge",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Foreign body ": "T19.9",
        "Vulvovaginitis ": "N76.0",
        "Yeast infection ": "B37.3",
        "Bacterial vaginosis ": "N76.0",
        "Non-specific discharge ": "R87.619",
      },
    },
    520: {
      "Chief Complaint": "vaginal discharge",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Bacterial vaginosis ": "N76.0",
        "Yeast infection ": "B37.3",
        "Trichomoniasis ": "A59.01",
        "Gonorrhea ": "A54.00",
        "Chlamydia ": "A56.00",
      },
    },
    521: {
      "Chief Complaint": "vaginal discharge",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Atrophic vaginitis ": "N95.2",
        "Bacterial vaginosis ": "N76.0",
        "Yeast infection ": "B37.3",
      },
    },
    522: {
      "Chief Complaint": "weight gain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Cushing's syndrome ": "E24.9",
        "Growth spurt ": "R62.50",
        "Medication side effect ": "T88.7XXA",
        "Poor diet and lack of exercise ": "E66.9",
      },
    },
    523: {
      "Chief Complaint": "weight gain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Cushing's syndrome ": "E24.9",
        "Polycystic ovary syndrome ": "E28.2",
        "Medication side effect ": "T88.7XXA",
        "Pregnancy ": "Z33.1",
      },
    },
    524: {
      "Chief Complaint": "weight gain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Medication side effect ": "T88.7XXA",
        "Heart failure ": "I50.9",
        "Renal disease ": "N19",
        "Poor mobility and sedentary lifestyle ": "Z91.89",
      },
    },
    525: {
      "Chief Complaint": "jaundice",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Physiological neonatal jaundice ": "P59.9",
        "Hemolytic disease of the newborn ": "P55.9",
        "Biliary atresia ": "Q44.2",
        "Hepatitis ": "K75.9",
        "Gilbert's syndrome ": "E80.4",
      },
    },
    526: {
      "Chief Complaint": "jaundice",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hepatitis ": "K75.9",
        "Cirrhosis ": "K74.60",
        "Gallstones ": "K80.20",
        "Hemolytic anemia ": "D59.9",
        "Alcohol-related liver disease ": "K70.9",
      },
    },
    527: {
      "Chief Complaint": "jaundice",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cirrhosis ": "K74.60",
        "Hepatitis ": "K75.9",
        "Gallstones ": "K80.20",
        "Pancreatic cancer ": "C25.9",
        "Hemolytic anemia ": "D59.9",
      },
    },
    528: {
      "Chief Complaint": "vision changes",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Refractive error ": "H52.4",
        "Amblyopia ": "H53.00",
        "Conjunctivitis ": "H10.9",
        "Retinoblastoma ": "C69.2",
        "Strabismus ": "H50.9",
      },
    },
    529: {
      "Chief Complaint": "vision changes",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Refractive error ": "H52.4",
        "Cataract ": "H25.9",
        "Glaucoma ": "H40.9",
        "Macular degeneration ": "H35.30",
        "Diabetic retinopathy ": "E14.359",
      },
    },
    530: {
      "Chief Complaint": "vision changes",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cataract ": "H25.9",
        "Glaucoma ": "H40.9",
        "Macular degeneration ": "H35.30",
        "Presbyopia ": "H52.4",
        "Diabetic retinopathy ": "E14.359",
      },
    },
    531: {
      "Chief Complaint": "vision problems",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Refractive error ": "H52.4",
        "Amblyopia ": "H53.00",
        "Conjunctivitis ": "H10.9",
        "Retinoblastoma ": "C69.2",
        "Strabismus ": "H50.9",
      },
    },
    532: {
      "Chief Complaint": "vision problems",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Refractive error ": "H52.4",
        "Cataract ": "H25.9",
        "Glaucoma ": "H40.9",
        "Macular degeneration ": "H35.30",
        "Diabetic retinopathy ": "E14.359",
      },
    },
    533: {
      "Chief Complaint": "vision problems",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cataract ": "H25.9",
        "Glaucoma ": "H40.9",
        "Macular degeneration ": "H35.30",
        "Presbyopia ": "H52.4",
        "Diabetic retinopathy ": "E14.359",
      },
    },
    534: {
      "Chief Complaint": "unexplained weight loss",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hyperthyroidism ": "E05.90",
        "Diabetes mellitus ": "E10.9",
        "Malabsorption ": "K90.9",
        "Chronic infection ": "B99.9",
        "Emotional stress or anxiety ": "F93.8",
      },
    },
    535: {
      "Chief Complaint": "unexplained weight loss",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hyperthyroidism ": "E05.90",
        "Diabetes mellitus ": "E10.9",
        "Cancer ": "C80.1",
        "Malabsorption ": "K90.9",
        "Chronic infection ": "B99.9",
      },
    },
    536: {
      "Chief Complaint": "unexplained weight loss",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cancer ": "C80.1",
        "Hyperthyroidism ": "E05.90",
        "Malabsorption ": "K90.9",
        "Depression ": "F32.9",
        "Dementia ": "F03.90",
      },
    },
    537: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Soft tissue injury ": "S39.91XA",
        "Concussion ": "S06.0X0A",
        "Fracture ": "S42.9XXA",
        "Contusion ": "S00.81XA",
        "Whiplash ": "S13.4XXA",
      },
    },
    538: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Whiplash ": "S13.4XXA",
        "Fracture ": "S42.9XXA",
        "Concussion ": "S06.0X0A",
        "Soft tissue injury ": "S39.91XA",
        "Internal injury ": "S36.9XXA",
      },
    },
    539: {
      "Chief Complaint": "motor vehicle accident",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fracture ": "S72.9XXA",
        "Soft tissue injury ": "S39.91XA",
        "Concussion ": "S06.0X0A",
        "Internal injury ": "S36.9XXA",
        "Contusion ": "S00.81XA",
      },
    },
    540: {
      "Chief Complaint": "yellowing of the skin or eyes",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Physiological neonatal jaundice ": "P59.9",
        "Hemolytic disease of the newborn ": "P55.9",
        "Biliary atresia ": "Q44.2",
        "Hepatitis ": "K75.9",
        "Gilbert's syndrome ": "E80.4",
      },
    },
    541: {
      "Chief Complaint": "yellowing of the skin or eyes",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hepatitis ": "K75.9",
        "Cirrhosis ": "K74.60",
        "Gallstones ": "K80.20",
        "Hemolytic anemia ": "D59.9",
        "Alcohol-related liver disease ": "K70.9",
      },
    },
    542: {
      "Chief Complaint": "yellowing of the skin or eyes",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cirrhosis ": "K74.60",
        "Hepatitis ": "K75.9",
        "Gallstones ": "K80.20",
        "Pancreatic cancer ": "C25.9",
        "Hemolytic anemia ": "D59.9",
      },
    },
    543: {
      "Chief Complaint": "injuries due to accident",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fracture ": "S82.9XXA",
        "Contusion ": "S00.81XA",
        "Concussion ": "S06.0X0A",
        "Laceration ": "S01.91XA",
        "Sprain ": "S93.4XXA",
      },
    },
    544: {
      "Chief Complaint": "injuries due to accident",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S72.9XXA",
        "Sprain ": "S93.4XXA",
        "Contusion ": "S00.81XA",
        "Laceration ": "S01.91XA",
        "Dislocation ": "S43.9XXA",
      },
    },
    545: {
      "Chief Complaint": "injuries due to accident",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hip Fracture ": "S72.0XXA",
        "Wrist Fracture ": "S52.5XXA",
        "Contusion ": "S00.81XA",
        "Concussion ": "S06.0X0A",
        "Sprain ": "S93.4XXA",
      },
    },
    546: {
      "Chief Complaint": "chest tightness or pressure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Anxiety ": "F41.9",
        "Precordial catch syndrome ": "R07.2",
        "Costochondritis ": "M94.0",
        "Gastroesophageal reflux ": "K21.9",
      },
    },
    547: {
      "Chief Complaint": "chest tightness or pressure",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Acute myocardial infarction ": "I21.9",
        "Angina pectoris ": "I20.9",
        "Gastroesophageal reflux ": "K21.9",
        "Costochondritis ": "M94.0",
        "Aortic dissection ": "I71.0",
      },
    },
    548: {
      "Chief Complaint": "chest tightness or pressure",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Angina pectoris ": "I20.9",
        "Gastroesophageal reflux ": "K21.9",
        "Costochondritis ": "M94.0",
        "Anxiety ": "F41.9",
        "Pulmonary embolism ": "I26.99",
      },
    },
    549: {
      "Chief Complaint": "chest tightness or pressure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Acute myocardial infarction ": "I21.9",
        "Angina pectoris ": "I20.9",
        "Aortic dissection ": "I71.0",
        "Pulmonary embolism ": "I26.99",
        "Gastroesophageal reflux ": "K21.9",
      },
    },
    550: {
      "Chief Complaint": "palpitations or skipped beats",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anxiety ": "F41.9",
        "Supraventricular tachycardia ": "I47.1",
        "Electrolyte imbalance ": "E87.8",
        "Anemia ": "D64.9",
        "Hyperthyroidism ": "E05.90",
      },
    },
    551: {
      "Chief Complaint": "palpitations or skipped beats",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    552: {
      "Chief Complaint": "palpitations or skipped beats",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Heart failure ": "I50.9",
        "Valvular heart disease ": "I34.9",
        "Electrolyte imbalance ": "E87.8",
        "Ischemic heart disease ": "I25.9",
      },
    },
    553: {
      "Chief Complaint": "Lower extremity edema",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Sprain or strain ": "S93.4XXA",
        "Venous insufficiency ": "I87.2",
        "Allergic reaction ": "T78.40XA",
        "Nephrotic syndrome ": "N04.9",
      },
    },
    554: {
      "Chief Complaint": "Lower extremity edema",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "Venous insufficiency ": "I87.2",
        "Deep vein thrombosis ": "I82.90",
        "Lymphedema ": "I89.0",
        "Kidney disease ": "N19",
      },
    },
    555: {
      "Chief Complaint": "Lower extremity edema",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Heart failure ": "I50.9",
        "Venous insufficiency ": "I87.2",
        "Deep vein thrombosis ": "I82.90",
        "Lymphedema ": "I89.0",
        "Chronic kidney disease ": "N18.9",
      },
    },
    556: {
      "Chief Complaint": "pain or cramping in the legs with walking",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Growing pains ": "R29.89",
        "Overuse injury ": "T73.3XXA",
        "Osgood-Schlatter disease ": "M92.52",
        "Juvenile arthritis ": "M08.9",
        "Muscle strain ": "S39.012A",
      },
    },
    557: {
      "Chief Complaint": "pain or cramping in the legs with walking",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Peripheral artery disease ": "I70.209",
        "Lumbar spinal stenosis ": "M48.06",
        "Deep vein thrombosis ": "I82.409",
        "Muscle strain ": "S39.012A",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    558: {
      "Chief Complaint": "pain or cramping in the legs with walking",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Peripheral artery disease ": "I70.209",
        "Lumbar spinal stenosis ": "M48.06",
        "Arthritis ": "M19.90",
        "Deep vein thrombosis ": "I82.409",
        "Venous insufficiency ": "I87.2",
      },
    },
    559: {
      "Chief Complaint": "Urinary Frequency",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Diabetes mellitus ": "E10.9",
        "Overactive bladder ": "N32.81",
        "Diabetes insipidus ": "E23.2",
        "Anxiety ": "F41.9",
        "Interstitial cystitis ": "N30.10",
        "Constipation ": "K59.00",
        "Polydipsia ": "R63.1",
        "Bladder stones ": "N21.0",
        "Neurogenic bladder ": "N31.9",
      },
    },
    560: {
      "Chief Complaint": "Urinary Frequency",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Prostatitis ": "N41.9",
        "Benign prostatic hyperplasia ": "N40.1",
        "Urinary tract infection ": "N39.0",
        "Diabetes mellitus ": "E11.9",
        "Overactive bladder ": "N32.81",
        "Bladder stones ": "N21.0",
        "Interstitial cystitis ": "N30.10",
        "Neurogenic bladder ": "N31.9",
        "Anxiety ": "F41.9",
        "Diabetes insipidus ": "E23.2",
      },
    },
    561: {
      "Chief Complaint": "Urinary Frequency",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Overactive bladder ": "N32.81",
        "Interstitial cystitis ": "N30.10",
        "Pregnancy ": "Z33.1",
        "Diabetes mellitus ": "E11.9",
        "Bladder stones ": "N21.0",
        "Neurogenic bladder ": "N31.9",
        "Pelvic organ prolapse ": "N81.4",
        "Stress incontinence ": "N39.3",
        "Diabetes insipidus ": "E23.2",
      },
    },
    562: {
      "Chief Complaint": "Urinary Frequency",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Benign prostatic hyperplasia ": "N40.1",
        "Overactive bladder ": "N32.81",
        "Diabetes mellitus ": "E11.9",
        "Bladder stones ": "N21.0",
        "Interstitial cystitis ": "N30.10",
        "Neurogenic bladder ": "N31.9",
        "Diabetes insipidus ": "E23.2",
        "Renal insufficiency ": "N18.9",
        "Medication side effects ": "T88.7",
      },
    },
    563: {
      "Chief Complaint": "blood in the urine or hematuria",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Kidney stones ": "N20.0",
        "Trauma 4. Glomerulonephritis ": "N05.9",
      },
    },
    564: {
      "Chief Complaint": "blood in the urine or hematuria",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Kidney stones ": "N20.0",
        "Bladder cancer ": "C67.9",
        "Kidney cancer ": "C64.9",
      },
    },
    565: {
      "Chief Complaint": "blood in the urine or hematuria",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Bladder cancer ": "C67.9",
        "Kidney stones ": "N20.0",
        "Urinary tract infection ": "N39.0",
        "Enlarged prostate in males ": "N40.0",
        "Kidney cancer ": "C64.9",
      },
    },
    566: {
      "Chief Complaint": "elbow infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.115",
        "Septic arthritis ": "M00.822",
        "Osteomyelitis ": "M86.62",
        "Insect bite ": "S60.451A",
        "Abscess ": "L02.419",
      },
    },
    567: {
      "Chief Complaint": "elbow infection",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.115",
        "Septic arthritis ": "M00.822",
        "Osteomyelitis ": "M86.62",
        "Abscess ": "L02.419",
        "Bursitis ": "M70.20",
      },
    },
    568: {
      "Chief Complaint": "elbow infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.115",
        "Septic arthritis ": "M00.822",
        "Osteomyelitis ": "M86.62",
        "Abscess ": "L02.419",
        "Bursitis ": "M70.20",
      },
    },
    569: {
      "Chief Complaint": "surgical drain dislodgement",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Surgical site infection ": "T81.4XXA",
        "Hematoma ": "T81.89XA",
        "Seroma ": "T81.89XA",
        "Foreign body ": "T15.91XA",
        "Wound dehiscence ": "T81.3XXA",
      },
    },
    570: {
      "Chief Complaint": "surgical drain dislodgement",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Surgical site infection ": "T81.4XXA",
        "Hematoma ": "T81.89XA",
        "Seroma ": "T81.89XA",
        "Foreign body ": "T15.91XA",
        "Wound dehiscence ": "T81.3XXA",
      },
    },
    571: {
      "Chief Complaint": "surgical drain dislodgement",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Surgical site infection ": "T81.4XXA",
        "Hematoma ": "T81.89XA",
        "Seroma ": "T81.89XA",
        "Foreign body ": "T15.91XA",
        "Wound dehiscence ": "T81.3XXA",
      },
    },
    572: {
      "Chief Complaint": "unwitnessed fall",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Traumatic injury ": "S09.90XA",
        "Fracture ": "S42.9XXA",
        "Concussion ": "S06.0X0A",
        "Epileptic seizure ": "G40.909",
        "Syncope ": "R55",
      },
    },
    573: {
      "Chief Complaint": "unwitnessed fall",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Traumatic injury ": "S09.90XA",
        "Fracture ": "S42.9XXA",
        "Syncope ": "R55",
        "Vertigo ": "R42",
        "Epileptic seizure ": "G40.909",
      },
    },
    574: {
      "Chief Complaint": "unwitnessed fall",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fracture ": "S72.9XXA",
        "Traumatic injury ": "S09.90XA",
        "Syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Stroke ": "I63.9",
      },
    },
    575: {
      "Chief Complaint": "light headedness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Anxiety ": "F41.9",
        "Hyperventilation ": "R06.4",
        "Low blood sugar ": "E16.2",
      },
    },
    576: {
      "Chief Complaint": "light headedness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Vertigo ": "R42",
        "Anxiety ": "F41.9",
        "Hypotension ": "I95.9",
      },
    },
    577: {
      "Chief Complaint": "light headedness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Orthostatic hypotension ": "I95.1",
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Medication side effect 5. Cardiac arrhythmia ": "I49.9",
      },
    },
    578: {
      "Chief Complaint": "trauma to eye",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0XXA",
        "Foreign body ": "T15.01XA",
        "Conjunctival hemorrhage ": "H11.3",
        "Chemical burn ": "T26.00XA",
        "Orbital fracture ": "S02.3XXA",
      },
    },
    579: {
      "Chief Complaint": "trauma to eye",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0XXA",
        "Foreign body ": "T15.01XA",
        "Chemical burn ": "T26.00XA",
        "Conjunctival hemorrhage ": "H11.3",
        "Orbital fracture ": "S02.3XXA",
      },
    },
    580: {
      "Chief Complaint": "trauma to eye",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Corneal abrasion ": "S05.0XXA",
        "Foreign body ": "T15.01XA",
        "Chemical burn ": "T26.00XA",
        "Conjunctival hemorrhage ": "H11.3",
        "Orbital fracture ": "S02.3XXA",
      },
    },
    581: {
      "Chief Complaint": "hip pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Transient synovitis ": "M12.52",
        "Legg-Calv\u00e9-Perthes disease ": "M91.2",
        "Juvenile idiopathic arthritis ": "M08.05",
        "Fracture ": "S72.002A",
        "Osteomyelitis ": "M86.052",
      },
    },
    582: {
      "Chief Complaint": "hip pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M16.12",
        "Trochanteric bursitis ": "M70.62",
        "Hip labral tear ": "M24.151",
        "Hip fracture ": "S72.002A",
        "Lumbar radiculopathy ": "M54.16",
      },
    },
    583: {
      "Chief Complaint": "hip pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M16.12",
        "Hip fracture ": "S72.002A",
        "Trochanteric bursitis ": "M70.62",
        "Hip labral tear ": "M24.151",
        "Lumbar radiculopathy ": "M54.16",
      },
    },
    584: {
      "Chief Complaint": "knee pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Osgood-Schlatter disease ": "M92.50",
        "Juvenile idiopathic arthritis ": "M08.06",
        "Patellar subluxation ": "S83.001A",
        "Ligament sprain ": "S83.201A",
        "Meniscal tear ": "S83.201A",
      },
    },
    585: {
      "Chief Complaint": "knee pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M17.12",
        "Patellar tendinitis ": "M76.50",
        "Meniscal tear ": "S83.201A",
        "Ligament sprain ": "S83.201A",
        "Bursitis ": "M70.20",
      },
    },
    586: {
      "Chief Complaint": "knee pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M17.12",
        "Meniscal tear ": "S83.201A",
        "Ligament sprain ": "S83.201A",
        "Bursitis ": "M70.20",
        "Gout ": "M10.062",
      },
    },
    587: {
      "Chief Complaint": "shoulder pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Rotator cuff tendinitis ": "M75.101",
        "Brachial neuritis ": "G54.0",
        "Fracture ": "S42.001A",
        "Sprain ": "S43.401A",
        "Septic arthritis ": "M00.211",
      },
    },
    588: {
      "Chief Complaint": "shoulder pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Rotator cuff tear ": "M75.101",
        "Adhesive capsulitis ": "M75.01",
        "Bursitis ": "M75.51",
        "Osteoarthritis ": "M19.011",
        "Tendinitis ": "M75.101",
      },
    },
    589: {
      "Chief Complaint": "shoulder pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Rotator cuff tear ": "M75.101",
        "Adhesive capsulitis ": "M75.01",
        "Osteoarthritis ": "M19.011",
        "Bursitis ": "M75.51",
        "Fracture ": "S42.001A",
      },
    },
    590: {
      "Chief Complaint": "elbow pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nursemaid's elbow ": "S53.031A",
        "Fracture ": "S52.001A",
        "Juvenile idiopathic arthritis ": "M08.22",
        "Osteochondritis dissecans ": "M93.221",
        "Sprain ": "S53.401A",
      },
    },
    591: {
      "Chief Complaint": "elbow pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Lateral epicondylitis ": "M77.01",
        "Medial epicondylitis ": "M77.01",
        "Bursitis ": "M70.21",
        "Fracture ": "S52.001A",
        "Ulnar nerve entrapment ": "G56.21",
      },
    },
    592: {
      "Chief Complaint": "elbow pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.021",
        "Lateral epicondylitis ": "M77.01",
        "Bursitis ": "M70.21",
        "Fracture ": "S52.001A",
        "Rheumatoid arthritis ": "M05.721",
      },
    },
    593: {
      "Chief Complaint": "foot pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Plantar fasciitis ": "M72.2",
        "Sprain ": "S93.401A",
        "Fracture ": "S92.902A",
        "Flatfoot ": "M21.40",
        "Sever's disease ": "M92.52",
      },
    },
    594: {
      "Chief Complaint": "foot pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Plantar fasciitis ": "M72.2",
        "Metatarsalgia ": "M77.40",
        "Morton's neuroma ": "G57.6",
        "Fracture ": "S92.902A",
        "Gout ": "M10.072",
      },
    },
    595: {
      "Chief Complaint": "foot pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Plantar fasciitis ": "M72.2",
        "Osteoarthritis ": "M19.072",
        "Fracture ": "S92.902A",
        "Peripheral neuropathy ": "G62.9",
        "Gout ": "M10.072",
      },
    },
    596: {
      "Chief Complaint": "hand pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    597: {
      "Chief Complaint": "hand pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Carpal tunnel syndrome ": "G56.02",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Osteoarthritis ": "M19.042",
        "Rheumatoid arthritis ": "M05.742",
      },
    },
    598: {
      "Chief Complaint": "hand pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Carpal tunnel syndrome ": "G56.02",
        "Fracture ": "S62.602A",
        "Rheumatoid arthritis ": "M05.742",
        "Gout ": "M10.042",
      },
    },
    599: {
      "Chief Complaint": "hand injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    600: {
      "Chief Complaint": "hand injury",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Carpal tunnel syndrome ": "G56.02",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Osteoarthritis ": "M19.042",
        "Rheumatoid arthritis ": "M05.742",
      },
    },
    601: {
      "Chief Complaint": "hand injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Carpal tunnel syndrome ": "G56.02",
        "Fracture ": "S62.602A",
        "Rheumatoid arthritis ": "M05.742",
        "Gout ": "M10.042",
      },
    },
    602: {
      "Chief Complaint": "rib pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Costochondritis ": "M94.0",
        "Fracture ": "S22.3XXA",
        "Muscle strain ": "S29.810A",
        "Viral infection ": "B97.89",
        "Bruise ": "S20.2XXA",
      },
    },
    603: {
      "Chief Complaint": "rib pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Costochondritis ": "M94.0",
        "Fracture ": "S22.3XXA",
        "Pleurisy ": "R09.1",
        "Pulmonary embolism ": "I26.99",
        "Muscle strain ": "S29.810A",
      },
    },
    604: {
      "Chief Complaint": "rib pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fracture ": "S22.3XXA",
        "Costochondritis ": "M94.0",
        "Osteoporosis ": "M81.0",
        "Pleurisy ": "R09.1",
        "Pulmonary embolism ": "I26.99",
      },
    },
    605: {
      "Chief Complaint": "thumb pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    606: {
      "Chief Complaint": "thumb pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "De Quervain's tenosynovitis ": "M65.4",
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Sprain ": "S63.602A",
        "Thumb carpometacarpal arthritis ": "M19.042",
      },
    },
    607: {
      "Chief Complaint": "thumb pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "De Quervain's tenosynovitis ": "M65.4",
        "Fracture ": "S62.602A",
        "Thumb carpometacarpal arthritis ": "M19.042",
        "Gout ": "M10.042",
      },
    },
    608: {
      "Chief Complaint": "thumb injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    609: {
      "Chief Complaint": "thumb injury",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "De Quervain's tenosynovitis ": "M65.4",
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Sprain ": "S63.602A",
        "Thumb carpometacarpal arthritis ": "M19.042",
      },
    },
    610: {
      "Chief Complaint": "thumb injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "De Quervain's tenosynovitis ": "M65.4",
        "Fracture ": "S62.602A",
        "Thumb carpometacarpal arthritis ": "M19.042",
        "Gout ": "M10.042",
      },
    },
    611: {
      "Chief Complaint": "index finger pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    612: {
      "Chief Complaint": "index finger pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Tendinitis ": "M65.842",
        "Sprain ": "S63.602A",
        "Trigger finger ": "M65.3",
      },
    },
    613: {
      "Chief Complaint": "index finger pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Trigger finger ": "M65.3",
        "Gout ": "M10.042",
      },
    },
    614: {
      "Chief Complaint": "index finger injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    615: {
      "Chief Complaint": "index finger injury",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Tendinitis ": "M65.842",
        "Sprain ": "S63.602A",
        "Trigger finger ": "M65.3",
      },
    },
    616: {
      "Chief Complaint": "index finger injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Trigger finger ": "M65.3",
        "Gout ": "M10.042",
      },
    },
    617: {
      "Chief Complaint": "middle finger pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    618: {
      "Chief Complaint": "middle finger pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Tendinitis ": "M65.842",
        "Sprain ": "S63.602A",
        "Trigger finger ": "M65.3",
      },
    },
    619: {
      "Chief Complaint": "middle finger pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Trigger finger ": "M65.3",
        "Gout ": "M10.042",
      },
    },
    620: {
      "Chief Complaint": "ring finger pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    621: {
      "Chief Complaint": "ring finger pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Tendinitis ": "M65.842",
        "Sprain ": "S63.602A",
        "Trigger finger ": "M65.3",
      },
    },
    622: {
      "Chief Complaint": "ring finger pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Trigger finger ": "M65.3",
        "Gout ": "M10.042",
      },
    },
    623: {
      "Chief Complaint": "little finger pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain ": "S63.602A",
        "Fracture ": "S62.602A",
        "Laceration ": "S61.419",
        "Infection ": "A49.9",
        "Tendinitis ": "M65.842",
      },
    },
    624: {
      "Chief Complaint": "little finger pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.602A",
        "Osteoarthritis ": "M19.042",
        "Tendinitis ": "M65.842",
        "Sprain ": "S63.602A",
        "Trigger finger ": "M65.3",
      },
    },
    625: {
      "Chief Complaint": "little finger pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.042",
        "Fracture ": "S62.602A",
        "Tendinitis ": "M65.842",
        "Trigger finger ": "M65.3",
        "Gout ": "M10.042",
      },
    },
    626: {
      "Chief Complaint": "elbow injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nursemaid's elbow ": "S53.031A",
        "Fracture ": "S52.001A",
        "Juvenile idiopathic arthritis ": "M08.22",
        "Osteochondritis dissecans ": "M93.221",
        "Sprain ": "S53.401A",
      },
    },
    627: {
      "Chief Complaint": "elbow injury",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Lateral epicondylitis ": "M77.01",
        "Medial epicondylitis ": "M77.01",
        "Bursitis ": "M70.21",
        "Fracture ": "S52.001A",
        "Ulnar nerve entrapment ": "G56.21",
      },
    },
    628: {
      "Chief Complaint": "elbow injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.021",
        "Lateral epicondylitis ": "M77.01",
        "Bursitis ": "M70.21",
        "Fracture ": "S52.001A",
        "Rheumatoid arthritis ": "M05.721",
      },
    },
    629: {
      "Chief Complaint": "diverticulitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Appendicitis ": "K35.80",
        "Gastroenteritis ": "K52.9",
        "Inflammatory bowel disease ": "K50.90",
        "Constipation ": "K59.00",
        "Intestinal obstruction ": "K56.609",
      },
    },
    630: {
      "Chief Complaint": "diverticulitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Diverticulitis ": "K57.32",
        "Irritable bowel syndrome ": "K58.9",
        "Colorectal cancer ": "C18.9",
        "Gastroenteritis ": "K52.9",
        "Inflammatory bowel disease ": "K50.90",
      },
    },
    631: {
      "Chief Complaint": "diverticulitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Diverticulitis ": "K57.32",
        "Colorectal cancer ": "C18.9",
        "Ischemic colitis ": "K55.0",
        "Irritable bowel syndrome ": "K58.9",
        "Inflammatory bowel disease ": "K50.90",
      },
    },
    632: {
      "Chief Complaint": "facial swelling",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Cellulitis ": "L03.211",
        "Dental abscess ": "K02.9",
        "Mumps ": "B26.9",
        "Trauma ": "S00.83XA",
      },
    },
    633: {
      "Chief Complaint": "facial swelling",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.211",
        "Dental abscess ": "K02.9",
        "Sinusitis ": "J32.9",
        "Allergic reaction ": "T78.40XA",
        "Angioedema ": "T78.3XXA",
      },
    },
    634: {
      "Chief Complaint": "facial swelling",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.211",
        "Dental abscess ": "K02.9",
        "Sinusitis ": "J32.9",
        "Angioedema ": "T78.3XXA",
        "Congestive heart failure ": "I50.9",
      },
    },
    635: {
      "Chief Complaint": "facial pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sinusitis ": "J01.90",
        "Dental pain ": "K08.8",
        "Trauma ": "S00.83XA",
        "Herpes zoster ": "B02.9",
        "Temporomandibular joint dysfunction ": "M26.60",
      },
    },
    636: {
      "Chief Complaint": "facial pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Sinusitis ": "J01.90",
        "Temporomandibular joint dysfunction ": "M26.60",
        "Trigeminal neuralgia ": "G50.0",
        "Dental abscess ": "K02.9",
        "Herpes zoster ": "B02.9",
      },
    },
    637: {
      "Chief Complaint": "facial pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Trigeminal neuralgia ": "G50.0",
        "Sinusitis ": "J01.90",
        "Temporomandibular joint dysfunction ": "M26.60",
        "Dental abscess ": "K02.9",
        "Herpes zoster ": "B02.9",
      },
    },
    638: {
      "Chief Complaint": "wrist pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain of wrist ": "S63.501A",
        "Juvenile idiopathic arthritis ": "M08.03",
        "Fracture ": "S62.009A",
        "Ganglion cyst ": "M67.4",
        "Tendinitis ": "M65.811",
      },
    },
    639: {
      "Chief Complaint": "wrist pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Carpal tunnel syndrome ": "G56.00",
        "Sprain of wrist ": "S63.501A",
        "De Quervain's tenosynovitis ": "M65.4",
        "Fracture ": "S62.009A",
        "Ganglion cyst ": "M67.4",
      },
    },
    640: {
      "Chief Complaint": "wrist pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis of wrist ": "M19.032",
        "Carpal tunnel syndrome ": "G56.00",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Sprain of wrist ": "S63.501A",
        "Tendinitis ": "M65.811",
      },
    },
    641: {
      "Chief Complaint": "wrist injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sprain of right wrist ": "S63.501A",
        "Fracture of right wrist ": "e.g., distal radius fracture",
        "Contusion of right wrist ": "S60.811A",
        "Ligament tear or strain 5. Ganglion cyst ": "M67.4",
      },
    },
    642: {
      "Chief Complaint": "wrist injury",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Sprain of right wrist ": "S63.501A",
        "Fracture of right wrist ": "e.g., scaphoid fracture",
        "Tendonitis or tenosynovitis ": "M65.812",
        "De Quervain's tenosynovitis ": "M65.4",
        "Carpal tunnel syndrome due to injury ": "G56.00",
      },
    },
    643: {
      "Chief Complaint": "wrist injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis exacerbation due to injury ": "M19.031",
        "Fracture due to osteoporosis ": "e.g., Colles' fracture",
        "Sprain of right wrist ": "S63.501A",
        "Contusion of right wrist ": "S60.811A",
      },
    },
    644: {
      "Chief Complaint": "hyperglycemia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Type 1 DM ": "E10.9",
        "Diabetic ketoacidosis ": "E10.10",
        "Hyperglycemic hyperosmolar state ": "E11.00",
        "Steroid-induced diabetes ": "E24.0",
        "Maturity onset diabetes of the young ": "E13.9",
      },
    },
    645: {
      "Chief Complaint": "hyperglycemia",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Type 2 DM ": "E11.9",
        "Diabetic ketoacidosis ": "E11.10",
        "Hyperglycemic hyperosmolar state ": "E11.00",
        "Steroid-induced diabetes ": "E24.0",
        "Secondary diabetes due to pancreatitis ": "E13.9",
      },
    },
    646: {
      "Chief Complaint": "hyperglycemia",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Type 2 DM ": "E11.9",
        "Gestational diabetes ": "O24.9",
        "Diabetic ketoacidosis ": "E11.10",
        "Hyperglycemic hyperosmolar state ": "E11.00",
        "Steroid-induced diabetes ": "E24.0",
      },
    },
    647: {
      "Chief Complaint": "hyperglycemia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Type 2 DM ": "E11.9",
        "Diabetic complications ": "E11.69",
        "Hyperglycemic hyperosmolar state ": "E11.00",
        "Steroid-induced diabetes ": "E24.0",
        "Secondary diabetes due to medications ": "E13.9",
      },
    },
    648: {
      "Chief Complaint": "hypoglycemia",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Hyperinsulinism ": "E16.1",
        "Inborn errors of metabolism ": "E74.9",
        "Sepsis ": "P36.9",
        "Maternal diabetes effects ": "P70.1",
        "Prematurity ": "P07.3",
      },
    },
    649: {
      "Chief Complaint": "hypoglycemia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hypoglycemia ": "E16.2",
        "Diabetic hypoglycemia ": "E13.649",
        "Adrenal insufficiency ": "E27.1",
        "Insulin overdose ": "T38.3X6A",
        "Starvation ": "E40",
        "Inborn errors of metabolism ": "E74.9",
        "Sepsis ": "P36.9",
      },
    },
    650: {
      "Chief Complaint": "hypoglycemia",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hypoglycemia ": "E16.2",
        "Diabetic hypoglycemia ": "E13.649",
        "Insulinoma ": "D13.7",
        "Insulin overdose ": "T38.3X6A",
        "Alcohol-induced hypoglycemia ": "E16.0",
      },
    },
    651: {
      "Chief Complaint": "hypoglycemia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hypoglycemia ": "E16.2",
        "Diabetic hypoglycemia ": "E13.649",
        "Insulinoma ": "D13.7",
        "Insulin overdose ": "T38.3X6A",
        "Adrenal insufficiency ": "E27.1",
      },
    },
    652: {
      "Chief Complaint": "gout",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Juvenile idiopathic arthritis ": "M08.9",
        "Reactive arthritis ": "M02.9",
        "Trauma ": "T14.8",
        "Osteomyelitis ": "M86.69",
        "Lyme disease ": "A69.20",
      },
    },
    653: {
      "Chief Complaint": "gout",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Gouty arthropathy ": "M10.9",
        "Rheumatoid arthritis ": "M06.9",
        "Osteoarthritis ": "M19.90",
        "Pseudogout ": "M11.9",
        "Septic arthritis ": "M00.9",
      },
    },
    654: {
      "Chief Complaint": "gout",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Gouty arthropathy ": "M10.9",
        "Rheumatoid arthritis ": "M06.9",
        "Osteoarthritis ": "M19.90",
        "Pseudogout ": "M11.9",
        "Septic arthritis ": "M00.9",
      },
    },
    655: {
      "Chief Complaint": "gout",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gouty arthropathy ": "M10.9",
        "Osteoarthritis ": "M19.90",
        "Rheumatoid arthritis ": "M06.9",
        "Pseudogout ": "M11.9",
        "Septic arthritis ": "M00.9",
      },
    },
    656: {
      "Chief Complaint": "joint infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Septic arthritis ": "M00.9",
        "Osteomyelitis ": "M86.69",
        "Lyme disease ": "A69.20",
        "Reactive arthritis ": "M02.9",
        "Juvenile idiopathic arthritis ": "M08.9",
      },
    },
    657: {
      "Chief Complaint": "joint infection",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Septic arthritis ": "M00.9",
        "Rheumatoid arthritis ": "M06.9",
        "Gouty arthropathy ": "M10.9",
        "Osteomyelitis ": "M86.69",
        "Lyme disease ": "A69.20",
      },
    },
    658: {
      "Chief Complaint": "joint infection",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Septic arthritis ": "M00.9",
        "Rheumatoid arthritis ": "M06.9",
        "Gouty arthropathy ": "M10.9",
        "Osteomyelitis ": "M86.69",
        "Lyme disease ": "A69.20",
      },
    },
    659: {
      "Chief Complaint": "joint infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Septic arthritis ": "M00.9",
        "Osteoarthritis ": "M19.90",
        "Gouty arthropathy ": "M10.9",
        "Osteomyelitis ": "M86.69",
        "Rheumatoid arthritis ": "M06.9",
      },
    },
    660: {
      "Chief Complaint": "tia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Migraine ": "G43.909",
        "Seizure ": "G40.909",
        "Syncope ": "R55",
        "Hypoglycemia ": "E16.2",
        "Hemiplegic migraine ": "G43.531",
      },
    },
    661: {
      "Chief Complaint": "tia",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Transient ischemic attack ": "G45.9",
        "Migraine ": "G43.909",
        "Seizure ": "G40.909",
        "Syncope ": "R55",
        "Vertebrobasilar insufficiency ": "G45.0",
        "Cerebrovascular disease ": "I67.9",
        "Hypoglycemia ": "E16.2",
      },
    },
    662: {
      "Chief Complaint": "tia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Transient ischemic attack ": "G45.9",
        "Cerebrovascular disease ": "I67.9",
        "Syncope ": "R55",
        "Seizure ": "G40.909",
        "Normal pressure hydrocephalus ": "G91.2",
      },
    },
    663: {
      "Chief Complaint": "tooth abscess",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dental caries ": "K02.9",
        "Dental abscess ": "K04.7",
        "Gingival abscess ": "K05.21",
        "Pulpitis ": "K04.0",
        "Traumatic injury ": "S02.5XXA",
      },
    },
    664: {
      "Chief Complaint": "tooth abscess",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Dental abscess ": "K04.7",
        "Periodontal abscess ": "K05.22",
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Periapical abscess without sinus ": "K04.61",
      },
    },
    665: {
      "Chief Complaint": "tooth abscess",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dental abscess ": "K04.7",
        "Periodontal abscess ": "K05.22",
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Periapical abscess without sinus ": "K04.61",
      },
    },
    666: {
      "Chief Complaint": "tooth abscess",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dental abscess ": "K04.7",
        "Periodontal abscess ": "K05.22",
        "Dental caries ": "K02.9",
        "Pulpitis ": "K04.0",
        "Alveolar osteitis ": "dry socket",
      },
    },
    667: {
      "Chief Complaint": "ankle injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Ankle sprain ": "S93.4XXA",
        "Ankle fracture ": "S82.6XXA",
        "Growth plate injury ": "S89.8XXA",
        "Contusion ": "S90.0XXA",
        "Tendonitis ": "M76.69",
      },
    },
    668: {
      "Chief Complaint": "ankle injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Ankle sprain ": "S93.4XXA",
        "Ankle fracture ": "S82.6XXA",
        "Achilles tendon rupture ": "S86.0XXA",
        "Gout ": "M10.072",
        "Tendonitis ": "M76.69",
      },
    },
    669: {
      "Chief Complaint": "ankle injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ankle sprain ": "S93.4XXA",
        "Ankle fracture ": "S82.6XXA",
        "Tendonitis ": "M76.69",
        "Achilles tendon rupture ": "S86.0XXA",
        "Gout ": "M10.072",
      },
    },
    670: {
      "Chief Complaint": "ankle injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Ankle fracture ": "S82.6XXA",
        "Ankle sprain ": "S93.4XXA",
        "Osteoarthritis ": "M19.079",
        "Gout ": "M10.072",
        "Tendonitis ": "M76.69",
      },
    },
    671: {
      "Chief Complaint": "back injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Muscle strain ": "S39.012A",
        "Contusion ": "S30.0XXA",
        "Vertebral fracture ": "S22.0XXA",
        "Scoliosis ": "M41.9",
        "Disk injury ": "S33.0XXA",
      },
    },
    672: {
      "Chief Complaint": "back injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Lumbar strain ": "S39.012A",
        "Herniated disc ": "M51.27",
        "Vertebral fracture ": "S22.0XXA",
        "Contusion ": "S30.0XXA",
        "Spondylolisthesis ": "M43.10",
      },
    },
    673: {
      "Chief Complaint": "back injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Lumbar strain ": "S39.012A",
        "Herniated disc ": "M51.27",
        "Vertebral fracture ": "S22.0XXA",
        "Pregnancy-related back pain ": "O26.89",
        "Spondylolisthesis ": "M43.10",
      },
    },
    674: {
      "Chief Complaint": "arrest",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Vertebral fracture due to osteoporosis ": "M80.08XA",
        "Lumbar strain ": "S39.012A",
        "Herniated disc ": "M51.27",
        "Spinal stenosis ": "M48.06",
        "Spondylolisthesis ": "M43.10",
      },
    },
    675: {
      "Chief Complaint": "back ache",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Muscle strain ": "S39.012A",
        "Scoliosis ": "M41.9",
        "Growing pains ": "R29.89",
        "Disk injury ": "S33.0XXA",
        "Kidney infection ": "N39.0",
      },
    },
    676: {
      "Chief Complaint": "back ache",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Lumbar strain ": "S39.012A",
        "Herniated disc ": "M51.27",
        "Spondylolisthesis ": "M43.10",
        "Kidney stones ": "N20.0",
        "Ankylosing spondylitis ": "M45",
      },
    },
    677: {
      "Chief Complaint": "back ache",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Lumbar strain ": "S39.012A",
        "Herniated disc ": "M51.27",
        "Pregnancy-related back pain ": "O26.89",
        "Kidney infection ": "N39.0",
        "Ankylosing spondylitis ": "M45",
      },
    },
    678: {
      "Chief Complaint": "back ache",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.90",
        "Lumbar strain ": "S39.012A",
        "Spinal stenosis ": "M48.06",
        "Vertebral fracture due to osteoporosis ": "M80.08XA",
        "Herniated disc ": "M51.27",
      },
    },
    679: {
      "Chief Complaint": "Hemoptysis (coughing up blood)",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Foreign body aspiration ": "T17.298A",
        "Cystic fibrosis ": "E84.9",
        "Bronchitis ": "J20.9",
        "Tuberculosis ": "A15.9",
        "Bronchiectasis ": "J47.9",
      },
    },
    680: {
      "Chief Complaint": "Hemoptysis (coughing up blood)",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Bronchitis ": "J40",
        "Lung cancer ": "C34.90",
        "Tuberculosis ": "A15.9",
        "Pulmonary embolism ": "I26.99",
        "Bronchiectasis ": "J47.9",
      },
    },
    681: {
      "Chief Complaint": "Hemoptysis (coughing up blood)",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Bronchitis ": "J40",
        "Lung cancer ": "C34.90",
        "Tuberculosis ": "A15.9",
        "Pulmonary embolism ": "I26.99",
        "Goodpasture syndrome ": "M31.0",
      },
    },
    682: {
      "Chief Complaint": "Hemoptysis (coughing up blood)",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Lung cancer ": "C34.90",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Bronchiectasis ": "J47.9",
        "Tuberculosis ": "A15.9",
        "Pulmonary embolism ": "I26.99",
      },
    },
    683: {
      "Chief Complaint": "hand injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.509A",
        "Sprain ": "S63.509A",
        "Laceration ": "S61.0XXA",
        "Contusion ": "S60.0XXA",
        "Animal bite ": "S61.8XXA",
      },
    },
    684: {
      "Chief Complaint": "hand injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Fracture ": "S62.509A",
        "Tendon injury ": "S66.9XXA",
        "Laceration ": "S61.0XXA",
        "Crush injury ": "S67.8XXA",
        "Burn ": "T23.00XA",
      },
    },
    685: {
      "Chief Complaint": "hand injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Fracture ": "S62.509A",
        "Sprain ": "S63.509A",
        "Laceration ": "S61.0XXA",
        "Contusion ": "S60.0XXA",
        "Tendon injury ": "S66.9XXA",
      },
    },
    686: {
      "Chief Complaint": "hand injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fracture ": "S62.509A",
        "Sprain ": "S63.509A",
        "Laceration ": "S61.0XXA",
        "Contusion ": "S60.0XXA",
        "Burn ": "T23.00XA",
      },
    },
    687: {
      "Chief Complaint": "neck injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Strain ": "S13.4XXA",
        "Whiplash ": "S13.0XXA",
        "Contusion ": "S10.8XXA",
        "Laceration ": "S11.1XXA",
        "Fracture ": "S12.9XXA",
      },
    },
    688: {
      "Chief Complaint": "neck injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Whiplash ": "S13.0XXA",
        "Strain ": "S13.4XXA",
        "Cervical disc injury ": "S13.2XXA",
        "Contusion ": "S10.8XXA",
        "Laceration ": "S11.1XXA",
      },
    },
    689: {
      "Chief Complaint": "neck injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Whiplash ": "S13.0XXA",
        "Strain ": "S13.4XXA",
        "Contusion ": "S10.8XXA",
        "Cervical disc injury ": "S13.2XXA",
        "Laceration ": "S11.1XXA",
      },
    },
    690: {
      "Chief Complaint": "neck injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Fracture ": "S12.9XXA",
        "Whiplash ": "S13.0XXA",
        "Strain ": "S13.4XXA",
        "Contusion ": "S10.8XXA",
        "Cervical disc injury ": "S13.2XXA",
      },
    },
    691: {
      "Chief Complaint": "something in ear",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Foreign body in ear ": "T16",
        "Otitis externa ": "H60.9",
        "Otitis media ": "H66.90",
        "Earwax impaction ": "H61.2",
        "Insect in ear ": "T16",
      },
    },
    692: {
      "Chief Complaint": "something in ear",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Foreign body in ear ": "T16",
        "Otitis externa ": "H60.9",
        "Earwax impaction ": "H61.2",
        "Otitis media ": "H66.90",
        "Insect in ear ": "T16",
      },
    },
    693: {
      "Chief Complaint": "bug bite",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Insect bite, non-venomous ": "S00.3XXA",
        "Spider bite, venomous ": "T63.3XXA",
        "Tick bite ": "Z01.818",
        "Mosquito bite ": "S00.3XXA",
        "Flea bite ": "S00.3XXA",
      },
    },
    694: {
      "Chief Complaint": "bug bite",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Insect bite, non-venomous ": "S00.3XXA",
        "Spider bite, venomous ": "T63.3XXA",
        "Tick bite ": "Z01.818",
        "Mosquito bite ": "S00.3XXA",
        "Flea bite ": "S00.3XXA",
      },
    },
    695: {
      "Chief Complaint": "cardiac arrest",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital heart disease ": "Q24.9",
        "Myocarditis ": "I51.4",
        "Arrhythmia ": "I49.9",
        "Hypoxic-ischemic encephalopathy ": "P91.6",
        "Sepsis ": "P36.9",
      },
    },
    696: {
      "Chief Complaint": "cardiac arrest",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Congenital heart disease ": "Q24.9",
        "Myocarditis ": "I51.4",
        "Arrhythmia ": "I49.9",
        "Hypertrophic cardiomyopathy ": "I42.1",
        "Long QT syndrome ": "I49.8",
      },
    },
    697: {
      "Chief Complaint": "cardiac arrest",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Myocardial infarction ": "I21.9",
        "Arrhythmia ": "I49.9",
        "Pulmonary embolism ": "I26.9",
        "Hypertrophic cardiomyopathy ": "I42.1",
        "Aortic dissection ": "I71.0",
      },
    },
    698: {
      "Chief Complaint": "cardiac arrest",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Myocardial infarction ": "I21.9",
        "Arrhythmia ": "I49.9",
        "Heart failure ": "I50.9",
        "Aortic stenosis ": "I35.0",
        "Pulmonary embolism ": "I26.9",
      },
    },
    699: {
      "Chief Complaint": "stepped on nail",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Puncture wound ": "S91.301A",
        "Tetanus ": "A35",
        "Cellulitis ": "L03.115",
        "Osteomyelitis ": "M86.69",
        "Foreign body ": "Z18.9",
      },
    },
    700: {
      "Chief Complaint": "bug bite",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Insect bite, non-venomous ": "S00.3XXA",
        "Spider bite, venomous ": "T63.3XXA",
        "Tick bite ": "Z01.818",
        "Mosquito bite ": "S00.3XXA",
        "Flea bite ": "S00.3XXA",
      },
    },
    701: {
      "Chief Complaint": "insect sting",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Bee sting ": "X23.XXXA",
        "Allergic reaction to insect sting ": "T78.40XA",
        "Wasp sting ": "X23.XXXA",
        "Hornet sting ": "X23.XXXA",
        "Ant sting ": "X23.XXXA",
      },
    },
    702: {
      "Chief Complaint": "overdose",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Opioid overdose ": "T40.0X1A",
        "Benzodiazepine overdose ": "T42.4X1A",
        "Acetaminophen overdose ": "T39.1X1A",
        "Alcohol poisoning ": "T51.0X1A",
        "Antidepressant overdose ": "T43.0X1A",
      },
    },
    703: {
      "Chief Complaint": "self injury",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Laceration ": "S61.0XXA",
        "Poisoning ": "T50.901A",
        "Overdose ": "T40.0X1A",
        "Fracture ": "S52.501A",
        "Burns ": "T23.00XA",
      },
    },
    704: {
      "Chief Complaint": "broken tooth",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Fractured tooth ": "S02.5XXA",
        "Dental caries ": "K02.9",
        "Tooth avulsion ": "S02.5XXA",
        "Toothache ": "K08.8",
        "Pulpitis ": "K04.0",
      },
    },
    705: {
      "Chief Complaint": "difficulty speaking",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Developmental speech delay ": "F80.9",
        "Cerebral palsy ": "G80.9",
        "Autism spectrum disorder ": "F84.0",
        "Epilepsy ": "G40.909",
        "Hearing loss ": "H91.9",
      },
    },
    706: {
      "Chief Complaint": "difficulty speaking",
      Age: "Adult",
      Gender: "Male & Female",
      DDx: {
        "Stroke ": "I63.9",
        "Brain tumor ": "C71.9",
        "Multiple sclerosis ": "G35",
        "Myasthenia gravis ": "G70.01",
        "Laryngitis ": "J04.0",
      },
    },
    707: {
      "Chief Complaint": "difficulty speaking",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Stroke ": "I63.9",
        "Alzheimer's disease ": "G30.9",
        "Parkinson's disease ": "G20",
        "Brain tumor ": "C71.9",
        "Laryngitis ": "J04.0",
      },
    },
    708: {
      "Chief Complaint": "knee injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anterior cruciate ligament ": "ACL",
        "Osgood-Schlatter disease ": "M92.50",
        "Meniscus tear ": "S83.2XXA",
        "Patellar dislocation ": "S83.001A",
        "Growth plate fracture ": "S89.8XXA",
      },
    },
    709: {
      "Chief Complaint": "knee injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "ACL tear ": "S83.511A",
        "Meniscus tear ": "S83.2XXA",
        "Patellar tendinitis ": "M76.50",
        "Medial collateral ligament ": "MCL",
        "Osteoarthritis ": "M17.9",
      },
    },
    710: {
      "Chief Complaint": "knee injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "ACL tear ": "S83.511A",
        "Patellar tendinitis ": "M76.50",
        "Meniscus tear ": "S83.2XXA",
        "Patellar dislocation ": "S83.001A",
        "Osteoarthritis ": "M17.9",
      },
    },
    711: {
      "Chief Complaint": "knee injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M17.9",
        "Meniscus tear ": "S83.2XXA",
        "ACL tear ": "S83.511A",
        "Fracture ": "S82.9XXA",
        "Bursitis ": "M70.50",
      },
    },
    712: {
      "Chief Complaint": "shoulder injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Clavicle fracture ": "S42.0XXA",
        "Rotator cuff strain ": "S46.01XA",
        "Dislocation ": "S43.0XXA",
        "Contusion ": "S40.0XXA",
        "Growth plate injury ": "S49.8XXA",
      },
    },
    713: {
      "Chief Complaint": "shoulder injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Rotator cuff tear ": "S46.01XA",
        "Dislocation ": "S43.0XXA",
        "Acromioclavicular ": "AC",
        "Bursitis ": "M75.5",
        "Fracture ": "S42.9XXA",
      },
    },
    714: {
      "Chief Complaint": "shoulder injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Rotator cuff tear ": "S46.01XA",
        "Bursitis ": "M75.5",
        "Dislocation ": "S43.0XXA",
        "AC joint sprain ": "S43.1XXA",
        "Frozen shoulder ": "M75.0",
      },
    },
    715: {
      "Chief Complaint": "shoulder injury",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Rotator cuff tear ": "S46.01XA",
        "Osteoarthritis ": "M19.019",
        "Fracture ": "S42.9XXA",
        "Bursitis ": "M75.5",
        "Frozen shoulder ": "M75.0",
      },
    },
    716: {
      "Chief Complaint": "hernia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Inguinal hernia ": "K40.9",
        "Umbilical hernia ": "K42.9",
        "Diaphragmatic hernia ": "Q79.0",
        "Epigastric hernia ": "K44.9",
        "Femoral hernia ": "K41.9",
      },
    },
    717: {
      "Chief Complaint": "hernia",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Inguinal hernia ": "K40.9",
        "Hiatal hernia ": "K44.9",
        "Incisional hernia ": "K43.9",
        "Femoral hernia ": "K41.9",
        "Umbilical hernia ": "K42.9",
      },
    },
    718: {
      "Chief Complaint": "hernia",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Femoral hernia ": "K41.9",
        "Inguinal hernia ": "K40.9",
        "Incisional hernia ": "K43.9",
        "Hiatal hernia ": "K44.9",
        "Umbilical hernia ": "K42.9",
      },
    },
    719: {
      "Chief Complaint": "hernia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Inguinal hernia ": "K40.9",
        "Hiatal hernia ": "K44.9",
        "Incisional hernia ": "K43.9",
        "Femoral hernia ": "K41.9",
        "Umbilical hernia ": "K42.9",
      },
    },
    720: {
      "Chief Complaint": "hip dislocation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Developmental dysplasia of the hip ": "Q65.9",
        "Traumatic hip dislocation ": "S73.0XXA",
        "Slipped capital femoral epiphysis ": "M93.0",
        "Legg-Calv\u00e9-Perthes disease ": "M91.1",
        "Fracture ": "S72.9XXA",
      },
    },
    721: {
      "Chief Complaint": "hip dislocation",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Traumatic hip dislocation ": "S73.0XXA",
        "Osteoarthritis ": "M16.9",
        "Fracture ": "S72.9XXA",
        "Hip labral tear ": "M24.151",
        "Septic arthritis ": "M00.9",
      },
    },
    722: {
      "Chief Complaint": "finger dislocation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Traumatic dislocation ": "S63.0XXA",
        "Fracture ": "S62.609A",
        "Sprain ": "S63.609A",
        "Tendon injury ": "S66.0XXA",
        "Contusion ": "S60.1XXA",
      },
    },
    723: {
      "Chief Complaint": "finger dislocation",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Traumatic dislocation ": "S63.0XXA",
        "Fracture ": "S62.609A",
        "Sprain ": "S63.609A",
        "Arthritis ": "M19.049",
        "Tendon injury ": "S66.0XXA",
      },
    },
    724: {
      "Chief Complaint": "finger dislocation",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Traumatic dislocation ": "S63.0XXA",
        "Fracture ": "S62.609A",
        "Arthritis ": "M19.049",
        "Sprain ": "S63.609A",
        "Tendon injury ": "S66.0XXA",
      },
    },
    725: {
      "Chief Complaint": "ovarian cyst",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Follicular cyst ": "N83.0",
        "Corpus luteum cyst ": "N83.1",
        "Dermoid cyst ": "D27",
        "Polycystic ovary syndrome ": "E28.2",
        "Endometrioma ": "N80.1",
      },
    },
    726: {
      "Chief Complaint": "ovarian cyst",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Follicular cyst ": "N83.0",
        "Corpus luteum cyst ": "N83.1",
        "Dermoid cyst ": "D27",
        "Polycystic ovary syndrome ": "E28.2",
        "Endometrioma ": "N80.1",
      },
    },
    727: {
      "Chief Complaint": "ovarian cyst",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Follicular cyst ": "N83.0",
        "Dermoid cyst ": "D27",
        "Endometrioma ": "N80.1",
        "Ovarian cancer ": "C56.9",
        "Polycystic ovary syndrome ": "E28.2",
      },
    },
    728: {
      "Chief Complaint": "pilonidal cyst",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pilonidal cyst ": "L05.91",
        "Abscess ": "L02.91",
        "Folliculitis ": "L73.9",
        "Dermoid cyst ": "D17.0",
        "Skin infection ": "L08.9",
      },
    },
    729: {
      "Chief Complaint": "pilonidal cyst",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Pilonidal cyst ": "L05.91",
        "Abscess ": "L02.91",
        "Folliculitis ": "L73.9",
        "Dermoid cyst ": "D17.0",
        "Fistula ": "K60.9",
      },
    },
    730: {
      "Chief Complaint": "pilonidal cyst",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Pilonidal cyst ": "L05.91",
        "Abscess ": "L02.91",
        "Skin infection ": "L08.9",
        "Dermoid cyst ": "D17.0",
        "Fistula ": "K60.9",
      },
    },
    731: {
      "Chief Complaint": "electrocution & electric shock",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Burns ": "T20-T25",
        "Arrhythmias ": "I49.9",
        "Nerve injury ": "S04.9XXA",
        "Muscle injury ": "S76.9XXA",
        "Respiratory arrest ": "R09.2",
      },
    },
    732: {
      "Chief Complaint": "electrocution & electric shock",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Burns ": "T20-T25",
        "Arrhythmias ": "I49.9",
        "Nerve injury ": "S04.9XXA",
        "Muscle injury ": "S76.9XXA",
        "Respiratory arrest ": "R09.2",
      },
    },
    733: {
      "Chief Complaint": "electrocution & electric shock",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Burns ": "T20-T25",
        "Arrhythmias ": "I49.9",
        "Nerve injury ": "S04.9XXA",
        "Muscle injury ": "S76.9XXA",
        "Respiratory arrest ": "R09.2",
      },
    },
    734: {
      "Chief Complaint": "syncope",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Dehydration ": "E86.0",
        "Arrhythmia ": "I49.9",
        "Seizure ": "G40.909",
        "Hyperventilation ": "R06.4",
      },
    },
    735: {
      "Chief Complaint": "syncope",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Cardiac arrhythmia ": "I49.9",
        "Orthostatic hypotension ": "I95.1",
        "Carotid sinus hypersensitivity ": "G90.01",
        "Anemia ": "D64.9",
      },
    },
    736: {
      "Chief Complaint": "syncope",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Anemia ": "D64.9",
        "Arrhythmia ": "I49.9",
        "Hypoglycemia ": "E16.2",
        "Pregnancy ": "O99.89",
      },
    },
    737: {
      "Chief Complaint": "syncope",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Cardiac arrhythmia ": "I49.9",
        "Medication side effect ": "T88.7XXA",
        "Carotid sinus hypersensitivity ": "G90.01",
      },
    },
    738: {
      "Chief Complaint": "dka",
      Age: "Newborn",
      Gender: "Male",
      DDx: {
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal sepsis ": "P36.9",
        "Congenital hyperinsulinism ": "E16.0",
        "Inborn errors of metabolism ": "E88.8",
        "Gastroenteritis ": "A09",
      },
    },
    739: {
      "Chief Complaint": "dka",
      Age: "Infant",
      Gender: "Male",
      DDx: {
        "Gastroenteritis ": "A09",
        "Urinary tract infection ": "N39.0",
        "Sepsis ": "P36.9",
        "Appendicitis ": "K35.9",
        "Pneumonia ": "J18.9",
      },
    },
    740: {
      "Chief Complaint": "dka",
      Age: "Pediatric",
      Gender: "Male",
      DDx: {
        "Type 1 diabetes ": "E10.9",
        "Gastroenteritis ": "A09",
        "Appendicitis ": "K35.9",
        "Viral illness ": "B34.9",
        "Sepsis ": "P36.9",
      },
    },
    741: {
      "Chief Complaint": "dka",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Type 1 diabetes ": "E10.9",
        "Type 2 diabetes ": "E11.9",
        "Sepsis ": "A41.9",
        "Acute pancreatitis ": "K85.9",
        "Alcoholic ketoacidosis ": "E87.2",
      },
    },
    742: {
      "Chief Complaint": "dka",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Type 2 diabetes ": "E11.9",
        "Sepsis ": "A41.9",
        "Acute pancreatitis ": "K85.9",
        "Myocardial infarction ": "I21.9",
        "Cerebral infarction ": "I63.9",
      },
    },
    743: {
      "Chief Complaint": "dka",
      Age: "Newborn",
      Gender: "Female",
      DDx: {
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal sepsis ": "P36.9",
        "Congenital hyperinsulinism ": "E16.0",
        "Inborn errors of metabolism ": "E88.8",
        "Gastroenteritis ": "A09",
      },
    },
    744: {
      "Chief Complaint": "dka",
      Age: "Infant",
      Gender: "Female",
      DDx: {
        "Gastroenteritis ": "A09",
        "Urinary tract infection ": "N39.0",
        "Sepsis ": "P36.9",
        "Appendicitis ": "K35.9",
        "Pneumonia ": "J18.9",
      },
    },
    745: {
      "Chief Complaint": "dka",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Type 1 diabetes ": "E10.9",
        "Gastroenteritis ": "A09",
        "Appendicitis ": "K35.9",
        "Viral illness ": "B34.9",
        "Sepsis ": "P36.9",
      },
    },
    746: {
      "Chief Complaint": "dka",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Type 1 diabetes ": "E10.9",
        "Type 2 diabetes ": "E11.9",
        "Sepsis ": "A41.9",
        "Acute pancreatitis ": "K85.9",
        "Alcoholic ketoacidosis ": "E87.2",
      },
    },
    747: {
      "Chief Complaint": "dka",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Type 2 diabetes ": "E11.9",
        "Sepsis ": "A41.9",
        "Acute pancreatitis ": "K85.9",
        "Myocardial infarction ": "I21.9",
        "Cerebral infarction ": "I63.9",
      },
    },
    748: {
      "Chief Complaint": "drowning",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Accidental drowning ": "T75.1",
        "Water-related injury ": "W65.9",
        "Hypothermia ": "T68",
        "Alcohol intoxication ": "F10.9",
        "Traumatic injury ": "S00-T88",
      },
    },
    749: {
      "Chief Complaint": "drowning",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Accidental drowning ": "T75.1",
        "Water-related injury ": "W65.9",
        "Hypothermia ": "T68",
        "Cardiovascular event ": "I00-I99",
        "Traumatic injury ": "S00-T88",
      },
    },
    750: {
      "Chief Complaint": "ectopic pregnancy",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ectopic pregnancy ": "O00.9",
        "Miscarriage ": "O03.9",
        "Pelvic inflammatory disease ": "N74.0",
        "Ovarian cyst rupture ": "N83.2",
        "Urinary tract infection ": "N39.0",
      },
    },
    751: {
      "Chief Complaint": "epilepsy",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Febrile seizure ": "R56.0",
        "Epilepsy ": "G40.909",
        "Electrolyte imbalance 4. Meningitis ": "G03.9",
        "Brain tumor ": "C71.9",
      },
    },
    752: {
      "Chief Complaint": "epilepsy",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Epilepsy ": "G40.909",
        "Alcohol withdrawal ": "F10.239",
        "Brain tumor ": "C71.9",
        "Stroke ": "I63.9",
      },
    },
    753: {
      "Chief Complaint": "epilepsy",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Epilepsy ": "G40.909",
        "Stroke ": "I63.9",
        "Brain tumor ": "C71.9",
        "Alzheimer's disease ": "G30.9",
      },
    },
    754: {
      "Chief Complaint": "liver failure",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal hemochromatosis ": "E83.11",
        "Biliary atresia ": "Q44.2",
        "Neonatal hepatitis ": "P55.3",
        "Galactosemia ": "E74.21",
        "Tyrosinemia ": "E70.21",
      },
    },
    755: {
      "Chief Complaint": "liver failure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Wilson's disease ": "E83.0",
        "Autoimmune hepatitis ": "K75.4",
        "Viral hepatitis ": "B19.10",
        "Alagille syndrome ": "Q44.7",
        "Alpha-1 antitrypsin deficiency ": "E88.01",
      },
    },
    756: {
      "Chief Complaint": "liver failure",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cirrhosis ": "K74.60",
        "Hepatitis B or C ": "B18.1, B18.2",
        "Alcoholic liver disease ": "K70.30",
        "Non-alcoholic steatohepatitis ": "NASH",
        "Hepatocellular carcinoma ": "C22.0",
      },
    },
    757: {
      "Chief Complaint": "liver failure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cirrhosis ": "K74.60",
        "Hepatocellular carcinoma ": "C22.0",
        "Alcoholic liver disease ": "K70.30",
        "Drug-induced liver injury ": "K71.0",
        "Primary biliary cholangitis ": "K74.3",
      },
    },
    758: {
      "Chief Complaint": "liver failure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Alcoholic liver disease ": "K70.30",
        "Non-alcoholic fatty liver disease ": "K76.0",
        "Hepatitis B ": "B18.1",
        "Hepatitis C ": "B18.2",
        "Cirrhosis ": "K74.6",
      },
    },
    759: {
      "Chief Complaint": "glaucoma",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital glaucoma ": "Q15.0",
        "Corneal opacity ": "H17.9",
        "Retinopathy of prematurity ": "H35.11",
        "Ocular trauma ": "S05.9",
        "Ocular infection ": "H10.9",
      },
    },
    760: {
      "Chief Complaint": "glaucoma",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Congenital glaucoma ": "Q15.0",
        "Ocular trauma ": "S05.9",
        "Ocular infection ": "H10.9",
        "Retinopathy of prematurity ": "H35.11",
        "Ocular inflammation ": "H20.9",
      },
    },
    761: {
      "Chief Complaint": "glaucoma",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Primary open-angle glaucoma ": "H40.11",
        "Ocular trauma ": "S05.9",
        "Ocular infection ": "H10.9",
        "Ocular inflammation ": "H20.9",
        "Retinopathy of prematurity ": "H35.11",
      },
    },
    762: {
      "Chief Complaint": "glaucoma",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Primary open-angle glaucoma ": "H40.11",
        "Secondary glaucoma ": "H40.89",
        "Ocular trauma ": "S05.9",
        "Ocular infection ": "H10.9",
        "Ocular inflammation ": "H20.9",
      },
    },
    763: {
      "Chief Complaint": "glaucoma",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Primary open-angle glaucoma ": "H40.11",
        "Secondary glaucoma ": "H40.89",
        "Cataract ": "H25.9",
        "Age-related macular degeneration ": "H35.32",
        "Diabetic retinopathy ": "H36.0",
      },
    },
    764: {
      "Chief Complaint": "opiate withdrawal",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal abstinence syndrome ": "P96.1",
        "Premature birth ": "P07.2",
        "Neonatal sepsis ": "P36.9",
        "Intrauterine drug exposure ": "P04.49",
        "Hypoglycemia ": "P70.0",
      },
    },
    765: {
      "Chief Complaint": "opiate withdrawal",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Neonatal abstinence syndrome ": "P96.1",
        "Premature birth ": "P07.2",
        "Neonatal sepsis ": "P36.9",
        "Intrauterine drug exposure ": "P04.49",
        "Gastroenteritis ": "A09",
      },
    },
    766: {
      "Chief Complaint": "opiate withdrawal",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Opioid dependence ": "F11.20",
        "Attention-deficit/hyperactivity disorder ": "F90.0",
        "Conduct disorder ": "F91.1",
        "Oppositional defiant disorder ": "F91.3",
        "Generalized anxiety disorder ": "F41.1",
      },
    },
    767: {
      "Chief Complaint": "opiate withdrawal",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Opioid dependence ": "F11.20",
        "Alcohol withdrawal ": "F10.239",
        "Benzodiazepine withdrawal ": "F13.239",
        "Stimulant withdrawal ": "F15.20",
        "Anxiety disorder ": "F41.9",
      },
    },
    768: {
      "Chief Complaint": "opiate withdrawal",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Opioid dependence ": "F11.20",
        "Alcohol withdrawal ": "F10.239",
        "Benzodiazepine withdrawal ": "F13.239",
        "Stimulant withdrawal ": "F15.20",
        "Anxiety disorder ": "F41.9",
      },
    },
    769: {
      "Chief Complaint": "gastroparesis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal feeding problems ": "P92",
        "Gastroesophageal reflux ": "K21.9",
        "Intestinal malrotation ": "Q41.9",
        "Hirschsprung's disease ": "Q43.1",
        "Pyloric stenosis ": "Q40.0",
      },
    },
    770: {
      "Chief Complaint": "gastroparesis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Neonatal feeding problems ": "P92",
        "Gastroesophageal reflux ": "K21.9",
        "Intestinal malrotation ": "Q41.9",
        "Hirschsprung's disease ": "Q43.1",
        "Pyloric stenosis ": "Q40.0",
      },
    },
    771: {
      "Chief Complaint": "gastroparesis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gastroesophageal reflux disease ": "K21.9",
        "Functional dyspepsia ": "K30",
        "Chronic constipation ": "K59.00",
        "Cyclic vomiting syndrome ": "R11.12",
        "Eosinophilic esophagitis ": "K20.0",
      },
    },
    772: {
      "Chief Complaint": "gastroparesis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Diabetes mellitus with gastroparesis ": "E08.43",
        "Idiopathic gastroparesis ": "K31.84",
        "Post-viral gastroparesis ": "K31.83",
        "Narcotic-induced constipation ": "K59.03",
        "Functional dyspepsia ": "K30",
      },
    },
    773: {
      "Chief Complaint": "gastroparesis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Diabetes mellitus with gastroparesis ": "E08.43",
        "Idiopathic gastroparesis ": "K31.84",
        "Post-viral gastroparesis ": "K31.83",
        "Narcotic-induced constipation ": "K59.03",
        "Functional dyspepsia ": "K30",
      },
    },
    774: {
      "Chief Complaint": "labor pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "True labor contractions ": "O47.9",
        "False labor contractions ": "O47.0",
        "Preterm labor ": "O47.1",
        "Uterine rupture ": "O71.9",
        "Placental abruption ": "O45.9",
      },
    },
    775: {
      "Chief Complaint": "urinary retention",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital urinary tract obstruction ": "Q63.9",
        "Posterior urethral valves ": "Q64.0",
        "Neurogenic bladder ": "N31.0",
        "Urethral stricture ": "N35.0",
        "Urinary tract infection ": "N39.0",
      },
    },
    776: {
      "Chief Complaint": "urinary retention",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Congenital urinary tract obstruction ": "Q63.9",
        "Posterior urethral valves ": "Q64.0",
        "Neurogenic bladder ": "N31.0",
        "Urethral stricture ": "N35.0",
        "Urinary tract infection ": "N39.0",
      },
    },
    777: {
      "Chief Complaint": "urinary retention",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Neurogenic bladder ": "N31.0",
        "Urethral stricture ": "N35.0",
        "Urinary tract infection ": "N39.0",
        "Posterior urethral valves ": "Q64.0",
        "Bladder dysfunction ": "N31.9",
      },
    },
    778: {
      "Chief Complaint": "urinary retention",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.1",
        "Prostate cancer ": "C61",
        "Urethral stricture ": "N35.0",
        "Neurogenic bladder ": "N31.0",
        "Medication side effects ": "T36-T50",
      },
    },
    779: {
      "Chief Complaint": "urinary retention",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.1",
        "Prostate cancer ": "C61",
        "Urethral stricture ": "N35.0",
        "Neurogenic bladder ": "N31.0",
        "Medication side effects ": "T36-T50",
      },
    },
    780: {
      "Chief Complaint": "unable to pee",
      Age: "Newborn",
      Gender: "Male",
      DDx: {
        "Congenital urinary tract obstruction ": "Q63.9",
        "Posterior urethral valves ": "Q64.0",
        "Neurogenic bladder ": "N31.0",
        "Urethral stricture ": "N35.0",
        "Urinary tract infection ": "N39.0",
      },
    },
    781: {
      "Chief Complaint": "unable to pee",
      Age: "Infant",
      Gender: "Male",
      DDx: {
        "Congenital urinary tract obstruction ": "Q63.9",
        "Posterior urethral valves ": "Q64.0",
        "Neurogenic bladder ": "N31.0",
        "Urethral stricture ": "N35.0",
        "Urinary tract infection ": "N39.0",
      },
    },
    782: {
      "Chief Complaint": "unable to pee",
      Age: "Pediatric",
      Gender: "Male",
      DDx: {
        "Neurogenic bladder ": "N31.0",
        "Urethral stricture ": "N35.0",
        "Urinary tract infection ": "N39.0",
        "Posterior urethral valves ": "Q64.0",
        "Bladder dysfunction ": "N31.9",
      },
    },
    783: {
      "Chief Complaint": "unable to pee",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.1",
        "Prostate cancer ": "C61",
        "Urethral stricture ": "N35.0",
        "Neurogenic bladder ": "N31.0",
        "Medication side effects ": "T36-T50",
      },
    },
    784: {
      "Chief Complaint": "unable to pee",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.1",
        "Prostate cancer ": "C61",
        "Urethral stricture ": "N35.0",
        "Neurogenic bladder ": "N31.0",
        "Medication side effects ": "T36-T50",
      },
    },
    785: {
      "Chief Complaint": "body piercing",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Local infection at piercing site ": "T81.8",
        "Keloid formation ": "L91.0",
        "Allergic reaction ": "T78.4",
        "Skin irritation ": "L25.9",
        "Hemorrhage ": "R58",
      },
    },
    786: {
      "Chief Complaint": "body piercing",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Local infection at piercing site ": "T81.8",
        "Keloid formation ": "L91.0",
        "Allergic reaction ": "T78.4",
        "Skin irritation ": "L25.9",
        "Hemorrhage ": "R58",
      },
    },
    787: {
      "Chief Complaint": "tongue piercing",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Infection at piercing site ": "T81.8",
        "Allergic reaction ": "T78.4",
        "Tongue swelling ": "K14.6",
        "Dental issues ": "K00-K14",
        "Trauma to tongue ": "S00-T88",
      },
    },
    788: {
      "Chief Complaint": "tongue piercing",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Infection at piercing site ": "T81.8",
        "Allergic reaction ": "T78.4",
        "Tongue swelling ": "K14.6",
        "Dental issues ": "K00-K14",
        "Trauma to tongue ": "S00-T88",
      },
    },
    789: {
      "Chief Complaint": "ear piercing problem",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Infection at piercing site ": "T81.8",
        "Allergic reaction ": "T78.4",
        "Keloid formation ": "L91.0",
        "Otitis externa ": "H60.9",
        "Trauma to ear ": "S00-T88",
      },
    },
    790: {
      "Chief Complaint": "ear piercing problem",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Infection at piercing site ": "T81.8",
        "Allergic reaction ": "T78.4",
        "Keloid formation ": "L91.0",
        "Otitis externa ": "H60.9",
        "Trauma to ear ": "S00-T88",
      },
    },
    791: {
      "Chief Complaint": "small bowel obstruction",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal intestinal obstruction ": "P76.0",
        "Hirschsprung's disease ": "Q43.1",
        "Malrotation of intestine ": "Q41.3",
        "Meconium ileus ": "E84.11",
        "Intussusception ": "K56.1",
      },
    },
    792: {
      "Chief Complaint": "small bowel obstruction",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Intussusception ": "K56.1",
        "Appendicitis ": "K35.9",
        "Inguinal hernia ": "K40.9",
        "Malrotation of intestine ": "Q41.3",
        "Abdominal adhesions ": "K66.0",
      },
    },
    793: {
      "Chief Complaint": "small bowel obstruction",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Intussusception ": "K56.1",
        "Appendicitis ": "K35.9",
        "Abdominal adhesions ": "K66.0",
        "Meckel's diverticulum ": "K57.3",
        "Inguinal hernia ": "K40.9",
      },
    },
    794: {
      "Chief Complaint": "small bowel obstruction",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adhesive small bowel obstruction ": "K56.7",
        "Hernia ": "K40-K46",
        "Crohn's disease ": "K50.9",
        "Tumor ": "C17-C21",
        "Volvulus ": "K56.2",
      },
    },
    795: {
      "Chief Complaint": "small bowel obstruction",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Adhesive small bowel obstruction ": "K56.7",
        "Hernia ": "K40-K46",
        "Tumor ": "C17-C21",
        "Diverticulitis ": "K57.32",
        "Volvulus ": "K56.2",
      },
    },
    796: {
      "Chief Complaint": "miscarriage",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ectopic pregnancy ": "O00",
        "Threatened abortion ": "O20.0",
        "Vaginal bleeding ": "N93.9",
        "Uterine fibroids ": "D25.9",
        "Cervical insufficiency ": "N88.9",
      },
    },
    797: {
      "Chief Complaint": "foley catheter displacement",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Inadequate catheter insertion ": "T83.590A",
        "Catheter malfunction ": "T83.592A",
        "Urinary tract infection ": "N39.0",
        "Bladder outlet obstruction ": "N13.3",
        "Urethral stricture ": "N35.0",
      },
    },
    798: {
      "Chief Complaint": "foley catheter malfunction",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Catheter blockage ": "T83.591A",
        "Catheter kinking ": "T83.598A",
        "Urinary tract infection ": "N39.0",
        "Catheter dislodgment ": "T83.593A",
        "Catheter leakage ": "T83.594A",
      },
    },
    799: {
      "Chief Complaint": "pregnancy problem",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ectopic pregnancy ": "O00",
        "Gestational diabetes ": "O24.4",
        "Preeclampsia ": "O14.9",
        "Placenta previa ": "O44.9",
        "Preterm labor ": "O60-O77",
      },
    },
    800: {
      "Chief Complaint": "pregnant",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Pregnancy ": "O00-O99",
        "Ectopic pregnancy ": "O00",
        "Gestational diabetes ": "O24.4",
        "Preeclampsia ": "O14.9",
        "Placenta previa ": "O44.9",
      },
    },
    801: {
      "Chief Complaint": "chemical in eye",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Chemical eye injury ": "T26.0",
        "Conjunctivitis ": "H10.9",
        "Foreign body in eye ": "T15-T19",
        "Corneal abrasion ": "S05.0",
        "Trauma to eye ": "S00-T14",
      },
    },
    802: {
      "Chief Complaint": "bleach in eye",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Chemical eye injury ": "T26.0",
        "Corneal abrasion ": "S05.0",
        "Conjunctivitis ": "H10.9",
        "Foreign body in eye ": "T15-T19",
        "Trauma to eye ": "S00-T14",
      },
    },
    803: {
      "Chief Complaint": "ostomy bag leaking",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Ostomy site irritation ": "T85.611A",
        "Skin infection ": "L08.9",
        "Poor ostomy appliance fit ": "T85.612A",
        "Stoma prolapse ": "K63.3",
        "Peristomal hernia ": "K44.9",
      },
    },
    804: {
      "Chief Complaint": "ostomy problem",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal ostomy-related complication ": "P78.3",
        "Ostomy site infection ": "T85.610A",
        "Stoma prolapse ": "K63.3",
        "Poor ostomy appliance fit ": "T85.612A",
        "Peristomal hernia ": "K44.9",
      },
    },
    805: {
      "Chief Complaint": "ostomy problem",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Ostomy site infection ": "T85.610A",
        "Neonatal ostomy-related complication ": "P78.3",
        "Stoma prolapse ": "K63.3",
        "Poor ostomy appliance fit ": "T85.612A",
        "Peristomal hernia ": "K44.9",
      },
    },
    806: {
      "Chief Complaint": "ostomy problem",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Poor ostomy appliance fit ": "T85.612A",
        "Stoma prolapse ": "K63.3",
        "Peristomal hernia ": "K44.9",
        "Ostomy site infection ": "T85.610A",
        "Ileostomy/colostomy obstruction ": "K91.3",
      },
    },
    807: {
      "Chief Complaint": "ostomy problem",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Poor ostomy appliance fit ": "T85.612A",
        "Stoma prolapse ": "K63.3",
        "Peristomal hernia ": "K44.9",
        "Ostomy site infection ": "T85.610A",
        "Ileostomy/colostomy obstruction ": "K91.3",
      },
    },
    808: {
      "Chief Complaint": "ostomy problem",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Poor ostomy appliance fit ": "T85.612A",
        "Stoma prolapse ": "K63.3",
        "Peristomal hernia ": "K44.9",
        "Ostomy site infection ": "T85.610A",
        "Ileostomy/colostomy obstruction ": "K91.3",
      },
    },
    809: {
      "Chief Complaint": "vaginal itching",
      Age: "Newborn",
      Gender: "Female",
      DDx: {
        "Vulvovaginitis in newborn ": "P39.9",
        "Skin irritation ": "L29.9",
        "Allergic reaction ": "T78.40XA",
        "Infectious dermatitis ": "L30.9",
        "Irritant contact dermatitis ": "L24.9",
      },
    },
    810: {
      "Chief Complaint": "vaginal itching",
      Age: "Infant",
      Gender: "Female",
      DDx: {
        "Vulvovaginitis in infant ": "N76.1",
        "Diaper rash ": "L22",
        "Infectious dermatitis ": "L30.9",
        "Allergic reaction ": "T78.40XA",
        "Pinworm infection ": "B80",
      },
    },
    811: {
      "Chief Complaint": "vaginal itching",
      Age: "Pediatric",
      Gender: "Female",
      DDx: {
        "Vulvovaginitis in pediatric age ": "N76.1",
        "Allergic reaction ": "T78.40XA",
        "Infectious dermatitis ": "L30.9",
        "Irritant contact dermatitis ": "L24.9",
        "Pinworm infection ": "B80",
      },
    },
    812: {
      "Chief Complaint": "vaginal itching",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Genital itching ": "N94.1",
        "Contact dermatitis ": "L25.9",
        "Sexually transmitted infection ": "A64-A69",
        "Allergic reaction ": "T78.40XA",
        "Fungal infection ": "B37.3",
      },
    },
    813: {
      "Chief Complaint": "vaginal itching",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Genital itching ": "N94.1",
        "Contact dermatitis ": "L25.9",
        "Sexually transmitted infection ": "A64-A69",
        "Allergic reaction ": "T78.40XA",
        "Fungal infection ": "B37.3",
      },
    },
    814: {
      "Chief Complaint": "atrial fibrillation (Afib)",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital heart block ": "Q24.6",
        "Neonatal arrhythmia ": "P29.1",
        "Structural heart defect ": "Q24.9",
        "Premature atrial contractions ": "I49.0",
        "Hypoxia ": "R09.02",
      },
    },
    815: {
      "Chief Complaint": "atrial fibrillation (Afib)",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Congenital heart block ": "Q24.6",
        "Structural heart defect ": "Q24.9",
        "Premature atrial contractions ": "I49.0",
        "Hypoxia ": "R09.02",
        "Neonatal arrhythmia ": "P29.1",
      },
    },
    816: {
      "Chief Complaint": "atrial fibrillation (Afib)",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Structural heart defect ": "Q24.9",
        "Premature atrial contractions ": "I49.0",
        "Hypoxia ": "R09.02",
        "Ventricular tachycardia ": "I47.2",
        "Wolff-Parkinson-White syndrome ": "I45.6",
      },
    },
    817: {
      "Chief Complaint": "atrial fibrillation (Afib)",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.9",
        "Atrial flutter ": "I48.0",
        "Ventricular tachycardia ": "I47.2",
        "Ventricular fibrillation ": "I49.0",
        "Hypertension ": "I10",
      },
    },
    818: {
      "Chief Complaint": "atrial fibrillation (Afib)",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.9",
        "Atrial flutter ": "I48.0",
        "Hypertension ": "I10",
        "Coronary artery disease ": "I25.1",
        "Heart failure ": "I50.9",
      },
    },
    819: {
      "Chief Complaint": "penile fracture",
      Age: "Newborn",
      Gender: "Male",
      DDx: {
        "Congenital penile anomaly ": "Q55.1",
        "Penile torsion ": "N47.1",
        "Penile fibrosis ": "N48.89",
        "Paraphimosis ": "N47.2",
        "Priapism ": "N48.4",
      },
    },
    820: {
      "Chief Complaint": "penile fracture",
      Age: "Infant",
      Gender: "Male",
      DDx: {
        "Congenital penile anomaly ": "Q55.1",
        "Penile torsion ": "N47.1",
        "Penile fibrosis ": "N48.89",
        "Paraphimosis ": "N47.2",
        "Priapism ": "N48.4",
      },
    },
    821: {
      "Chief Complaint": "penile fracture",
      Age: "Pediatric",
      Gender: "Male",
      DDx: {
        "Penile trauma ": "S30.850A",
        "Penile contusion ": "S30.850D",
        "Penile laceration ": "S30.850B",
        "Penile hematoma ": "S30.850C",
        "Penile abscess ": "N48.89",
      },
    },
    822: {
      "Chief Complaint": "penile fracture",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Penile fracture ": "N48.5",
        "Penile trauma ": "S30.850A",
        "Peyronie's disease ": "N48.6",
        "Priapism ": "N48.4",
        "Paraphimosis ": "N47.2",
      },
    },
    823: {
      "Chief Complaint": "penile fracture",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Penile trauma ": "S30.850A",
        "Penile contusion ": "S30.850D",
        "Penile laceration ": "S30.850B",
        "Penile hematoma ": "S30.850C",
        "Penile abscess ": "N48.89",
      },
    },
    824: {
      "Chief Complaint": "picc line problem",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "PICC line infection ": "T82.898A",
        "Catheter-related thrombosis ": "I87.1",
        "Malposition of catheter ": "T82.858A",
        "Catheter dislodgement ": "T82.857A",
        "Allergic reaction to catheter material ": "T80.89XA",
      },
    },
    825: {
      "Chief Complaint": "hypokalemia",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal hypokalemia ": "P74.0",
        "Inborn error of metabolism ": "E70-E88",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Renal tubular acidosis ": "N25.89",
        "Diarrhea ": "K52.9",
      },
    },
    826: {
      "Chief Complaint": "hypokalemia",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Infantile hypokalemia ": "E83.511",
        "Inborn error of metabolism ": "E70-E88",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Renal tubular acidosis ": "N25.89",
        "Diarrhea ": "K52.9",
      },
    },
    827: {
      "Chief Complaint": "hypokalemia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric hypokalemia ": "E83.59",
        "Inborn error of metabolism ": "E70-E88",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Renal tubular acidosis ": "N25.89",
        "Gastrointestinal disorders ": "K00-K92",
      },
    },
    828: {
      "Chief Complaint": "hypokalemia",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hypokalemia ": "E87.6",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Renal tubular acidosis ": "N25.89",
        "Gastrointestinal disorders ": "K00-K92",
        "Primary aldosteronism ": "E26.0",
      },
    },
    829: {
      "Chief Complaint": "hypokalemia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hypokalemia ": "E87.6",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Gastrointestinal disorders ": "K00-K92",
        "Renal tubular acidosis ": "N25.89",
        "Primary aldosteronism ": "E26.0",
      },
    },
    830: {
      "Chief Complaint": "low blood sugar",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Hyperinsulinism ": "E16.1",
        "Inborn errors of metabolism ": "E74.9",
        "Sepsis ": "P36.9",
        "Maternal diabetes effects ": "P70.1",
        "Prematurity ": "P07.3",
      },
    },
    831: {
      "Chief Complaint": "low blood sugar",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Hyperinsulinism ": "E16.1",
        "Inborn errors of metabolism ": "E74.9",
        "Gastroenteritis ": "K52.9",
        "Sepsis ": "A41.9",
        "Poor feeding ": "R63.3",
      },
    },
    832: {
      "Chief Complaint": "low blood sugar",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hyperinsulinism ": "E16.1",
        "Diabetic hypoglycemia ": "E16.2",
        "Ketotic hypoglycemia ": "E16.2",
        "Adrenal insufficiency ": "E27.1",
        "Fasting hypoglycemia ": "E16.0",
      },
    },
    833: {
      "Chief Complaint": "low blood sugar",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Diabetic hypoglycemia ": "E16.2",
        "Insulinoma ": "D13.7",
        "Alcohol abuse ": "F10.1",
        "Liver disease ": "K76.9",
      },
    },
    834: {
      "Chief Complaint": "low blood sugar",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Diabetic hypoglycemia ": "E16.2",
        "Insulinoma ": "D13.7",
        "Pregnancy ": "O99.81",
        "Liver disease ": "K76.9",
      },
    },
    835: {
      "Chief Complaint": "low potassium",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Diabetic hypoglycemia ": "E16.2",
        "Medication side effect 3. Malnutrition ": "E46",
        "Liver disease ": "K76.9",
        "Renal failure ": "N17.9",
      },
    },
    836: {
      "Chief Complaint": "low potassium",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Infantile hypokalemia ": "E83.511",
        "Inborn error of metabolism ": "E70-E88",
        "Renal tubular acidosis ": "N25.89",
        "Gastrointestinal disorders ": "K00-K92",
        "Medication-induced hypokalemia ": "T50.0X1A",
      },
    },
    837: {
      "Chief Complaint": "low potassium",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric hypokalemia ": "E83.59",
        "Inborn error of metabolism ": "E70-E88",
        "Renal tubular acidosis ": "N25.89",
        "Gastrointestinal disorders ": "K00-K92",
        "Medication-induced hypokalemia ": "T50.0X1A",
      },
    },
    838: {
      "Chief Complaint": "low potassium",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hypokalemia ": "E87.6",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Renal tubular acidosis ": "N25.89",
        "Gastrointestinal disorders ": "K00-K92",
        "Hyperaldosteronism ": "E26.0",
      },
    },
    839: {
      "Chief Complaint": "low potassium",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hypokalemia ": "E87.6",
        "Medication-induced hypokalemia ": "T50.0X1A",
        "Gastrointestinal disorders ": "K00-K92",
        "Renal tubular acidosis ": "N25.89",
        "Hyperaldosteronism ": "E26.0",
      },
    },
    840: {
      "Chief Complaint": "snake bite",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Nonvenomous snake bite ": "T63.131A",
        "Venomous snake bite ": "T63.031A",
        "Allergic reaction to snake venom ": "T63.331A",
        "Infection at the bite site ": "T63.131A",
        "Local tissue damage ": "T63.131A",
      },
    },
    841: {
      "Chief Complaint": "c. difficile infection",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal diarrhea ": "P78.3",
        "Infectious gastroenteritis ": "A09",
        "Antibiotic-associated diarrhea ": "K52.8",
        "Food poisoning ": "A05.9",
        "Malabsorption syndrome ": "K90.9",
      },
    },
    842: {
      "Chief Complaint": "c. difficile infection",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Infectious gastroenteritis ": "A09",
        "Antibiotic-associated diarrhea ": "K52.8",
        "Gastrointestinal infection ": "K52.9",
        "Viral gastroenteritis ": "A08.4",
        "Malabsorption syndrome ": "K90.9",
      },
    },
    843: {
      "Chief Complaint": "c. difficile infection",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Infectious gastroenteritis ": "A09",
        "Antibiotic-associated diarrhea ": "K52.8",
        "Gastrointestinal infection ": "K52.9",
        "Viral gastroenteritis ": "A08.4",
        "Inflammatory bowel disease ": "K50-K51",
      },
    },
    844: {
      "Chief Complaint": "c. difficile infection",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "C. difficile infection ": "A04.7",
        "Antibiotic-associated diarrhea ": "K52.8",
        "Gastrointestinal infection ": "K52.9",
        "Inflammatory bowel disease ": "K50-K51",
        "Malabsorption syndrome ": "K90.9",
      },
    },
    845: {
      "Chief Complaint": "c. difficile infection",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "C. difficile infection ": "A04.7",
        "Antibiotic-associated diarrhea ": "K52.8",
        "Gastrointestinal infection ": "K52.9",
        "Inflammatory bowel disease ": "K50-K51",
        "Malabsorption syndrome ": "K90.9",
      },
    },
    846: {
      "Chief Complaint": "copd",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Premature birth ": "P07.21",
        "Respiratory distress syndrome ": "P22.1",
        "Meconium aspiration syndrome ": "P24.0",
        "Pulmonary hypertension of newborn ": "P29.3",
        "Neonatal pneumonia ": "P23.9",
      },
    },
    847: {
      "Chief Complaint": "copd",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Bronchiolitis ": "J21.9",
        "Cystic fibrosis ": "E84.9",
        "Pneumonia ": "J18.9",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    848: {
      "Chief Complaint": "copd",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Asthma ": "J45.909",
        "Bronchiolitis ": "J21.9",
        "Cystic fibrosis ": "E84.9",
        "Pneumonia ": "J18.9",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    849: {
      "Chief Complaint": "copd",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "COPD ": "J44.9",
        "Asthma ": "J45.909",
        "Bronchitis ": "J20.9",
        "Pneumonia ": "J18.9",
        "Lung cancer ": "C34.9",
      },
    },
    850: {
      "Chief Complaint": "copd",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "COPD ": "J44.9",
        "Asthma ": "J45.909",
        "Bronchitis ": "J20.9",
        "Pneumonia ": "J18.9",
        "Lung cancer ": "C34.9",
      },
    },
    851: {
      "Chief Complaint": "memory loss",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal hypoxic-ischemic encephalopathy ": "P91.6",
        "Neonatal seizures ": "P90.0",
        "Neonatal intracranial hemorrhage ": "P52.9",
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal meningitis ": "P36.9",
      },
    },
    852: {
      "Chief Complaint": "memory loss",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Developmental delay ": "F88",
        "Autism spectrum disorder ": "F84.0",
        "Pediatric epilepsy ": "G40.9",
        "Brain injury ": "S06.9",
        "Intellectual disability ": "F79",
      },
    },
    853: {
      "Chief Complaint": "memory loss",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Developmental delay ": "F88",
        "Attention-deficit/hyperactivity disorder ": "F90.9",
        "Pediatric epilepsy ": "G40.9",
        "Brain injury ": "S06.9",
        "Intellectual disability ": "F79",
      },
    },
    854: {
      "Chief Complaint": "memory loss",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Alzheimer's disease ": "G30.9",
        "Mild cognitive impairment ": "G31.84",
        "Depression ": "F32.9",
        "Substance abuse ": "F19.9",
        "Normal age-related memory decline ": "R41.81",
      },
    },
    855: {
      "Chief Complaint": "memory loss",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Alzheimer's disease ": "G30.9",
        "Mild cognitive impairment ": "G31.84",
        "Vascular dementia ": "F01.50",
        "Normal age-related memory decline ": "R41.81",
        "Depression ": "F32.9",
      },
    },
    856: {
      "Chief Complaint": "sickle cell crisis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Sickle cell crisis ": "D57.00",
        "Neonatal jaundice due to hemolysis ": "P55.0",
        "Hemolytic anemia ": "D55-D59",
        "Newborn anemia ": "P61.9",
        "Sepsis in newborn ": "P36.9",
      },
    },
    857: {
      "Chief Complaint": "sickle cell crisis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Sickle cell crisis ": "D57.00",
        "Hemolytic anemia ": "D55-D59",
        "Acute chest syndrome ": "D57.1",
        "Pneumonia ": "J18.9",
        "Osteomyelitis ": "M86.9",
      },
    },
    858: {
      "Chief Complaint": "sickle cell crisis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sickle cell crisis ": "D57.00",
        "Acute chest syndrome ": "D57.1",
        "Osteomyelitis ": "M86.9",
        "Pneumonia ": "J18.9",
        "Avascular necrosis of bone ": "M87.9",
      },
    },
    859: {
      "Chief Complaint": "sickle cell crisis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Sickle cell crisis ": "D57.00",
        "Acute chest syndrome ": "D57.1",
        "Avascular necrosis of bone ": "M87.9",
        "Cholecystitis ": "K81.9",
        "Renal papillary necrosis ": "N28.9",
      },
    },
    860: {
      "Chief Complaint": "sickle cell crisis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sickle cell crisis ": "D57.00",
        "Avascular necrosis of bone ": "M87.9",
        "Cholecystitis ": "K81.9",
        "Renal papillary necrosis ": "N28.9",
        "Gout ": "M10.9",
      },
    },
    861: {
      "Chief Complaint": "svt",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal supraventricular tachycardia ": "P29.11",
        "Wolff-Parkinson-White syndrome ": "I45.6",
        "Congenital heart disease ": "Q20-Q28",
        "Anemia of prematurity ": "P61.2",
        "Neonatal sepsis ": "P36.9",
      },
    },
    862: {
      "Chief Complaint": "svt",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Supraventricular tachycardia ": "I47.1",
        "Gastroesophageal reflux disease ": "K21.9",
        "Pneumonia ": "J18.9",
        "Respiratory distress syndrome ": "P22.1",
        "Heart failure ": "I50.9",
      },
    },
    863: {
      "Chief Complaint": "svt",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Supraventricular tachycardia ": "I47.1",
        "Gastroesophageal reflux disease ": "K21.9",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "Anxiety ": "F41.9",
      },
    },
    864: {
      "Chief Complaint": "svt",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Supraventricular tachycardia ": "I47.1",
        "Atrial fibrillation ": "I48.9",
        "Anxiety ": "F41.9",
        "Heart failure ": "I50.9",
        "Hyperthyroidism ": "E05.90",
      },
    },
    865: {
      "Chief Complaint": "svt",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Supraventricular tachycardia ": "I47.1",
        "Atrial fibrillation ": "I48.9",
        "Heart failure ": "I50.9",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
      },
    },
    866: {
      "Chief Complaint": "tooth knocked out",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Dental trauma due to birth ": "K02.71",
        "Congenital absence of teeth ": "K00.0",
        "Congenital malformations of the teeth ": "K00-K00.9",
        "Tooth impaction ": "K01.1",
        "Neonatal oral infection ": "P37.9",
      },
    },
    867: {
      "Chief Complaint": "tooth knocked out",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Dental trauma ": "S00-S09",
        "Tooth impaction ": "K01.1",
        "Dental caries ": "K02.9",
        "Tooth fracture ": "S02.5",
        "Facial fracture ": "S02.0",
      },
    },
    868: {
      "Chief Complaint": "tooth knocked out",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dental trauma ": "S00-S09",
        "Tooth impaction ": "K01.1",
        "Dental caries ": "K02.9",
        "Tooth fracture ": "S02.5",
        "Facial fracture ": "S02.0",
      },
    },
    869: {
      "Chief Complaint": "tooth knocked out",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Dental trauma ": "S00-S09",
        "Tooth impaction ": "K01.1",
        "Dental caries ": "K02.9",
        "Tooth fracture ": "S02.5",
        "Facial fracture ": "S02.0",
      },
    },
    870: {
      "Chief Complaint": "tooth knocked out",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dental trauma ": "S00-S09",
        "Tooth impaction ": "K01.1",
        "Dental caries ": "K02.9",
        "Tooth fracture ": "S02.5",
        "Facial fracture ": "S02.0",
      },
    },
    871: {
      "Chief Complaint": "altitude sickness",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal respiratory distress syndrome ": "P22.9",
        "Neonatal hypoxia ": "P84.5",
        "Neonatal pneumonia ": "P23.9",
        "Neonatal sepsis ": "P36.9",
        "Neonatal cyanosis ": "P29.12",
      },
    },
    872: {
      "Chief Complaint": "altitude sickness",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Respiratory distress syndrome ": "P22.9",
        "Bronchiolitis ": "J21.9",
        "Pneumonia ": "J18.9",
        "Congenital heart disease ": "Q20-Q28",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    873: {
      "Chief Complaint": "altitude sickness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Acute mountain sickness ": "T70.0",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Upper respiratory infection ": "J06.9",
        "High-altitude pulmonary edema ": "T70.1",
      },
    },
    874: {
      "Chief Complaint": "altitude sickness",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Acute mountain sickness ": "T70.0",
        "High-altitude pulmonary edema ": "T70.1",
        "High-altitude cerebral edema ": "T70.2",
        "Pneumonia ": "J18.9",
        "Cardiac ischemia ": "I24.9",
      },
    },
    875: {
      "Chief Complaint": "altitude sickness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "High-altitude pulmonary edema ": "T70.1",
        "Acute mountain sickness ": "T70.0",
        "High-altitude cerebral edema ": "T70.2",
        "Cardiac ischemia ": "I24.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
      },
    },
    876: {
      "Chief Complaint": "assault",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal injury due to birth trauma ": "P10-P15",
        "Neonatal fractures ": "P13",
        "Neonatal intracranial hemorrhage ": "P52",
        "Neonatal contusions and abrasions ": "P14",
        "Neonatal lacerations ": "P15",
      },
    },
    877: {
      "Chief Complaint": "assault",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Child abuse ": "T74.12",
        "Fractures ": "S00-T14",
        "Contusions and abrasions ": "S00-T14",
        "Head injury ": "S06",
        "Facial injuries ": "S00-S09",
      },
    },
    878: {
      "Chief Complaint": "assault",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Child abuse ": "T74.12",
        "Fractures ": "S00-T14",
        "Contusions and abrasions ": "S00-T14",
        "Head injury ": "S06",
        "Facial injuries ": "S00-S09",
      },
    },
    879: {
      "Chief Complaint": "assault",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Assault ": "Y04-Y09",
        "Fractures ": "S00-T14",
        "Contusions and abrasions ": "S00-T14",
        "Head injury ": "S06",
        "Facial injuries ": "S00-S09",
      },
    },
    880: {
      "Chief Complaint": "assault",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Assault ": "Y04-Y09",
        "Fractures ": "S00-T14",
        "Contusions and abrasions ": "S00-T14",
        "Head injury ": "S06",
        "Facial injuries ": "S00-S09",
      },
    },
    881: {
      "Chief Complaint": "fistula bleeding",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal gastrointestinal bleeding ": "P54.0",
        "Meconium ileus ": "E84.11",
        "Necrotizing enterocolitis ": "P77",
        "Congenital anomalies of the gastrointestinal tract ": "Q38-Q45",
        "Neonatal coagulation defects ": "P61.9",
      },
    },
    882: {
      "Chief Complaint": "fistula bleeding",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Gastrointestinal bleeding ": "K92.9",
        "Anal fissure ": "K60.3",
        "Hemorrhoids ": "K64.9",
        "Inflammatory bowel disease ": "K50.9",
        "Infectious enteritis ": "A09",
      },
    },
    883: {
      "Chief Complaint": "fistula bleeding",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gastrointestinal bleeding ": "K92.9",
        "Inflammatory bowel disease ": "K50.9",
        "Anal fissure ": "K60.3",
        "Hemorrhoids ": "K64.9",
        "Colorectal polyps ": "K63.5",
      },
    },
    884: {
      "Chief Complaint": "fistula bleeding",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Gastrointestinal bleeding ": "K92.9",
        "Hemorrhoids ": "K64.9",
        "Anal fissure ": "K60.3",
        "Diverticulosis of colon ": "K57.50",
        "Colorectal cancer ": "C18.9",
      },
    },
    885: {
      "Chief Complaint": "fistula bleeding",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gastrointestinal bleeding ": "K92.9",
        "Hemorrhoids ": "K64.9",
        "Diverticulosis of colon ": "K57.50",
        "Colorectal cancer ": "C18.9",
        "Ischemic colitis ": "K55.0",
      },
    },
    886: {
      "Chief Complaint": "fistula problem",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal gastrointestinal fistula ": "P78.89",
        "Neonatal urinary tract fistula ": "P83.09",
        "Congenital anomalies of the gastrointestinal tract ": "Q38-Q45",
        "Neonatal coagulation defects ": "P61.9",
        "Neonatal necrotizing enterocolitis ": "P77.9",
      },
    },
    887: {
      "Chief Complaint": "fistula problem",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Gastrointestinal fistula ": "K63.89",
        "Urinary tract fistula ": "N82.9",
        "Anal fistula ": "K60.3",
        "Congenital anomalies of the gastrointestinal tract ": "Q38-Q45",
        "Crohn's disease ": "K50.9",
      },
    },
    888: {
      "Chief Complaint": "fistula problem",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gastrointestinal fistula ": "K63.89",
        "Urinary tract fistula ": "N82.9",
        "Anal fistula ": "K60.3",
        "Crohn's disease ": "K50.9",
        "Colorectal polyps ": "K63.5",
      },
    },
    889: {
      "Chief Complaint": "fistula problem",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Gastrointestinal fistula ": "K63.89",
        "Urinary tract fistula ": "N82.9",
        "Anal fistula ": "K60.3",
        "Crohn's disease ": "K50.9",
        "Diverticulitis ": "K57.30",
      },
    },
    890: {
      "Chief Complaint": "fistula problem",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gastrointestinal fistula ": "K63.89",
        "Urinary tract fistula ": "N82.9",
        "Anal fistula ": "K60.3",
        "Diverticulitis ": "K57.30",
        "Colorectal cancer ": "C18.9",
      },
    },
    891: {
      "Chief Complaint": "Sepsis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Neonatal sepsis ": "P36.9",
        "Meningitis ": "G03.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Congenital infections ": "P35.9",
        "Omphalitis ": "L08.0",
        "Necrotizing enterocolitis ": "P77",
        "Respiratory distress syndrome ": "P22.0",
        "Metabolic disorders ": "E70-E90",
      },
    },
    892: {
      "Chief Complaint": "Sepsis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Urinary tract infection ": "N39.0",
        "Meningitis ": "G03.9",
        "Pneumonia ": "J18.9",
        "Gastroenteritis ": "K52.9",
        "Osteomyelitis ": "M86.9",
        "Bacteremia ": "R78.81",
        "Viral infections ": "B34.9",
        "Congenital heart disease ": "Q24.9",
        "Metabolic disorders ": "E70-E90",
      },
    },
    893: {
      "Chief Complaint": "Sepsis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Meningitis ": "G03.9",
        "Appendicitis ": "K35.80",
        "Osteomyelitis ": "M86.9",
        "Gastroenteritis ": "K52.9",
        "Bacteremia ": "R78.81",
        "Cellulitis ": "L03.90",
        "Viral infections ": "B34.9",
      },
    },
    894: {
      "Chief Complaint": "Sepsis",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Sepsis ": "A41.9",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Bacteremia ": "R78.81",
        "Cellulitis ": "L03.90",
        "Diverticulitis ": "K57.90",
        "Cholecystitis ": "K81.9",
        "Pancreatitis ": "K85.9",
        "Endocarditis ": "I33.0",
        "Diabetic foot infection ": "E11.621",
      },
    },
    895: {
      "Chief Complaint": "Sepsis",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Sepsis ": "A41.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Pelvic inflammatory disease ": "N73.9",
        "Bacteremia ": "R78.81",
        "Cellulitis ": "L03.90",
        "Postpartum infections ": "O86.4",
        "Cholecystitis ": "K81.9",
        "Diverticulitis ": "K57.90",
        "Tubo-ovarian abscess ": "N70.93",
      },
    },
    896: {
      "Chief Complaint": "Sepsis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Bacteremia ": "R78.81",
        "Cellulitis ": "L03.90",
        "Diverticulitis ": "K57.90",
        "Cholecystitis ": "K81.9",
        "Decubitus ulcers ": "L89.9",
        "Chronic kidney disease ": "N18.9",
        "Aspiration pneumonia ": "J69.0",
      },
    },
    897: {
      "Chief Complaint": "dog bite",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal injury due to animal bite ": "W53.0",
        "Neonatal facial laceration ": "S01.22",
        "Neonatal infection due to animal bite ": "T37-T37.9",
        "Neonatal tetanus ": "A33",
        "Neonatal cellulitis ": "P39.0",
      },
    },
    898: {
      "Chief Complaint": "dog bite",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Dog bite ": "W54.0",
        "Infection due to dog bite ": "T38.0",
        "Laceration due to dog bite ": "S01.22",
        "Rabies ": "A82",
        "Cellulitis due to dog bite ": "L02.0",
      },
    },
    899: {
      "Chief Complaint": "dog bite",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dog bite ": "W54.0",
        "Infection due to dog bite ": "T38.0",
        "Laceration due to dog bite ": "S01.22",
        "Rabies ": "A82",
        "Cellulitis due to dog bite ": "L02.0",
      },
    },
    900: {
      "Chief Complaint": "dog bite",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Dog bite ": "W54.0",
        "Infection due to dog bite ": "T38.0",
        "Laceration due to dog bite ": "S01.22",
        "Rabies ": "A82",
        "Cellulitis due to dog bite ": "L02.0",
      },
    },
    901: {
      "Chief Complaint": "dog bite",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dog bite ": "W54.0",
        "Infection due to dog bite ": "T38.0",
        "Laceration due to dog bite ": "S01.22",
        "Rabies ": "A82",
        "Cellulitis due to dog bite ": "L02.0",
      },
    },
    902: {
      "Chief Complaint": "cat bite",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal injury due to animal bite ": "W53.0",
        "Neonatal facial laceration ": "S01.22",
        "Neonatal infection due to animal bite ": "T37-T37.9",
        "Neonatal cellulitis ": "P39.0",
        "Neonatal abscess due to animal bite ": "L02.0",
      },
    },
    903: {
      "Chief Complaint": "cat bite",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Cat bite ": "W54.1",
        "Infection due to cat bite ": "T38.1",
        "Laceration due to cat bite ": "S01.22",
        "Cellulitis due to cat bite ": "L02.1",
        "Cat-scratch disease ": "A28.1",
      },
    },
    904: {
      "Chief Complaint": "cat bite",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cat bite ": "W54.1",
        "Infection due to cat bite ": "T38.1",
        "Laceration due to cat bite ": "S01.22",
        "Cellulitis due to cat bite ": "L02.1",
        "Cat-scratch disease ": "A28.1",
      },
    },
    905: {
      "Chief Complaint": "cat bite",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cat bite ": "W54.1",
        "Infection due to cat bite ": "T38.1",
        "Laceration due to cat bite ": "S01.22",
        "Cellulitis due to cat bite ": "L02.1",
        "Cat-scratch disease ": "A28.1",
      },
    },
    906: {
      "Chief Complaint": "cat bite",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cat bite ": "W54.1",
        "Infection due to cat bite ": "T38.1",
        "Laceration due to cat bite ": "S01.22",
        "Cellulitis due to cat bite ": "L02.1",
        "Cat-scratch disease ": "A28.1",
      },
    },
    907: {
      "Chief Complaint": "aspiration",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal aspiration syndrome ": "P24.01",
        "Meconium aspiration syndrome ": "P24.00",
        "Neonatal pneumonia due to aspiration ": "P24.02",
        "Neonatal respiratory distress syndrome ": "P22.0",
        "Neonatal respiratory failure ": "P28.5",
      },
    },
    908: {
      "Chief Complaint": "aspiration",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Aspiration pneumonia ": "J69.0",
        "Foreign body aspiration ": "T17",
        "Gastroesophageal reflux disease ": "K21.9",
        "Pneumonia ": "J18.9",
        "Bronchiolitis ": "J21.9",
      },
    },
    909: {
      "Chief Complaint": "aspiration",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Aspiration pneumonia ": "J69.0",
        "Foreign body aspiration ": "T17",
        "Gastroesophageal reflux disease ": "K21.9",
        "Pneumonia ": "J18.9",
        "Bronchiolitis ": "J21.9",
      },
    },
    910: {
      "Chief Complaint": "aspiration",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Aspiration pneumonia ": "J69.0",
        "Gastroesophageal reflux disease ": "K21.9",
        "Pneumonia ": "J18.9",
        "Chronic obstructive pulmonary disease ": "COPD",
        "Acute respiratory distress syndrome ": "ARDS",
      },
    },
    911: {
      "Chief Complaint": "aspiration",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Aspiration pneumonia ": "J69.0",
        "Gastroesophageal reflux disease ": "K21.9",
        "Pneumonia ": "J18.9",
        "Chronic obstructive pulmonary disease ": "COPD",
        "Acute respiratory distress syndrome ": "ARDS",
      },
    },
    912: {
      "Chief Complaint": "pink eye",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal conjunctivitis ": "P39.0",
        "Neonatal eye infection ": "P39.1",
        "Neonatal chlamydial conjunctivitis ": "P39.2",
        "Neonatal gonococcal conjunctivitis ": "A54.31",
        "Neonatal herpetic conjunctivitis ": "B00.51",
      },
    },
    913: {
      "Chief Complaint": "pink eye",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Viral conjunctivitis ": "H10.9",
        "Bacterial conjunctivitis ": "H10.3",
        "Allergic conjunctivitis ": "H10.4",
        "Chemical conjunctivitis ": "T26.0",
        "Chlamydial conjunctivitis ": "A74.0",
      },
    },
    914: {
      "Chief Complaint": "pink eye",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral conjunctivitis ": "H10.9",
        "Bacterial conjunctivitis ": "H10.3",
        "Allergic conjunctivitis ": "H10.4",
        "Chemical conjunctivitis ": "T26.0",
        "Foreign body in the eye ": "T15",
      },
    },
    915: {
      "Chief Complaint": "pink eye",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Viral conjunctivitis ": "H10.9",
        "Bacterial conjunctivitis ": "H10.3",
        "Allergic conjunctivitis ": "H10.4",
        "Chemical conjunctivitis ": "T26.0",
        "Dry eye syndrome ": "H04.12",
      },
    },
    916: {
      "Chief Complaint": "pink eye",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dry eye syndrome ": "H04.12",
        "Allergic conjunctivitis ": "H10.4",
        "Bacterial conjunctivitis ": "H10.3",
        "Viral conjunctivitis ": "H10.9",
        "Chemical conjunctivitis ": "T26.0",
      },
    },
    917: {
      "Chief Complaint": "manic",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Attention-deficit/hyperactivity disorder ": "ADHD",
        "Pediatric bipolar disorder ": "F31.81",
        "Substance use disorder ": "F10-F19",
        "Conduct disorder ": "F91.9",
        "Oppositional defiant disorder ": "F91.3",
      },
    },
    918: {
      "Chief Complaint": "manic",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Bipolar disorder ": "F31.9",
        "Schizophrenia ": "F20.9",
        "Substance use disorder ": "F10-F19",
        "Attention-deficit/hyperactivity disorder ": "ADHD",
        "Delirium ": "F05",
      },
    },
    919: {
      "Chief Complaint": "manic",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Bipolar disorder ": "F31.9",
        "Dementia ": "F03.90",
        "Delirium ": "F05",
        "Substance use disorder ": "F10-F19",
        "Major depressive disorder ": "F32.9",
      },
    },
    920: {
      "Chief Complaint": "high blood pressure",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal hypertension ": "P29.2",
        "Neonatal renal disease ": "P29.0",
        "Neonatal heart disease ": "P29.1",
        "Neonatal endocrine disorder ": "P70.2",
        "Neonatal vascular disorder ": "P29.8",
      },
    },
    921: {
      "Chief Complaint": "high blood pressure",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Hypertension secondary to renal disease ": "I15.0",
        "Hypertensive heart disease ": "I11.9",
        "Primary hypertension ": "I10",
        "Hypertensive crisis ": "I16",
        "Renovascular hypertension ": "I15.2",
      },
    },
    922: {
      "Chief Complaint": "high blood pressure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Primary hypertension ": "I10",
        "Hypertensive heart disease ": "I11.9",
        "Hypertension secondary to renal disease ": "I15.0",
        "Hypertensive crisis ": "I16",
        "Secondary hypertension ": "I15",
      },
    },
    923: {
      "Chief Complaint": "high blood pressure",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Primary hypertension ": "I10",
        "Hypertensive heart disease ": "I11.9",
        "Hypertensive crisis ": "I16",
        "Secondary hypertension ": "I15",
        "Renovascular hypertension ": "I15.2",
      },
    },
    924: {
      "Chief Complaint": "high blood pressure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Primary hypertension ": "I10",
        "Hypertensive heart disease ": "I11.9",
        "Hypertensive crisis ": "I16",
        "Secondary hypertension ": "I15",
        "Renovascular hypertension ": "I15.2",
      },
    },
    925: {
      "Chief Complaint": "rectal pain",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal anal fissure ": "K60.3",
        "Neonatal hemorrhoids ": "K64.0",
        "Neonatal perianal abscess ": "K61.0",
        "Neonatal rectal prolapse ": "K62.3",
        "Neonatal constipation ": "K59.00",
      },
    },
    926: {
      "Chief Complaint": "rectal pain",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.3",
        "Hemorrhoids ": "K64.0",
        "Perianal abscess ": "K61.0",
        "Rectal prolapse ": "K62.3",
        "Constipation ": "K59.00",
      },
    },
    927: {
      "Chief Complaint": "rectal pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.3",
        "Hemorrhoids ": "K64.0",
        "Perianal abscess ": "K61.0",
        "Constipation ": "K59.00",
        "Crohn's disease of large intestine ": "K50.10",
      },
    },
    928: {
      "Chief Complaint": "rectal pain",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hemorrhoids ": "K64.9",
        "Anal fissure ": "K60.3",
        "Perianal abscess ": "K61.0",
        "Proctitis ": "K51.20",
        "Colon cancer ": "C18.9",
      },
    },
    929: {
      "Chief Complaint": "rectal pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hemorrhoids ": "K64.9",
        "Anal fissure ": "K60.3",
        "Perianal abscess ": "K61.0",
        "Proctitis ": "K51.20",
        "Colon cancer ": "C18.9",
      },
    },
    930: {
      "Chief Complaint": "rectal bleeding",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Meconium ileus ": "K56.1",
        "Neonatal intestinal hemorrhage ": "P54.1",
        "Anal fissure in newborn ": "P92.0",
        "Necrotizing enterocolitis ": "P77",
        "Intussusception in newborn ": "P83.1",
      },
    },
    931: {
      "Chief Complaint": "rectal bleeding",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.3",
        "Infectious colitis ": "A09",
        "Gastrointestinal bleeding ": "K92.2",
        "Hirschsprung's disease ": "Q43.1",
        "Meckel's diverticulum ": "Q43.0",
      },
    },
    932: {
      "Chief Complaint": "rectal bleeding",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anal fissure ": "K60.3",
        "Infectious colitis ": "A09",
        "Gastrointestinal bleeding ": "K92.2",
        "Juvenile polyps ": "D12.6",
        "Crohn's disease ": "K50.9",
      },
    },
    933: {
      "Chief Complaint": "rectal bleeding",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hemorrhoids ": "K64.9",
        "Anal fissure ": "K60.3",
        "Diverticulosis of colon ": "K57.20",
        "Colorectal cancer ": "C18.9",
        "Gastrointestinal bleeding ": "K92.2",
      },
    },
    934: {
      "Chief Complaint": "rectal bleeding",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hemorrhoids ": "K64.9",
        "Colorectal cancer ": "C18.9",
        "Diverticulosis of colon ": "K57.20",
        "Gastrointestinal bleeding ": "K92.2",
        "Anal fissure ": "K60.3",
      },
    },
    935: {
      "Chief Complaint": "tongue swelling",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal tongue tie ": "Q38.0",
        "Neonatal macroglossia ": "P89.8",
        "Neonatal angioedema ": "T78.3",
        "Neonatal infection ": "P39.9",
        "Neonatal allergic reaction ": "T78.40",
      },
    },
    936: {
      "Chief Complaint": "tongue swelling",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Allergic reaction ": "T78.40",
        "Angioedema ": "T78.3",
        "Tongue tie ": "Q38.0",
        "Infection ": "K14.0",
        "Trauma ": "S01.5",
      },
    },
    937: {
      "Chief Complaint": "tongue swelling",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Allergic reaction ": "T78.40",
        "Angioedema ": "T78.3",
        "Tongue tie ": "Q38.0",
        "Infection ": "K14.0",
        "Trauma ": "S01.5",
      },
    },
    938: {
      "Chief Complaint": "tongue swelling",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Allergic reaction ": "T78.40",
        "Angioedema ": "T78.3",
        "Infection ": "K14.0",
        "Trauma ": "S01.5",
        "Glossitis ": "K14.8",
      },
    },
    939: {
      "Chief Complaint": "tongue swelling",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Angioedema ": "T78.3",
        "Allergic reaction ": "T78.40",
        "Infection ": "K14.0",
        "Trauma ": "S01.5",
        "Glossitis ": "K14.8",
      },
    },
    940: {
      "Chief Complaint": "priapism",
      Age: "Newborn",
      Gender: "Male",
      DDx: {
        "Neonatal priapism ": "N48.30",
        "Neonatal penile injury ": "S30.891A",
        "Neonatal hematologic disorder ": "D69.9",
        "Neonatal sickle cell disease ": "D57.00",
        "Neonatal leukemia ": "C91.10",
      },
    },
    941: {
      "Chief Complaint": "priapism",
      Age: "Infant",
      Gender: "Male",
      DDx: {
        "Idiopathic priapism ": "N48.30",
        "Sickle cell disease ": "D57.00",
        "Medication-induced priapism ": "N48.30",
        "Leukemia ": "C91.10",
        "Hematologic disorders ": "D69.9",
      },
    },
    942: {
      "Chief Complaint": "priapism",
      Age: "Pediatric",
      Gender: "Male",
      DDx: {
        "Idiopathic priapism ": "N48.30",
        "Sickle cell disease ": "D57.00",
        "Medication-induced priapism ": "N48.30",
        "Leukemia ": "C91.10",
        "Hematologic disorders ": "D69.9",
      },
    },
    943: {
      "Chief Complaint": "priapism",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Idiopathic priapism ": "N48.30",
        "Medication-induced priapism ": "N48.30",
        "Sickle cell disease ": "D57.00",
        "Leukemia ": "C91.10",
        "Hematologic disorders ": "D69.9",
      },
    },
    944: {
      "Chief Complaint": "priapism",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Idiopathic priapism ": "N48.30",
        "Medication-induced priapism ": "N48.30",
        "Sickle cell disease ": "D57.00",
        "Leukemia ": "C91.10",
        "Hematologic disorders ": "D69.9",
      },
    },
    945: {
      "Chief Complaint": "testicle swelling",
      Age: "Newborn",
      Gender: "Male",
      DDx: {
        "Neonatal hydrocele ": "P83.5",
        "Neonatal testicular torsion ": "P83.6",
        "Neonatal epididymitis ": "P39.2",
        "Neonatal inguinal hernia ": "P83.0",
        "Neonatal orchitis ": "P39.1",
      },
    },
    946: {
      "Chief Complaint": "testicle swelling",
      Age: "Infant",
      Gender: "Male",
      DDx: {
        "Hydrocele ": "N43.4",
        "Epididymitis ": "N45.1",
        "Inguinal hernia ": "K40.90",
        "Testicular torsion ": "N44.0",
        "Orchitis ": "N45.0",
      },
    },
    947: {
      "Chief Complaint": "testicle swelling",
      Age: "Pediatric",
      Gender: "Male",
      DDx: {
        "Epididymitis ": "N45.1",
        "Orchitis ": "N45.0",
        "Hydrocele ": "N43.4",
        "Testicular torsion ": "N44.0",
        "Inguinal hernia ": "K40.90",
      },
    },
    948: {
      "Chief Complaint": "testicle swelling",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Epididymitis ": "N45.1",
        "Orchitis ": "N45.0",
        "Hydrocele ": "N43.4",
        "Testicular torsion ": "N44.0",
        "Inguinal hernia ": "K40.90",
      },
    },
    949: {
      "Chief Complaint": "testicle swelling",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Epididymitis ": "N45.1",
        "Orchitis ": "N45.0",
        "Hydrocele ": "N43.4",
        "Testicular torsion ": "N44.0",
        "Inguinal hernia ": "K40.90",
      },
    },
    950: {
      "Chief Complaint": "alcohol withdrawal",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal withdrawal syndrome ": "P96.1",
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal seizures ": "P90",
        "Neonatal infection ": "P39.9",
        "Neonatal metabolic acidosis ": "P74.1",
      },
    },
    951: {
      "Chief Complaint": "alcohol withdrawal",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Neonatal withdrawal syndrome ": "P96.1",
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal seizures ": "P90",
        "Neonatal infection ": "P39.9",
        "Neonatal metabolic acidosis ": "P74.1",
      },
    },
    952: {
      "Chief Complaint": "alcohol withdrawal",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric alcohol use disorder ": "F10.1",
        "Pediatric benzodiazepine withdrawal ": "F13.94",
        "Pediatric opioid withdrawal ": "F11.23",
        "Pediatric delirium tremens ": "F10.232",
        "Pediatric substance-induced mood disorder ": "F10.94",
      },
    },
    953: {
      "Chief Complaint": "alcohol withdrawal",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Alcohol use disorder ": "F10.2",
        "Benzodiazepine withdrawal ": "F13.94",
        "Opioid withdrawal ": "F11.23",
        "Delirium tremens ": "F10.232",
        "Substance-induced mood disorder ": "F10.94",
      },
    },
    954: {
      "Chief Complaint": "alcohol withdrawal",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Alcohol use disorder ": "F10.2",
        "Benzodiazepine withdrawal ": "F13.94",
        "Opioid withdrawal ": "F11.23",
        "Delirium tremens ": "F10.232",
        "Substance-induced mood disorder ": "F10.94",
      },
    },
    955: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal intoxication syndrome ": "P04.3",
        "Neonatal hypoglycemia ": "P70.4",
        "Neonatal seizures ": "P90",
        "Neonatal infection ": "P39.9",
        "Neonatal metabolic acidosis ": "P74.1",
      },
    },
    956: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Pediatric alcohol intoxication ": "F10.121",
        "Medication overdose ": "T50.905A",
        "Pediatric substance intoxication ": "F19.10",
        "Pediatric poisoning by alcohol ": "T51.0X1A",
        "Pediatric delirium tremens ": "F10.232",
      },
    },
    957: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric alcohol intoxication ": "F10.121",
        "Medication overdose ": "T50.905A",
        "Pediatric substance intoxication ": "F19.10",
        "Pediatric poisoning by alcohol ": "T51.0X1A",
        "Pediatric delirium tremens ": "F10.232",
      },
    },
    958: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Alcohol intoxication ": "F10.929",
        "Substance intoxication ": "F19.20",
        "Drug overdose ": "T50.9X1A",
        "Delirium tremens ": "F10.232",
        "Psychotic disorder due to substance intoxication ": "F10.129",
      },
    },
    959: {
      "Chief Complaint": "alcohol intoxication",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Alcohol intoxication ": "F10.929",
        "Substance intoxication ": "F19.20",
        "Drug overdose ": "T50.9X1A",
        "Delirium tremens ": "F10.232",
        "Psychotic disorder due to substance intoxication ": "F10.129",
      },
    },
    960: {
      "Chief Complaint": "pancreatitis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal pancreatitis ": "P78.1",
        "Neonatal cystic fibrosis ": "E84.9",
        "Neonatal sepsis ": "P36.9",
        "Neonatal metabolic disorder ": "E88.9",
        "Neonatal cholelithiasis ": "P77.9",
      },
    },
    961: {
      "Chief Complaint": "pancreatitis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Acute viral gastroenteritis ": "A08",
        "Intestinal malrotation ": "Q41.1",
        "Intussusception ": "K56.1",
        "Gastroesophageal reflux disease ": "K21.9",
        "Appendicitis ": "K35.9",
      },
    },
    962: {
      "Chief Complaint": "pancreatitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Gallstones ": "K80.1",
        "Idiopathic pancreatitis ": "K86.1",
        "Alcohol-induced pancreatitis ": "K86.0",
        "Medication-induced pancreatitis ": "K86.0",
        "Cystic fibrosis-related pancreatitis ": "E84.11",
      },
    },
    963: {
      "Chief Complaint": "pancreatitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Gallstones ": "K80.1",
        "Alcohol-induced pancreatitis ": "K86.0",
        "Idiopathic pancreatitis ": "K86.1",
        "Medication-induced pancreatitis ": "K86.0",
        "Hypertriglyceridemia-induced pancreatitis ": "E78.1",
      },
    },
    964: {
      "Chief Complaint": "pancreatitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gallstones ": "K80.1",
        "Alcohol-induced pancreatitis ": "K86.0",
        "Idiopathic pancreatitis ": "K86.1",
        "Medication-induced pancreatitis ": "K86.0",
        "Hypertriglyceridemia-induced pancreatitis ": "E78.1",
      },
    },
    965: {
      "Chief Complaint": "drug reaction",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal drug withdrawal syndrome ": "P96.1",
        "Neonatal drug hypersensitivity ": "T78.2",
        "Neonatal drug overdose ": "T50.9X1A",
        "Neonatal drug-induced rash ": "L27.0",
        "Neonatal medication side effects ": "T88.7",
      },
    },
    966: {
      "Chief Complaint": "drug reaction",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Medication allergy ": "T78.2",
        "Medication side effects ": "T88.7",
        "Skin rash due to drug ": "L27.0",
        "Medication overdose ": "T50.9X1A",
        "Pediatric anaphylaxis ": "T78.0",
      },
    },
    967: {
      "Chief Complaint": "drug reaction",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Medication allergy ": "T78.2",
        "Medication side effects ": "T88.7",
        "Skin rash due to drug ": "L27.0",
        "Medication overdose ": "T50.9X1A",
        "Pediatric anaphylaxis ": "T78.0",
      },
    },
    968: {
      "Chief Complaint": "drug reaction",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Medication allergy ": "T78.2",
        "Medication side effects ": "T88.7",
        "Skin rash due to drug ": "L27.0",
        "Medication overdose ": "T50.9X1A",
        "Anaphylaxis ": "T78.0",
      },
    },
    969: {
      "Chief Complaint": "drug reaction",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Medication allergy ": "T78.2",
        "Medication side effects ": "T88.7",
        "Skin rash due to drug ": "L27.0",
        "Medication overdose ": "T50.9X1A",
        "Anaphylaxis ": "T78.0",
      },
    },
    970: {
      "Chief Complaint": "concussion",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Birth-related head injury ": "P10.2",
        "Neonatal skull fracture ": "P13.0",
        "Neonatal intracranial hemorrhage ": "P52.4",
        "Neonatal seizure ": "P90",
        "Neonatal head trauma ": "P15.9",
      },
    },
    971: {
      "Chief Complaint": "concussion",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Infant head injury ": "S09.90XA",
        "Subdural hematoma ": "S06.5X0A",
        "Skull fracture ": "S02.90XA",
        "Intracranial hemorrhage ": "I62.9",
        "Pediatric concussion ": "S06.0X0A",
      },
    },
    972: {
      "Chief Complaint": "concussion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric concussion ": "S06.0X0A",
        "Mild traumatic brain injury ": "S06.0X0A",
        "Skull fracture ": "S02.90XA",
        "Subdural hematoma ": "S06.5X0A",
        "Intracranial hemorrhage ": "I62.9",
      },
    },
    973: {
      "Chief Complaint": "concussion",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adult concussion ": "S06.0X0A",
        "Mild traumatic brain injury ": "S06.0X0A",
        "Skull fracture ": "S02.90XA",
        "Subdural hematoma ": "S06.5X0A",
        "Post-concussion syndrome ": "F07.81",
      },
    },
    974: {
      "Chief Complaint": "concussion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elderly head injury ": "S09.90XA",
        "Subdural hematoma ": "S06.5X0A",
        "Skull fracture ": "S02.90XA",
        "Intracranial hemorrhage ": "I62.9",
        "Cerebral contusion ": "S06.3X0A",
      },
    },
    975: {
      "Chief Complaint": "sudden vision loss",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal retinal hemorrhage ": "P59.0",
        "Neonatal retinopathy of prematurity ": "H35.1",
        "Neonatal congenital cataract ": "Q12.0",
        "Neonatal glaucoma ": "Q15.0",
        "Neonatal optic nerve hypoplasia ": "Q04.0",
      },
    },
    976: {
      "Chief Complaint": "sudden vision loss",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Retinopathy of prematurity ": "H35.1",
        "Congenital cataract ": "Q12.0",
        "Glaucoma ": "H40.9",
        "Amblyopia ": "H53.001",
        "Pediatric retinal detachment ": "H33.0",
      },
    },
    977: {
      "Chief Complaint": "sudden vision loss",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Amblyopia ": "H53.001",
        "Retinal detachment ": "H33.0",
        "Pediatric glaucoma ": "H40.9",
        "Pediatric cataract ": "H26.0",
        "Retinoblastoma ": "C69.2",
      },
    },
    978: {
      "Chief Complaint": "sudden vision loss",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Retinal artery occlusion ": "H34.2",
        "Retinal vein occlusion ": "H34.1",
        "Ischemic optic neuropathy ": "H47.01",
        "Diabetic retinopathy ": "H36.0",
        "Macular degeneration ": "H35.30",
      },
    },
    979: {
      "Chief Complaint": "sudden vision loss",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Age-related macular degeneration ": "H35.31",
        "Glaucoma ": "H40.9",
        "Diabetic retinopathy ": "E11.359",
        "Giant cell arteritis ": "M31.6",
        "Cataract ": "H25.9",
      },
    },
    980: {
      "Chief Complaint": "meningitis",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal bacterial meningitis ": "P37.0",
        "Neonatal viral meningitis ": "P37.1",
        "Neonatal aseptic meningitis ": "P89.2",
        "Neonatal sepsis ": "P36.9",
        "Neonatal encephalitis ": "P37.2",
      },
    },
    981: {
      "Chief Complaint": "meningitis",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Viral meningitis ": "G02",
        "Bacterial meningitis ": "G00",
        "Encephalitis ": "G04",
        "Tuberculous meningitis ": "A17.0",
        "Meningococcal infection ": "A39",
      },
    },
    982: {
      "Chief Complaint": "meningitis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral meningitis ": "G02",
        "Bacterial meningitis ": "G00",
        "Encephalitis ": "G04",
        "Tuberculous meningitis ": "A17.0",
        "Meningococcal infection ": "A39",
      },
    },
    983: {
      "Chief Complaint": "meningitis",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Viral meningitis ": "G02",
        "Bacterial meningitis ": "G00",
        "Encephalitis ": "G04",
        "Tuberculous meningitis ": "A17.0",
        "Meningococcal infection ": "A39",
      },
    },
    984: {
      "Chief Complaint": "meningitis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Viral meningitis ": "G02",
        "Bacterial meningitis ": "G00",
        "Encephalitis ": "G04",
        "Tuberculous meningitis ": "A17.0",
        "Meningococcal infection ": "A39",
      },
    },
    985: {
      "Chief Complaint": "boil",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal skin infection ": "P39.3",
        "Neonatal abscess ": "L02.92",
        "Neonatal cellulitis ": "L03.115",
        "Neonatal impetigo ": "L01.119",
        "Neonatal staphylococcal scalded skin syndrome ": "L00",
      },
    },
    986: {
      "Chief Complaint": "boil",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Cutaneous abscess ": "L02.92",
        "Cellulitis ": "L03.115",
        "Impetigo ": "L01.119",
        "Carbuncle ": "L02.93",
        "Folliculitis ": "L73.9",
      },
    },
    987: {
      "Chief Complaint": "boil",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Cutaneous abscess ": "L02.92",
        "Cellulitis ": "L03.115",
        "Impetigo ": "L01.119",
        "Carbuncle ": "L02.93",
        "Folliculitis ": "L73.9",
      },
    },
    988: {
      "Chief Complaint": "boil",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Cutaneous abscess ": "L02.92",
        "Cellulitis ": "L03.115",
        "Folliculitis ": "L73.9",
        "Hidradenitis suppurativa ": "L73.2",
        "Ecthyma ": "L08.0",
      },
    },
    989: {
      "Chief Complaint": "boil",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Cutaneous abscess ": "L02.92",
        "Cellulitis ": "L03.115",
        "Folliculitis ": "L73.9",
        "Hidradenitis suppurativa ": "L73.2",
        "Ecthyma ": "L08.0",
      },
    },
    990: {
      "Chief Complaint": "spider bite",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal skin irritation ": "P83.3",
        "Neonatal contact dermatitis ": "L25.9",
        "Neonatal insect bite ": "S30.879A",
        "Neonatal cellulitis ": "L03.115",
        "Neonatal allergic reaction ": "T78.4",
      },
    },
    991: {
      "Chief Complaint": "spider bite",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Infant skin irritation ": "L29.9",
        "Insect bite ": "S30.879A",
        "Contact dermatitis ": "L25.9",
        "Cellulitis ": "L03.115",
        "Allergic reaction ": "T78.4",
      },
    },
    992: {
      "Chief Complaint": "spider bite",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric insect bite ": "S30.879A",
        "Pediatric skin irritation ": "L29.9",
        "Contact dermatitis ": "L25.9",
        "Cellulitis ": "L03.115",
        "Allergic reaction ": "T78.4",
      },
    },
    993: {
      "Chief Complaint": "spider bite",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Skin irritation ": "L29.9",
        "Insect bite ": "S30.879A",
        "Cellulitis ": "L03.115",
        "Allergic reaction ": "T78.4",
        "Erythema migrans ": "A69.20",
      },
    },
    994: {
      "Chief Complaint": "spider bite",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Skin irritation ": "L29.9",
        "Insect bite ": "S30.879A",
        "Cellulitis ": "L03.115",
        "Allergic reaction ": "T78.4",
        "Erythema migrans ": "A69.20",
      },
    },
    995: {
      "Chief Complaint": "croup",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal laryngomalacia ": "Q31.4",
        "Neonatal tracheomalacia ": "Q31.3",
        "Neonatal subglottic stenosis ": "Q31.1",
        "Neonatal respiratory distress syndrome ": "P22.0",
        "Neonatal aspiration ": "P24.8",
      },
    },
    996: {
      "Chief Complaint": "croup",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Viral croup ": "J05.0",
        "Bacterial tracheitis ": "J05.1",
        "Foreign body aspiration ": "J68.0",
        "Epiglottitis ": "J05.1",
        "Bronchiolitis ": "J21.9",
      },
    },
    997: {
      "Chief Complaint": "croup",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral croup ": "J05.0",
        "Bacterial tracheitis ": "J05.1",
        "Foreign body aspiration ": "J68.0",
        "Epiglottitis ": "J05.1",
        "Bronchiolitis ": "J21.9",
      },
    },
    998: {
      "Chief Complaint": "croup",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Viral croup ": "J05.0",
        "Laryngeal cancer ": "C32.9",
        "Epiglottitis ": "J05.1",
        "Tracheal stenosis ": "J39.8",
        "Inhalation injury ": "T59.7",
      },
    },
    999: {
      "Chief Complaint": "croup",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Laryngeal cancer ": "C32.9",
        "Tracheal stenosis ": "J39.8",
        "Inhalation injury ": "T59.7",
        "Vocal cord paralysis ": "J38.0",
        "Gastroesophageal reflux disease ": "K21.9",
      },
    },
    1000: {
      "Chief Complaint": "heat stroke",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal hyperthermia ": "P81.81",
        "Neonatal dehydration ": "P74.1",
        "Neonatal sepsis ": "P36.9",
        "Neonatal convulsions ": "P90",
        "Neonatal metabolic acidosis ": "P74.9",
      },
    },
    1001: {
      "Chief Complaint": "heat stroke",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Pediatric heat stroke ": "T67.0",
        "Dehydration ": "E86.0",
        "Heat exhaustion ": "T67.0",
        "Heat cramps ": "T67.2",
        "Fever of unknown origin ": "R50.9",
      },
    },
    1002: {
      "Chief Complaint": "heat stroke",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric heat stroke ": "T67.0",
        "Dehydration ": "E86.0",
        "Heat exhaustion ": "T67.0",
        "Heat cramps ": "T67.2",
        "Fever of unknown origin ": "R50.9",
      },
    },
    1003: {
      "Chief Complaint": "heat stroke",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adult heat stroke ": "T67.0",
        "Dehydration ": "E86.0",
        "Heat exhaustion ": "T67.0",
        "Heat cramps ": "T67.2",
        "Malignant hyperthermia ": "T88.3",
      },
    },
    1004: {
      "Chief Complaint": "heat stroke",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elderly heat stroke ": "T67.0",
        "Dehydration ": "E86.0",
        "Heat exhaustion ": "T67.0",
        "Heat cramps ": "T67.2",
        "Fever of unknown origin ": "R50.9",
      },
    },
    1005: {
      "Chief Complaint": "heat exhaustion",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal hyperthermia ": "P81.81",
        "Neonatal dehydration ": "P74.1",
        "Neonatal sepsis ": "P36.9",
        "Neonatal convulsions ": "P90",
        "Neonatal metabolic acidosis ": "P74.9",
      },
    },
    1006: {
      "Chief Complaint": "heat exhaustion",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Pediatric heat exhaustion ": "T67.0",
        "Dehydration ": "E86.0",
        "Gastroenteritis ": "A09",
        "Viral illness ": "B34.9",
        "Heat cramps ": "T67.2",
      },
    },
    1007: {
      "Chief Complaint": "heat exhaustion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric heat exhaustion ": "T67.0",
        "Dehydration ": "E86.0",
        "Gastroenteritis ": "A09",
        "Viral illness ": "B34.9",
        "Heat cramps ": "T67.2",
      },
    },
    1008: {
      "Chief Complaint": "heat exhaustion",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adult heat exhaustion ": "T67.0",
        "Dehydration ": "E86.0",
        "Gastroenteritis ": "A09",
        "Viral illness ": "B34.9",
        "Heat cramps ": "T67.2",
      },
    },
    1009: {
      "Chief Complaint": "heat exhaustion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elderly heat exhaustion ": "T67.0",
        "Dehydration ": "E86.0",
        "Gastroenteritis ": "A09",
        "Viral illness ": "B34.9",
        "Heat cramps ": "T67.2",
      },
    },
    1010: {
      "Chief Complaint": "smoke exposure",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal respiratory distress syndrome ": "P22.0",
        "Neonatal pneumonia ": "P23.9",
        "Neonatal bronchitis ": "P23.0",
        "Neonatal aspiration syndrome ": "P24.9",
        "Neonatal meconium aspiration syndrome ": "P24.01",
      },
    },
    1011: {
      "Chief Complaint": "smoke exposure",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Pediatric smoke exposure ": "T59.0",
        "Pediatric respiratory distress syndrome ": "J80",
        "Pediatric bronchitis ": "J20.9",
        "Pediatric pneumonia ": "J18.9",
        "Pediatric asthma ": "J45.909",
      },
    },
    1012: {
      "Chief Complaint": "smoke exposure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric smoke exposure ": "T59.0",
        "Pediatric respiratory distress syndrome ": "J80",
        "Pediatric bronchitis ": "J20.9",
        "Pediatric pneumonia ": "J18.9",
        "Pediatric asthma ": "J45.909",
      },
    },
    1013: {
      "Chief Complaint": "smoke exposure",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adult smoke exposure ": "T59.0",
        "Acute bronchitis ": "J20.9",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Chronic obstructive pulmonary disease ": "J44.9",
      },
    },
    1014: {
      "Chief Complaint": "smoke exposure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elderly smoke exposure ": "T59.0",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Pneumonia ": "J18.9",
        "Lung cancer ": "C34.9",
        "Pulmonary fibrosis ": "J84.9",
      },
    },
    1015: {
      "Chief Complaint": "carbon monoxide poisoning",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal asphyxia ": "P21.9",
        "Neonatal respiratory distress syndrome ": "P22.0",
        "Neonatal apnea ": "P28.4",
        "Neonatal pneumonia ": "P23.9",
        "Neonatal sepsis ": "P36.9",
      },
    },
    1016: {
      "Chief Complaint": "carbon monoxide poisoning",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Pediatric carbon monoxide poisoning ": "T58",
        "Pediatric pneumonia ": "J18.9",
        "Pediatric asthma ": "J45.909",
        "Pediatric bronchitis ": "J20.9",
        "Pediatric viral illness ": "B34.9",
      },
    },
    1017: {
      "Chief Complaint": "carbon monoxide poisoning",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric carbon monoxide poisoning ": "T58",
        "Pediatric pneumonia ": "J18.9",
        "Pediatric asthma ": "J45.909",
        "Pediatric bronchitis ": "J20.9",
        "Pediatric viral illness ": "B34.9",
      },
    },
    1018: {
      "Chief Complaint": "carbon monoxide poisoning",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adult carbon monoxide poisoning ": "T58",
        "Acute respiratory distress syndrome ": "J80",
        "Pneumonia ": "J18.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Congestive heart failure ": "I50.9",
      },
    },
    1019: {
      "Chief Complaint": "carbon monoxide poisoning",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elderly carbon monoxide poisoning ": "T58",
        "Acute respiratory distress syndrome ": "J80",
        "Pneumonia ": "J18.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Congestive heart failure ": "I50.9",
      },
    },
    1020: {
      "Chief Complaint": "chemical exposure",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal chemical exposure ": "T59.9",
        "Neonatal aspiration syndrome ": "P24.9",
        "Neonatal respiratory distress syndrome ": "P22.0",
        "Neonatal pneumonia ": "P23.9",
        "Neonatal apnea ": "P28.4",
      },
    },
    1021: {
      "Chief Complaint": "chemical exposure",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Pediatric chemical exposure ": "T59.9",
        "Pediatric aspiration syndrome ": "P24.9",
        "Pediatric respiratory distress syndrome ": "J80",
        "Pediatric pneumonia ": "J18.9",
        "Pediatric asthma ": "J45.909",
      },
    },
    1022: {
      "Chief Complaint": "chemical exposure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Pediatric chemical exposure ": "T59.9",
        "Pediatric aspiration syndrome ": "P24.9",
        "Pediatric respiratory distress syndrome ": "J80",
        "Pediatric pneumonia ": "J18.9",
        "Pediatric asthma ": "J45.909",
      },
    },
    1023: {
      "Chief Complaint": "chemical exposure",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Adult chemical exposure ": "T59.9",
        "Adult respiratory distress syndrome ": "J80",
        "Pneumonia ": "J18.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Chemical burn ": "T20-T32",
      },
    },
    1024: {
      "Chief Complaint": "chemical exposure",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Elderly chemical exposure ": "T59.9",
        "Adult respiratory distress syndrome ": "J80",
        "Pneumonia ": "J18.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Chemical burn ": "T20-T32",
      },
    },
    1025: {
      "Chief Complaint": "chemical exposure",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Neonatal chemical exposure ": "T59.9",
        "Neonatal aspiration syndrome ": "P24.9",
      },
    },
    1026: {
      "Chief Complaint": "abdominal pain in pregnancy",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ectopic pregnancy ": "O00.9",
        "Preterm labor ": "O60.14",
        "Placental abruption ": "O45.9",
        "Round ligament pain ": "O99.89",
        "Urinary tract infection ": "O23.9",
      },
    },
    1027: {
      "Chief Complaint": "low temperature",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Hypothermia due to exposure ": "P80.0",
        "Sepsis ": "P36.9",
        "Hypoglycemia ": "P70.4",
        "Hypothyroidism ": "P72.1",
        "Cold injury syndrome ": "P80.8",
      },
    },
    1028: {
      "Chief Complaint": "low temperature",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Hypothermia ": "T68",
        "Infection ": "R50.9",
        "Hypoglycemia ": "E16.2",
        "Hypothyroidism ": "E03.9",
        "Malnutrition ": "E46",
      },
    },
    1029: {
      "Chief Complaint": "low temperature",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Hypothermia ": "T68",
        "Hypothyroidism ": "E03.9",
        "Sepsis ": "A41.9",
        "Hypoglycemia ": "E16.2",
        "Drug overdose ": "T50.906",
      },
    },
    1030: {
      "Chief Complaint": "low temperature",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Hypothermia ": "T68",
        "Hypothyroidism ": "E03.9",
        "Sepsis ": "A41.9",
        "Malnutrition ": "E46",
        "Drug overdose ": "T50.906",
      },
    },
    1031: {
      "Chief Complaint": "nasal congestion",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital nasal pyriform aperture stenosis ": "Q30.2",
        "Choanal atresia ": "Q30.0",
        "Nasal congestion of newborn ": "P22.9",
        "Viral upper respiratory infection ": "J06.9",
        "Allergic rhinitis ": "J30.9",
      },
    },
    1032: {
      "Chief Complaint": "nasal congestion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Common cold ": "J00",
        "Allergic rhinitis ": "J30.9",
        "Sinusitis ": "J32.9",
        "Adenoid hypertrophy ": "J35.2",
        "Foreign body in nose ": "T17.0",
      },
    },
    1033: {
      "Chief Complaint": "nasal congestion",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Allergic rhinitis ": "J30.9",
        "Sinusitis ": "J32.9",
        "Nasal polyps ": "J33.9",
        "Deviated nasal septum ": "J34.2",
        "Viral upper respiratory infection ": "J06.9",
      },
    },
    1034: {
      "Chief Complaint": "nasal congestion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Allergic rhinitis ": "J30.9",
        "Sinusitis ": "J32.9",
        "Nasal polyps ": "J33.9",
        "Atrophic rhinitis ": "J31.0",
        "Medication side effect ": "Y57.9",
      },
    },
    1035: {
      "Chief Complaint": "fast heart rate",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fever ": "R50.9",
        "Anxiety ": "F93.0",
        "Supraventricular tachycardia ": "I47.1",
        "Dehydration ": "E86.0",
        "Hyperthyroidism ": "E05.90",
      },
    },
    1036: {
      "Chief Complaint": "fast heart rate",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
        "Alcohol withdrawal ": "F10.239",
      },
    },
    1037: {
      "Chief Complaint": "fast heart rate",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Pregnancy-related tachycardia 5. Supraventricular tachycardia ":
          "I47.1",
      },
    },
    1038: {
      "Chief Complaint": "fast heart rate",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Heart failure ": "I50.9",
        "Ischemic heart disease ": "I25.9",
        "Hyperthyroidism ": "E05.90",
        "Pulmonary embolism ": "I26.99",
      },
    },
    1039: {
      "Chief Complaint": "tachycardia",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Fever ": "R50.9",
        "Anemia ": "D64.9",
        "Hyperthyroidism ": "E05.90",
        "Congenital heart disease ": "Q24.9",
      },
    },
    1040: {
      "Chief Complaint": "tachycardia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Fever ": "R50.9",
        "Anemia ": "D64.9",
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
      },
    },
    1041: {
      "Chief Complaint": "tachycardia",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
        "Atrial fibrillation ": "I48.91",
        "Substance abuse ": "F19.10",
        "Fever ": "R50.9",
      },
    },
    1042: {
      "Chief Complaint": "tachycardia",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Anxiety ": "F41.9",
        "Hyperthyroidism ": "E05.90",
        "Supraventricular tachycardia ": "I47.1",
        "Atrial fibrillation ": "I48.91",
        "Anemia ": "D64.9",
        "Fever ": "R50.9",
      },
    },
    1043: {
      "Chief Complaint": "tachycardia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Atrial fibrillation ": "I48.91",
        "Heart failure ": "I50.9",
        "Anemia ": "D64.9",
        "Hyperthyroidism ": "E05.90",
        "Chronic kidney disease ": "N18.9",
        "Fever ": "R50.9",
      },
    },
    1044: {
      "Chief Complaint": "slow heart rate",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital heart block ": "Q24.6",
        "Hypothyroidism ": "E03.1",
        "Electrolyte imbalance ": "E87.1",
        "Sepsis ": "A41.9",
      },
    },
    1045: {
      "Chief Complaint": "slow heart rate",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Congenital heart disease ": "Q24.9",
        "Hypothyroidism ": "E03.1",
        "Electrolyte imbalance ": "E87.1",
      },
    },
    1046: {
      "Chief Complaint": "slow heart rate",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Sick sinus syndrome ": "I49.5",
        "Atrioventricular block ": "I44.1",
        "Medication side effect 5. Ischemic heart disease ": "I25.9",
      },
    },
    1047: {
      "Chief Complaint": "slow heart rate",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Sick sinus syndrome ": "I49.5",
        "Atrioventricular block ": "I44.1",
        "Medication side effect 5. Ischemic heart disease ": "I25.9",
      },
    },
    1048: {
      "Chief Complaint": "slow heart rate",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sick sinus syndrome ": "I49.5",
        "Atrioventricular block ": "I44.1",
        "Medication side effect 4. Hypothyroidism ": "E03.9",
        "Ischemic heart disease ": "I25.9",
      },
    },
    1049: {
      "Chief Complaint": "bradycardia",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Congenital heart block ": "Q24.6",
        "Hypothyroidism ": "E03.1",
        "Electrolyte imbalance ": "E87.1",
        "Sepsis ": "A41.9",
      },
    },
    1050: {
      "Chief Complaint": "bradycardia",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Congenital heart disease ": "Q24.9",
        "Hypothyroidism ": "E03.1",
        "Electrolyte imbalance ": "E87.1",
      },
    },
    1051: {
      "Chief Complaint": "bradycardia",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Sick sinus syndrome ": "I49.5",
        "Atrioventricular block ": "I44.1",
        "Medication side effect 5. Ischemic heart disease ": "I25.9",
      },
    },
    1052: {
      "Chief Complaint": "bradycardia",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Hypothyroidism ": "E03.9",
        "Sick sinus syndrome ": "I49.5",
        "Atrioventricular block ": "I44.1",
        "Medication side effect 5. Ischemic heart disease ": "I25.9",
      },
    },
    1053: {
      "Chief Complaint": "bradycardia",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sick sinus syndrome ": "I49.5",
        "Atrioventricular block ": "I44.1",
        "Medication side effect 4. Hypothyroidism ": "E03.9",
        "Ischemic heart disease ": "I25.9",
      },
    },
    1054: {
      "Chief Complaint": "suture removal",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Suture removal ": "Z48.01",
        "Wound check ": "Z48.01",
        "Infection at the site of suture ": "T81.4XXA",
        "Allergic reaction to suture material ": "T78.40XA",
        "Keloid formation ": "L91.0",
      },
    },
    1055: {
      "Chief Complaint": "suture removal",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Suture removal ": "Z48.01",
        "Wound check ": "Z48.01",
        "Infection at the site of suture ": "T81.4XXA",
        "Allergic reaction to suture material ": "T78.40XA",
        "Keloid formation ": "L91.0",
      },
    },
    1056: {
      "Chief Complaint": "suture removal",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Suture removal ": "Z48.01",
        "Wound check ": "Z48.01",
        "Infection at the site of suture ": "T81.4XXA",
        "Allergic reaction to suture material ": "T78.40XA",
        "Keloid formation ": "L91.0",
      },
    },
    1057: {
      "Chief Complaint": "suture removal",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Suture removal ": "Z48.01",
        "Wound check ": "Z48.01",
        "Infection at the site of suture ": "T81.4XXA",
        "Allergic reaction to suture material ": "T78.40XA",
        "Keloid formation ": "L91.0",
      },
    },
    1058: {
      "Chief Complaint": "tooth extraction",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Tooth extraction ": "Z41.811",
        "Post-operative infection ": "T81.4XXA",
        "Dry socket ": "K09.90",
        "Oral bleeding ": "K13.70",
        "Nerve injury ": "S04.4XXA",
      },
    },
    1059: {
      "Chief Complaint": "tooth extraction",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Tooth extraction ": "Z41.811",
        "Post-operative infection ": "T81.4XXA",
        "Dry socket ": "K09.90",
        "Oral bleeding ": "K13.70",
        "Nerve injury ": "S04.4XXA",
      },
    },
    1060: {
      "Chief Complaint": "tooth extraction",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Tooth extraction ": "Z41.811",
        "Post-operative infection ": "T81.4XXA",
        "Dry socket ": "K09.90",
        "Oral bleeding ": "K13.70",
        "Nerve injury ": "S04.4XXA",
      },
    },
    1061: {
      "Chief Complaint": "tooth extraction",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Tooth extraction ": "Z41.811",
        "Post-operative infection ": "T81.4XXA",
        "Dry socket ": "K09.90",
        "Oral bleeding ": "K13.70",
        "Nerve injury ": "S04.4XXA",
      },
    },
    1062: {
      "Chief Complaint": "oral bleeding",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Hemophilia ": "D66",
        "Platelet disorder ": "D69.9",
        "Trauma to the mouth ": "S00.5",
        "Gingivitis ": "K05.1",
      },
    },
    1063: {
      "Chief Complaint": "oral bleeding",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Gum disease/Periodontitis ": "K05.3",
        "Trauma to the mouth ": "S00.5",
        "Bleeding disorder ": "D68.9",
        "Oral cancer ": "C06.9",
      },
    },
    1064: {
      "Chief Complaint": "oral bleeding",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Gum disease/Periodontitis ": "K05.3",
        "Trauma to the mouth ": "S00.5",
        "Bleeding disorder ": "D68.9",
        "Oral cancer ": "C06.9",
      },
    },
    1065: {
      "Chief Complaint": "oral bleeding",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Gum disease/Periodontitis ": "K05.3",
        "Oral cancer ": "C06.9",
        "Bleeding disorder ": "D68.9",
        "Trauma to the mouth ": "S00.5",
      },
    },
    1066: {
      "Chief Complaint": "unresponsive",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Severe infection/sepsis ": "A41.9",
        "Poisoning ": "T65.9",
        "Severe dehydration ": "E86.0",
        "Head trauma ": "S06.9",
        "Diabetic ketoacidosis ": "E10.10",
      },
    },
    1067: {
      "Chief Complaint": "unresponsive",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Drug overdose ": "T50.901",
        "Ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Myocardial infarction ": "I21.9",
        "Severe head trauma ": "S06.9",
        "Alcohol poisoning ": "T51.0",
      },
    },
    1068: {
      "Chief Complaint": "unresponsive",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Drug overdose ": "T50.901",
        "Ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Myocardial infarction ": "I21.9",
        "Severe head trauma ": "S06.9",
        "Alcohol poisoning ": "T51.0",
      },
    },
    1069: {
      "Chief Complaint": "unresponsive",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Ischemic stroke ": "I63.9",
        "Hemorrhagic stroke ": "I61.9",
        "Myocardial infarction ": "I21.9",
        "Severe infection/sepsis ": "A41.9",
        "Fall resulting in trauma ": "W18.30",
        "Drug overdose ": "T50.901",
      },
    },
    1070: {
      "Chief Complaint": "blood per mouth",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Hemophilia ": "D66",
        "Platelet disorder ": "D69.9",
        "Trauma to the mouth ": "S00.5",
        "Gingivitis ": "K05.1",
      },
    },
    1071: {
      "Chief Complaint": "blood per mouth",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Gum disease/Periodontitis ": "K05.3",
        "Trauma to the mouth ": "S00.5",
        "Bleeding disorder ": "D68.9",
        "Oral cancer ": "C06.9",
      },
    },
    1072: {
      "Chief Complaint": "blood per mouth",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Gum disease/Periodontitis ": "K05.3",
        "Trauma to the mouth ": "S00.5",
        "Bleeding disorder ": "D68.9",
        "Oral cancer ": "C06.9",
      },
    },
    1073: {
      "Chief Complaint": "blood per mouth",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Oral bleeding ": "R04.0",
        "Gum disease/Periodontitis ": "K05.3",
        "Oral cancer ": "C06.9",
        "Bleeding disorder ": "D68.9",
        "Trauma to the mouth ": "S00.5",
      },
    },
    1074: {
      "Chief Complaint": "sepsis",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Bacterial infection ": "A49.9",
        "Viral infection ": "B34.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Meningitis ": "G03.9",
      },
    },
    1075: {
      "Chief Complaint": "sepsis",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Sepsis ": "A41.9",
        "Bacterial infection ": "A49.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Diverticulitis ": "K57.90",
        "Pyelonephritis ": "N10",
      },
    },
    1076: {
      "Chief Complaint": "sepsis",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Sepsis ": "A41.9",
        "Bacterial infection ": "A49.9",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Pelvic inflammatory disease ": "N73.9",
        "Pyelonephritis ": "N10",
      },
    },
    1077: {
      "Chief Complaint": "sepsis",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Bacterial infection ": "A49.9",
        "Diverticulitis ": "K57.90",
        "Pyelonephritis ": "N10",
      },
    },
    1078: {
      "Chief Complaint": "finger laceration",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Laceration of finger ": "S61.0",
        "Foreign body in finger ": "Z18.11",
        "Fracture of finger ": "S62.601A",
        "Contusion of finger ": "S60.1",
        "Infection of finger ": "L03.119",
      },
    },
    1079: {
      "Chief Complaint": "finger laceration",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Laceration of finger ": "S61.0",
        "Tendon injury of finger ": "S66.0",
        "Fracture of finger ": "S62.601A",
        "Foreign body in finger ": "Z18.11",
        "Infection of finger ": "L03.119",
      },
    },
    1080: {
      "Chief Complaint": "finger laceration",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Laceration of finger ": "S61.0",
        "Fracture of finger due to osteoporosis ": "M80.8XXA",
        "Tendon injury of finger ": "S66.0",
        "Foreign body in finger ": "Z18.11",
        "Infection of finger ": "L03.119",
      },
    },
    1081: {
      "Chief Complaint": "medical clearance for incarceration",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Medical clearance ": "Z02.89",
        "Substance withdrawal ": "F10.239, F11.239",
        "Mental health evaluation ": "F01-F99",
        "Recent injuries or trauma ": "S00-T88",
        "Chronic diseases such as diabetes, hypertension ": "E08-E13, I10-I15",
        "Infectious diseases screening ": "Z11.59",
      },
    },
    1082: {
      "Chief Complaint": "Nausea and Vomiting",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Nausea and Vomiting ": "R11.2",
        "Gastroesophageal reflux ": "K21.9",
        "Pyloric stenosis ": "K31.0",
        "Infections ": "R78.81",
        "sepsis ": "A41.9",
        "Metabolic disorders ": "E70-E90",
        "Intestinal obstruction ": "K56.60",
        "Milk allergy ": "L27.2",
        "Congenital anomalies ": "Q89.9",
        "Electrolyte imbalance ": "E87.8",
        "Intracranial pressure ": "G93.6",
      },
    },
    1083: {
      "Chief Complaint": "Nausea and Vomiting",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Nausea and Vomiting ": "R11.2",
        "Gastroesophageal reflux ": "K21.9",
        "Gastroenteritis ": "K52.9",
        "Pyloric stenosis ": "K31.0",
        "Intestinal obstruction ": "K56.60",
        "Milk allergy ": "L27.2",
        "Urinary tract infection ": "N39.0",
        "Infections ": "R78.81",
        "meningitis) ": "G03.9",
        "Metabolic disorders ": "E70-E90",
        "Intussusception ": "K56.1",
      },
    },
    1084: {
      "Chief Complaint": "Nausea and Vomiting",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nausea and Vomiting ": "R11.2",
        "Gastroenteritis ": "K52.9",
        "Appendicitis ": "K35.80",
        "Food poisoning ": "A05.9",
        "Migraine ": "G43.909",
        "Urinary tract infection ": "N39.0",
        "Gastroesophageal reflux ": "K21.9",
        "Intestinal obstruction ": "K56.60",
        "Motion sickness ": "T75.3",
        "Brain tumor ": "C71.9",
      },
    },
    1085: {
      "Chief Complaint": "Nausea and Vomiting",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Nausea and Vomiting ": "R11.2",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Peptic ulcer ": "K27.9",
        "Pancreatitis ": "K85.9",
        "Gallstones ": "K80.20",
        "Alcohol intoxication ": "F10.92",
        "Appendicitis ": "K35.80",
        "Medication side effect ": "T88.7",
        "Gastroesophageal reflux ": "K21.9",
      },
    },
    1086: {
      "Chief Complaint": "Nausea and Vomiting",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Nausea and Vomiting ": "R11.2",
        "Pregnancy ": "O21.0",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Pancreatitis ": "K85.9",
        "Acute myocardial infarction ": "I21.9",
        "UTI ": "N39.0",
        "Medication side effect ": "T88.7",
      },
    },
    1087: {
      "Chief Complaint": "nausea and vomiting",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Gastroenteritis ": "A09.9",
        "Peptic ulcer disease ": "K27.9",
        "Gastroesophageal reflux disease ": "GERD",
        "Gastritis ": "K29.70",
        "Adverse effect of medication ": "T88.7",
        "Acute myocardial infarction ": "I21.9",
        "Gallstones ": "K80.20",
        "Alcohol intoxication ": "F10.92",
        "Medication side effect ": "T88.7",
        "UTI ": "N39.0",
      },
    },
    1088: {
      "Chief Complaint": "bladder symtoms",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Overactive bladder ": "N32.81",
        "Bladder stones ": "N21.0",
        "Constipation affecting bladder ": "K59.00",
        "Vesicoureteral reflux ": "N13.70",
      },
    },
    1089: {
      "Chief Complaint": "bladder symtoms",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.0",
        "Urinary tract infection ": "N39.0",
        "Bladder cancer ": "C67.9",
        "Interstitial cystitis ": "N30.10",
        "Bladder stones ": "N21.0",
      },
    },
    1090: {
      "Chief Complaint": "bladder symtoms",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Interstitial cystitis ": "N30.10",
        "Bladder cancer ": "C67.9",
        "Overactive bladder ": "N32.81",
        "Urinary incontinence ": "N39.3",
      },
    },
    1091: {
      "Chief Complaint": "bladder symtoms",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Benign prostatic hyperplasia ": "N40.0",
        "Urinary tract infection ": "N39.0",
        "Bladder cancer ": "C67.9",
        "Bladder stones ": "N21.0",
        "Urinary retention ": "N36.0",
      },
    },
    1092: {
      "Chief Complaint": "bladder symtoms",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Bladder cancer ": "C67.9",
        "Urinary incontinence ": "N39.3",
        "Overactive bladder ": "N32.81",
        "Bladder prolapse ": "N81.1",
      },
    },
    1093: {
      "Chief Complaint": "chest pain after fall",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Rib fracture ": "S22.3XXA",
        "Contusion of chest wall ": "S20.2XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Muscle strain ": "S29.91XA",
        "Pneumothorax ": "S27.0XXA",
      },
    },
    1094: {
      "Chief Complaint": "chest pain after fall",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Rib fracture ": "S22.3XXA",
        "Pneumothorax ": "S27.0XXA",
        "Cardiac contusion ": "S26.9XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Musculoskeletal pain ": "M79.1",
      },
    },
    1095: {
      "Chief Complaint": "chest pain after fall",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Rib fracture ": "S22.3XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Pneumothorax ": "S27.0XXA",
        "Breast contusion ": "S20.1XXA",
        "Musculoskeletal pain ": "M79.1",
      },
    },
    1096: {
      "Chief Complaint": "chest pain after fall",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Rib fracture due to osteoporosis ": "S22.3XXA",
        "Pneumothorax ": "S27.0XXA",
        "Myocardial infarction triggered by trauma ": "I21.9",
        "Musculoskeletal pain ": "M79.1",
        "Pulmonary contusion ": "S27.3XXA",
      },
    },
    1097: {
      "Chief Complaint": "chest pain after fall",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Rib fracture due to osteoporosis ": "S22.3XXA",
        "Pneumothorax ": "S27.0XXA",
        "Breast contusion ": "S20.1XXA",
        "Musculoskeletal pain ": "M79.1",
        "Pulmonary contusion ": "S27.3XXA",
      },
    },
    1098: {
      "Chief Complaint": "head trauma",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Skull fracture ": "S02.1XXA",
        "Brain contusion ": "S06.2X0A",
        "Scalp laceration ": "S01.0XXA",
        "Hematoma ": "S06.1X0A",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1099: {
      "Chief Complaint": "head trauma",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Subdural hematoma ": "S06.5X0A",
        "Epidural hematoma ": "S06.4X0A",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1100: {
      "Chief Complaint": "head trauma",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Subdural hematoma ": "S06.5X0A",
        "Scalp laceration ": "S01.0XXA",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1101: {
      "Chief Complaint": "head trauma",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Subdural hematoma due to fall ": "S06.5X0A",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Cerebral hemorrhage ": "S06.6X0A",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1102: {
      "Chief Complaint": "head trauma",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Subdural hematoma due to fall ": "S06.5X0A",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Scalp laceration ": "S01.0XXA",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1103: {
      "Chief Complaint": "head injury",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Skull fracture ": "S02.1XXA",
        "Brain contusion ": "S06.2X0A",
        "Scalp laceration ": "S01.0XXA",
        "Hematoma ": "S06.1X0A",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1104: {
      "Chief Complaint": "head injury",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Subdural hematoma ": "S06.5X0A",
        "Epidural hematoma ": "S06.4X0A",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1105: {
      "Chief Complaint": "head injury",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Subdural hematoma ": "S06.5X0A",
        "Scalp laceration ": "S01.0XXA",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1106: {
      "Chief Complaint": "head injury",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Subdural hematoma due to fall ": "S06.5X0A",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Cerebral hemorrhage ": "S06.6X0A",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1107: {
      "Chief Complaint": "head injury",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Head trauma, unspecified ": "S09.90XA",
        "Subdural hematoma due to fall ": "S06.5X0A",
        "Concussion ": "S06.0X0A",
        "Traumatic brain injury ": "S06.9X0A",
        "Skull fracture ": "S02.1XXA",
        "Scalp laceration ": "S01.0XXA",
        "Abrasion, head ": "S00.81XA",
      },
    },
    1108: {
      "Chief Complaint": "Altered Mental Status (AMS)",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Hypoxic-ischemic encephalopathy ": "P91.60",
        "Neonatal sepsis ": "P36.9",
        "Metabolic disorders ": "E70-E90",
        "Intracranial hemorrhage ": "P52.9",
        "Congenital heart disease ": "Q24.9",
        "Electrolyte imbalances ": "E87.8",
        "Hypoglycemia ": "P70.4",
        "Inborn errors of metabolism ": "E88.9",
        "Birth trauma ": "P15.9",
      },
    },
    1109: {
      "Chief Complaint": "Altered Mental Status (AMS)",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Meningitis ": "G03.9",
        "Dehydration ": "E86.0",
        "Electrolyte imbalances ": "E87.8",
        "Febrile seizure ": "R56.00",
        "Intussusception ": "K56.1",
        "Poisoning ": "T39.0X1A",
        "Hypoglycemia ": "E16.2",
        "Inborn errors of metabolism ": "E88.9",
        "Head injury ": "S09.90XA",
      },
    },
    1110: {
      "Chief Complaint": "Altered Mental Status (AMS)",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Meningitis ": "G03.9",
        "Head injury ": "S09.90XA",
        "Seizure disorders ": "G40.909",
        "Poisoning ": "T39.0X1A",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Electrolyte imbalances ": "E87.8",
        "Infections ": "R78.81",
        "encephalitis) ": "G05.1",
        "Diabetic ketoacidosis ": "E10.10",
      },
    },
    1111: {
      "Chief Complaint": "Altered Mental Status (AMS)",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Alcohol intoxication ": "F10.92",
        "Drug overdose ": "T50.901A",
        "Stroke ": "I63.9",
        "Head trauma ": "S09.90XA",
        "Hypoglycemia ": "E16.2",
        "Seizure disorders ": "G40.909",
        "Electrolyte imbalances ": "E87.8",
        "Psychiatric disorders ": "F29",
        "Carbon monoxide poisoning ": "T58.01XA",
        "Urinary tract infection ": "N39.0",
      },
    },
    1112: {
      "Chief Complaint": "Altered Mental Status (AMS)",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Urinary tract infection ": "N39.0",
        "Stroke ": "I63.9",
        "Hypoglycemia ": "E16.2",
        "Drug overdose ": "T50.901A",
        "Alcohol intoxication ": "F10.92",
        "Seizure disorders ": "G40.909",
        "Electrolyte imbalances ": "E87.8",
        "Migraine ": "G43.909",
        "Psychiatric disorders ": "F29",
      },
    },
    1113: {
      "Chief Complaint": "Altered Mental Status (AMS)",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Stroke ": "I63.9",
        "Dementia ": "F03.90",
        "Urinary tract infection ": "N39.0",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Electrolyte imbalances ": "E87.8",
        "Medication side effects ": "T88.7",
        "Heart failure ": "I50.9",
        "Chronic kidney disease ": "N18.9",
        "Psychiatric disorders ": "F29",
      },
    },
    1114: {
      "Chief Complaint": "Cough",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral upper respiratory infection ": "J06.9",
        "Bronchiolitis ": "J21.9",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Foreign body aspiration ": "T17.298A",
      },
    },
    1115: {
      "Chief Complaint": "Cough",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Upper respiratory infection ": "J06.9",
        "Bronchitis ": "J40",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
      },
    },
    1116: {
      "Chief Complaint": "Cough",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Upper respiratory infection ": "J06.9",
        "Bronchitis ": "J40",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
      },
    },
    1117: {
      "Chief Complaint": "Cough",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "Bronchitis ": "J40",
        "Lung cancer ": "C34.90",
      },
    },
    1118: {
      "Chief Complaint": "Cough",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "Bronchitis ": "J40",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
      },
    },
    1119: {
      "Chief Complaint": "Motorcycle Accident",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture of unspecified bone ": "S82.009A",
        "Soft tissue injury ": "S39.012A",
        "Abdominal injury ": "S36.9XXA",
        "Spinal cord injury ": "S14.1XXA",
      },
    },
    1120: {
      "Chief Complaint": "Motorcycle Accident",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture of unspecified bone ": "S82.009A",
        "Soft tissue injury ": "S39.012A",
        "Abdominal injury ": "S36.9XXA",
        "Spinal cord injury ": "S14.1XXA",
      },
    },
    1121: {
      "Chief Complaint": "Motorcycle Accident",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture of unspecified bone ": "S82.009A",
        "Soft tissue injury ": "S39.012A",
        "Abdominal injury ": "S36.9XXA",
        "Spinal cord injury ": "S14.1XXA",
      },
    },
    1122: {
      "Chief Complaint": "Motorcycle Accident",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Soft tissue injury ": "S39.012A",
        "Abdominal injury ": "S36.9XXA",
        "Spinal cord injury ": "S14.1XXA",
      },
    },
    1123: {
      "Chief Complaint": "Motorcycle Accident",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Head injury ": "S09.90XA",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Soft tissue injury ": "S39.012A",
        "Abdominal injury ": "S36.9XXA",
        "Spinal cord injury ": "S14.1XXA",
      },
    },
    1124: {
      "Chief Complaint": "Bladder Symptoms",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Overactive bladder ": "N33.9",
        "Bladder spasm ": "N32.89",
        "Constipation ": "K59.00",
        "Urinary retention ": "R33.8",
      },
    },
    1125: {
      "Chief Complaint": "Bladder Symptoms",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Prostate enlargement ": "N40.1",
        "Urinary tract infection ": "N39.0",
        "Bladder stones ": "N21.0",
        "Overactive bladder ": "N33.9",
        "Bladder cancer ": "C67.9",
      },
    },
    1126: {
      "Chief Complaint": "Bladder Symptoms",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Bladder stones ": "N21.0",
        "Overactive bladder ": "N33.9",
        "Interstitial cystitis ": "N30.10",
        "Bladder cancer ": "C67.9",
      },
    },
    1127: {
      "Chief Complaint": "Bladder Symptoms",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Prostate enlargement ": "N40.1",
        "Bladder cancer ": "C67.9",
        "Bladder stones ": "N21.0",
        "Urinary tract infection ": "N39.0",
        "Overactive bladder ": "N33.9",
      },
    },
    1128: {
      "Chief Complaint": "Bladder Symptoms",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Bladder cancer ": "C67.9",
        "Bladder stones ": "N21.0",
        "Overactive bladder ": "N33.9",
        "Urinary incontinence ": "R32",
      },
    },
    1129: {
      "Chief Complaint": "Chest Pain After Fall",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Musculoskeletal pain ": "M79.1",
        "Chest wall contusion ": "S20.211A",
        "Rib fracture ": "S22.3XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Heart contusion ": "S26.90XA",
      },
    },
    1130: {
      "Chief Complaint": "Chest Pain After Fall",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Musculoskeletal pain ": "M79.1",
        "Chest wall contusion ": "S20.211A",
        "Rib fracture ": "S22.3XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Heart contusion ": "S26.90XA",
      },
    },
    1131: {
      "Chief Complaint": "Chest Pain After Fall",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Musculoskeletal pain ": "M79.1",
        "Chest wall contusion ": "S20.211A",
        "Rib fracture ": "S22.3XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Heart contusion ": "S26.90XA",
      },
    },
    1132: {
      "Chief Complaint": "Chest Pain After Fall",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Musculoskeletal pain ": "M79.1",
        "Chest wall contusion ": "S20.211A",
        "Rib fracture ": "S22.3XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Heart contusion ": "S26.90XA",
      },
    },
    1133: {
      "Chief Complaint": "Chest Pain After Fall",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Musculoskeletal pain ": "M79.1",
        "Chest wall contusion ": "S20.211A",
        "Rib fracture ": "S22.3XXA",
        "Pulmonary contusion ": "S27.3XXA",
        "Heart contusion ": "S26.90XA",
      },
    },
    1134: {
      "Chief Complaint": "Jellyfish Sting",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Skin infection ": "L08.9",
        "Toxic effect of contact with venomous marine animals ": "T63.622A",
        "Anaphylactic shock ": "T78.2XXA",
        "Cellulitis ": "L03.90",
      },
    },
    1135: {
      "Chief Complaint": "Jellyfish Sting",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Skin infection ": "L08.9",
        "Toxic effect of contact with venomous marine animals ": "T63.622A",
        "Anaphylactic shock ": "T78.2XXA",
        "Cellulitis ": "L03.90",
      },
    },
    1136: {
      "Chief Complaint": "Jellyfish Sting",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Skin infection ": "L08.9",
        "Toxic effect of contact with venomous marine animals ": "T63.622A",
        "Anaphylactic shock ": "T78.2XXA",
        "Cellulitis ": "L03.90",
      },
    },
    1137: {
      "Chief Complaint": "Jellyfish Sting",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Skin infection ": "L08.9",
        "Toxic effect of contact with venomous marine animals ": "T63.622A",
        "Anaphylactic shock ": "T78.2XXA",
        "Cellulitis ": "L03.90",
      },
    },
    1138: {
      "Chief Complaint": "Jellyfish Sting",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Allergic reaction ": "T78.40XA",
        "Skin infection ": "L08.9",
        "Toxic effect of contact with venomous marine animals ": "T63.622A",
        "Anaphylactic shock ": "T78.2XXA",
        "Cellulitis ": "L03.90",
      },
    },
    1139: {
      "Chief Complaint": "Non-Syncopal Fall",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Non-syncopal fall ": "W18.30XA",
        "Head trauma ": "S09.90XA",
        "Muscle strain ": "M62.521",
        "Fracture ": "S42.301A",
        "Contusion ": "S40.021A",
        "Sprain ": "S93.409A",
      },
    },
    1140: {
      "Chief Complaint": "Non-Syncopal Fall",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Non-syncopal fall ": "W18.30XA",
        "Head trauma ": "S09.90XA",
        "Muscle strain ": "M62.521",
        "Fracture ": "S42.301A",
        "Contusion ": "S40.021A",
        "Sprain ": "S93.409A",
      },
    },
    1141: {
      "Chief Complaint": "Non-Syncopal Fall",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Non-syncopal fall ": "W18.30XA",
        "Head trauma ": "S09.90XA",
        "Muscle strain ": "M62.521",
        "Fracture ": "S42.301A",
        "Contusion ": "S40.021A",
        "Sprain ": "S93.409A",
      },
    },
    1142: {
      "Chief Complaint": "Non-Syncopal Fall",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Non-syncopal fall ": "W18.30XA",
        "Head trauma ": "S09.90XA",
        "Muscle strain ": "M62.521",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Contusion ": "S40.021A",
        "Sprain ": "S93.409A",
      },
    },
    1143: {
      "Chief Complaint": "Non-Syncopal Fall",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Non-syncopal fall ": "W18.30XA",
        "Head trauma ": "S09.90XA",
        "Muscle strain ": "M62.521",
        "Fracture due to osteoporosis ": "M80.08XA",
        "Contusion ": "S40.021A",
        "Sprain ": "S93.409A",
      },
    },
    1144: {
      "Chief Complaint": "Encounter for management of wound VAC",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Follow-up examination after treatment for conditions other than malignant neoplasm ":
          "Z09",
        "Encounter for aftercare following surgery for injury and trauma ":
          "Z48.89",
        "Encounter for change or removal of surgical wound dressing ": "Z48.01",
        "Encounter for other specified aftercare ": "Z51.89",
        "Encounter for attention to surgical wound dressings ": "Z48.01",
      },
    },
    1145: {
      "Chief Complaint": "Abdominal Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Abdominal Pain ": "R10.9",
        "Appendicitis ": "K35.80",
        "Gastroenteritis ": "K52.9",
        "Constipation ": "K59.00",
        "Intussusception ": "K56.1",
        "Urinary tract infection ": "N39.0",
        "Lactose intolerance ": "E73.9",
        "Mesenteric lymphadenitis ": "I88.0",
        "Food poisoning ": "A05.9",
        "Peptic ulcer ": "K27.9",
      },
    },
    1146: {
      "Chief Complaint": "Abdominal Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Abdominal Pain ": "R10.9",
        "Appendicitis ": "K35.80",
        "Cholecystitis ": "K81.9",
        "Pancreatitis ": "K85.9",
        "Diverticulitis ": "K57.90",
        "Bowel obstruction ": "K56.60",
        "AAA (Abdominal Aortic Aneurysm) ": "I71.4",
        "Mesenteric ischemia ": "K55.9",
        "Kidney stone ": "N20.0",
        "Gastritis ": "K29.70",
        "Gastroenteritis ": "K52.9",
        "Bowel perforation ": "K63.9",
        "Irritable bowel syndrome ": "K58.9",
        "Inflammatory bowel disease ": "K50.90",
        "Peptic ulcer ": "K27.9",
      },
    },
    1147: {
      "Chief Complaint": "Abdominal Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Abdominal Pain ": "R10.9",
        "Appendicitis ": "K35.80",
        "Cholecystitis ": "K81.9",
        "Pancreatitis ": "K85.9",
        "Diverticulitis ": "K57.90",
        "Bowel obstruction ": "K56.60",
        "AAA (Abdominal Aortic Aneurysm) ": "I71.4",
        "Mesenteric ischemia ": "K55.9",
        "Kidney stone ": "N20.0",
        "Gastritis ": "K29.70",
        "Gastroenteritis ": "K52.9",
        "Bowel perforation ": "K63.9",
        "Menstrual cramps ": "N94.6",
        "Urinary tract infection ": "N39.0",
        "Endometriosis ": "N80.9",
        "Ovarian cyst ": "N83.20",
        "Pelvic inflammatory disease ": "N73.9",
        "Ectopic pregnancy ": "O00.9",
      },
    },
    1148: {
      "Chief Complaint": "Abdominal Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Abdominal Pain ": "R10.9",
        "Appendicitis ": "K35.80",
        "Cholecystitis ": "K81.9",
        "Pancreatitis ": "K85.9",
        "Diverticulitis ": "K57.90",
        "Bowel obstruction ": "K56.60",
        "AAA (Abdominal Aortic Aneurysm) ": "I71.4",
        "Mesenteric ischemia ": "K55.9",
        "Kidney stone ": "N20.0",
        "Gastritis ": "K29.70",
        "Gastroenteritis ": "K52.9",
        "Bowel perforation ": "K63.9",
        "Constipation ": "K59.00",
        "Hernia ": "K40.90",
        "Colonic diverticulosis ": "K57.20",
        "Ischemic bowel disease ": "K55.9",
        "Colorectal cancer ": "C18.9",
        "Gastrointestinal bleeding ": "K92.9",
        "Inflammatory bowel disease ": "K50.90",
      },
    },
    1149: {
      "Chief Complaint": "Pre-Syncope",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Arrhythmia ": "I49.9",
        "Orthostatic hypotension ": "I95.1",
      },
    },
    1150: {
      "Chief Complaint": "Pre-Syncope",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Arrhythmia ": "I49.9",
        "Dehydration ": "E86.0",
        "Cardiac ischemia ": "I20.9",
      },
    },
    1151: {
      "Chief Complaint": "Pre-Syncope",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Vasovagal syncope ": "R55",
        "Orthostatic hypotension ": "I95.1",
        "Arrhythmia ": "I49.9",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
      },
    },
    1152: {
      "Chief Complaint": "Pre-Syncope",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Orthostatic hypotension ": "I95.1",
        "Cardiac arrhythmias ": "I49.9",
        "Carotid sinus hypersensitivity ": "G90.01",
        "Vasovagal syncope ": "R55",
        "Cardiac ischemia ": "I20.9",
      },
    },
    1153: {
      "Chief Complaint": "Penile Swelling",
      Age: "Pediatric",
      Gender: "Male",
      DDx: {
        "Phimosis ": "N47.0",
        "Balanitis ": "N48.1",
        "Allergic reaction ": "T78.40XA",
        "Insect bite ": "S30.867A",
        "Trauma or injury ": "S30.840A",
      },
    },
    1154: {
      "Chief Complaint": "Penile Swelling",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Balanitis ": "N48.1",
        "Phimosis ": "N47.0",
        "Priapism ": "N48.30",
        "Peyronie's disease ": "N48.6",
        "Penile fracture ": "S39.840A",
      },
    },
    1155: {
      "Chief Complaint": "Penile Swelling",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Balanitis ": "N48.1",
        "Priapism ": "N48.30",
        "Peyronie's disease ": "N48.6",
        "Penile cancer ": "C60.9",
        "Vascular disorders ": "I99.9",
      },
    },
    1156: {
      "Chief Complaint": "Aggressive Behavior",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Substance abuse ": "F19.10",
        "Mental health disorder (e.g., Bipolar disorder) ": "F31.9",
        "Brain injury ": "S06.9X9A",
        "Alcohol intoxication ": "F10.929",
        "Altered mental status ": "R41.82",
      },
    },
    1157: {
      "Chief Complaint": "Psychiatric Evaluation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "ADHD ": "F90.9",
        "Autism Spectrum Disorder ": "F84.0",
        "Anxiety Disorders ": "F41.9",
        "Depressive Disorders ": "F32.9",
        "Behavioral Disorders ": "F91.9",
      },
    },
    1158: {
      "Chief Complaint": "Psychiatric Evaluation",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Depression ": "F32.9",
        "Anxiety Disorders ": "F41.9",
        "Bipolar Disorder ": "F31.9",
        "Schizophrenia ": "F20.9",
        "Substance Abuse ": "F19.10",
      },
    },
    1159: {
      "Chief Complaint": "Psychiatric Evaluation",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dementia ": "F03.90",
        "Depression ": "F32.9",
        "Anxiety Disorders ": "F41.9",
        "Bipolar Disorder ": "F31.9",
        "Delirium ": "F05",
      },
    },
    1160: {
      "Chief Complaint": "STD Exposure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sexual abuse ": "T74.21XA",
        "Gonorrhea ": "A54.9",
        "Chlamydia ": "A56.9",
        "Herpes Simplex Virus ": "B00.9",
        "Human Papillomavirus ": "B97.7",
      },
    },
    1161: {
      "Chief Complaint": "STD Exposure",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Gonorrhea ": "A54.9",
        "Chlamydia ": "A56.9",
        "HIV ": "B20",
        "Herpes Simplex Virus ": "B00.9",
        "Syphilis ": "A53.9",
      },
    },
    1162: {
      "Chief Complaint": "STD Exposure",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Gonorrhea ": "A54.9",
        "Chlamydia ": "A56.9",
        "HIV ": "B20",
        "Herpes Simplex Virus ": "B00.9",
        "Human Papillomavirus ": "B97.7",
      },
    },
    1163: {
      "Chief Complaint": "STD Exposure",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Gonorrhea ": "A54.9",
        "Chlamydia ": "A56.9",
        "HIV ": "B20",
        "Herpes Simplex Virus ": "B00.9",
        "Syphilis ": "A53.9",
      },
    },
    1164: {
      "Chief Complaint": "STD Exposure",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Gonorrhea ": "A54.9",
        "Chlamydia ": "A56.9",
        "HIV ": "B20",
        "Herpes Simplex Virus ": "B00.9",
        "Human Papillomavirus ": "B97.7",
      },
    },
    1165: {
      "Chief Complaint": "Disorientation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Febrile seizure ": "R56.0",
        "Meningitis ": "G03.9",
        "Electrolyte imbalance ": "E87.8",
        "Head injury ": "S09.90XA",
        "Encephalitis ": "G04.90",
      },
    },
    1166: {
      "Chief Complaint": "Disorientation",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Alcohol intoxication ": "F10.92",
        "Drug overdose ": "T50.901A",
        "Hypoglycemia ": "E16.2",
        "Stroke ": "I63.9",
        "Head injury ": "S09.90XA",
      },
    },
    1167: {
      "Chief Complaint": "Disorientation",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary tract infection ": "N39.0",
        "Hypoglycemia ": "E16.2",
        "Drug overdose ": "T50.901A",
        "Alcohol intoxication ": "F10.92",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    1168: {
      "Chief Complaint": "Disorientation",
      Age: "Elderly",
      Gender: "Male",
      DDx: {
        "Dementia ": "F03.90",
        "Stroke ": "I63.9",
        "Hypoglycemia ": "E16.2",
        "Electrolyte imbalance ": "E87.8",
        "Urinary tract infection ": "N39.0",
      },
    },
    1169: {
      "Chief Complaint": "Disorientation",
      Age: "Elderly",
      Gender: "Female",
      DDx: {
        "Dementia ": "F03.90",
        "Stroke ": "I63.9",
        "Hypoglycemia ": "E16.2",
        "Electrolyte imbalance ": "E87.8",
        "Urinary tract infection ": "N39.0",
      },
    },
    1170: {
      "Chief Complaint": "Near-Drowning",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Near-Drowning ": "T75.1XXA",
        "Aspiration pneumonia ": "J69.0",
        "Acute respiratory distress syndrome ": "J80",
        "Hypothermia ": "T68",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    1171: {
      "Chief Complaint": "Near-Drowning",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Near-Drowning ": "T75.1XXA",
        "Aspiration pneumonia ": "J69.0",
        "Acute respiratory distress syndrome ": "J80",
        "Hypothermia ": "T68",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    1172: {
      "Chief Complaint": "Near-Drowning",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Near-Drowning ": "T75.1XXA",
        "Aspiration pneumonia ": "J69.0",
        "Acute respiratory distress syndrome ": "J80",
        "Hypothermia ": "T68",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    1173: {
      "Chief Complaint": "Near-Drowning",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Near-Drowning ": "T75.1XXA",
        "Aspiration pneumonia ": "J69.0",
        "Acute respiratory distress syndrome ": "J80",
        "Hypothermia ": "T68",
        "Electrolyte imbalance ": "E87.8",
      },
    },
    1174: {
      "Chief Complaint": "Catheter Site Bleeding",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Catheter Site Bleeding ": "T82.868A",
        "Hemorrhage ": "R58",
        "Infection ": "T81.4XXA",
        "Hematoma ": "T81.3XXA",
        "Allergic reaction to catheter material ": "T88.7XXA",
      },
    },
    1175: {
      "Chief Complaint": "Catheter Site Bleeding",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Catheter Site Bleeding ": "T82.868A",
        "Hemorrhage ": "R58",
        "Infection ": "T81.4XXA",
        "Hematoma ": "T81.3XXA",
        "Allergic reaction to catheter material ": "T88.7XXA",
      },
    },
    1176: {
      "Chief Complaint": "Catheter Site Bleeding",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Catheter Site Bleeding ": "T82.868A",
        "Hemorrhage ": "R58",
        "Infection ": "T81.4XXA",
        "Hematoma ": "T81.3XXA",
        "Allergic reaction to catheter material ": "T88.7XXA",
      },
    },
    1177: {
      "Chief Complaint": "Catheter Site Bleeding",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Catheter Site Bleeding ": "T82.868A",
        "Hemorrhage ": "R58",
        "Infection ": "T81.4XXA",
        "Hematoma ": "T81.3XXA",
        "Allergic reaction to catheter material ": "T88.7XXA",
      },
    },
    1178: {
      "Chief Complaint": "Ascites",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Ascites ": "R18.8",
        "Liver disease ": "K76.9",
        "Heart failure ": "I50.9",
        "Malnutrition ": "E46",
        "Tuberculosis ": "A18.85",
      },
    },
    1179: {
      "Chief Complaint": "Ascites",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Ascites ": "R18.8",
        "Liver cirrhosis ": "K74.60",
        "Heart failure ": "I50.9",
        "Hepatitis ": "K75.9",
        "Peritoneal carcinomatosis ": "C78.6",
      },
    },
    1180: {
      "Chief Complaint": "Ascites",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Ascites ": "R18.8",
        "Ovarian cancer ": "C56.9",
        "Liver cirrhosis ": "K74.60",
        "Heart failure ": "I50.9",
        "Pancreatic cancer ": "C25.9",
      },
    },
    1181: {
      "Chief Complaint": "Ascites",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Ascites ": "R18.8",
        "Liver cirrhosis ": "K74.60",
        "Heart failure ": "I50.9",
        "Peritoneal carcinomatosis ": "C78.6",
        "Kidney failure ": "N17.9",
      },
    },
    1182: {
      "Chief Complaint": "THC overdose",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "THC intoxication ": "F12.929",
        "Anxiety ": "F41.9",
        "Psychotic disorder ": "F29",
        "Depression ": "F32.9",
        "Substance abuse ": "F19.10",
      },
    },
    1183: {
      "Chief Complaint": "THC overdose",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "THC intoxication ": "F12.929",
        "Anxiety ": "F41.9",
        "Substance abuse ": "F19.10",
        "Psychotic disorder ": "F29",
        "Depression ": "F32.9",
      },
    },
    1184: {
      "Chief Complaint": "THC overdose",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "THC intoxication ": "F12.929",
        "Anxiety ": "F41.9",
        "Depression ": "F32.9",
        "Substance abuse ": "F19.10",
        "Psychotic disorder ": "F29",
      },
    },
    1185: {
      "Chief Complaint": "THC overdose",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "THC intoxication ": "F12.929",
        "Anxiety ": "F41.9",
        "Depression ": "F32.9",
        "Psychotic disorder ": "F29",
        "Substance abuse ": "F19.10",
      },
    },
    1186: {
      "Chief Complaint": "Food Bolus Impaction",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Food bolus impaction ": "T18.198A",
        "Esophagitis ": "K20.9",
        "Gastroesophageal reflux ": "K21.9",
        "Eosinophilic esophagitis ": "K20.0",
        "Foreign body ingestion ": "T18.9",
      },
    },
    1187: {
      "Chief Complaint": "Food Bolus Impaction",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Food bolus impaction ": "T18.198A",
        "Esophagitis ": "K20.9",
        "Gastroesophageal reflux ": "K21.9",
        "Eosinophilic esophagitis ": "K20.0",
        "Esophageal stricture ": "K22.2",
      },
    },
    1188: {
      "Chief Complaint": "Food Bolus Impaction",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Food bolus impaction ": "T18.198A",
        "Esophagitis ": "K20.9",
        "Gastroesophageal reflux ": "K21.9",
        "Eosinophilic esophagitis ": "K20.0",
        "Esophageal stricture ": "K22.2",
      },
    },
    1189: {
      "Chief Complaint": "Food Bolus Impaction",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Food bolus impaction ": "T18.198A",
        "Esophagitis ": "K20.9",
        "Gastroesophageal reflux ": "K21.9",
        "Esophageal stricture ": "K22.2",
        "Eosinophilic esophagitis ": "K20.0",
      },
    },
    1190: {
      "Chief Complaint": "Scalp Laceration",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Scalp laceration ": "S01.0",
        "Head injury ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Skull fracture ": "S02.0XXA",
        "Scalp hematoma ": "S00.0",
      },
    },
    1191: {
      "Chief Complaint": "Scalp Laceration",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Scalp laceration ": "S01.0",
        "Head injury ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Skull fracture ": "S02.0XXA",
        "Scalp hematoma ": "S00.0",
      },
    },
    1192: {
      "Chief Complaint": "Scalp Laceration",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Scalp laceration ": "S01.0",
        "Head injury ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Skull fracture ": "S02.0XXA",
        "Scalp hematoma ": "S00.0",
      },
    },
    1193: {
      "Chief Complaint": "Scalp Laceration",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Scalp laceration ": "S01.0",
        "Head injury ": "S09.90XA",
        "Concussion ": "S06.0X0A",
        "Skull fracture ": "S02.0XXA",
        "Scalp hematoma ": "S00.0",
      },
    },
    1194: {
      "Chief Complaint": "Positive Blood Culture",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Bacterial endocarditis ": "I33.0",
        "Osteomyelitis ": "M86.9",
        "Urinary tract infection ": "N39.0",
        "Central line-associated bloodstream infection ": "T80.211A",
      },
    },
    1195: {
      "Chief Complaint": "Positive Blood Culture",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Bacterial endocarditis ": "I33.0",
        "Osteomyelitis ": "M86.9",
        "Pneumonia ": "J18.9",
        "Intra-abdominal abscess ": "K65.0",
      },
    },
    1196: {
      "Chief Complaint": "Positive Blood Culture",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sepsis ": "A41.9",
        "Bacterial endocarditis ": "I33.0",
        "Pneumonia ": "J18.9",
        "Urinary tract infection ": "N39.0",
        "Skin and soft tissue infection ": "L08.9",
      },
    },
    1197: {
      "Chief Complaint": "Impacted Cerumen Ear",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Impacted cerumen ": "H61.20",
        "Otitis externa ": "H60.9",
        "Otitis media ": "H66.90",
        "Foreign body in ear ": "T16.1",
        "Eustachian tube dysfunction ": "H68.10",
      },
    },
    1198: {
      "Chief Complaint": "Impacted Cerumen Ear",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Impacted cerumen ": "H61.20",
        "Otitis externa ": "H60.9",
        "Tinnitus ": "H93.19",
        "Hearing loss ": "H91.90",
        "Eustachian tube dysfunction ": "H68.10",
      },
    },
    1199: {
      "Chief Complaint": "Impacted Cerumen Ear",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Impacted cerumen ": "H61.20",
        "Hearing loss ": "H91.90",
        "Otitis externa ": "H60.9",
        "Tinnitus ": "H93.19",
        "Age-related auditory decline ": "H91.1X",
      },
    },
    1200: {
      "Chief Complaint": "Laceration",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Laceration ": "S01.0",
        "Wound infection ": "T81.4XXA",
        "Hemorrhage ": "R58",
        "Tetanus risk ": "A35",
        "Foreign body ": "T15.90",
      },
    },
    1201: {
      "Chief Complaint": "Prescription Refill",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Prescription Refill ": "Z76.0",
        "Chronic condition management ": "Z76.0",
        "Medication routine follow-up ": "Z76.0",
        "Long-term drug therapy ": "Z79.899",
        "Preventive medication therapy ": "Z79.899",
      },
    },
    1202: {
      "Chief Complaint": "Medication Refill",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Prescription Refill ": "Z76.0",
        "Chronic condition management ": "Z76.0",
        "Medication routine follow-up ": "Z76.0",
        "Long-term drug therapy ": "Z79.899",
        "Preventive medication therapy ": "Z79.899",
      },
    },
    1203: {
      "Chief Complaint": "Skin Lesion",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Skin lesion, unspecified ": "L98.9",
        "Basal cell carcinoma ": "C44.91",
        "Melanoma ": "C43.9",
        "Psoriasis ": "L40.9",
        "Eczema ": "L30.9",
        "Fungal infection ": "B35.9",
        "Actinic keratosis ": "L57.0",
      },
    },
    1204: {
      "Chief Complaint": "Febrile Seizure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Febrile seizure ": "R56.00",
        "Viral infection ": "B34.9",
        "Bacterial infection ": "A49.9",
        "Meningitis ": "G03.9",
        "Electrolyte imbalance ": "E87.8",
        "Heat exhaustion ": "T67.4",
        "Central nervous system infection ": "G07",
      },
    },
    1205: {
      "Chief Complaint": "Post-Op Pain",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Postoperative pain ": "G89.18",
        "Surgical site infection ": "T81.4XX",
        "Hematoma at surgical site ": "T81.3XXA",
        "Deep vein thrombosis ": "I82.401",
        "Pulmonary embolism ": "I26.99",
      },
    },
    1206: {
      "Chief Complaint": "Motor Vehicle Crash (MVC)",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Motor Vehicle Crash ": "V89.2",
        "Traumatic brain injury ": "S06.9X9A",
        "Whiplash injury ": "S13.4XXA",
        "Fracture, unspecified ": "S22.9XXA",
        "Internal organ injury ": "S36.9XXA",
        "Post-traumatic stress disorder ": "F43.10",
        "Spinal cord injury ": "S14.109A",
        "Contusion ": "S00.93XA",
      },
    },
    1207: {
      "Chief Complaint": "Motor Vehicle accident (MVA)",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Motor Vehicle Crash ": "V89.2",
        "Traumatic brain injury ": "S06.9X9A",
        "Whiplash injury ": "S13.4XXA",
        "Fracture, unspecified ": "S22.9XXA",
        "Internal organ injury ": "S36.9XXA",
        "Post-traumatic stress disorder ": "F43.10",
        "Spinal cord injury ": "S14.109A",
        "Contusion ": "S00.93XA",
      },
    },
    1208: {
      "Chief Complaint": "Mouth Sores",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Mouth Sores ": "K12.0",
        "Canker sores ": "K12.0",
        "Herpes simplex ": "B00.2",
        "Oral candidiasis ": "B37.0",
        "Hand, foot, and mouth disease ": "B08.4",
        "Beh\u00e7et's disease ": "M35.2",
      },
    },
    1209: {
      "Chief Complaint": "Vaginal Bleeding in Pregnancy",
      Age: "All Ages",
      Gender: "Female",
      DDx: {
        "Vaginal bleeding in pregnancy ": "O20.9",
        "Ectopic pregnancy ": "O00.9",
        "Placenta previa ": "O44.00",
        "Placental abruption ": "O45.9",
        "Miscarriage ": "O03.9",
        "Cervical insufficiency ": "N88.3",
      },
    },
    1210: {
      "Chief Complaint": "Decreased Fetal Movement",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Decreased fetal movement ": "O36.8130",
        "Fetal growth restriction ": "O36.5930",
        "Antepartum stillbirth ": "P95",
        "Oligohydramnios ": "O41.0X00",
        "Umbilical cord complications ": "O69.89X0",
        "Maternal dehydration ": "O26.82",
      },
    },
    1211: {
      "Chief Complaint": "Cellulitis",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Cellulitis ": "L03.90",
        "Abscess ": "L02.91",
        "Erysipelas ": "A46",
        "Deep vein thrombosis ": "I82.401",
        "Allergic reaction ": "T78.40XA",
        "Venous stasis dermatitis ": "I83.90",
      },
    },
    1212: {
      "Chief Complaint": "Accidental Overdose",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Accidental Overdose ": "T50.901A",
        "Prescription medication overdose ": "T50.901A",
        "Over-the-counter medication overdose ": "T50.901A",
        "Illicit drug overdose ": "T40.9X1A",
        "Alcohol poisoning ": "T51.0X1A",
        "Carbon monoxide poisoning ": "T58.01XA",
        "Benzodiazepine overdose ": "T42.4X1A",
        "Opioid overdose ": "T40.2X1A",
        "Acetaminophen overdose ": "T39.1X1A",
        "Antidepressant overdose ": "T43.0X1A",
        "Stimulant overdose ": "T43.6X1A",
        "Antipsychotic overdose ": "T43.1X1A",
        "Sedative overdose ": "T42.3X1A",
        "Recreational drug overdose ": "T40.9X1A",
        "Plant or mushroom poisoning ": "T62.0X1A",
      },
    },
    1213: {
      "Chief Complaint": "Hypercapnia",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Hypercapnia ": "R06.83",
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Obstructive sleep apnea ": "G47.33",
        "Respiratory failure ": "J96.9",
        "Neuromuscular disorders ": "G70.9",
        "Pulmonary edema ": "J81.1",
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Chest wall deformities ": "Q67.6",
        "Obesity hypoventilation syndrome ": "E66.2",
        "Interstitial lung disease ": "J84.9",
        "Pulmonary embolism ": "I26.99",
        "Drug overdose ": "T50.901A",
        "Sedative use ": "F13.20",
        "Brainstem disorders ": "G93.8",
      },
    },
    1214: {
      "Chief Complaint": "AICD Discharge",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "AICD Discharge ": "T82.191A",
        "Cardiac arrhythmia ": "I49.9",
        "Ventricular tachycardia ": "I47.2",
        "Ventricular fibrillation ": "I49.01",
        "Heart failure ": "I50.9",
        "Myocardial infarction ": "I21.9",
        "Electrolyte imbalance ": "E87.8",
        "Device malfunction ": "T82.898A",
        "Lead displacement ": "T82.218A",
        "Infection at device site ": "T82.7XXA",
      },
    },
    1215: {
      "Chief Complaint": "Benadryl Overdose",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Benadryl Overdose ": "T45.0X1A",
        "Anticholinergic effects ": "T45.0X1A",
        "Central nervous system depression ": "R40.20",
        "Respiratory depression ": "R06.81",
        "Tachycardia ": "R00.0",
      },
    },
    1216: {
      "Chief Complaint": "Benadryl Overdose",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Benadryl Overdose ": "T45.0X1A",
        "Anticholinergic effects ": "T45.0X1A",
        "Central nervous system depression ": "R40.20",
        "Respiratory depression ": "R06.81",
        "Tachycardia ": "R00.0",
      },
    },
    1217: {
      "Chief Complaint": "Benadryl Overdose",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Benadryl Overdose ": "T45.0X1A",
        "Anticholinergic effects ": "T45.0X1A",
        "Central nervous system depression ": "R40.20",
        "Respiratory depression ": "R06.81",
        "Tachycardia ": "R00.0",
      },
    },
    1218: {
      "Chief Complaint": "Benadryl Overdose",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Benadryl Overdose ": "T45.0X1A",
        "Anticholinergic effects ": "T45.0X1A",
        "Central nervous system depression ": "R40.20",
        "Respiratory depression ": "R06.81",
        "Tachycardia ": "R00.0",
      },
    },
    1219: {
      "Chief Complaint": "Febrile Seizure",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Febrile Seizure ": "R56.00",
        "Viral infection ": "B34.9",
        "Bacterial infection ": "A49.9",
        "Meningitis ": "G03.9",
        "Electrolyte imbalance ": "E87.8",
        "Roseola ": "B05.9",
        "Otitis media ": "H66.90",
        "Urinary tract infection ": "N39.0",
        "Pneumonia ": "J18.9",
        "Influenza ": "J09.X2",
      },
    },
    1220: {
      "Chief Complaint": "Oil of Wintergreen Overdose",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Oil of Wintergreen Overdose ": "T39.0X1A",
        "Salicylate toxicity ": "T39.0X1A",
        "Reye's syndrome ": "G93.7",
        "Metabolic acidosis ": "E87.2",
        "Respiratory alkalosis ": "E87.1",
        "Gastrointestinal irritation ": "K52.9",
        "Renal failure ": "N17.9",
        "Tinnitus ": "H93.19",
        "Altered mental status ": "R41.82",
        "Hypoglycemia ": "E16.2",
      },
    },
    1221: {
      "Chief Complaint": "Dry Mouth",
      Age: "All Ages",
      Gender: "Both",
      DDx: {
        "Dry mouth ": "R68.2",
        "Dehydration ": "E86.0",
        "Sj\u00f6gren's syndrome ": "M35.0",
        "Medication side effect ": "Y57.9",
        "Diabetes mellitus ": "E10.9",
        "Oral candidiasis ": "B37.0",
        "Anxiety ": "F41.9",
        "Hypothyroidism ": "E03.9",
        "Radiation therapy ": "Y84.2",
        "Autoimmune diseases ": "M35.9",
      },
    },
    1222: {
      "Chief Complaint": "Nausea, Vomiting, Diarrhea",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Nausea, vomiting and Diarrhea ": "R11.2, R19.7",
        "Gastroenteritis ": "K52.9",
        "Milk allergy ": "L27.2",
        "Infections ": "R78.81",
        "sepsis) ": "A41.9",
        "Metabolic disorders ": "E70-E90",
        "Intestinal obstruction ": "K56.60",
        "Congenital anomalies ": "Q89.9",
        "Electrolyte imbalance ": "E87.8",
        "Lactose intolerance ": "E73.9",
        "Rotavirus infection ": "A08.0",
      },
    },
    1223: {
      "Chief Complaint": "Nausea, Vomiting, Diarrhea",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Nausea, vomiting and Diarrhea ": "R11.2, R19.7",
        "Gastroenteritis ": "K52.9",
        "Rotavirus infection ": "A08.0",
        "Lactose intolerance ": "E73.9",
        "Food allergy ": "T78.1",
        "Intestinal obstruction ": "K56.60",
        "Urinary tract infection ": "N39.0",
        "Pyloric stenosis ": "K31.0",
        "Cystic fibrosis ": "E84.9",
        "Infections ": "R78.81",
        "meningitis) ": "G03.9",
      },
    },
    1224: {
      "Chief Complaint": "Nausea, Vomiting, Diarrhea",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Nausea, vomiting and Diarrhea ": "R11.2, R19.7",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Appendicitis ": "K35.80",
        "Viral infections ": "B34.9",
        "Lactose intolerance ": "E73.9",
        "Parasitic infections ": "B82.0",
        "Inflammatory bowel disease ": "K50.9",
        "Celiac disease ": "K90.0",
        "Urinary tract infection ": "N39.0",
      },
    },
    1225: {
      "Chief Complaint": "Nausea, Vomiting, Diarrhea",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Nausea, vomiting and Diarrhea ": "R11.2, R19.7",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Viral infections ": "B34.9",
        "Peptic ulcer ": "K27.9",
        "Pancreatitis ": "K85.9",
        "Gallstones ": "K80.20",
        "Medication side effect ": "T88.7",
        "Alcohol intoxication ": "F10.92",
        "Inflammatory bowel disease ": "K50.9",
      },
    },
    1226: {
      "Chief Complaint": "Nausea, Vomiting, Diarrhea",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Nausea, vomiting and Diarrhea ": "R11.2, R19.7",
        "Pregnancy ": "O21.0",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Viral infections ": "B34.9",
        "Urinary tract infection ": "N39.0",
        "Medication side effect ": "T88.7",
        "Gallstones ": "K80.20",
        "Inflammatory bowel disease ": "K50.9",
        "Irritable bowel syndrome ": "K58.9",
      },
    },
    1227: {
      "Chief Complaint": "Nausea, Vomiting, Diarrhea",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Nausea, vomiting and Diarrhea ": "R11.2, R19.7",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Medication side effect ": "T88.7",
        "Chronic kidney disease ": "N18.9",
        "Gallstones ": "K80.20",
        "Diverticulitis ": "K57.90",
        "Inflammatory bowel disease ": "K50.9",
        "Ischemic bowel disease ": "K55.9",
      },
    },
    1228: {
      "Chief Complaint": "Generalized Weakness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Viral infection ": "B34.9",
        "Asthma ": "J45.909",
        "Malnutrition ": "E46",
        "Muscular dystrophy ": "G71.0",
        "Leukemia ": "C91.00",
        "Hypothyroidism ": "E03.9",
        "Diabetes mellitus ": "E10.9",
        "Chronic fatigue syndrome ": "R53.82",
      },
    },
    1229: {
      "Chief Complaint": "Generalized Weakness",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Anemia ": "D64.9",
        "Diabetes mellitus ": "E11.9",
        "Hypothyroidism ": "E03.9",
        "Chronic kidney disease ": "N18.9",
        "Depression ": "F32.9",
        "Heart failure ": "I50.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Electrolyte imbalance ": "E87.8",
        "Sleep apnea ": "G47.33",
        "Liver disease ": "K76.9",
      },
    },
    1230: {
      "Chief Complaint": "Generalized Weakness",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Diabetes mellitus ": "E11.9",
        "Chronic kidney disease ": "N18.9",
        "Depression ": "F32.9",
        "Multiple sclerosis ": "G35",
        "Fibromyalgia ": "M79.7",
        "Pregnancy ": "O26.89",
        "Rheumatoid arthritis ": "M06.9",
        "Chronic fatigue syndrome ": "R53.82",
      },
    },
    1231: {
      "Chief Complaint": "Generalized Weakness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Chronic kidney disease ": "N18.9",
        "Heart failure ": "I50.9",
        "Anemia ": "D64.9",
        "Hypothyroidism ": "E03.9",
        "Diabetes mellitus ": "E11.9",
        "Parkinson's disease ": "G20",
        "Stroke ": "I63.9",
        "Chronic obstructive pulmonary disease ": "J44.9",
        "Cancer ": "C80.1",
        "Dementia ": "F03.90",
      },
    },
    1232: {
      "Chief Complaint": "Dyspnea",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Dyspnea ": "R06.02",
        "Respiratory distress syndrome ": "P22.0",
        "Congenital heart disease ": "Q24.9",
        "Pneumonia ": "J18.9",
        "Bronchopulmonary dysplasia ": "P27.1",
        "Tracheoesophageal fistula ": "Q39.1",
        "Cystic fibrosis ": "E84.9",
        "Aspiration ": "P24.9",
        "Airway malformation ": "Q31.8",
        "Anemia ": "P61.2",
      },
    },
    1233: {
      "Chief Complaint": "Dyspnea",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Dyspnea ": "R06.02",
        "Bronchiolitis ": "J21.9",
        "Pneumonia ": "J18.9",
        "Asthma ": "J45.909",
        "Croup ": "J05.0",
        "Congenital heart disease ": "Q24.9",
        "Cystic fibrosis ": "E84.9",
        "Gastroesophageal reflux ": "K21.9",
        "Foreign body aspiration ": "T17.9",
        "Anemia ": "D64.9",
      },
    },
    1234: {
      "Chief Complaint": "Dyspnea",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dyspnea ": "R06.02",
        "Asthma ": "J45.909",
        "Pneumonia ": "J18.9",
        "Foreign body aspiration ": "T17.9",
        "Allergic reaction ": "T78.40XA",
        "Croup ": "J05.0",
        "Anemia ": "D64.9",
        "Congenital heart disease ": "Q24.9",
        "Gastroesophageal reflux ": "K21.9",
        "Upper respiratory infection ": "J06.9",
      },
    },
    1235: {
      "Chief Complaint": "Dyspnea",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Dyspnea ": "R06.02",
        "Chronic obstructive pulmonary disease (COPD) ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Asthma ": "J45.909",
        "Heart failure ": "I50.9",
        "Anxiety ": "F41.9",
        "Gastroesophageal reflux disease (GERD) ": "K21.9",
        "Anemia ": "D64.9",
        "Tuberculosis ": "A15.9",
      },
    },
    1236: {
      "Chief Complaint": "Dyspnea",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dyspnea ": "R06.02",
        "Asthma ": "J45.909",
        "Pulmonary embolism ": "I26.99",
        "Pneumonia ": "J18.9",
        "Heart failure ": "I50.9",
        "COPD ": "J44.9",
        "Anxiety ": "F41.9",
        "GERD ": "K21.9",
        "Anemia ": "D64.9",
        "Pregnancy-related changes ": "O99.89",
      },
    },
    1237: {
      "Chief Complaint": "Dyspnea",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dyspnea ": "R06.02",
        "Heart failure ": "I50.9",
        "COPD ": "J44.9",
        "Pneumonia ": "J18.9",
        "Pulmonary embolism ": "I26.99",
        "Chronic kidney disease ": "N18.9",
        "Anemia ": "D64.9",
        "Lung cancer ": "C34.90",
        "GERD ": "K21.9",
        "Cardiac ischemia ": "I20.9",
      },
    },
    1238: {
      "Chief Complaint": "Skin Rash",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Diaper rash ": "L22",
        "Erythema toxicum ": "L53.9",
        "Neonatal acne ": "L70.4",
        "Birthmarks ": "Q82.5",
        "Congenital ichthyosis ": "Q80.9",
        "Seborrheic dermatitis ": "L21.0",
        "Candidiasis ": "B37.9",
        "Infections ": "R78.81",
        "herpes) ": "B00.9",
        "Allergic reaction ": "T78.40XA",
      },
    },
    1239: {
      "Chief Complaint": "Skin Rash",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Atopic dermatitis ": "L20.9",
        "Diaper rash ": "L22",
        "Viral exanthems ": "B09",
        "Seborrheic dermatitis ": "L21.0",
        "Impetigo ": "L01.0",
        "Scabies ": "B86",
        "Candidiasis ": "B37.9",
        "Allergic reaction ": "T78.40XA",
        "Eczema ": "L30.9",
      },
    },
    1240: {
      "Chief Complaint": "Skin Rash",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Atopic dermatitis ": "L20.9",
        "Impetigo ": "L01.0",
        "Urticaria ": "L50.9",
        "Scabies ": "B86",
        "Viral exanthems ": "B09",
        "Allergic reaction ": "T78.40XA",
        "Eczema ": "L30.9",
        "Psoriasis ": "L40.9",
        "Insect bites ": "S00.83XA",
      },
    },
    1241: {
      "Chief Complaint": "Skin Rash",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Skin Rash ": "R21",
        "Contact dermatitis ": "L23.9",
        "Psoriasis ": "L40.9",
        "Eczema ": "L30.9",
        "Drug reaction ": "L27.0",
        "Urticaria ": "L50.9",
        "Fungal infections ": "B35.9",
        "Scabies ": "B86",
        "Seborrheic dermatitis ": "L21.9",
        "Lyme disease ": "A69.2",
      },
    },
    1242: {
      "Chief Complaint": "Skin Rash",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Skin Rash ": "R21",
        "Contact dermatitis ": "L23.9",
        "Eczema ": "L30.9",
        "Psoriasis ": "L40.9",
        "Drug reaction ": "L27.0",
        "Urticaria ": "L50.9",
        "Autoimmune disorders ": "D89. 9",
        "lupus ": "M32.9",
        "Scabies ": "B86",
        "Fungal infections ": "B35.9",
        "Pregnancy-related rash ": "O99.7",
      },
    },
    1243: {
      "Chief Complaint": "Skin Rash",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Skin Rash ": "R21",
        "Drug reaction ": "L27.0",
        "Psoriasis ": "L40.9",
        "Eczema ": "L30.9",
        "Seborrheic dermatitis ": "L21.9",
        "Urticaria ": "L50.9",
        "Fungal infections ": "B35.9",
        "Actinic keratosis ": "L57.0",
        "Stasis dermatitis ": "I83.1",
        "Autoimmune disorders ": "M32.9",
      },
    },
    1244: {
      "Chief Complaint": "Vomiting",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Vomiting ": "R11.10",
        "Gastroesophageal reflux ": "K21.9",
        "Pyloric stenosis ": "K31.0",
        "Infections ": "R78.81",
        "Sepsis ": "A41.9",
        "Metabolic disorders ": "E70-E90",
        "Intestinal obstruction ": "K56.60",
        "Milk allergy ": "L27.2",
        "Congenital anomalies ": "Q89.9",
        "Electrolyte imbalance ": "E87.8",
        "Intracranial pressure ": "G93.6",
      },
    },
    1245: {
      "Chief Complaint": "Vomiting",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Vomiting ": "R11.10",
        "Gastroesophageal reflux ": "K21.9",
        "Gastroenteritis ": "K52.9",
        "Pyloric stenosis ": "K31.0",
        "Intestinal obstruction ": "K56.60",
        "Milk allergy ": "L27.2",
        "Urinary tract infection ": "N39.0",
        "Infections ": "R78.81",
        "meningitis) ": "G03.9",
        "Metabolic disorders ": "E70-E90",
        "Intussusception ": "K56.1",
      },
    },
    1246: {
      "Chief Complaint": "Vomiting",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Vomiting ": "R11.10",
        "Gastroenteritis ": "K52.9",
        "Appendicitis ": "K35.80",
        "Food poisoning ": "A05.9",
        "Migraine ": "G43.909",
        "Urinary tract infection ": "N39.0",
        "Gastroesophageal reflux ": "K21.9",
        "Intestinal obstruction ": "K56.60",
        "Motion sickness ": "T75.3",
        "Brain tumor ": "C71.9",
      },
    },
    1247: {
      "Chief Complaint": "Vomiting",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Vomiting ": "R11.10",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Peptic ulcer ": "K27.9",
        "Pancreatitis ": "K85.9",
        "Gallstones ": "K80.20",
        "Alcohol intoxication ": "F10.92",
        "Appendicitis ": "K35.80",
        "Medication side effect ": "T88.7",
        "Gastroesophageal reflux ": "K21.9",
      },
    },
    1248: {
      "Chief Complaint": "Vomiting",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Vomiting ": "R11.10",
        "Pregnancy ": "O21.0",
        "Gastroenteritis ": "K52.9",
        "Food poisoning ": "A05.9",
        "Gallstones ": "K80.20",
        "Urinary tract infection ": "N39.0",
        "Migraine ": "G43.909",
        "Peptic ulcer ": "K27.9",
        "Pancreatitis ": "K85.9",
        "Gastroesophageal reflux ": "K21.9",
      },
    },
    1249: {
      "Chief Complaint": "Vomiting",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Vomiting ": "R11.10",
        "Gastroenteritis ": "K52.9",
        "Peptic ulcer ": "K27.9",
        "Gallstones ": "K80.20",
        "Gastroesophageal reflux ": "K21.9",
        "Pancreatitis ": "K85.9",
        "Intestinal obstruction ": "K56.60",
        "Medication side effect ": "T88.7",
        "Chronic kidney disease ": "N18.9",
        "Heart failure ": "I50.9",
      },
    },
    1250: {
      "Chief Complaint": "URINARY TRACT INFECTION (UTI)",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "UTI ": "N39.0",
        "Congenital anomalies of the urinary system ": "Q60.9",
        "Vesicoureteral reflux ": "N13.70",
        "Neonatal sepsis ": "P36.9",
        "Dehydration ": "E86.0",
        "Metabolic disorders ": "E70-E90",
        "Hydronephrosis ": "Q62.0",
        "Cystitis ": "N30.00",
        "Pyelonephritis ": "N10",
        "Urethritis ": "N34.1",
      },
    },
    1251: {
      "Chief Complaint": "URINARY TRACT INFECTION (UTI)",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "UTI ": "N39.0",
        "Vesicoureteral reflux ": "N13.70",
        "Hydronephrosis ": "Q62.0",
        "Congenital anomalies of the urinary system ": "Q60.9",
        "Cystitis ": "N30.00",
        "Pyelonephritis ": "N10",
        "Diaper rash ": "L22",
        "Dehydration ": "E86.0",
        "Urethritis ": "N34.1",
        "Balanitis ": "N48.1",
      },
    },
    1252: {
      "Chief Complaint": "URINARY TRACT INFECTION (UTI)",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "UTI ": "N39.0",
        "Vesicoureteral reflux ": "N13.70",
        "Cystitis ": "N30.00",
        "Pyelonephritis ": "N10",
        "Constipation ": "K59.00",
        "Urethritis ": "N34.1",
        "Hydronephrosis ": "Q62.0",
        "Kidney stones ": "N20.0",
        "Bladder stones ": "N21.0",
        "Diabetes mellitus ": "E10.9",
      },
    },
    1253: {
      "Chief Complaint": "URINARY TRACT INFECTION (UTI)",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "UTI ": "N39.0",
        "Prostatitis ": "N41.9",
        "Urethritis ": "N34.1",
        "Kidney stones ": "N20.0",
        "Bladder cancer ": "C67.9",
        "Benign prostatic hyperplasia ": "N40.1",
        "Sexually transmitted infections ": "A64",
        "Diabetes mellitus ": "E11.9",
        "Interstitial cystitis ": "N30.10",
        "Bladder stones ": "N21.0",
      },
    },
    1254: {
      "Chief Complaint": "URINARY TRACT INFECTION (UTI)",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "UTI ": "N39.0",
        "Cystitis ": "N30.00",
        "Pyelonephritis ": "N10",
        "Pregnancy ": "O23.5",
        "Urethritis ": "N34.1",
        "Kidney stones ": "N20.0",
        "Sexually transmitted infections ": "A64",
        "Diabetes mellitus ": "E11.9",
        "Interstitial cystitis ": "N30.10",
        "Vaginitis ": "N76.0",
      },
    },
    1255: {
      "Chief Complaint": "URINARY TRACT INFECTION (UTI)",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "UTI ": "N39.0",
        "Pyelonephritis ": "N10",
        "Cystitis ": "N30.00",
        "Bladder cancer ": "C67.9",
        "Kidney stones ": "N20.0",
        "Prostatitis ": "N41.9",
        "Diabetes mellitus ": "E11.9",
        "Benign prostatic hyperplasia ": "N40.1",
        "Urethral stricture ": "N35.9",
        "Chronic kidney disease ": "N18.9",
      },
    },
    1256: {
      "Chief Complaint": "Confusion",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Hypoxic-ischemic encephalopathy ": "P91.60",
        "Neonatal sepsis ": "P36.9",
        "Metabolic disorders ": "E70-E90",
        "Intracranial hemorrhage ": "P52.9",
        "Congenital heart disease ": "Q24.9",
        "Electrolyte imbalances ": "E87.8",
        "Hypoglycemia ": "P70.4",
        "Inborn errors of metabolism ": "E88.9",
        "Birth trauma ": "P15.9",
      },
    },
    1257: {
      "Chief Complaint": "Confusion",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Meningitis ": "G03.9",
        "Dehydration ": "E86.0",
        "Electrolyte imbalances ": "E87.8",
        "Febrile seizure ": "R56.00",
        "Intussusception ": "K56.1",
        "Poisoning ": "T39.0X1A",
        "Hypoglycemia ": "E16.2",
        "Inborn errors of metabolism ": "E88.9",
        "Head injury ": "S09.90XA",
      },
    },
    1258: {
      "Chief Complaint": "Confusion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Meningitis ": "G03.9",
        "Head injury ": "S09.90XA",
        "Seizure disorders ": "G40.909",
        "Poisoning ": "T39.0X1A",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Electrolyte imbalances ": "E87.8",
        "Infections ": "R78.81",
        "encephalitis) ": "G05.1",
        "Diabetic ketoacidosis ": "E10.10",
      },
    },
    1259: {
      "Chief Complaint": "Confusion",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Alcohol intoxication ": "F10.92",
        "Drug overdose ": "T50.901A",
        "Stroke ": "I63.9",
        "Head trauma ": "S09.90XA",
        "Hypoglycemia ": "E16.2",
        "Seizure disorders ": "G40.909",
        "Electrolyte imbalances ": "E87.8",
        "Psychiatric disorders ": "F29",
        "Carbon monoxide poisoning ": "T58.01XA",
        "Urinary tract infection ": "N39.0",
      },
    },
    1260: {
      "Chief Complaint": "Confusion",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Urinary tract infection ": "N39.0",
        "Stroke ": "I63.9",
        "Hypoglycemia ": "E16.2",
        "Drug overdose ": "T50.901A",
        "Alcohol intoxication ": "F10.92",
        "Seizure disorders ": "G40.909",
        "Electrolyte imbalances ": "E87.8",
        "Migraine ": "G43.909",
        "Psychiatric disorders ": "F29",
      },
    },
    1261: {
      "Chief Complaint": "Confusion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Altered Mental Status ": "R41.82",
        "Stroke ": "I63.9",
        "Dementia ": "F03.90",
        "Urinary tract infection ": "N39.0",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Electrolyte imbalances ": "E87.8",
        "Medication side effects ": "T88.7",
        "Heart failure ": "I50.9",
        "Chronic kidney disease ": "N18.9",
        "Psychiatric disorders ": "F29",
      },
    },
    1262: {
      "Chief Complaint": "Dizziness",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Congenital heart disease ": "Q24.9",
        "Anemia ": "P61.2",
        "Dehydration ": "E86.0",
        "Metabolic disorders ": "E70-E90",
        "Infections ": "R78.81",
        "meningitis) ": "G03.9",
        "Electrolyte imbalances ": "E87.8",
        "Intracranial hemorrhage ": "P52.9",
        "Birth trauma ": "P15.9",
        "Hypoglycemia ": "P70.4",
      },
    },
    1263: {
      "Chief Complaint": "Dizziness",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Ear infections ": "H66.90",
        "Congenital heart disease ": "Q24.9",
        "Metabolic disorders ": "E70-E90",
        "Electrolyte imbalances ": "E87.8",
        "Gastroenteritis ": "K52.9",
        "Meningitis ": "G03.9",
        "Head injury ": "S09.90XA",
      },
    },
    1264: {
      "Chief Complaint": "Dizziness",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Migraine ": "G43.909",
        "Ear infections ": "H66.90",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Epilepsy ": "G40.909",
        "Hypoglycemia ": "E16.2",
        "Orthostatic hypotension ": "I95.1",
        "Head injury ": "S09.90XA",
      },
    },
    1265: {
      "Chief Complaint": "Dizziness",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Dizziness ": "R42",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Vestibular neuritis ": "H81.2",
        "Meniere's disease ": "H81.0",
        "Hypotension ": "I95.9",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Migraine ": "G43.909",
        "Cardiac arrhythmias ": "I49.9",
      },
    },
    1266: {
      "Chief Complaint": "Dizziness",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dizziness ": "R42",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Vestibular neuritis ": "H81.2",
        "Meniere's disease ": "H81.0",
        "Migraine ": "G43.909",
        "Pregnancy ": "O26.89",
        "Anemia ": "D64.9",
        "Hypoglycemia ": "E16.2",
        "Hypotension ": "I95.9",
        "Anxiety ": "F41.9",
      },
    },
    1267: {
      "Chief Complaint": "Dizziness",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Vestibular neuritis ": "H81.2",
        "Meniere's disease ": "H81.0",
        "Cerebrovascular disease ": "I67.9",
        "Cardiac arrhythmias ": "I49.9",
        "Hypotension ": "I95.9",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Diabetes mellitus ": "E11.9",
      },
    },
    1268: {
      "Chief Complaint": "Dizzy",
      Age: "Newborn",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Congenital heart disease ": "Q24.9",
        "Anemia ": "P61.2",
        "Dehydration ": "E86.0",
        "Metabolic disorders ": "E70-E90",
        "Infections ": "R78.81",
        "meningitis) ": "G03.9",
        "Electrolyte imbalances ": "E87.8",
        "Intracranial hemorrhage ": "P52.9",
        "Birth trauma ": "P15.9",
        "Hypoglycemia ": "P70.4",
      },
    },
    1269: {
      "Chief Complaint": "Dizzy",
      Age: "Infant",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Ear infections ": "H66.90",
        "Congenital heart disease ": "Q24.9",
        "Metabolic disorders ": "E70-E90",
        "Electrolyte imbalances ": "E87.8",
        "Gastroenteritis ": "K52.9",
        "Meningitis ": "G03.9",
        "Head injury ": "S09.90XA",
      },
    },
    1270: {
      "Chief Complaint": "Dizzy",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Anemia ": "D64.9",
        "Dehydration ": "E86.0",
        "Migraine ": "G43.909",
        "Ear infections ": "H66.90",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Epilepsy ": "G40.909",
        "Hypoglycemia ": "E16.2",
        "Orthostatic hypotension ": "I95.1",
        "Head injury ": "S09.90XA",
      },
    },
    1271: {
      "Chief Complaint": "Dizzy",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Dizziness ": "R42",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Vestibular neuritis ": "H81.2",
        "Meniere's disease ": "H81.0",
        "Hypotension ": "I95.9",
        "Hypoglycemia ": "E16.2",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Migraine ": "G43.909",
        "Cardiac arrhythmias ": "I49.9",
      },
    },
    1272: {
      "Chief Complaint": "Dizzy",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Dizziness ": "R42",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Vestibular neuritis ": "H81.2",
        "Meniere's disease ": "H81.0",
        "Migraine ": "G43.909",
        "Pregnancy ": "O26.89",
        "Anemia ": "D64.9",
        "Hypoglycemia ": "E16.2",
        "Hypotension ": "I95.9",
        "Anxiety ": "F41.9",
      },
    },
    1273: {
      "Chief Complaint": "Dizzy",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Dizziness ": "R42",
        "Benign paroxysmal positional vertigo (BPPV) ": "H81.1",
        "Vestibular neuritis ": "H81.2",
        "Meniere's disease ": "H81.0",
        "Cerebrovascular disease ": "I67.9",
        "Cardiac arrhythmias ": "I49.9",
        "Hypotension ": "I95.9",
        "Dehydration ": "E86.0",
        "Anemia ": "D64.9",
        "Diabetes mellitus ": "E11.9",
      },
    },
    1274: {
      "Chief Complaint": "Buttock Irritation",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Contact Dermatitis ": "L25.9",
        "Fungal Infection (Tinea) ": "B35.9",
        "Irritant Dermatitis ": "L24.9",
        "Psoriasis ": "L40.9",
        "Pilonidal Cyst ": "L05.91",
        "Allergic Reaction ": "T78.40XA",
        "Friction or Chafing ": "L24. A9",
      },
    },
    1275: {
      "Chief Complaint": "Buttock Irritation",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Contact Dermatitis ": "L25.9",
        "Fungal Infection (Tinea) ": "B35.9",
        "Irritant Dermatitis ": "L24.9",
        "Psoriasis ": "L40.9",
        "Hemorrhoids ": "K64.9",
        "Allergic Reaction ": "T78.40XA",
        "Friction or Chafing ": "L24. A9",
      },
    },
    1276: {
      "Chief Complaint": "Buttock Irritation",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Contact Dermatitis ": "L25.9",
        "Fungal Infection (Tinea) ": "B35.9",
        "Irritant Dermatitis ": "L24.9",
        "Psoriasis ": "L40.9",
        "Allergic Reaction ": "T78.40XA",
        "Inflammatory Bowel Disease (IBD) ":
          "K50.9 - Crohn's Disease, K51.9 - Ulcerative Colitis",
        "Friction or Chafing ": "L24. A9",
      },
    },
    1277: {
      "Chief Complaint": "Buttock Irritation",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Contact Dermatitis ": "L25.9",
        "Fungal Infection (Tinea) ": "B35.9",
        "Irritant Dermatitis ": "L24.9",
        "Psoriasis ": "L40.9",
        "Hemorrhoids ": "K64.9",
        "Pilonidal Cyst ": "L05.91",
        "Allergic Reaction ": "T78.40XA",
        "Inflammatory Bowel Disease (IBD) ":
          "K50.9 - Crohn's Disease, K51.9 - Ulcerative Colitis",
      },
    },
    1278: {
      "Chief Complaint": "Slurred Speech",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Foreign Body Aspiration ": "T17.9",
        "Congenital Malformations (e.g., CP) ": "Q03.9",
        "Anoxic Brain Injury ": "T75.89",
        "Metabolic Disorders (e.g., hypoglycemia) ": "E16.2",
        "Febrile Seizure ": "R56.00",
        "Neurological Disorders (e.g., epilepsy) ": "G40.9",
        "Demyelinating Diseases (e.g., MS) ": "G35",
        "Guillain-Barr\u00e9 Syndrome ": "G61.0",
      },
    },
    1279: {
      "Chief Complaint": "Slurred Speech",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Stroke (Ischemic or Hemorrhagic) ": "I63.9",
        "Transient Ischemic Attack (TIA) ": "G45.9",
        "Brain Tumor ": "C71.9",
        "Drug or Alcohol Intoxication ": "F10.9",
        "Medication Side Effects ": "T88.7",
        "Neurological Disorders (e.g., MS) ": "G35",
        "Metabolic Disorders (e.g., diabetes) ": "E11.9",
        "Neuromuscular Disorders (e.g., ALS) ": "G12.21",
      },
    },
    1280: {
      "Chief Complaint": "Slurred Speech",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Stroke (Ischemic or Hemorrhagic) ": "I63.9",
        "Transient Ischemic Attack (TIA) ": "G45.9",
        "Brain Tumor ": "C71.9",
        "Drug or Alcohol Intoxication ": "F10.9",
        "Medication Side Effects ": "T88.7",
        "Neurological Disorders (e.g., MS) ": "G35",
        "Metabolic Disorders (e.g., diabetes) ": "E11.9",
        "Neuromuscular Disorders (e.g., ALS) ": "G12.21",
      },
    },
    1281: {
      "Chief Complaint": "Slurred Speech",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Stroke (Ischemic or Hemorrhagic) ": "I63.9",
        "Transient Ischemic Attack (TIA) ": "G45.9",
        "Brain Tumor ": "C71.9",
        "Drug or Alcohol Intoxication ": "F10.9",
        "Medication Side Effects ": "T88.7",
        "Neurological Disorders (e.g., MS) ": "G35",
        "Metabolic Disorders (e.g., diabetes) ": "E11.9",
        "Neuromuscular Disorders (e.g., ALS) ": "G12.21",
      },
    },
    1282: {
      "Chief Complaint": "Dysarthria",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Childhood Apraxia of Speech ": "R47.01",
        "Developmental Delay ": "F88",
        "Cerebral Palsy ": "G80.9",
        "Muscular Dystrophy ": "G71.9",
        "Brain Tumor ": "C71.9",
        "Stroke (uncommon in pediatrics) ": "I64.9",
        "Neuromuscular Disorders ": "G70.00",
        "Guillain-Barr\u00e9 Syndrome ": "G61.0",
      },
    },
    1283: {
      "Chief Complaint": "Dysarthria",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Stroke (Ischemic or Hemorrhagic) ": "I63.9",
        "Traumatic Brain Injury ": "S06.9",
        "Brain Tumor ": "C71.9",
        "ALS ": "G12.21",
        "Medication Side Effects ": "T88.7",
        "Parkinson's Disease ": "G20",
        "Multiple Sclerosis (MS) ": "G35",
        "Myasthenia Gravis ": "G70.00",
      },
    },
    1284: {
      "Chief Complaint": "Dysarthria",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Stroke (Ischemic or Hemorrhagic) ": "I63.9",
        "Traumatic Brain Injury ": "S06.9",
        "Brain Tumor ": "C71.9",
        "Medication Side Effects ": "T88.7",
        "ALS ": "G12.21",
        "Multiple Sclerosis (MS) ": "G35",
        "Parkinson's Disease ": "G20",
        "Myasthenia Gravis ": "G70.00",
      },
    },
    1285: {
      "Chief Complaint": "Dysarthria",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Stroke (Ischemic or Hemorrhagic) ": "I63.9",
        "Traumatic Brain Injury ": "S06.9",
        "Brain Tumor ": "C71.9",
        "Medication Side Effects ": "T88.7",
        "ALS ": "G12.21",
        "Parkinson's Disease ": "G20",
        "Multiple Sclerosis (MS) ": "G35",
        "Myasthenia Gravis ": "G70.00",
      },
    },
    1286: {
      "Chief Complaint": "Frequent urinary tract infections",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Voiding Dysfunction ": "N32.0",
        "Vesicoureteral Reflux ": "N13.70",
        "Constipation ": "K59.00",
        "Structural Anomalies ": "Q64.9",
        "Urinary Retention ": "R33.8",
        "Foreign Body in Urinary Tract ": "T19.9",
        "Diabetes ": "E10-E14",
        "Sexual Abuse ": "T76.19",
      },
    },
    1287: {
      "Chief Complaint": "Frequent urinary tract infections",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Benign Prostatic Hyperplasia (BPH) ": "N40.1",
        "Urinary Stones (Urolithiasis) ": "N20.0",
        "Prostatitis ": "N41.9",
        "Diabetes ": "E10-E14",
        "Voiding Dysfunction ": "N32.0",
        "Urinary Retention ": "R33.8",
        "Interstitial Cystitis ": "N30.10",
        "Neurogenic Bladder ": "N31.9",
      },
    },
    1288: {
      "Chief Complaint": "Frequent urinary tract infections",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary Stones (Urolithiasis) ": "N20.0",
        "Interstitial Cystitis ": "N30.10",
        "Pelvic Organ Prolapse ": "N81.2",
        "Voiding Dysfunction ": "N32.0",
        "Diabetes ": "E10-E14",
        "Atrophic Vaginitis ": "N95.2",
        "Vaginal Infections ": "N76.0",
        "Sexual Intercourse ": "Z72.51",
      },
    },
    1289: {
      "Chief Complaint": "Frequent urinary tract infections",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Urinary Stones (Urolithiasis) ": "N20.0",
        "Benign Prostatic Hyperplasia (BPH) ": "N40.1",
        "Voiding Dysfunction ": "N32.0",
        "Diabetes ": "E10-E14",
        "Neurogenic Bladder ": "N31.9",
        "Atrophic Vaginitis ": "N95.2",
        "Fecal Incontinence ": "K62.9",
        "Urinary Retention ": "R33.8",
      },
    },
    1290: {
      "Chief Complaint": "Dysuria",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Urinary Tract Infection (UTI) ": "N39.0",
        "Vesicoureteral Reflux ": "N13.70",
        "Urethritis ": "N34.1",
        "Constipation ": "K59.00",
        "Bladder Stones ": "N21.0",
        "Foreign Body in Urinary Tract ": "T19.9",
        "Vulvovaginitis ": "N76.0",
        "Sexual Abuse ": "T76.19",
      },
    },
    1291: {
      "Chief Complaint": "Dysuria",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Urinary Tract Infection (UTI) ": "N39.0",
        "Prostatitis ": "N41.9",
        "Urethritis ": "N34.1",
        "Urinary Stones (Urolithiasis) ": "N20.0",
        "Interstitial Cystitis ": "N30.10",
        "Gonorrhea ": "A54.9",
        "Chlamydia ": "A56.9",
        "Reactive Arthritis ": "M02.3",
      },
    },
    1292: {
      "Chief Complaint": "Dysuria",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Urinary Tract Infection (UTI) ": "N39.0",
        "Interstitial Cystitis ": "N30.10",
        "Vulvovaginitis ": "N76.0",
        "Urethritis ": "N34.1",
        "Atrophic Vaginitis ": "N95.2",
        "Vaginal Infections ": "N76.0",
        "Trichomoniasis ": "A59.00",
        "Chlamydia ": "A56.9",
      },
    },
    1293: {
      "Chief Complaint": "Dysuria",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Urinary Tract Infection (UTI) ": "N39.0",
        "Benign Prostatic Hyperplasia (BPH) ": "N40.1",
        "Interstitial Cystitis ": "N30.10",
        "Urethritis ": "N34.1",
        "Atrophic Vaginitis ": "N95.2",
        "Neurogenic Bladder ": "N31.9",
        "Fecal Incontinence ": "K62.9",
        "Urethral Stricture ": "N35.01",
      },
    },
    1294: {
      "Chief Complaint": "Foreign Body in Throat",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Foreign Body in Pharynx ": "T17.0",
        "Tonsillitis ": "J03.90",
        "Allergic Reaction ": "T78.40",
        "Gastroesophageal Reflux ": "K21.9",
        "Strep Throat ": "J02.9",
        "Retropharyngeal Abscess ": "J39.0",
        "Viral Pharyngitis ": "J02.9",
        "Infection of Tonsils ": "J35.01",
      },
    },
    1295: {
      "Chief Complaint": "Foreign Body in Throat",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Foreign Body in Pharynx ": "T17.0",
        "Gastroesophageal Reflux ": "K21.9",
        "Tonsillitis ": "J03.90",
        "Retropharyngeal Abscess ": "J39.0",
        "Allergic Reaction ": "T78.40",
        "Pharyngeal Cancer ": "C13.9",
        "Strep Throat ": "J02.9",
        "Viral Pharyngitis ": "J02.9",
      },
    },
    1296: {
      "Chief Complaint": "Foreign Body in Throat",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Foreign Body in Pharynx ": "T17.0",
        "Gastroesophageal Reflux ": "K21.9",
        "Tonsillitis ": "J03.90",
        "Retropharyngeal Abscess ": "J39.0",
        "Allergic Reaction ": "T78.40",
        "Pharyngeal Cancer ": "C13.9",
        "Strep Throat ": "J02.9",
        "Viral Pharyngitis ": "J02.9",
      },
    },
    1297: {
      "Chief Complaint": "Foreign Body in Throat",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Foreign Body in Pharynx ": "T17.0",
        "Gastroesophageal Reflux ": "K21.9",
        "Pharyngeal Cancer ": "C13.9",
        "Tonsillitis ": "J03.90",
        "Retropharyngeal Abscess ": "J39.0",
        "Allergic Reaction ": "T78.40",
        "Strep Throat ": "J02.9",
        "Viral Pharyngitis ": "J02.9",
      },
    },
    1298: {
      "Chief Complaint": "Musculoskeletal Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Growing Pains ": "M79.2",
        "Osgood-Schlatter Disease ": "M92.5",
        "Juvenile Idiopathic Arthritis ": "M08",
        "Muscle Strain ": "S39.012A",
        "Infection ": "A49.9",
        "Tumor or Mass ": "D49.9",
        "Trauma ": "S09.90XA",
        "Juvenile Rheumatoid Arthritis ": "M08.3",
      },
    },
    1299: {
      "Chief Complaint": "Musculoskeletal Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Muscle Strain ": "S39.012A",
        "Osteoarthritis ": "M19.90",
        "Herniated Disc ": "M51.26",
        "Sciatica ": "M54.30",
        "Gout ": "M10.9",
        "Tumor or Mass ": "D49.9",
        "Fibromyalgia ": "M79.7",
        "Ankylosing Spondylitis ": "M45.0",
      },
    },
    1300: {
      "Chief Complaint": "Musculoskeletal Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Muscle Strain ": "S39.012A",
        "Osteoarthritis ": "M19.90",
        "Herniated Disc ": "M51.26",
        "Fibromyalgia ": "M79.7",
        "Tumor or Mass ": "D49.9",
        "Interstitial Cystitis ": "N30.10",
        "Endometriosis ": "N80.9",
        "Pelvic Organ Prolapse ": "N81.2",
      },
    },
    1301: {
      "Chief Complaint": "Musculoskeletal Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.90",
        "Degenerative Disc Disease ": "M51.36",
        "Lumbar Spondylosis ": "M47.816",
        "Compression Fracture ": "M80.08XA",
        "Osteoporosis ": "M81.0",
        "Renal Insufficiency ": "N18.9",
        "Herniated Disc ": "M51.26",
        "Arthritis ": "M13.9",
      },
    },
    1302: {
      "Chief Complaint": "Shoulder Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Clavicle Fracture ": "S42.00XA",
        "Shoulder Dislocation ": "S43.00",
        "Growth Plate Injury ": "S42.0",
        "Tendinitis ": "M75.00",
        "Rotator Cuff Tear ": "M75.100",
        "Brachial Plexus Injury ": "S14.00",
        "Juvenile Idiopathic Arthritis ": "M08.9",
        "Osteomyelitis ": "M86.9",
        "Muscle Strain ": "S39.012A",
        "Arthritis ": "M19.90",
      },
    },
    1303: {
      "Chief Complaint": "Shoulder Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Rotator Cuff Tear ": "M75.100",
        "Frozen Shoulder ": "M75.00",
        "Bursitis ": "M75.20",
        "Arthritis ": "M19.90",
        "Tendinitis ": "M75.00",
        "Labral Tear ": "S43.40",
        "Cervical Radiculopathy ": "M54.10",
        "Heart Attack ": "I21.9",
        "Muscle Strain ": "S39.012A",
      },
    },
    1304: {
      "Chief Complaint": "Shoulder Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Rotator Cuff Tear ": "M75.100",
        "Frozen Shoulder ": "M75.00",
        "Bursitis ": "M75.20",
        "Arthritis ": "M19.90",
        "Tendinitis ": "M75.00",
        "Labral Tear ": "S43.40",
        "Cervical Radiculopathy ": "M54.10",
        "Gallbladder Disease ": "K82.9",
        "Heart Attack ": "I21.9",
      },
    },
    1305: {
      "Chief Complaint": "Shoulder Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Rotator Cuff Tear ": "M75.100",
        "Arthritis ": "M19.90",
        "Frozen Shoulder ": "M75.00",
        "Bursitis ": "M75.20",
        "Cervical Radiculopathy ": "M54.10",
        "Labral Tear ": "S43.40",
        "Osteoporotic Fracture ": "M80.08XA",
        "Heart Attack ": "I21.9",
        "Muscle Strain ": "S39.012A",
      },
    },
    1306: {
      "Chief Complaint": "Neck Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Muscle Strain ": "S16.1XXA",
        "Torticollis ": "M43.6",
        "Cervical Lymphadenitis ": "R59.0",
        "Spinal Cord Injury ": "S14.1",
        "Rheumatoid Arthritis ": "M05.9",
        "Juvenile Idiopathic Arthritis ": "M08.9",
        "Osteomyelitis ": "M86.9",
        "Otitis Media ": "H66.90",
      },
    },
    1307: {
      "Chief Complaint": "Neck Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Muscle Strain ": "S16.1XXA",
        "Cervical Radiculopathy ": "M54.10",
        "Osteoarthritis ": "M19.90",
        "Herniated Disc ": "M51.26",
        "Cervical Spondylosis ": "M47.812",
        "Whiplash Injury ": "S13.4",
        "Tension Headache ": "G44.209",
        "Angina Pectoris ": "I20.9",
      },
    },
    1308: {
      "Chief Complaint": "Neck Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Muscle Strain ": "S16.1XXA",
        "Cervical Radiculopathy ": "M54.10",
        "Osteoarthritis ": "M19.90",
        "Herniated Disc ": "M51.26",
        "Cervical Spondylosis ": "M47.812",
        "Whiplash Injury ": "S13.4",
        "Tension Headache ": "G44.209",
        "Migraine ": "G43.909",
      },
    },
    1309: {
      "Chief Complaint": "Neck Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Osteoarthritis ": "M19.90",
        "Cervical Spondylosis ": "M47.812",
        "Herniated Disc ": "M51.26",
        "Myelopathy ": "G99.2",
        "Cervical Stenosis ": "M48.06",
        "Rheumatoid Arthritis ": "M05.9",
        "Osteoporosis ": "M81.0",
        "Muscle Strain ": "S16.1XXA",
      },
    },
    1310: {
      "Chief Complaint": "Sickle Cell Pain",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Sickle Cell Crisis ": "D57.00",
        "Vaso-occlusive Crisis ": "D57.00",
        "Acute Chest Syndrome ": "D57.82",
        "Aplastic Crisis ": "D57.1",
        "Splenic Sequestration ": "D57.0",
        "Hemolytic Crisis ": "D57.2",
        "Infection ": "B95.5",
        "Cholecystitis ": "K81.0",
      },
    },
    1311: {
      "Chief Complaint": "Sickle Cell Pain",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Sickle Cell Crisis ": "D57.00",
        "Vaso-occlusive Crisis ": "D57.00",
        "Acute Chest Syndrome ": "D57.82",
        "Aplastic Crisis ": "D57.1",
        "Splenic Sequestration ": "D57.0",
        "Hemolytic Crisis ": "D57.2",
        "Infection ": "B95.5",
        "Cholecystitis ": "K81.0",
      },
    },
    1312: {
      "Chief Complaint": "Sickle Cell Pain",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Sickle Cell Crisis ": "D57.00",
        "Vaso-occlusive Crisis ": "D57.00",
        "Acute Chest Syndrome ": "D57.82",
        "Aplastic Crisis ": "D57.1",
        "Splenic Sequestration ": "D57.0",
        "Hemolytic Crisis ": "D57.2",
        "Infection ": "B95.5",
        "Cholecystitis ": "K81.0",
      },
    },
    1313: {
      "Chief Complaint": "Sickle Cell Pain",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Sickle Cell Crisis ": "D57.00",
        "Vaso-occlusive Crisis ": "D57.00",
        "Acute Chest Syndrome ": "D57.82",
        "Aplastic Crisis ": "D57.1",
        "Splenic Sequestration ": "D57.0",
        "Hemolytic Crisis ": "D57.2",
        "Infection ": "B95.5",
        "Cholecystitis ": "K81.0",
      },
    },
    1314: {
      "Chief Complaint": "High Blood Sugar",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Diabetic Ketoacidosis ": "E10.10",
        "Type 1 Diabetes ": "E10.9",
        "Stress Hyperglycemia ": "R73.0",
        "Viral Infection ": "B34.9",
        "Medication Side Effects ": "T88.7",
        "Pancreatitis ": "K85.9",
        "Cystic Fibrosis ": "E84.9",
        "Malnutrition ": "E46",
      },
    },
    1315: {
      "Chief Complaint": "High Blood Sugar",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Type 2 Diabetes ": "E11.9",
        "Diabetic Ketoacidosis ": "E11.10",
        "Hyperosmolar Hyperglycemic State ": "E11.00",
        "Medication Side Effects ": "T88.7",
        "Stress Hyperglycemia ": "R73.0",
        "Pancreatitis ": "K85.9",
        "Infection ": "B95.5",
        "Cushing's Syndrome ": "E24.9",
      },
    },
    1316: {
      "Chief Complaint": "High Blood Sugar",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Type 2 Diabetes ": "E11.9",
        "Diabetic Ketoacidosis ": "E11.10",
        "Hyperosmolar Hyperglycemic State ": "E11.00",
        "Medication Side Effects ": "T88.7",
        "Stress Hyperglycemia ": "R73.0",
        "Pancreatitis ": "K85.9",
        "Infection ": "B95.5",
        "Polycystic Ovary Syndrome ": "E28.9",
      },
    },
    1317: {
      "Chief Complaint": "High Blood Sugar",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Type 2 Diabetes ": "E11.9",
        "Medication Side Effects ": "T88.7",
        "Hyperosmolar Hyperglycemic State ": "E11.00",
        "Stress Hyperglycemia ": "R73.0",
        "Infection ": "B95.5",
        "Cushing's Syndrome ": "E24.9",
        "Pancreatitis ": "K85.9",
        "Malnutrition ": "E46",
      },
    },
    1318: {
      "Chief Complaint": "Asymptomatic Hypertension",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Essential Hypertension ": "I10",
        "Secondary Hypertension ": "I15",
        "Renal Disease ": "N28.9",
        "Medication Side Effects ": "T88.7",
        "Coarctation of Aorta ": "Q25.1",
        "Hyperthyroidism ": "E05.9",
        "Pheochromocytoma ": "D35.9",
        "Obesity ": "E66.9",
      },
    },
    1319: {
      "Chief Complaint": "Asymptomatic Hypertension",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Essential Hypertension ": "I10",
        "Secondary Hypertension ": "I15",
        "Medication Side Effects ": "T88.7",
        "Renal Disease ": "N28.9",
        "Hyperthyroidism ": "E05.9",
        "Coarctation of Aorta ": "Q25.1",
        "Sleep Apnea ": "G47.33",
        "Pheochromocytoma ": "D35.9",
      },
    },
    1320: {
      "Chief Complaint": "Asymptomatic Hypertension",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Essential Hypertension ": "I10",
        "Secondary Hypertension ": "I15",
        "Medication Side Effects ": "T88.7",
        "Renal Disease ": "N28.9",
        "Hyperthyroidism ": "E05.9",
        "Coarctation of Aorta ": "Q25.1",
        "Sleep Apnea ": "G47.33",
      },
    },
    1321: {
      "Chief Complaint": "Asymptomatic Hypertension",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Essential Hypertension ": "I10",
        "Secondary Hypertension ": "I15",
        "Medication Side Effects ": "T88.7",
        "Renal Disease ": "N28.9",
        "Coarctation of Aorta ": "Q25.1",
        "Hyperthyroidism ": "E05.9",
        "Atherosclerosis ": "I70.90",
        "Sleep Apnea ": "G47.33",
      },
    },
    1322: {
      "Chief Complaint": "Nosebleed",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Epistaxis ": "R04.0",
        "Trauma ": "S09.8",
        "Foreign Body ": "T17.9",
        "Nasal Polyps ": "J33.9",
        "Bleeding Disorders ": "D69.9",
        "Upper Respiratory Infection ": "J06.9",
        "Coagulopathy ": "D68.9",
        "Medication Side Effects ": "T88.7",
      },
    },
    1323: {
      "Chief Complaint": "Nosebleed",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Epistaxis ": "R04.0",
        "Trauma ": "S09.8",
        "Medication Side Effects ": "T88.7",
        "Nasal Polyps ": "J33.9",
        "Bleeding Disorders ": "D69.9",
        "Coagulopathy ": "D68.9",
        "Foreign Body ": "T17.9",
        "Upper Respiratory Infection ": "J06.9",
      },
    },
    1324: {
      "Chief Complaint": "Nosebleed",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Epistaxis ": "R04.0",
        "Trauma ": "S09.8",
        "Medication Side Effects ": "T88.7",
        "Nasal Polyps ": "J33.9",
        "Bleeding Disorders ": "D69.9",
        "Coagulopathy ": "D68.9",
        "Upper Respiratory Infection ": "J06.9",
        "Foreign Body ": "T17.9",
      },
    },
    1325: {
      "Chief Complaint": "Nosebleed",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Epistaxis ": "R04.0",
        "Medication Side Effects ": "T88.7",
        "Coagulopathy ": "D68.9",
        "Hypertension ": "I10",
        "Nasal Polyps ": "J33.9",
        "Bleeding Disorders ": "D69.9",
        "Upper Respiratory Infection ": "J06.9",
        "Trauma ": "S09.8",
      },
    },
    1326: {
      "Chief Complaint": "Nose Congestion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Viral Upper Respiratory Infection ": "J06.9",
        "Allergic Rhinitis ": "J30.9",
        "Adenoid Hypertrophy ": "J35.2",
        "Sinusitis ": "J01.90",
        "Foreign Body ": "T17.9",
        "Nasal Polyps ": "J33.9",
        "Septal Deviation ": "J34.2",
        "Enlarged Tonsils ": "J35.1",
      },
    },
    1327: {
      "Chief Complaint": "Nose Congestion",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "Allergic Rhinitis ": "J30.9",
        "Viral Upper Respiratory Infection ": "J06.9",
        "Sinusitis ": "J01.90",
        "Nasal Polyps ": "J33.9",
        "Chronic Rhinosinusitis ": "J32.9",
        "Deviated Septum ": "J34.2",
        "Medication Side Effects ": "T88.7",
        "Nasal Trauma ": "S09.8",
      },
    },
    1328: {
      "Chief Complaint": "Nose Congestion",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "Allergic Rhinitis ": "J30.9",
        "Viral Upper Respiratory Infection ": "J06.9",
        "Sinusitis ": "J01.90",
        "Nasal Polyps ": "J33.9",
        "Chronic Rhinosinusitis ": "J32.9",
        "Deviated Septum ": "J34.2",
        "Pregnancy-induced Rhinitis ": "O99.82",
        "Medication Side Effects ": "T88.7",
      },
    },
    1329: {
      "Chief Complaint": "Nose Congestion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Allergic Rhinitis ": "J30.9",
        "Viral Upper Respiratory Infection ": "J06.9",
        "Sinusitis ": "J01.90",
        "Nasal Polyps ": "J33.9",
        "Chronic Rhinosinusitis ": "J32.9",
        "Deviated Septum ": "J34.2",
        "Medication Side Effects ": "T88.7",
        "Environmental Allergies ": "T78.4",
      },
    },
    1330: {
      "Chief Complaint": "Positive for COVID-19",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "COVID-19 ": "U07.1",
        "Viral Upper Respiratory Infection ": "J06.9",
        "Influenza ": "J11.1",
        "Respiratory Syncytial Virus ": "J12.89",
        "Croup ": "J05.0",
        "Streptococcal Pharyngitis ": "J02.9",
        "Pneumonia ": "J18.9",
        "Bronchiolitis ": "J21.9",
      },
    },
    1331: {
      "Chief Complaint": "Positive for COVID-19",
      Age: "Adult",
      Gender: "Male",
      DDx: {
        "COVID-19 ": "U07.1",
        "Influenza ": "J11.1",
        "Pneumonia ": "J18.9",
        "Acute Bronchitis ": "J20.9",
        "Chronic Obstructive Pulmonary Disease ": "J44.9",
        "Asthma ": "J45.909",
        "Heart Failure ": "I50.9",
        "Acute Respiratory Distress Syndrome ": "J80",
      },
    },
    1332: {
      "Chief Complaint": "Positive for COVID-19",
      Age: "Adult",
      Gender: "Female",
      DDx: {
        "COVID-19 ": "U07.1",
        "Influenza ": "J11.1",
        "Pneumonia ": "J18.9",
        "Acute Bronchitis ": "J20.9",
        "Chronic Obstructive Pulmonary Disease ": "J44.9",
        "Asthma ": "J45.909",
        "Heart Failure ": "I50.9",
        "Acute Respiratory Distress Syndrome ": "J80",
      },
    },
    1333: {
      "Chief Complaint": "Positive for COVID-19",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "COVID-19 ": "U07.1",
        "Influenza ": "J11.1",
        "Pneumonia ": "J18.9",
        "Chronic Obstructive Pulmonary Disease ": "J44.9",
        "Heart Failure ": "I50.9",
        "Acute Respiratory Distress Syndrome ": "J80",
        "Septic Shock ": "R65.21",
        "Sepsis ": "A41.9",
      },
    },
    1334: {
      "Chief Complaint": "Blood Transfusion",
      Age: "Pediatric",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Transfusion reaction ": "T80.6",
        "Transfusion-associated circulatory overload ": "T80.29",
        "Hypocalcemia ": "E83.52",
        "Hypothermia ": "T68.0",
        "Allergic reaction ": "T78.2",
        "Transfusion-associated graft-vs-host disease ": "T80.2",
        "Hyperkalemia ": "E87.5",
        "Infection ": "T80.1",
        "Delayed hemolytic reaction ": "T80.5",
      },
    },
    1335: {
      "Chief Complaint": "Blood Transfusion",
      Age: "Adult",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Transfusion reaction ": "T80.6",
        "Transfusion-associated circulatory overload ": "T80.29",
        "Hypocalcemia ": "E83.52",
        "Hypothermia ": "T68.0",
        "Allergic reaction ": "T78.2",
        "Transfusion-associated graft-vs-host disease ": "T80.2",
        "Hyperkalemia ": "E87.5",
        "Infection ": "T80.1",
        "Delayed hemolytic reaction ": "T80.5",
      },
    },
    1336: {
      "Chief Complaint": "Blood Transfusion",
      Age: "Elderly",
      Gender: "Both",
      DDx: {
        "Anemia ": "D64.9",
        "Transfusion reaction ": "T80.6",
        "Transfusion-associated circulatory overload ": "T80.29",
        "Hypocalcemia ": "E83.52",
        "Hypothermia ": "T68.0",
        "Allergic reaction ": "T78.2",
        "Transfusion-associated graft-vs-host disease ": "T80.2",
        "Hyperkalemia ": "E87.5",
        "Infection ": "T80.1",
        "Delayed hemolytic reaction ": "T80.5",
      },
    },
  },
  ros: {
    Constitutional: ["Weight changes", "Fatigue", "Malaise"],
    Skin: ["Lesions", "Discoloration"],
    Head: ["Head injury", "Facial trauma"],
    Eyes: ["Dry eyes", "Excessive tearing"],
    Ears: ["Acute hearing loss", "Ear fullness"],
    Nose: ["Postnasal drip", "Nasal trauma"],
    Neck: ["Neck pain", "Hoarseness", "Voice change", "New masses"],
    Mouth: ["Mouth sores", "Drooling"],
    Respiratory: ["Apnea"],
    Cardiovascular: ["Palpitations", "Leg swelling"],
    Gastrointestinal: ["New masses", "Bright red blood per rectum"],
    Genitourinary: ["Urinary urgency", "Urinary frequency"],
    Musculoskeletal: [
      "Joint pain",
      "Joint stiffness",
      "Muscle weakness",
      "Muscle pain",
    ],
    Neurological: ["Motor changes", "Sensory changes"],
    Psychiatric: ["Mood changes", "Sleep disturbances", "Appetite changes"],
    Endocrine: ["Heat intolerance", "Cold intolerance"],
    "Hematologic ": ["Adenopathy", "Bruises"],
  },
  pe: {
    Constitutional: [
      "Acute distress",
      "Toxic appearance",
      "Ill appearance",
      "Diaphoresis",
    ],
    Skin: ["Rashes", "Jaundiced", "Lesions", "Discoloration"],
    Head: ["Acute trauma of the head", "Acute trauma of the face"],
    Eyes: [
      "Left eye discharge",
      "Right eye discharge",
      "Left conjunctiva appearing injected",
      "Right conjunctiva appearing injected",
      "Scleral icterus",
      "Abnormal extraocular movements",
    ],
    Ears: [
      "Left external ear appearning abnormal",
      "Right external ear appearning abnormal",
    ],
    Nose: ["Epistaxis", "Nasal discharge"],
    Neck: ["Neck trauma", "Acute issue with neck range of motion"],
    Mouth: ["Dry mouth", "Mouth not being patent"],
    Respiratory: ["Abnormal respiratory effort", "Abnormal respiratory rate"],
    Cardiovascular: [
      "Abnormal heart rate",
      "Cynosis",
      "Abnormal breathing pattern",
    ],
    Gastrointestinal: [
      "Visible abdominal mass",
      "Visible abdominal distention",
    ],
    Genitourinary: ["-"],
    Musculoskeletal: [
      "Tenderness",
      "Observed acute injury",
      "Left lower extremity edema",
      "Right lower extremity edema",
      "Swelling",
    ],
    Neurological: [
      "Acute focal deficit",
      "Disoriented",
      "Observed motor weakness",
    ],
    Psychiatric: [
      "Abnormalities in affect",
      "Abnormalities in mood",
      "Abnormalities in behavior",
      "Abnormalities in speech patterns",
      "Abnormalities in thought processes",
    ],
    Endocrine: ["-"],
    "Hematologic ": ["Bruising"],
  },
  mdm: [
    {
      title: "Summary of Visit",
      content:
        "Patient presented with the chief complaint(s) mentioned above in my history of present illness and my concerns aligned with the differential diagnosis listed above. Furthermore, the complexity of the case was considered, and appropriate tests were ordered as indicated. The appropriate management for the chief complaint(s) mentioned above was implemented and the patient was provided a safe disposition.",
    },
    {
      title: "Number and Complexity of Problems Addressed (COPA)",
      content:
        "The patient presented with the chief complaint(s) mentioned above and both acute and chronic concerns were assessed. Considering my differential diagnosis listed above, the range of potential diagnoses and management options underscores the encounter's severity and complexity.",
    },
    {
      title: "Data",
      content:
        "After reviewing the DDx and complexity of the case, the HPI and physical exam for the chief complaint(s) mentioned above, the appropriate tests and treatment were considered and initiated.\nTests results were analyzed when reported & have been included in the thought processes for diagnosis, evaluation, and treatment. The results that were impactful to the decision making have been identified.",
    },
    {
      title: "MDM Risk",
      content:
        "The patient's care plan was significantly limited or impacted by a social determinant of health",
    },
    {
      title: "MDM Risk",
      content:
        "Decision for potential hospitalization for the chief complaint(s) mentioned above was contemplated. \nFurthermore, social determinants of health, such as homelessness, unemployment and/or polysubstance abuse were considered, while assessing the suitable disposition for the patient. \nThe Patient’s current medication(s) were reviewed and the appropriate recommendations provided.\n Lastly, the patient and/or family have been given an opportunity to ask questions and exhibit an understanding of what happened today in their encounter.",
    },
  ],
  mips: [
    {
      id: 65,
      name: "Appropriate Treatment for Patients with URI",
      description:
        "Percentage of episodes for patients 3 months of age and older with a diagnosis of upper respiratory infection (URI) that did not result in an antibiotic order",
      chief_complaints: ["respiratory infection"],
      icd10_codes: ["J06.9", "J40"],
      age_type: null,
      default_statement_name: "default",
      statements: {
        YES: {
          name: "YES",
          description: "",
          content: "",
          sub_statements: {
            comorbid_condition: {
              name: "The patient has a competing comorbid condition within the last 12 months.",
              description: "",
              content:
                "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was",
              sub_statements: {
                neutropenia: {
                  name: "Neutropenia",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was neutropenia. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                cystic_fibrosis: {
                  name: "Cystic fibrosis",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was cystic fibrosis. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                chronic_bronchitis: {
                  name: "Chronic Bronchitis",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was chronic bronchitis. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                pulmonary_edema: {
                  name: "Pulmonary Edema",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was pulmonary edema. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                respiratory_failure: {
                  name: "Respiratory Failure",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was respiratory failure. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                rheumatoid: {
                  name: "Rheumatoid",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was rheumatoid. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                lung_disease: {
                  name: "Lung Disease",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was lung disease. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                other: {
                  name: "Other",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
              },
            },
            already_antibiotics: {
              name: "The patient is already on antibiotics, or has taken them within the last 30 days.",
              description: "",
              content:
                "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was",
              sub_statements: {},
            },
            competing_diagosis: {
              name: "The patient had a competing diagnosis of",
              description: "",
              content:
                "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was",
              sub_statements: {
                acute_otitis_media: {
                  name: "Acute Otitis Media",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was acute otitis media. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                chronic_sinusitis: {
                  name: "Chronic Sinusitis",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was chronic sinusitis. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                cellulitis: {
                  name: "Cellulitis",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was cellulitis. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                uti: {
                  name: "UTI",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was UTI. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
                other: {
                  name: "Other",
                  description: "",
                  content:
                    "The patient has a competing comorbid condition within the last 12 months. The comorbid condition was Other. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                  sub_statements: {},
                },
              },
            },
          },
        },
        NO: {
          name: "No Antibiotics",
          description: "",
          content:
            "The patient was diagnosed with upper respiratory infection and was not prescribed or dispensed an antibiotic. [SATISFIES MIPS PERFORMANCE]",
          sub_statements: {},
        },
      },
    },
    {
      id: 116,
      name: "Avoidance of Antibiotic Treatment for Acute Bronchitis/Bronchiolitis",
      description:
        "The percentage of episodes for patients ages 3 months and older with a diagnosis of acute bronchitis/bronchiolitis that did not result in an antibiotic dispensing event",
      chief_complaints: [
        "respiratory infection",
        "cough",
        "sore throat",
        "upper respiratory tract infections",
        "influenza (flu)",
        "asthma exacerbation",
        "breathing difficulty",
        "cold symptoms",
        "lung disease",
        "pneumonia",
        "respiratory infection",
        "wheezing",
        "copd",
        "altitude sickness",
        "aspiration",
        "croup",
      ],
      icd10_codes: [
        "J20.3",
        "J20.4",
        "J20.5",
        "J20.6",
        "J20.7",
        "J20.8",
        "J20.9",
        "J21.0",
        "J21.1",
        "J21.8",
        "J21.9",
      ],
      age_type: null,
      default_statement_name: "default",
      statements: {
        YES: {
          name: "Yes",
          description: "",
          content: "",
          sub_statements: {
            "bronchitis/bronchiolitis": {
              name: "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following: [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
              description: "",
              content: "",
              sub_statements: {
                prescribing_antibiotic: {
                  name: "Patient has a medical reason for prescribing or dispensing an antibiotic.",
                  description: "",
                  content: "",
                  sub_statements: {
                    copd: {
                      name: "COPD",
                      description: "",
                      content:
                        "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following:\n\tThe patient has a medical reason for prescribing or dispensing an antibiotic. That reason is COPD. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                      sub_statements: {},
                    },
                    bacterial_infection: {
                      name: "Bacterial Infection",
                      description: "",
                      content:
                        "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following:\n\tThe patient has a medical reason for prescribing or dispensing an antibiotic. That reason is bacterial infection. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                      sub_statements: {},
                    },
                    acute_sinusitis: {
                      name: "Acute Sinusitis",
                      description: "",
                      content:
                        "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following:\n\tThe patient has a medical reason for prescribing or dispensing an antibiotic. That reason is acute sinusitis. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                      sub_statements: {},
                    },
                    other: {
                      name: "Other",
                      description: "",
                      content:
                        "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following:\n\tThe patient has a medical reason for prescribing or dispensing an antibiotic. That reason is other. [MIPS PERFORMANCE EXCEPTION/EXCLUSION]",
                      sub_statements: {},
                    },
                  },
                },
                currently_antibiotics: {
                  name: "The patient is currently on antibiotics, or has taken them within the last 30 days.",
                  description: "",
                  content:
                    "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following: The patient is currently on antibiotics, or has taken them within the last 30 days.",
                  sub_statements: {},
                },
                inpatient_admission: {
                  name: "Patient\u2019s visit resulted in an inpatient admission.",
                  description: "",
                  content:
                    "The patient has acute bronchitis/ bronchiolitis. Antibiotics were prescribed or dispensed because the patient meets one of the following: Patient\u2019s visit resulted in an inpatient admission.",
                  sub_statements: {},
                },
              },
            },
          },
        },
        NO: {
          name: "No Antibiotics",
          description: "",
          content:
            "The patient has acute bronchitis/bronchiolitis and antibiotics were not prescribed or dispensed today. [SATISFIES MIPS PERFORMANCE]",
          sub_statements: {},
        },
      },
    },
    {
      id: 187,
      name: "Stroke & Stroke Rehabilitation: Thrombolytic Therapy",
      description:
        "Percentage of patients aged 18 years and older with a diagnosis of acute ischemic stroke who arrive at the hospital within 3.5 hours of time last known well and for whom IV thrombolytic therapy was initiated within 4.5 hours of time last known well.",
      chief_complaints: ["stroke", "unresponsive"],
      icd10_codes: [
        "I63.00",
        "I63.011",
        "I63.012",
        "I63.013",
        "I63.019",
        "I63.02",
        "I63.031",
        "I63.032",
        "I63.033",
        "I63.039",
        "I63.09",
        "I63.10",
        "I63.111",
        "I63.112",
        "I63.113",
        "I63.119",
        "I63.12",
        "I63.131",
        "I63.132",
        "I63.133",
        "I63.139",
        "I63.19",
        "I63.20",
        "I63.211",
        "I63.212",
        "I63.213",
        "I63.219",
        "I63.22",
        "I63.231",
        "I63.232",
        "I63.233",
        "I63.239",
        "I63.29",
        "I63.30",
        "I63.311",
        "I63.312",
        "I63.313",
        "I63.319",
        "I63.321",
        "I63.322",
        "I63.323",
        "I63.329",
        "I63.331",
        "I63.332",
        "I63.333",
        "I63.339",
        "I63.341",
        "I63.342",
        "I63.343",
        "I63.349",
        "I63.39",
        "I63.40",
        "I63.411",
        "I63.412",
        "I63.413",
        "I63.419",
        "I63.421",
        "I63.422",
        "I63.423",
        "I63.429",
        "I63.431",
        "I63.432",
        "I63.433",
        "I63.439",
        "I63.441",
        "I63.442",
        "I63.443",
        "I63.449",
        "I63.49",
        "I63.50",
        "I63.511",
        "I63.512",
        "I63.513",
        "I63.519",
        "I63.521",
        "I63.522",
        "I63.523",
        "I63.529",
        "I63.531",
        "I63.532",
        "I63.533",
        "I63.539",
        "I63.541",
        "I63.542",
        "I63.543",
        "I63.549",
        "I63.59",
        "I63.6",
        "I63.81",
        "I63.89",
        "I63.9",
      ],
      age_type: null,
      default_statement_name: "default",
      statements: {
        YES: {
          name: "YES",
          description: "",
          content:
            "The patient, who arrived at the hospital within 3.5 hours of time last known well, was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was initiated within 4.5 hours of time last known well. [SATISFIES MIPS PERFORMANCE]",
          sub_statements: {},
        },
        NO: {
          name: "NO",
          description: "",
          content: "",
          sub_statements: {
            patient_arrived_late: {
              name: "Patient arrived at the hospital more than 3.5 hours after time last known well is unknown.",
              description: "",
              content:
                "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient arrived at the hospital more than 3.5 hours after time last known well is unknown.",
              sub_statements: {},
            },
            patient_declined: {
              name: "Patient or family declined an IV thrombolytic",
              description: "",
              content:
                "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient or family declined an IV thrombolytic",
              sub_statements: {},
            },
            not_administering: {
              name: "Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy",
              description: "",
              content: "",
              sub_statements: {
                not_appropiate: {
                  name: "Neurologist does not believe a thrombolytic is appropriate",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is neurologist does not believe a thrombolytic is appropriate",
                  sub_statements: {},
                },
                active_internal_bleeding: {
                  name: "Active internal bleeding",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is active internal bleeding",
                  sub_statements: {},
                },
                serious_head_trauma: {
                  name: "Serious head trauma",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is serious head trauma",
                  sub_statements: {},
                },
                intracranial_hemorrhage: {
                  name: "Acute current or history of intracranial hemorrhage",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is acute current or history of intracranial hemorrhage",
                  sub_statements: {},
                },
                uncontrolled_hypertension: {
                  name: "Uncontrollable hypertension",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is uncontrollable hypertension",
                  sub_statements: {},
                },
                seizure_at_onset: {
                  name: "Seizure at onset of stroke",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is seizure at onset of stroke",
                  sub_statements: {},
                },
                cva: {
                  name: "CVA in last 3 months",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is CVA in last 3 months",
                  sub_statements: {},
                },
                intra_cranial_surgery: {
                  name: "Intracranial or intraspinal surgery in last 3 months",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to:\n\tPatient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is intracranial or intraspinal surgery in last 3 months",
                  sub_statements: {},
                },
                bleeding_disorder: {
                  name: "Bleeding disorder",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is bleeding disorder",
                  sub_statements: {},
                },
                thrombocytopenia: {
                  name: "Thrombocytopenia < 100,000",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is thrombocytopenia < 100,000",
                  sub_statements: {},
                },
                early_radiographic: {
                  name: "Early radiographic ischemic",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is early radiographic ischemic",
                  sub_statements: {},
                },
                head_ct: {
                  name: "Changes on head CT",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is changes on head CT",
                  sub_statements: {},
                },
                inr: {
                  name: "INR > 1.7",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is INR > 1.7",
                  sub_statements: {},
                },
                intracranial_neoplasm: {
                  name: "Intracranial neoplasm",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is intracranial neoplasm",
                  sub_statements: {},
                },
                avm: {
                  name: "AVM or aneurysm",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is AVM or aneurysm",
                  sub_statements: {},
                },
                stroke_trial: {
                  name: "Patient in stroke trial",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well due to: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is stroke trial.",
                  sub_statements: {},
                },
                carotid_intervention: {
                  name: "Patient admitted for elective carotid intervention",
                  description: "",
                  content:
                    "The patient was diagnosed with subacute or acute ischemic stroke. An IV thrombolytic therapy was not initiated within 4.5 hours of last known well because: Patient has a medical contra-indication or reason for not administering an IV thrombolytic therapy. That reason is patient admitted for elective carotid intervention.",
                  sub_statements: {},
                },
              },
            },
          },
        },
      },
    },
    {
      id: 254,
      name: "Ultrasound for Pregnant Patients with Abdominal Pain",
      description:
        "Percentage of pregnant female patients aged 14 to 50 who present to the emergency department (ED) with a chief complaint of abdominal pain or vaginal bleeding who receive a trans-abdominal or trans-vaginal ultrasound to determine pregnancy location.",
      chief_complaints: ["vaginal bleeding", "abdominal pain in pregnancy"],
      icd10_codes: ["N92.6", "O00.9"],
      age_type: null,
      default_statement_name: "default",
      statements: {
        YES: {
          name: "Ultrasound",
          description: "",
          content:
            "The patient is pregnant and presents with abdominal pain or vaginal bleeding. A trans-abdominal or trans-vaginal ultrasound was performed and the pregnancy location is documented.",
          sub_statements: {},
        },
        NO: {
          name: "No ultrasound",
          description: "",
          content:
            "The patient is pregnant and presents with abdominal pain or vaginal bleeding. A trans-abdominal or trans-vaginal ultrasound was NOT performed because patient has documented intrauterine pregnancy)",
          sub_statements: {},
        },
      },
    },
    {
      id: 331,
      name: "Antibiotic use with Sinusitis",
      description:
        "Percentage of patients, aged 18 years and older, with a diagnosis of acute viral sinusitis who were prescribed an antibiotic within 10 days after onset of symptoms",
      chief_complaints: [
        "headache",
        "upper respiratory tract infections",
        "ear pain",
        "influenza (flu)",
        "migraine/headache",
        "cold symptoms",
        "ear fullness",
        "migraine",
        "nosebleed",
        "sinusitis",
        "tinnitus",
        "facial swelling",
        "facial pain",
        "nasal congestion",
      ],
      icd10_codes: [
        "J01.00",
        "J01.01",
        "J01.10",
        "J01.11",
        "J01.20",
        "J01.21",
        "J01.30,J01.31",
        "J01.40",
        "J01.41",
        "J01.80",
        "J01.81",
        "J01.90",
        "J01.91",
      ],
      age_type: null,
      default_statement_name: "default",
      statements: {
        YES: {
          name: "Yes",
          description: "",
          content: "",
          sub_statements: {
            sinusitis_onset: {
              name: "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics.",
              description: "",
              content: "",
              sub_statements: {
                amoxicillin: {
                  name: "Patient was prescribed an amoxicillin-based antibiotic.",
                  description: "",
                  content:
                    "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed an amoxicillin-based antibiotic.",
                  sub_statements: {},
                },
                non_amoxicillin: {
                  name: "Patient was prescribed a non amoxicillin-based antibiotic.",
                  description: "",
                  content: "",
                  sub_statements: {
                    allergy: {
                      name: "Allergy",
                      description: "",
                      content:
                        "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of allergy.",
                      sub_statements: {},
                    },
                    intolerance: {
                      name: "Intolerance",
                      description: "",
                      content:
                        "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of intolerance.",
                      sub_statements: {},
                    },
                    secondary_infection: {
                      name: "Secondary Infection",
                      description: "",
                      content:
                        "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of secondary infection like Acute Pharyngitis, Cellulitis, UTI.",
                      sub_statements: {
                        blank_filed: {
                          name: "Blank Filed",
                          description: "",
                          content: "Acute Pharyngitis, Cellulitis, etc",
                          sub_statements: {},
                        },
                      },
                    },
                  },
                },
              },
            },
            sinusitis: {
              name: "The patient has sinusitis and was prescribed antibiotics",
              description: "",
              content: "",
              sub_statements: {
                sinusitis: {
                  name: "Antibiotics prescribed",
                  description: "",
                  content: "",
                  sub_statements: {
                    symptoms_worsened: {
                      name: "Patient\u2019s symptoms worsened after initial improvement.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because patient\u2019s symptoms worsened after initial improvement.",
                      sub_statements: {},
                    },
                    secondary_infection: {
                      name: "Patient has secondary infection.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because patient has secondary infection.",
                      sub_statements: {},
                    },
                    immunocompromised: {
                      name: "Patient is immunocompromised.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because patient is immunocompromised.",
                      sub_statements: {},
                    },
                    other: {
                      name: "Other",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because other",
                      sub_statements: {},
                    },
                  },
                },
                amoxicillin: {
                  name: "Patient was prescribed an amoxicillin-based antibiotic.",
                  description: "",
                  content:
                    "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed an amoxicillin-based antibiotic.",
                  sub_statements: {},
                },
                non_amoxicillin: {
                  name: "Patient was prescribed a non amoxicillin-based antibiotic.",
                  description: "",
                  content: "",
                  sub_statements: {
                    allergy: {
                      name: "Allergy.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of allergy.",
                      sub_statements: {},
                    },
                    intolerance: {
                      name: "Intolerance.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of intolerance.",
                      sub_statements: {},
                    },
                    secondary_infection: {
                      name: "Secondary infection",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of secondary infection.",
                      sub_statements: {
                        blank_filed: {
                          name: "Blank Filed",
                          description: "",
                          content:
                            "The patient has sinusitis and was prescribed antibiotics. Blank Filed for Acute Pharyngitis, Cellulitis, etc",
                          sub_statements: {},
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NO: {
          name: "No Antibiotics",
          description: "",
          content:
            "The patient has sinusitis and antibiotics are not indicated/not prescribed at this time.",
          sub_statements: {},
        },
      },
    },
    {
      id: 332,
      name: "Antibiotic use with Sinusitis",
      description:
        "Percentage of patients, aged 18 years and older, with a diagnosis of acute viral sinusitis who were prescribed an antibiotic within 10 days after onset of symptoms",
      chief_complaints: [
        "headache",
        "upper respiratory tract infections",
        "ear pain",
        "influenza (flu)",
        "migraine/headache",
        "cold symptoms",
        "ear fullness",
        "migraine",
        "nosebleed",
        "sinusitis",
        "tinnitus",
        "facial swelling",
        "facial pain",
        "nasal congestion",
      ],
      icd10_codes: [
        "J01.00",
        "J01.01",
        "J01.10",
        "J01.11",
        "J01.20",
        "J01.21",
        "J01.30,J01.31",
        "J01.40",
        "J01.41",
        "J01.80",
        "J01.81",
        "J01.90",
        "J01.91",
      ],
      age_type: null,
      default_statement_name: "default",
      statements: {
        YES: {
          name: "Yes",
          description: "",
          content: "",
          sub_statements: {
            sinusitis_onset: {
              name: "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics.",
              description: "",
              content: "",
              sub_statements: {
                amoxicillin: {
                  name: "Patient was prescribed an amoxicillin-based antibiotic.",
                  description: "",
                  content:
                    "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed an amoxicillin-based antibiotic.",
                  sub_statements: {},
                },
                non_amoxicillin: {
                  name: "Patient was prescribed a non amoxicillin-based antibiotic.",
                  description: "",
                  content: "",
                  sub_statements: {
                    allergy: {
                      name: "Allergy",
                      description: "",
                      content:
                        "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of allergy.",
                      sub_statements: {},
                    },
                    intolerance: {
                      name: "Intolerance",
                      description: "",
                      content:
                        "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of intolerance.",
                      sub_statements: {},
                    },
                    secondary_infection: {
                      name: "Secondary Infection",
                      description: "",
                      content:
                        "The patient has sinusitis with symptom onset greater than 10 days ago and the patient was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of secondary infection like Acute Pharyngitis, Cellulitis, UTI.",
                      sub_statements: {
                        blank_filed: {
                          name: "Blank Filed",
                          description: "",
                          content: "Acute Pharyngitis, Cellulitis, etc",
                          sub_statements: {},
                        },
                      },
                    },
                  },
                },
              },
            },
            sinusitis: {
              name: "The patient has sinusitis and was prescribed antibiotics",
              description: "",
              content: "",
              sub_statements: {
                sinusitis: {
                  name: "Antibiotics prescribed",
                  description: "",
                  content: "",
                  sub_statements: {
                    symptoms_worsened: {
                      name: "Patient\u2019s symptoms worsened after initial improvement.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because patient\u2019s symptoms worsened after initial improvement.",
                      sub_statements: {},
                    },
                    secondary_infection: {
                      name: "Patient has secondary infection.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because patient has secondary infection.",
                      sub_statements: {},
                    },
                    immunocompromised: {
                      name: "Patient is immunocompromised.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because patient is immunocompromised.",
                      sub_statements: {},
                    },
                    other: {
                      name: "Other",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics because other",
                      sub_statements: {},
                    },
                  },
                },
                amoxicillin: {
                  name: "Patient was prescribed an amoxicillin-based antibiotic.",
                  description: "",
                  content:
                    "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed an amoxicillin-based antibiotic.",
                  sub_statements: {},
                },
                non_amoxicillin: {
                  name: "Patient was prescribed a non amoxicillin-based antibiotic.",
                  description: "",
                  content: "",
                  sub_statements: {
                    allergy: {
                      name: "Allergy.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of allergy.",
                      sub_statements: {},
                    },
                    intolerance: {
                      name: "Intolerance.",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of intolerance.",
                      sub_statements: {},
                    },
                    secondary_infection: {
                      name: "Secondary infection",
                      description: "",
                      content:
                        "The patient has sinusitis and was prescribed antibiotics. Patient was prescribed a non amoxicillin-based antibiotic because of secondary infection.",
                      sub_statements: {
                        blank_filed: {
                          name: "Blank Filed",
                          description: "",
                          content:
                            "The patient has sinusitis and was prescribed antibiotics. Blank Filed for Acute Pharyngitis, Cellulitis, etc",
                          sub_statements: {},
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NO: {
          name: "No Antibiotics",
          description: "",
          content:
            "The patient has sinusitis and antibiotics are not indicated/not prescribed at this time.",
          sub_statements: {},
        },
      },
    },
    {
      id: 415,
      name: "Emergency Medicine: Utilization of CT for Minor Blunt Head Trauma (Adult)",
      description:
        "Percentage of emergency department visits for patients aged 18 years and older who presented with a minor blunt head trauma who had a head CT for trauma ordered by an emergency care provider who have an indication for a head CT.",
      chief_complaints: [
        "motor vehicle accident",
        "injuries due to falls",
        "motor vehicle accident",
        "injuries due to accident",
        "unwitnessed fall",
        "neck injury",
        "concussion",
        "chest pain after fall",
        "head trauma",
        "head injury",
        "Motorcycle Accident",
        "Chest Pain After Fall",
        "Non-Syncopal Fall",
      ],
      icd10_codes: [
        "S00.03XA",
        "S00.33XA",
        "S00.431A",
        "S00.432A",
        "S00.439A",
        "S00.531A",
        "S00.532A",
        "S00.83XA",
        "S00.93XA",
        "S06.A0XA",
        "S06.A1XA",
        "S06.0XAA",
        "S06.0X0A",
        "S06.0X1A",
        "S06.0X9A",
        "S06.1XAA",
        "S06.1X0A",
        "S06.1X1A",
        "S06.1X2A",
        "S06.1X3A",
        "S06.1X4A",
        "S06.1X9A",
        "S06.2XAA",
        "S06.2X0A",
        "S06.2X1A",
        "S06.2X2A",
        "S06.2X3A",
        "S06.2X4A",
        "S06.2X9A",
        "S06.30AA",
        "S06.300A",
        "S06.301A",
        "S06.302A",
        "S06.303A",
        "S06.304A",
        "S06.309A",
        "S06.31AA",
        "S06.32AA",
        "S06.33AA",
        "S06.34AA",
        "S06.340A",
        "S06.341A",
        "S06.342A",
        "S06.343A",
        "S06.344A",
        "S06.349A",
        "S06.35AA",
        "S06.350A",
        "S06.351A",
        "S06.352A",
        "S06.353A",
        "S06.354A",
        "S06.359A",
        "S06.36AA",
        "S06.360A",
        "S06.361A",
        "S06.362A",
        "S06.363A",
        "S06.364A",
        "S06.369A",
        "S06.37AA",
        "S06.38AA",
        "S06.4XAA",
        "S06.4X0A",
        "S06.4X1A",
        "S06.4X2A",
        "S06.4X3A",
        "S06.4X4A",
        "S06.4X9A",
        "S06.5XAA",
        "S06.5X0A",
        "S06.5X1A",
        "S06.5X2A",
        "S06.5X3A",
        "S06.5X4A",
        "S06.5X9A",
        "S06.6XAA",
        "S06.6X0A",
        "S06.6X1A",
        "S06.6X2A",
        "S06.6X3A",
        "S06.6X4A",
        "S06.6X9A",
        "S06.81AA",
        "S06.810A",
        "S06.811A",
        "S06.812A",
        "S06.813A",
        "S06.814A",
        "S06.819A",
        "S06.82AA",
        "S06.820A",
        "S06.821A",
        "S06.822A",
        "S06.823A",
        "S06.824A",
        "S06.829A",
        "S06.89AA",
        "S06.890A",
        "S06.891A",
        "S06.892A",
        "S06.893A",
        "S06.894A",
        "S06.899A",
        "S06.9XAA",
        "S06.9X0A",
        "S06.9X1A",
        "S06.9X2A",
        "S06.9X3A",
        "S06.9X4A",
        "S06.9X9A",
        "S09.11XA",
        "S09.19XA",
        "S09.8XXA",
      ],
      age_type: "adult",
      default_statement_name: "default",
      statements: {
        YES: {
          name: "Yes",
          description: "",
          content: "",
          sub_statements: {
            conditions: {
              name: "Patient has one or more of the following conditions that are excluded from the measure:",
              description: "",
              content: "",
              sub_statements: {
                ventricular_shunt: {
                  name: "Patient has a ventricular shunt.",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tPatient has a ventricular shunt.",
                  sub_statements: {},
                },
                brain_tumor: {
                  name: "Patient has a brain tumor.",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tPatient has a brain tumor.",
                  sub_statements: {},
                },
                pregnant: {
                  name: "Patient is pregnant.",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tPatient is pregnant.",
                  sub_statements: {},
                },
                multi_system_trauma: {
                  name: "Patient has multi-system trauma.",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tPatient has multi-system trauma.",
                  sub_statements: {},
                },
                antiplatelet: {
                  name: "Patient taking an antiplatelet medication (excluding aspirin)",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tPatient taking an antiplatelet medication (excluding aspirin)",
                  sub_statements: {},
                },
                head_ct_not_ordered: {
                  name: "Head CT was not ordered by emergency care clinician",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tHead CT was not ordered by emergency care clinician",
                  sub_statements: {},
                },
                head_ct_ordered: {
                  name: "Head CT ordered for reasons other than trauma",
                  description: "",
                  content:
                    "Patient has one or more of the following conditions that are excluded from the measure:\n\tHead CT ordered for reasons other than trauma",
                  sub_statements: {},
                },
              },
            },
            minor_head_trauma: {
              name: "Patient is 18 or older, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma because (select one or more) reasons:",
              description: "",
              content: "",
              sub_statements: {
                "65_or_older": {
                  name: "Patient is 65 or older.",
                  description: "",
                  content:
                    "Patient is 18 or older, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma because (select one or more) reasons:\n\tPatient is 65 or older.",
                  sub_statements: {},
                },
                "gcs<15": {
                  name: "Patient has a GCS < 15.",
                  description: "",
                  content:
                    "Patient is 18 or older, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma because (select one or more) reasons:\n\tPatient has a GCS < 15.",
                  sub_statements: {},
                },
                neurologic_deficit: {
                  name: "Patient has a neurologic deficit.",
                  description: "",
                  content:
                    "Patient is 18 or older, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma because (select one or more) reasons:\n\tPatient has a neurologic deficit.",
                  sub_statements: {},
                },
                severe_headache: {
                  name: "Patient has a severe headache.",
                  description: "",
                  content:
                    "Patient is 18 or older, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma because (select one or more) reasons:\n\tPatient has a severe headache.",
                  sub_statements: {},
                },
                vomiting: {
                  name: "Patient is vomiting.",
                  description: "",
                  content:
                    "Patient is 18 or older, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma because (select one or more) reasons:\n\tPatient is vomiting.",
                  sub_statements: {},
                },
              },
            },
            mechanism_of_injury: {
              name: "Severe/dangerous mechanism of injury was identified:",
              description: "",
              content: "",
              sub_statements: {
                mva: {
                  name: "MVA with: patient ejection, death of another passenger, rollover, speed > 40mph, airbag deployment, driver or passenger on ATV or motorcycle",
                  description: "",
                  content:
                    "Severe/dangerous mechanism of injury was identified:\n\tMVA with: patient ejection, death of another passenger, rollover, speed > 40mph, airbag deployment, driver or passenger on ATV or motorcycle",
                  sub_statements: {},
                },
                pedestrian_or_biciclyst: {
                  name: "Pedestrian or bicyclist without helmet: struck my motorized vehicle, in bicycle crash",
                  description: "",
                  content:
                    "Severe/dangerous mechanism of injury was identified:\n\tPedestrian or bicyclist without helmet: struck my motorized vehicle, in bicycle crash",
                  sub_statements: {},
                },
                fall: {
                  name: "fall > 3 feet or 5 stairs",
                  description: "",
                  content:
                    "Severe/dangerous mechanism of injury was identified:\n\tfall > 3 feet or 5 stairs",
                  sub_statements: {},
                },
                head_struck: {
                  name: "Head struck by high-impact object (hammer, baseball, baseball bat, heavy object such as falling brick)",
                  description: "",
                  content:
                    "Severe/dangerous mechanism of injury was identified:\n\tHead struck by high-impact object (hammer, baseball, baseball bat, heavy object such as falling brick)",
                  sub_statements: {},
                },
                Other: {
                  name: "Other",
                  description: "",
                  content:
                    "Severe/dangerous mechanism of injury was identified:\n\tOther",
                  sub_statements: {},
                },
              },
            },
            skull_fracture: {
              name: "Patient has physical signs of basilar skull fracture present",
              description: "",
              content:
                "Patient has physical signs of basilar skull fracture present",
              sub_statements: {},
            },
            anticoagulant_medication: {
              name: "Patient suspected of taking anticoagulant medication",
              description: "",
              content: "Patient suspected of taking anticoagulant medication",
              sub_statements: {},
            },
            thrombocytopenia: {
              name: "Patient has thrombocytopenia",
              description: "",
              content: "Patient has thrombocytopenia",
              sub_statements: {},
            },
            coagulopathy: {
              name: "Patient has coagulopathy",
              description: "",
              content: "Patient has coagulopathy",
              sub_statements: {},
            },
            loss_of_consciousness: {
              name: "Patient has loss of consciousness and:",
              description: "",
              content: "",
              sub_statements: {
                headache: {
                  name: "Headache",
                  description: "",
                  content: "Patient has loss of consciousness and:\n\tHeadache",
                  sub_statements: {},
                },
                short_term_memory: {
                  name: "Short-term memory deficit",
                  description: "",
                  content:
                    "Patient has loss of consciousness and:\n\tShort-term memory deficit",
                  sub_statements: {},
                },
                drug_intoxication: {
                  name: "Alcohol/Drug intoxication",
                  description: "",
                  content:
                    "Patient has loss of consciousness and:\n\tAlcohol/Drug intoxication",
                  sub_statements: {},
                },
                clavicles_trauma: {
                  name: "Evidence of trauma above the clavicles",
                  description: "",
                  content:
                    "Patient has loss of consciousness and:\n\tEvidence of trauma above the clavicles",
                  sub_statements: {},
                },
                "60_or_older": {
                  name: "Age 60 or older",
                  description: "",
                  content:
                    "Patient has loss of consciousness and:\n\tAge 60 or older",
                  sub_statements: {},
                },
                post_traumatic_seizure: {
                  name: "Post-traumatic seizure",
                  description: "",
                  content:
                    "Patient has loss of consciousness and:\n\tPost-traumatic seizure",
                  sub_statements: {},
                },
              },
            },
            post_traumatic_amnesia: {
              name: "Patient has post-traumatic amnesia and:",
              description: "",
              content: "",
              sub_statements: {
                headache: {
                  name: "Headache",
                  description: "",
                  content:
                    "Patient has post-traumatic amnesia and:\n\tHeadache",
                  sub_statements: {},
                },
                short_term_memory: {
                  name: "Short-term memory deficit",
                  description: "",
                  content:
                    "Patient has post-traumatic amnesia and:\n\tShort-term memory deficit",
                  sub_statements: {},
                },
                drug_intoxication: {
                  name: "Alcohol/Drug intoxication",
                  description: "",
                  content:
                    "Patient has post-traumatic amnesia and:\n\tAlcohol/Drug intoxication",
                  sub_statements: {},
                },
                clavicles_trauma: {
                  name: "Evidence of trauma above the clavicles",
                  description: "",
                  content:
                    "Patient has post-traumatic amnesia and:\n\tEvidence of trauma above the clavicles",
                  sub_statements: {},
                },
                "60_or_older": {
                  name: "Age 60 or older",
                  description: "",
                  content:
                    "Patient has post-traumatic amnesia and:\n\tAge 60 or older",
                  sub_statements: {},
                },
                post_traumatic_seizure: {
                  name: "Post-traumatic seizure",
                  description: "",
                  content:
                    "Patient has post-traumatic amnesia and:\n\tPost-traumatic seizure",
                  sub_statements: {},
                },
              },
            },
          },
        },
        NO: {
          name: "No Head CT",
          description: "",
          content: "CT for Minor Blunt Head Trauma was not ordered",
          sub_statements: {},
        },
      },
    },
    {
      id: 416,
      name: "Emergency Medicine: Utilization of CT for Minor Blunt Head Trauma (Pediatrics)",
      description:
        "Percentage of episodes for patients 3 months of age and older with a diagnosis of upper respiratory infection (URI) that did not result in an antibiotic order",
      chief_complaints: [
        "motor vehicle accident",
        "injuries due to falls",
        "injuries due to accident",
        "unwitnessed fall",
        "neck injury",
        "concussion",
        "chest pain after fall",
        "head trauma",
        "head injury",
        "Motorcycle Accident",
        "Chest Pain After Fall",
        "Non-Syncopal Fall",
      ],
      icd10_codes: [
        "S00.03XA",
        "S00.33XA",
        "S00.431A",
        "S00.432A",
        "S00.439A",
        "S00.531A",
        "S00.532A",
        "S00.83XA",
        "S00.93XA",
        "S06.A0XA",
        "S06.A1XA",
        "S06.0XAA",
        "S06.0X0A",
        "S06.0X1A",
        "S06.0X9A",
        "S06.1XAA",
        "S06.1X0A",
        "S06.1X1A",
        "S06.1X2A",
        "S06.1X3A",
        "S06.1X4A",
        "S06.1X9A",
        "S06.2XAA",
        "S06.2X0A",
        "S06.2X1A",
        "S06.2X2A",
        "S06.2X3A",
        "S06.2X4A",
        "S06.2X9A",
        "S06.30AA",
        "S06.300A",
        "S06.301A",
        "S06.302A",
        "S06.303A",
        "S06.304A",
        "S06.309A",
        "S06.31AA",
        "S06.32AA",
        "S06.33AA",
        "S06.34AA",
        "S06.340A",
        "S06.341A",
        "S06.342A",
        "S06.343A",
        "S06.344A",
        "S06.349A",
        "S06.35AA",
        "S06.350A",
        "S06.351A",
        "S06.352A",
        "S06.353A",
        "S06.354A",
        "S06.359A",
        "S06.36AA",
        "S06.360A",
        "S06.361A",
        "S06.362A",
        "S06.363A",
        "S06.364A",
        "S06.369A",
        "S06.37AA",
        "S06.38AA",
        "S06.4XAA",
        "S06.4X0A",
        "S06.4X1A",
        "S06.4X2A",
        "S06.4X3A",
        "S06.4X4A",
        "S06.4X9A",
        "S06.5XAA",
        "S06.5X0A",
        "S06.5X1A",
        "S06.5X2A",
        "S06.5X3A",
        "S06.5X4A",
        "S06.5X9A",
        "S06.6XAA",
        "S06.6X0A",
        "S06.6X1A",
        "S06.6X2A",
        "S06.6X3A",
        "S06.6X4A",
        "S06.6X9A",
        "S06.81AA",
        "S06.810A",
        "S06.811A",
        "S06.812A",
        "S06.813A",
        "S06.814A",
        "S06.819A",
        "S06.82AA",
        "S06.820A",
        "S06.821A",
        "S06.822A",
        "S06.823A",
        "S06.824A",
        "S06.829A",
        "S06.89AA",
        "S06.890A",
        "S06.891A",
        "S06.892A",
        "S06.893A",
        "S06.894A",
        "S06.899A",
        "S06.9XAA",
        "S06.9X0A",
        "S06.9X1A",
        "S06.9X2A",
        "S06.9X3A",
        "S06.9X4A",
        "S06.9X9A",
        "S09.11XA",
        "S09.19XA",
        "S09.8XXA",
      ],
      age_type: "pediatric",
      default_statement_name: "default",
      statements: {
        YES: {
          name: "Yes",
          description: "",
          content: "",
          sub_statements: {
            patient_exclusions: {
              name: "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician AND the one or more patient exclusions apply:",
              description: "",
              content: "",
              sub_statements: {
                ventricular_shunt: {
                  name: "Patient has a ventricular shunt.",
                  description: "",
                  content:
                    "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician AND the one or more patient exclusions apply:\n\tPatient has a ventricular shunt.",
                  sub_statements: {},
                },
                brain_tumor: {
                  name: "Patient has a brain tumor.",
                  description: "",
                  content:
                    "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician AND the one or more patient exclusions apply:\n\tPatient has a brain tumor.",
                  sub_statements: {},
                },
                antiplatelet: {
                  name: "Patient taking an antiplatelet medication (excluding aspirin)",
                  description: "",
                  content:
                    "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician AND the one or more patient exclusions apply:\n\tPatient taking an antiplatelet medication (excluding aspirin)",
                  sub_statements: {},
                },
                head_ct_not_ordered: {
                  name: "Head CT was not ordered by emergency care clinician",
                  description: "",
                  content:
                    "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician AND the one or more patient exclusions apply:\n\tHead CT was not ordered by emergency care clinician",
                  sub_statements: {},
                },
                head_ct_ordered: {
                  name: "Head CT ordered for reasons other than trauma",
                  description: "",
                  content:
                    "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician AND the one or more patient exclusions apply:\n\tHead CT ordered for reasons other than trauma",
                  sub_statements: {},
                },
              },
            },
            not_low_risk: {
              name: "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma AND the patient IS NOT classified as low risk according to PECARN prediction rule [SATISFIES MIPS PERFORMANCE]",
              description: "",
              content:
                "Patient is between 2-17 years, presenting with minor blunt head trauma. Head CT (including cosigned orders) was ordered by an emergency care clinician for trauma AND the patient IS NOT classified as low risk according to PECARN prediction rule [SATISFIES MIPS PERFORMANCE]",
              sub_statements: {},
            },
          },
        },
        NO: {
          name: "No",
          description: "",
          content: "CT for Minor Blunt Head Trauma was not ordered.",
          sub_statements: {},
        },
      },
    },
  ],
};
