// src/redux/customPhraseSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noteDetails: {
    note: null,
    summary: null,
    summary_json: null,
    followup: null,
    followupID: null,
    status: ''
  },
  loading: false,
  error: null,
};

const noteDetailsSlice = createSlice({
  name: 'noteDetails',
  initialState,
  reducers: {
    setnoteDetails: (state, action) => {
      const {note, results} = action.payload;
      state.noteDetails = {
        summary_json: results?.summary_convertedJson?.body || null,
        followup_json:results?.followup_convertedJson?.body || null,
        followup: results.followup || null,
        summary: results.summary || null,
        followupID: results.followupID || null,
        status: results.status || null,
        note: note 
      }
    },
    updateNoteDetailsBodyJSON: (state, action) =>{
      const {noteDetailType, key, type, value } = action.payload;
      console.log(state.noteDetails,noteDetailType,key,type)
      if(noteDetailType && !isNaN(key) && type)
        state.noteDetails[noteDetailType][key][type] = value
    },
    updateNoteDetails: (state, action) => {
      state.noteDetails[action.payload.key].push(action.payload.value)
    },
    resetNoteDetials: (state, action) => {
      state.noteDetails = initialState.noteDetails
    }
  }
});

export const { setnoteDetails, updateNoteDetails, resetNoteDetials, updateNoteDetailsBodyJSON } = noteDetailsSlice.actions;

export default noteDetailsSlice.reducer;
