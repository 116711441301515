import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { focusAtom } from "jotai-optics";
import { CDTRecursiveSections, isValidName } from "../../utils/cdt";
import Section from "../note/Section";
import classNames from "classnames";
import CopyButton from "../CopyButton";
import { RecursiveCDTToText } from "../../utils/toText";
// import { trpc } from "../../utils/trpc";
// import toast from "react-hot-toast";
import Spinner from "../Spinner";

export const isRecursiveChildren = (section) => {
  return "section" in section;
};

export const PECARNOptionsResultAtom = atomWithReset({
  Age: undefined,
  "GCS ≤14, palpable skull fracture or signs of AMS": undefined,
  "Occipital, parietal or temporal scalp hematoma; history of LOC ≥5 sec; not acting normally per parent or severe mechanism of injury?":
    undefined,
  "GCS ≤14 or signs of basilar skull fracture or signs of AMS": undefined,
  "History of LOC or history of vomiting or severe headache or severe mechanism of injury":
    undefined,
});

// export default function RecursiveCalculator({
//   noteId,
//   calculator,
//   calculatorData,
//   optionsResultsAtom,
//   showAtom,
// }) {
//   const [result, setResult] = useState(undefined);
//   const [optionsResults, setOptionsResults] = useAtom(optionsResultsAtom);
//   const [isUserModifying, setIsUserModifying] = useState(false);
//   const resetPointsAtom = useResetAtom(optionsResultsAtom);

//   const name = Object.entries(CDTRecursiveSections).find(
//     ([, value]) => value === calculator
//   )?.[0];

//   // if (!isValidName(name)) throw new Error("Invalid calculator name");

//   const { data: calculatorPreviousData, isLoading: isLoadingPreviousData } =
//     // trpc.cdt.getById.useQuery({
//     //   name,
//     //   noteId,
//     // });

//     useEffect(() => {
//       resetPointsAtom();
//     }, []);

//   useEffect(() => {
//     if (calculatorPreviousData?.results)
//       setOptionsResults(calculatorPreviousData.results);
//   }, [calculatorPreviousData]);

//   // const { mutate: upsertCalculatorResults } = trpc.cdt.upsert.useMutation({
//   //   onSuccess: () => {
//   //     toast.success("Changes saved");
//   //   },
//   // });

//   useEffect(() => {
//     const isChanged = Object.entries(optionsResults).some(
//       ([key, value]) => value !== calculatorPreviousData?.results?.[key]
//     );

//     if (isChanged && isUserModifying) {
//       // upsertCalculatorResults({
//       //   noteId,
//       //   name,
//       //   results: optionsResults,
//       // });
//     }
//   }, [optionsResults]);

//   return (
//     <Section title={calculator} showAtom={showAtom} styled={false}>
//       {isLoadingPreviousData ? (
//         <div className="flex items-center justify-center p-4">
//           <Spinner />
//         </div>
//       ) : (
//         <>
//           <div className="space-y-4 p-4">
//             <RecursiveCalcSubsection
//               calculatorData={calculatorData}
//               setResult={setResult}
//               optionsResultsAtom={optionsResultsAtom}
//               setIsUserModifying={setIsUserModifying}
//             />
//           </div>
//           <div className="grid grid-cols-[4fr_2fr] rounded-b-md bg-gray-200 px-8 py-6">
//             <div>
//               <p className="mb-2.5 text-xs text-gray-800">
//                 All calculations should be rechecked by clinician prior to use
//               </p>
//               {result ? (
//                 result.split("\n\n").map((line, i) => (
//                   <p key={i} className="mb-1 text-sm">
//                     {line}
//                   </p>
//                 ))
//               ) : (
//                 <p className="mb-1 text-sm">Please fill out required fields.</p>
//               )}
//             </div>
//             <div className="flex items-center justify-end pr-10">
//               <CopyButton
//                 disabled={!result}
//                 containerClassName="px-6 py-3 shadow-md"
//                 content={RecursiveCDTToText({
//                   calculator,
//                   optionsResults,
//                   calculatorData,
//                 })}
//               >
//                 Copy Results
//               </CopyButton>
//             </div>
//           </div>
//         </>
//       )}
//     </Section>
//   );
// }

export default function RecursiveCalculator({
  noteId,
  calculator,
  calculatorData,
  optionsResultsAtom,
  showAtom,
}) {
  const [result, setResult] = useState(undefined);
  const [optionsResults, setOptionsResults] = useAtom(optionsResultsAtom);
  const [isUserModifying, setIsUserModifying] = useState(false);
  const resetPointsAtom = useResetAtom(optionsResultsAtom);

  const [calculatorPreviousData, setCalculatorPreviousData] = useState(null);
  const [isLoadingPreviousData, setIsLoadingPreviousData] = useState(false);

  const name = Object.entries(CDTRecursiveSections).find(
    ([, value]) => value === calculator
  )?.[0];

  useEffect(() => {
    resetPointsAtom();
  }, []);

  useEffect(() => {
    const fetchPreviousData = async () => {
      setIsLoadingPreviousData(false);
      try {
        // Replace this with your actual data fetching logic
        // const response = await fetch(`/api/cdt/${name}?noteId=${noteId}`);
        // const data = await response.json();
        // setCalculatorPreviousData(data);

        // For now, let's simulate an API call
        setTimeout(() => {
          setCalculatorPreviousData({ results: {} });
          setIsLoadingPreviousData(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching previous data:", error);
        setIsLoadingPreviousData(false);
      }
    };

    if (name && noteId) {
      fetchPreviousData();
    }
  }, [name, noteId]);

  useEffect(() => {
    if (calculatorPreviousData?.results) {
      setOptionsResults(calculatorPreviousData.results);
    }
  }, [calculatorPreviousData]);

  useEffect(() => {
    const isChanged = Object.entries(optionsResults).some(
      ([key, value]) => value !== calculatorPreviousData?.results?.[key]
    );

    if (isChanged && isUserModifying) {
      // Implement your save logic here
      console.log("Saving changes:", optionsResults);
      // For example:
      // saveCalculatorResults({ noteId, name, results: optionsResults });
    }
  }, [optionsResults, calculatorPreviousData, isUserModifying]);

  return (
    <Section title={calculator} showAtom={showAtom} styled={false}>
      {isLoadingPreviousData ? (
        <div className="flex items-center justify-center p-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="space-y-4 p-4">
            <RecursiveCalcSubsection
              calculatorData={calculatorData}
              setResult={setResult}
              optionsResultsAtom={optionsResultsAtom}
              setIsUserModifying={setIsUserModifying}
            />
          </div>
          <div className="grid grid-cols-[4fr_2fr] rounded-b-md bg-gray-200 px-8 py-6">
            <div>
              <p className="mb-2.5 text-xs text-gray-800">
                All calculations should be rechecked by clinician prior to use
              </p>
              {result ? (
                result.split("\n\n").map((line, i) => (
                  <p key={i} className="mb-1 text-sm">
                    {line}
                  </p>
                ))
              ) : (
                <p className="mb-1 text-sm">Please fill out required fields.</p>
              )}
            </div>
            <div className="flex items-center justify-end pr-10">
              <CopyButton
                disabled={!result}
                containerClassName="px-6 py-3 shadow-md"
                content={RecursiveCDTToText({
                  calculator,
                  optionsResults,
                  calculatorData,
                })}
              >
                Copy Results
              </CopyButton>
            </div>
          </div>
        </>
      )}
    </Section>
  );
}

function RecursiveCalcSubsection({
  calculatorData: { name, description, options },
  setResult,
  optionsResultsAtom,
  setIsUserModifying,
}) {
  const [children, setChildren] = useState(undefined);

  const results = useAtomValue(optionsResultsAtom);

  useEffect(() => {
    const candidateChildren = options.find(
      (option) => option.id === results[name]
    );
    if (candidateChildren && isRecursiveChildren(candidateChildren))
      setChildren(candidateChildren.section);
  }, [results]);

  return (
    <>
      <div className="grid grid-cols-12 gap-12">
        <div className="col-span-6 rounded-md px-2 py-1.5">
          <h2 className="mb-1 text-xl">{name}</h2>
          {description && (
            <div className="col-span-12 rounded-md text-sm text-gray-500">
              {description}
            </div>
          )}
        </div>
        <div className="col-span-6 my-auto">
          <RecursiveCalcOptions
            calculatorDataSubsection={options}
            setChildren={setChildren}
            setResult={setResult}
            optionAtom={focusAtom(optionsResultsAtom, (optic) =>
              optic.prop(name)
            )}
            setIsUserModifying={setIsUserModifying}
          />
        </div>
      </div>
      {children && (
        <RecursiveCalcSubsection
          calculatorData={children}
          setResult={setResult}
          optionsResultsAtom={optionsResultsAtom}
          setIsUserModifying={setIsUserModifying}
        />
      )}
    </>
  );
}

function RecursiveCalcOptions({
  calculatorDataSubsection,
  setChildren,
  setResult,
  optionAtom,
  setIsUserModifying,
}) {
  const [selectedOption, setSelectedOption] = useAtom(optionAtom);

  useEffect(() => {
    if (selectedOption !== undefined) {
      const candidateChildren = calculatorDataSubsection.find(
        (option) => option.id == selectedOption
      );
      if (candidateChildren && !isRecursiveChildren(candidateChildren)) {
        setResult(candidateChildren.result);
      }
    }
  }, []);

  return (
    <div className="overflow-hidden rounded-md bg-white shadow-md">
      {calculatorDataSubsection.map(
        (option) =>
          (selectedOption === option.id || selectedOption === undefined) && (
            <button
              key={option.id}
              onClick={() => {
                setIsUserModifying(true);
                if (selectedOption === option.id) {
                  setChildren(undefined);
                  setSelectedOption(undefined);
                  setResult(undefined);
                } else {
                  if (isRecursiveChildren(option)) {
                    setChildren(option.section);
                    setSelectedOption(option.id);
                  } else {
                    setResult(option.result);
                    setSelectedOption(option.id);
                  }
                }
              }}
              className={classNames(
                "flex w-full justify-between border-b border-gray-300 px-4 py-3",
                selectedOption === option.id
                  ? "bg-blue-500 text-white"
                  : "text-black"
              )}
            >
              <p className="text-left">{option.label}</p>
            </button>
          )
      )}
    </div>
  );
}
