import React from 'react';
import CopyButton from "../CopyButton";

export default function NoteFooter({
  backButton,
  copiableContent,
  isBlocked,
}) {
  return (
    <footer className="mt-8 flex items-center justify-start">
      {backButton}
      <div className="ml-8">
        <CopyButton content={copiableContent} disabled={isBlocked}>
          Copy All
        </CopyButton>
      </div>
    </footer>
  );
}