import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Switch, Box, TextField, InputAdornment, IconButton, Card } from '@mui/material';
import { DragIndicator, Send } from '@mui/icons-material';
import { updateSection } from '../utils';
import { IOSSwitch } from './styled';
import DragAbleControlOptionGroup from './DragAbleControlOptionGroup';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDispatch } from 'react-redux';
import { setNoteTemplateOnReorder } from '../slices/customNotes';

const ControlOptionsGroup = React.memo(({ addnewOption, onAddNewOption ,options,optionsStatus, onChange, controlType = 'checkbox', disabled, handleReorder= ()=>{}}) => {
  const [newSection, setNewSection] = useState('');
  const [values, setValues] = useState(options);
  const ControlComponent = controlType === 'switch' ? IOSSwitch : Checkbox;
  const color = controlType === 'switch' ? 'success' : 'primary';

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor)
  )

  useEffect(()=>{
    setValues(options)
  },[options])
  
  return (
    <Box padding="16px" border="1px solid #2E6FF3" borderRadius="8px" margin="20px 0px" bgcolor="transparent" flexGrow={1}>
      <DndContext
      sensors={sensors}
      onDragEnd={(e)=>{

        const { active, over} = e;
        if(!active || !over ) return ;
        if(active?.id === over?.id) return;

        setValues(values => {
          const orignalPos = values.findIndex(value => value.id === active.id)
          const newPos = values.findIndex(value => value.id === over.id)
          const newArray = arrayMove(values, orignalPos, newPos);
          handleReorder(newArray);
          return newArray;
        })

      }}
      collisionDetection={closestCenter} 
      >
      <SortableContext
       items={options}
       strategy={verticalListSortingStrategy}
      >
      {values.map((option, index) => {
        return <DragAbleControlOptionGroup 
        key={option.id} 
        disabled={disabled} 
        color={color} 
        onChange={(checked)=>{
          setValues((values) => {
            const newValues = [...values];
            newValues[index] = {...newValues[index], checked};
            handleReorder(newValues);
            return newValues
          })
        }} ControlComponent={ControlComponent} index={index} option={option} />
      })}
      </SortableContext>
      </DndContext>
      {
        addnewOption && (
          <TextField
          fullWidth
            color={color}
            variant='standard'
            placeholder='Add your own section'
            margin='dense'
            value={newSection}
            onChange={(e)=> setNewSection(e.target.value)}
            size='small'
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton disabled={newSection.trim().length === 0} onClick={()=> {
                      onAddNewOption(newSection);
                      setNewSection('');
                      }} color={color}>
                      <Send />
                    </IconButton>
                  </InputAdornment>
                )
              }
            }}
          />
        )
      }
    </Box>
  );
});

export default ControlOptionsGroup;
