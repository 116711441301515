import React, { useState, useContext, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
// import Head from "next/head";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import { Switch } from "@headlessui/react";
import classNames from "classnames";
// import { trpc } from "../utils/trpc";
import { DataContext } from "../dataContext/DataContext";

function SubscriptionCard({
  product,
  price,
  currentPriceId,
  idx,
  cols,
  yearly,
  redirectToCheckout,
  redirectToCustomerPortal,
}) {
  const priceMontly = yearly ? price.unit_amount / 12 : price.unit_amount;

  const { subscriptionObject, createSubscription } = useContext(DataContext);

  return (
    <div
      key={product.id}
      className={classNames(
        product.metadata?.featured
          ? "relative bg-white shadow-2xl"
          : "bg-white/60 sm:mx-8 lg:mx-0",
        {
          "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none":
            idx === 0 && cols > 1,
          "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl":
            idx !== 0 && cols > 1,
        },
        "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
      )}
    >
      <div className="flex items-center justify-between gap-x-4">
        <h3
          id={price.id}
          className={classNames(
            currentPriceId === price.id ? "text-primary-600" : "text-gray-900",
            "text-lg font-semibold leading-8"
          )}
        >
          {product.name}
        </h3>

        {currentPriceId === price.id && (
          <p className="rounded-full bg-primary-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary-600">
            Your plan!
          </p>
        )}
      </div>

      <p className="mt-4 flex items-baseline gap-x-2">
        <span className="text-5xl font-bold tracking-tight text-gray-900">
          ${(priceMontly || 0) / 100}
        </span>
        <span className="text-base text-gray-500">/month</span>
      </p>
      <p className="mt-6 text-base leading-7 text-gray-600">
        {product.description}
      </p>
      <ul
        role="list"
        className="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10"
      >
        {product.metadata.features?.split(",").map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon
              className="h-6 w-5 flex-none text-primary-600"
              aria-hidden="true"
            />
            {feature.trim()}
          </li>
        ))}
      </ul>
      <button
        aria-describedby={product.id}
        className={classNames(
          product.metadata?.featured
            ? "bg-primary-600 text-white shadow hover:bg-primary-500"
            : "text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300",
          "mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 sm:mt-10"
        )}
        onClick={() => {
          console.log("Current Price ID", price.id);

          const priceId = price.id;

          createSubscription(priceId).then((data) => {
            console.log(
              "Subs Object:",
              data?.intentObj?.latest_invoice?.hosted_invoice_url
            );
            const url = data?.intentObj?.latest_invoice?.hosted_invoice_url;
            window.open(url, "_blank", "noopener,noreferrer");
          });

          if (price) {
            // redirectToCustomerPortal({});
          } else {
            // redirectToCheckout({
            //   price: price.id || "",
            // });
          }
        }}
      >
        {currentPriceId
          ? currentPriceId === price.id
            ? "Manage subscription"
            : "Change plan"
          : "Buy now"}
      </button>
    </div>
  );
}

export default function Subscription() {
  const [yearly, setYearly] = useState(true);

  // const { data: stripeProducts, isSuccess: isStripeProductsSuccess } =
  //   trpc.subscription.products.useQuery();

  const { stripeProducts, getStripeProducts, sessionToken } =
    useContext(DataContext);
  const isStripeProductsSuccess = true;

  useEffect(() => {
    getStripeProducts();
  }, [sessionToken]);

  return (
    <>
      {/* <Head>
        <title>Time by DrH - Your subscription</title>
      </Head> */}
      <Layout>
        <div className="relative isolate bg-white px-6 py-8 sm:py-16 lg:px-8">
          {/* ... (gradient background div) ... */}
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              The right plan for you, and for your <em>time</em>.
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Extend your capabilities, get more done with your time, and help
            more patients than ever. Choose the plan that fits your needs the
            best.
          </p>

          <div className="mt-8 flex justify-center">
            <div className="flex items-center gap-x-2">
              <span className="text-sm font-medium text-gray-700">Monthly</span>
              <Switch
                checked={yearly}
                onChange={setYearly}
                className={`${yearly ? "bg-green-500" : "bg-primary-500"}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${yearly ? "translate-x-9" : "translate-x-0"}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <span className="text-sm font-medium text-gray-700">Yearly</span>
              <span className="text-sm font-medium text-green-500">
                (Save more!)
              </span>
            </div>
          </div>

          <div
            className={classNames(
              "mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0",
              {
                "lg:max-w-xl lg:grid-cols-1":
                  isStripeProductsSuccess && stripeProducts.length === 1,
                "lg:max-w-4xl lg:grid-cols-2":
                  isStripeProductsSuccess && stripeProducts.length === 2,
                "lg:max-w-7xl lg:grid-cols-3":
                  isStripeProductsSuccess && stripeProducts.length >= 3,
              }
            )}
          >
            {isStripeProductsSuccess &&
              stripeProducts.map((stripeProduct, productIdx) => (
                <SubscriptionCard
                  key={stripeProduct.product.id}
                  product={stripeProduct.product}
                  price={
                    stripeProduct.prices.filter((price) =>
                      yearly
                        ? price.lookup_key?.includes("yearly")
                        : price.lookup_key?.includes("monthly")
                    )[0]
                  }
                  idx={productIdx}
                  cols={stripeProducts.length}
                  yearly={yearly}
                  // currentPriceId={subscription?.plan.id}
                  //redirectToCheckout={redirectToCheckout}
                  //redirectToCustomerPortal={redirectToCustomerPortal}
                />
              ))}

            {/* {!isLoaded && (
              <div className="col-span-1 flex justify-center lg:col-span-2">
                <Spinner />
              </div>
            )} */}
          </div>
        </div>
      </Layout>
    </>
  );
}
