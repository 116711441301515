import { DocumentTextIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
// import { downloadFile } from "../../utils/lib";
// import { trpc } from "../../utils/trpc";

export default function DownloadButtons({ uri, noteId }) {
  // const { mutateAsync: getPresignedUrl } =
  //   trpc.note.getDownloadPresignedUrl.useMutation();
  const downloadAudio = () => {
    // getPresignedUrl({ uri, type: "audio" }).then((res) => {
    //   // downloadFile(res, `DRH-${noteId}-audio.m4a`)
    // });
  };
  const downloadTranscript = () => {
    // getPresignedUrl({ uri, type: "transcription" }).then((res) => {
    //   // downloadFile(res, `DRH-${noteId}-transcription.json`)
    // });
  };

  return (
    <div className="flex items-center gap-4">
      <p className="text-lg">Admin Download: </p>
      <button
        className="rounded-lg bg-primary p-2 text-white"
        onClick={downloadAudio}
      >
        <MicrophoneIcon className="w-6" />
      </button>
      <button
        className="rounded-lg bg-primary p-2 text-white"
        onClick={downloadTranscript}
      >
        <DocumentTextIcon className="w-6" />
      </button>
    </div>
  );
}
