import { Add, Close } from '@mui/icons-material'
import { Box, Card, CardContent, IconButton, Stack, Typography, Grid2 as Grid, ListItemText, ListItemButton, List, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeTool } from '../../slices/clinicalToolSlice'
import { updateNoteDetails } from '../../slices/noteDetialsSlice'
import _ from 'lodash'

const Heart = ({ data, preSelected= [], defaultSelections= {}, evaluateDataOnPoints= ()=>{}}) => {
  const dispatch = useDispatch();
  const [selectionSections, setSelectedSection] = useState(defaultSelections);


  useEffect(() => {
    setSelectedSection(defaultSelections)
    return () => {
      setSelectedSection(defaultSelections)
    }
  }, [data])

  const points = Object.values(selectionSections).reduce((acc, curr) => acc + curr?.value, 0)

  const getContent = () => {
    return ({
      heading: `${data.name.toUpperCase()}:`,
      key: data.name,
      content: `Result Summary\n${points} points ${evaluateDataOnPoints && evaluateDataOnPoints(points)} \nInputs:\n${preSelected.length > 0 ? preSelected.map(item =>`${item.name}:\n ${item.value}`).join('\n') : ''} \n${Object.entries(selectionSections).map(([key, value]) => `${key} -> ${value.value} = ${value.label}`).join('\n')}` 
    });
  }

  return (
    <Card variant='elevation' elevation={3} sx={{ width: '100%', marginBottom: '15px', backgroundColor: '#EDF2FF' }}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={() => dispatch(closeTool())} size='large' color='primary'>
            <Close />
          </IconButton>
        </Stack>
        <Typography color='#202224' fontWeight={500} fontSize="24px">
          {data.name}
        </Typography>
        {
          preSelected && preSelected.length > 0 && preSelected.map(item => (
            <Grid container component="main" spacing={3} key={item.name}>
              <Grid sx={{ margin: '15px 0px' }} size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <Typography color='#202224' fontWeight={500} fontSize="18px">
                  {item.name}
                </Typography>
                {
                  item.description && (
                    <Typography color='#5E5E5E' fontWeight={500} fontSize="16px">
                      {item.description}
                    </Typography>
                  )
                }
              </Grid>
              <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px' }}>
                          <ListItemButton
                            disabled
                            dense
                            sx={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                              '&.Mui-selected': {
                                backgroundColor: '#2E6FF3',
                                color: 'white'
                              },
                              '&.Mui-selected:hover': {
                                backgroundColor: '#2E6FF3',
                                color: 'white'
                              }
                            }}
                            key={item.id}
                          >
                            <ListItemText
                              primary={item.value}
                              primaryTypographyProps={{
                                fontSize: '14px',
                                fontWeight: 500,
                                maxWidth: '100%',
                                textAlign: 'center'
                              }}
                            />
                            {/* <Typography color={selected ? 'white' : 'rgba(0, 0, 0, 0.38)'} fontSize="1px" fontWeight={600}>
                              {sectionItem.value > 0 ? '+' : ''}{sectionItem.value}
                            </Typography> */}
                          </ListItemButton>
                </List>
              </Grid>
            </Grid>
          )
        )}
        {
          data.sections.map(section => (
            <Grid container component="main" spacing={3} key={section.name}>
              <Grid sx={{ margin: '15px 0px' }} size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <Typography color='#202224' fontWeight={500} fontSize="18px">
                  {section.name}
                </Typography>
                {
                  section.description && (
                    <Typography color='#5E5E5E' fontWeight={500} fontSize="16px">
                      {section.description}
                    </Typography>
                  )
                }
              </Grid>
              <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px' }}>
                  {
                    section.options.map((sectionItem) => {
                      const selected = selectionSections[section.name]?.id === sectionItem.id;
                      // Only show this item or the unselected items if none is selected
                      if (!selectionSections[section.name] || selected) {
                        return (
                          <ListItemButton
                            onClick={() => {
                              if(!sectionItem?.disabled)
                              setSelectedSection((prevSections) => {
                                const newSections = { ...prevSections };
                                if (selected) {
                                  delete newSections[section.name];
                                } else {
                                  newSections[section.name] = sectionItem;
                                }
                                return newSections;
                              })
                            }}
                            selected={selected && !sectionItem?.disabled}
                            disabled={sectionItem?.disabled}
                            dense
                            sx={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                              '&.Mui-selected': {
                                backgroundColor: '#2E6FF3',
                                color: 'white'
                              },
                              '&.Mui-selected:hover': {
                                backgroundColor: '#2E6FF3',
                                color: 'white'
                              }
                            }}
                            key={sectionItem.id}
                          >
                            <ListItemText
                              primary={sectionItem.label}
                              primaryTypographyProps={{
                                fontSize: '14px',
                                fontWeight: 500,
                                maxWidth: '80%'
                              }}
                            />
                            <Typography color={selected ? 'white' : 'rgba(0, 0, 0, 0.38)'} fontSize="10px" fontWeight={600}>
                              {sectionItem.value > 0 ? '+' : ''}{sectionItem.value}
                            </Typography>
                          </ListItemButton>
                        )
                      }
                      return null; // Hide the item if it's not selected
                    })
                  }
                </List>
              </Grid>
            </Grid>
          ))
        }
      </CardContent>
      <Box bgcolor="white" padding="15px" marginBottom="30px">
        <Grid component="main" container spacing={5}>
          <Grid size={{ lg: 8, md: 8, sm: 12, xs: 12 }}>
            <Typography fontSize="28px" fontWeight={600}>
              {points} points
            </Typography>
            <Typography>
               Inputs:
            </Typography>
            {
              Object.entries(selectionSections).map(([key, value]) => <Typography key={key}>{`${key} -> ${value.value} = ${value.label}`}</Typography>)
            }
          </Grid>
          <Grid size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
            {evaluateDataOnPoints && evaluateDataOnPoints(points)}
          </Grid>
        </Grid>
        <Stack marginTop="15px" direction="row-reverse">
          <Button disabled={
            Object.keys(selectionSections).length !== data.sections.length
          } onClick={() => {
            dispatch(updateNoteDetails({ key: 'summary_json', value: getContent() }));
            dispatch(closeTool())
          }
          } sx={{ bgcolor: '#2E6FF3' }} variant='contained' color='primary' size='large' startIcon={<Add />}>
            Insert
          </Button>
        </Stack>
      </Box>
    </Card>
  )
}

export default Heart;
