import React from "react";
import { Routes, Route } from "react-router-dom";
import SignInPage from "../pages/SignIn";
import SignUpPage from "../pages/SignUp";
const SignedOutRoutes = () => (
  <Routes>
    <Route path="/" element={<SignInPage />} />
    <Route path="/sign-up" element={<SignUpPage />} />
    {/* Add more signed-out routes here */}
  </Routes>
);

export default SignedOutRoutes;
