import React from "react";
import { UserButton } from "@clerk/clerk-react";

const CustomUserButton = ({ showName = false }) => {
  return (
    <UserButton
      showName={showName}
      signInUrl="/"
      appearance={{
        elements: {
          userButtonBox: "flex flex-row-reverse gap-5",
          userButtonOuterIdentifier: "text-white",
        },
      }}
    />
  );
};

export default CustomUserButton;
