import classNames from "classnames";
import React from "react";

export default function Spinner({ size }) {
  return (
    <div
      className={classNames(
        size &&
          (size === "Large"
            ? "h-24 w-24"
            : size === "Medium"
            ? "h-16 w-16"
            : "h-12 w-12"),
        !size && "h-24 w-24",
        "animate-spin rounded-full border-b-2 border-t-2 border-primary-600"
      )}
    ></div>
  );
}
