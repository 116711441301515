//import Head from "next/head";
import { useEffect, useState } from "react";
import { useUser, useSession, SignOutButton } from "@clerk/clerk-react";
import { Hourglass } from "react-loader-spinner";

const SendTokenMobile = () => {
  //const { user, setUser } = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [SessionToken, setSessionToken] = useState("abc");

  const { session } = useSession();

  useEffect(() => {
    const fetchSessionToken = async () => {
      if (session) {
        const token = await session.getToken({
          template: "Time-Drh-Backend",
        });
        setSessionToken(token);
      }
    };

    if (SessionToken === "abc") {
      fetchSessionToken();
    }
  }, []);

  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.tokenHandler &&
    SessionToken !== "abc"
  ) {
    window.webkit.messageHandlers.tokenHandler.postMessage(SessionToken);
  } else {
    console.warn("iOS message handler is not available");
  }

  // Android token handler
  if (window.Android && window.Android.handleToken && SessionToken !== "abc") {
    window.Android.handleToken(SessionToken);
  } else {
    console.warn("Android message handler is not available");
  }

  return (
    <>
      <div className="flex h-screen items-center justify-center">
        <Hourglass
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="hourglass-loading"
          visible={true}
        />
      </div>
    </>
  );
};

export default SendTokenMobile;
