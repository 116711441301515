export const professionOptions = [
  "Emergency Medicine",
  "Primary Care Provider",
  "Resident",
  "Student",
  "Cardiologist",
  "Clinical Psychologist",
  "Dermatologist",
  "EMS",
  "Gastroenterologist",
  "Hospitalist",
  "Infectious Disease Specialist",
  "Intensivist",
  "Nephrologist",
  "OB-GYN",
  "Ophthalmologist",
  "Pediatrician",
  "Podiatrist",
  "Psychiatrist",
  "Radiologist",
  "Social Worker",
  "Speech Therapist",
  "Surgeon",
].map((p) => ({ label: p, value: p }));

export const professionGroups = [
  {
    label: "Medical",
    options: professionOptions,
  },
  {
    label: "Other",
    options: [{ label: "Other", value: "Other" }],
  },
];
