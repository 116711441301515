import { Add, ArrowBack, Close, ContentCopy, Mic } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardContent, Chip, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IOSSwitch } from '../../components/styled';
import TextArealayout from '../../components/TextArealayout';
import useApiRequest from '../../hooks/useHandleRequests';
import { clinicalDecesionTools, getCopyContentFromValue, notevalues, statasesvalues } from '../../utils';
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { useDispatch, useSelector } from 'react-redux';
import { resetNoteDetials, setnoteDetails } from '../../slices/noteDetialsSlice';
import UpdateNoteDialog from '../../components/dialogs/updateNoteDialog';
import { enqueueSnackbar } from 'notistack';

const NoteDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { summary, followup ,note, followupID, status, summary_json, followup_json} = useSelector(state => state.noteDetails.noteDetails)
  const [ excludedHeadings, setExcludedHeadings] = useState({
    summary_json: [],
    followup_json: []
  });

  //using state here
  //using state here 2.0

  const [selectedAction, setSelectedAction] = useState(null);
  const {tool} = useSelector(state => state.clinicalTool)
  const { apiRequest: getSingleNote, loading: loadingNoteDetails} = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => dispatch(setnoteDetails(data)),
    showSuccessSnackbar: false
  })

  const handleClose = () => {
    setSelectedAction(null)
    getSingleNote(`/v2/note/${id}`);
  };

  const handleExcludedHeadings = (key,heading, checked) => {
    if(checked){
      setExcludedHeadings(headings => ({
        ...headings,
        [key]: headings[key].filter(item => item !== heading)
      }))
    }
    else{
      setExcludedHeadings(headings => ({
        ...headings,
        [key]: [...headings[key], heading]
      }))
    }
  }

  const getCopyAlllData = () => {
    let content = '';
    if(summary_json)
      content += summary_json.reduce((prev,value) => prev + getCopyContentFromValue(value) + '\n','Note:\n')
    if(followup_json)
      content += followup_json.reduce((prev,value) => prev + getCopyContentFromValue(value) + '\n','\n\n\nDisposition:\n')    
    return content;
  }

  const getCopySelectedData = () => {
    let content = '';
    if(summary_json)
      content += summary_json.reduce((prev,value) => prev + `${excludedHeadings.summary_json.includes(value.heading) ? '' : getCopyContentFromValue(value)}` + '\n','Note:\n')
    if(followup_json)
      content += followup_json.reduce((prev,value) => prev + `${excludedHeadings.followup_json.includes(value.heading) ? '' : getCopyContentFromValue(value)}` + '\n','\n\n\nDisposition:\n')    
    return content;
  }

  const { copyHandler: copyAll } = useCopyToClipboard(getCopyAlllData());
  const { copyHandler: copySelected } = useCopyToClipboard(getCopySelectedData());

  useEffect(()=>{
    getSingleNote(`/v2/note/${id}`);

    return ()=>{
      dispatch(resetNoteDetials());
    }
  },[]) 
  useEffect(()=>{
      const interval = setInterval(()=>{
        getSingleNote(`/v2/note/${id}`);
      return ()=>{
        dispatch(resetNoteDetials());
      }
      }, 30000)
      return () => clearInterval(interval)
  },[summary_json, note])
  return (
    <React.Fragment>
        
        <Stack gap={2} direction="row"  marginBottom="15px">
        <Button sx={{ color: '#2E6FF3'}} onClick={()=> {
          navigate("/all-notes")
          dispatch(resetNoteDetials());
          }} variant='text' startIcon={<ArrowBack />}>
          Back to Dashboard
        </Button>
          {
            Object.values(notevalues).map(noteValue => <Button startIcon={<Mic />} color='error' size='small' disabled={followup_json || !summary_json || summary_json.length <= 0   || loadingNoteDetails} disableElevation onClick={()=> setSelectedAction(noteValue)} variant='contained'>{noteValue.label}</Button>)
          }
        </Stack>
      {
        (summary === null || summary === undefined ) && (followup === null || followup === undefined ) || loadingNoteDetails ?
        <Stack justifyContent="center" marginTop="30px" gap={2} alignItems="center">
          <CircularProgress />
          <Typography variant='body2'>
            {statasesvalues[status]}
          </Typography>
        </Stack>
        : 
        (
          <>
          <Stack direction="row" gap={2} alignItems="center" marginBottom="15px">
        <Typography component="span" color='#202224' fontSize="32px" fontWeight={700}>
          {note?.patient?.innitials} | {note?.patient?.sex && note?.patient?.sex[0]} | {note?.patient?.age} {note?.patient?.ageType}
        </Typography>
        <Chip 
        sx={{ borderRadius: '8px', padding: '10px', bgcolor: '#2E6FF3'}} 
        clickable 
        variant='filled' 
        icon={<ContentCopy />} 
        label="Copy All" 
        color='primary'
        onClick={()=>{
          copyAll()
          enqueueSnackbar({
            variant: "success",
            message: "Content has been copied"
          })
        }} 
        />
        <Chip 
        sx={{ borderRadius: '8px', padding: '10px', bgcolor: '#2E6FF3'}} 
        clickable 
        variant='filled' 
        icon={<ContentCopy />} 
        label="Copy Selected" 
        color='primary'
        onClick={()=>{
          copySelected();
          enqueueSnackbar({
            variant: "success",
            message: "Selected Content has been copied"
          })
        }} 
        />
        <Chip 
        sx={{ borderRadius: '8px', padding: '10px', bgcolor: '#2E6FF3'}} 
        clickable 
        variant='filled' 
        icon={<Close />} 
        label="Unselect All" 
        color='primary'
        onClick={()=>{
          setExcludedHeadings((headings)=>({
            ...headings,
            summary_json: summary_json.map(summary => summary.heading),
            followup_json: followup_json ? followup_json.map(followup => followup.heading) : headings.followup_json
          }))
        }} 
        />
      </Stack>
      <Grid container component="main" spacing={2}>
        {
          !(summary === null || summary === undefined) && (
            <Grid size={{lg: 6, md: 6, sm: 12, xs: 12}}>
              {
                tool && (clinicalDecesionTools.find(clincalTool => clincalTool.tool === tool).componenet({sex: note?.patient?.sex,age: note?.patient?.age, ageType: note?.patient?.ageType}) || null)
              }
              <TextArealayout excludedHeadings={excludedHeadings.summary_json} handleExcludedHeadings={handleExcludedHeadings} id={note?.id} title="Note" noteKey="summary_json" />
            </Grid>
          )
        }
        {
          !(followup === null || followup === undefined) && (
            <Grid size={{lg: 6, md: 6, sm: 12, xs: 12}}>
              <TextArealayout excludedHeadings={excludedHeadings.followup_json} handleExcludedHeadings={handleExcludedHeadings} id={followupID} title="Disposition" noteKey="followup_json" />
            </Grid>
          )
        }
      </Grid>
          </>
        ) 
      }
    <UpdateNoteDialog data={note} type={selectedAction} handleClose={handleClose} />
    </React.Fragment>
  )
}

export default NoteDetails