import debounce from "just-debounce-it";
import { useMemo } from "react";
import { DataContext } from "../../dataContext/DataContext";
import { useContext } from "react";

export default function useAutoSave(delay = 500) {
  const { updateNote } = useContext(DataContext);

  const handleChange = useMemo(
    () =>
      debounce((data) => {
        console.log("Updatable data", data);
        updateNote(data);
      }, delay),
    [delay, updateNote]
  );

  return { handleChange };
}
