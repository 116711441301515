import SignInPage from "./pages/SignIn";
import SignUpPage from "./pages/SignUp";
import OnboardingPage from "./pages/Onboarding";
import Macros from "./pages/CustomPhrases";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import SignedInRoutes from "./routers/signedIn";
import SignedOutRoutes from "./routers/signedOut";

import { MyProvider } from "./dataContext/DataContext";

function App() {
  return (
    <>
      <Router>
        <SignedIn>
          <MyProvider>
            <SignedInRoutes />
          </MyProvider>
        </SignedIn>
        <SignedOut>
          <SignedOutRoutes />
        </SignedOut>
      </Router>
    </>
  );
}

export default App;
