import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ClerkProvider } from "@clerk/clerk-react";

// Access the environment variables
const frontendApi = process.env.REACT_APP_CLERK_SECRET_KEY;
const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// console.log("Public:", publishableKey);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ClerkProvider
      frontendApi={frontendApi}
      publishableKey={publishableKey}
      appearance={{
        layout: {
          privacyPageUrl: "https://drh.ai/legal",
          helpPageUrl: "https://drh.ai/contact",
        },
        elements: {
          footerPages: {
            flexDirection: "row-reverse",
          },
          footer: {
            display: "flex",
            flexDirection: "column-reverse",
            gap: 6,
          },
          footerPagesLink__privacy: {
            color: "rgb(16, 63, 239)",
          },
        },
      }}
      // localization={{
      //   footerPageLink__privacy: "By signing up you accept our privacy policy",
      // }}
    >
      <App />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
