import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext/DataContext";

// Assume we have a PaginationContext that provides pagination info and a function to change the page

function Table({ data, columns, onRowClick }) {
  const navigate = useNavigate();
  const { pagination, setPage, page, allQueryResult, setAllQueryResult } =
    useContext(DataContext);

  const headers = Object.keys(columns);

  const handleRowClick = (item) => {
    if (onRowClick) {
      onRowClick(item);
    } else if (item.id) {
      navigate(`/notes/${item.id}`);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th
                      key={header}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {data.map((item, index) => (
                  <tr
                    key={item.id || index}
                    onClick={() => handleRowClick(item)}
                    className={`cursor-pointer ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}
                  >
                    {headers.map((key) => (
                      <td
                        key={key}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                      >
                        {columns[key](item)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Pagination controls */}
      <div className="mt-4 flex justify-between items-center">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {(pagination.page - 1) * pagination.limit + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {Math.min(pagination.page * pagination.limit, pagination.total)}
            </span>{" "}
            of <span className="font-medium">{pagination.total}</span> results
          </p>
        </div>
        <div className="flex justify-center items-center space-x-4">
          <button
            onClick={() => {
              console.log("Previous Clicked - Current Page:", page);
              setPage(pagination.page - 1);
              setAllQueryResult({
                isLoading: true,
                isSuccess: false,
                error: null,
              });
            }}
            disabled={pagination.page === 1}
            className="px-4 py-2 border rounded-md disabled:opacity-50 text-sm"
          >
            Previous
          </button>
          <span className="text-sm">
            Page {pagination.page} of {pagination.pages}
          </span>
          <button
            onClick={() => {
              console.log("Next Clicked - Current Page: ", page);
              setPage(pagination.page + 1);
              setAllQueryResult({
                isLoading: true,
                isSuccess: false,
                error: null,
              });
            }}
            disabled={pagination.page === pagination.pages}
            className="px-4 py-2 border rounded-md disabled:opacity-50 text-sm"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Table;
