import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { focusAtom } from "jotai-optics";
import classNames from "classnames";
import Section from "../note/Section";
import {
  CDTSections,
  calculatePointsResult,
  isEnabledCopy,
  isValidName,
} from "../../utils/cdt";
import CopyButton from "../CopyButton";
import { CDTToText } from "../../utils/toText";
import toast from "react-hot-toast";
import Spinner from "../Spinner";

export const heartPointsResultAtom = atomWithReset({
  HISTORY: undefined,
  EKG: undefined,
  AGE: undefined,
  RISK_FACTORS: undefined,
  INITIAL_TROPONIN: undefined,
});

export const NIHSSPointsResultAtom = atomWithReset({
  LEVEL_OF_CONSCIOUSNESS: undefined,
  ASK_MONTH_AND_AGE: undefined,
  BLINK_EYES_AND_SQUEEZE_HANDS: undefined,
  HORIZONTAL_EXTRAOCULAR_MOVEMENTS: undefined,
  VISUAL_FIELDS: undefined,
  FACIAL_PALSY: undefined,
  LEFT_ARM_MOTOR_DRIFT: undefined,
  RIGHT_ARM_MOTOR_DRIFT: undefined,
  LEFT_LEG_MOTOR_DRIFT: undefined,
  RIGHT_LEG_MOTOR_DRIFT: undefined,
  LIMB_ATAXIA: undefined,
  SENSATION: undefined,
  LANGUAGE_OR_APHASIA: undefined,
  DYSARTHRIA: undefined,
  EXTINCTION_OR_INATTENTION: undefined,
});

export default function CDTCalculator({
  noteId,
  calculator,
  calculatorOptions,
  pointResultAtom,
  scoreRanges,
  showAtom,
}) {
  const name = Object.entries(CDTSections).find(
    ([, value]) => value === calculator
  )?.[0];

  const [isUserModifying, setIsUserModifying] = useState(false);
  const [pointResults, setPointResults] = useAtom(pointResultAtom);
  const resetPointsAtom = useResetAtom(pointResultAtom);

  const [calculatorPreviousData, setCalculatorPreviousData] = useState(null);
  const [isLoadingPreviousData, setIsLoadingPreviousData] = useState(true);

  useEffect(() => {
    resetPointsAtom();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingPreviousData(true);
      try {
        // Replace this with your actual data fetching logic
        // const response = await fetch(`/api/cdt/${name}?noteId=${noteId}`);
        // const data = await response.json();
        // setCalculatorPreviousData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        //toast.error("Failed to load previous data");
      } finally {
        setIsLoadingPreviousData(false);
      }
    };

    if (name && noteId) {
      fetchData();
    }
  }, [name, noteId]);

  useEffect(() => {
    if (calculatorPreviousData?.results) {
      setPointResults(calculatorPreviousData.results);
    }
  }, [calculatorPreviousData]);

  const { totalPoints, description } = calculatePointsResult(
    pointResults,
    calculatorOptions,
    scoreRanges
  );

  useEffect(() => {
    const isChanged = Object.entries(pointResults).some(
      ([key, value]) => value !== calculatorPreviousData?.results?.[key]
    );

    if (isChanged && isUserModifying) {
      const saveData = async () => {
        try {
          // Replace this with your actual save logic
          // const response = await fetch(`/api/cdt/${name}`, {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   body: JSON.stringify({ noteId, results: pointResults }),
          // });
          // if (!response.ok) throw new Error("Failed to save data");
          //toast.success("Changes saved");
        } catch (error) {
          console.error("Error saving data:", error);
          //toast.error("Failed to save changes");
        }
      };

      saveData();
    }
  }, [pointResults, calculatorPreviousData, isUserModifying, name, noteId]);

  return (
    <Section title={calculator} showAtom={showAtom} styled={false}>
      {isLoadingPreviousData ? (
        <div className="flex items-center justify-center p-4">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="space-y-4 p-4">
            {calculatorOptions.map((subSection) => (
              <CalcOption
                key={subSection.name}
                subSectionAtom={focusAtom(pointResultAtom, (optic) =>
                  optic.prop(subSection.name)
                )}
                subSection={subSection}
                setIsUserModifying={setIsUserModifying}
              />
            ))}
          </div>
          <div className="grid grid-cols-[5fr_2fr] rounded-b-md bg-gray-200/80 px-8 py-6">
            <div>
              <p className="mb-2.5 text-xs text-gray-800">
                All calculations should be rechecked by clinician prior to use
              </p>
              <h3 className="mb-2 text-2xl font-medium text-black">
                {totalPoints} points
              </h3>
              {description.split("\n\n").map((line, index) => (
                <p key={index} className="mb-1 text-sm">
                  {line}
                </p>
              ))}
            </div>
            <div className="flex items-center justify-end pr-10">
              <CopyButton
                disabled={!isEnabledCopy(pointResults, calculatorOptions)}
                containerClassName="px-6 py-3 shadow-md"
                content={CDTToText({
                  calculator,
                  calculatorOptions,
                  pointResults,
                  scoreRanges,
                })}
              >
                Copy Results
              </CopyButton>
            </div>
          </div>
        </>
      )}
    </Section>
  );
}

function CalcOption({ subSection, subSectionAtom, setIsUserModifying }) {
  const { name, description, options } = subSection;
  const [subSectionValue, setSubSectionValue] = useAtom(subSectionAtom);

  return (
    <div className="gap-12 xl:grid xl:grid-cols-2">
      <div className="rounded-md px-2 py-1.5">
        <h2 className="mb-1 text-xl">{name}</h2>
        {description && (
          <div className="col-span-12 rounded-md text-sm text-gray-500">
            {description}
          </div>
        )}
      </div>
      <div className="my-auto">
        <CalcOptionSelector
          options={options}
          subSectionValue={subSectionValue}
          setSubSectionValue={setSubSectionValue}
          setIsUserModifying={setIsUserModifying}
        />
      </div>
    </div>
  );
}

function CalcOptionSelector({
  options,
  subSectionValue: selectedOption,
  setSubSectionValue,
  setIsUserModifying,
}) {
  return (
    <div className="overflow-hidden rounded-md bg-white shadow-md">
      {options.map(
        ({ id, label, value }) =>
          (selectedOption === id || selectedOption === undefined) && (
            <button
              key={id}
              onClick={() => {
                setIsUserModifying(true);

                selectedOption === id
                  ? setSubSectionValue(undefined)
                  : setSubSectionValue(id);
              }}
              className={classNames(
                "flex w-full justify-between border-b border-gray-300 px-4 py-3",
                selectedOption === id ? "bg-blue-500 text-white" : "text-black"
              )}
            >
              <p className="text-left">{label}</p>
              <span
                className={classNames(
                  "text-sm font-semibold",
                  selectedOption === id ? "text-white" : "text-gray-400"
                )}
              >
                {value > 0 ? `+${value}` : value}
              </span>
            </button>
          )
      )}
    </div>
  );
}
