import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useCopyToClipboard } from "./hooks/useCopyToClipboard";
import { CheckIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";

export default function CopyButton({
  content,
  disabled,
  children,
  className,
  containerClassName,
  ...iconProps
}) {
  const { copyHandler, copied } = useCopyToClipboard(content);

  const icon = copied ? (
    <CheckIcon
      className={twMerge(
        "float-right block aspect-square w-7",
        className,
        disabled && "text-gray-500"
      )}
      {...iconProps}
    />
  ) : (
    <DocumentDuplicateIcon
      className={twMerge(
        "float-right block aspect-square w-7",
        className,
        disabled && children && "text-white",
        !disabled && children && "text-white",
        disabled && !children && "cursor-not-allowed text-gray-500"
      )}
      role={disabled ? "" : "button"}
      onClick={children || disabled ? undefined : copyHandler}
      {...iconProps}
    />
  );

  if (children) {
    return (
      <button
        className={classNames(
          "flex items-center rounded-md px-4 py-1 text-base ",
          {
            "cursor-pointer bg-primary text-white hover:bg-primary-600":
              !disabled,
            "cursor-not-allowed bg-gray-300 text-white": disabled,
          },
          containerClassName
        )}
        onClick={copyHandler}
        disabled={disabled}
      >
        <span className="mr-2">{children}</span>
        {icon}
      </button>
    );
  }

  return icon;
}
