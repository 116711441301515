import React, { useEffect, useContext, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import OnboardingPage from "../pages/Onboarding";
import Macros from "../pages/CustomPhrases";
import NoteDetail from "../pages/Notes";
import Home from "../pages/Home";
import Subscription from "../pages/Subscription";
import SendTokenMobile from "../pages/SignInMobile";
import { DataContext } from "../dataContext/DataContext";

// import AudioRecorderDemo from "../pages/Recorder";

const SignedInRoutes = () => {
  const { user } = useUser();
  const navigation = useNavigate();
  const { SessionToken } = useContext(DataContext);
  const [webView, setWebView] = useState(false);
  const location = useLocation();

  console.log(user.publicMetadata.profession);

  useEffect(() => {
    console.log("Default Routing");
    if (
      user.publicMetadata.profession === null ||
      user.publicMetadata.profession === undefined
    ) {
      navigation("/onboarding");
    } else if (
      (window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.tokenHandler) ||
      (window.Android && window.Android.handleToken)
    ) {
      setWebView(true);
      console.log("Opened in WebView");
      navigation("/auth-token");
    } else if (location.pathname === "/sign-up") {
      navigation("/");
    } else {
      console.log("Opened in Browser");
    }
  }, [SessionToken, user]);

  return (
    <Routes>
      {/* <Route path="/recorder" element={<AudioRecorderDemo />} /> */}

      <Route path="/auth-token" element={<SendTokenMobile />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/phrase" element={<Macros />} />
      <Route path="/notes/:id" element={<NoteDetail />} />
      <Route path="/" element={!webView ? <Home /> : <SendTokenMobile />} />
    </Routes>
  );
};

export default SignedInRoutes;
