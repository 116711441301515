import {
  CDTRecursiveSections,
  CDTSections,
  calculatePointsResult,
  isEnabledCopy,
} from "./cdt";
import {
  isRecursiveChildren,
} from "../components/CDT/RecursiveCalculator";

export const CDTToText = ({
  calculator,
  calculatorOptions,
  pointResults,
  scoreRanges,
}) => {
  if (!isEnabledCopy(pointResults, calculatorOptions)) return "";

  const { totalPoints, description } = calculatePointsResult(
    pointResults,
    calculatorOptions,
    scoreRanges,
  );
  const currentDate = new Date();

  return `${calculator} on ${currentDate.toLocaleDateString()}

RESULT SUMMARY:
${totalPoints} points
${description}

INPUTS:
${getInputs(calculatorOptions, pointResults)}\n\n`;
};

export default function getInputs(
  calculatorOptions,
  pointsData,
) {
  let res = "";
  for (const calculatorOption of calculatorOptions) {
    for (const option of calculatorOption.options) {
      if (option.id === pointsData[calculatorOption.name]) {
        res += `${calculatorOption.name} -> ${option.value} = ${option.label}\n`;
      }
    }
  }
  return res;
}

export const RecursiveCDTToText = ({
  calculator,
  optionsResults,
  calculatorData,
}) => {
  const [inputs, results] = getRecursiveInputs({
    res: "",
    optionsResults,
    section: calculatorData,
  });

  if (!results) return "";

  return `${calculator} on ${new Date().toLocaleDateString()}

RESULTS SUMMARY:
${results}

INPUTS:
${inputs}\n\n`;
};

const getRecursiveInputs = ({
  res,
  section,
  optionsResults,
}) => {
  const resultOption = section.options.find(
    (option) => option.id === optionsResults[section.name],
  );
  if (!resultOption) return [res, ""];
  if (!isRecursiveChildren(resultOption)) {
    return [
      res + `${section.name} -> ${resultOption.id} = ${resultOption.label}`,
      resultOption.result,
    ];
  }
  return getRecursiveInputs({
    res:
      res + `${section.name} -> ${resultOption.id} = ${resultOption.label}\n`,
    section: resultOption.section,
    optionsResults,
  });
};

export const ddxToText = (ddx) =>
  typeof ddx === "string"
    ? ddx
    : Object.entries(ddx[Object.keys(ddx)[0]] || {})
        .map(([key, value]) => {
          return `\t- ${key}: ${value}`;
        })
        .join("\n");

export const mdmToText = (mdm) =>
  mdm.map((s) => `${s.title}\n${s.content}`).join("\n\n");

export const wholeNoteToText = (
  results,
  include,
  cdtResults,
  cdtRecursiveResults,
) => {
  const summary =
    !include || include.summary ? `# Note\n\n${results.summary}\n\n` : "";

  const followup =
    results.followup && (!include || include.followup)
      ? `# Follow-up notes\n\n${results.followup}\n\n`
      : "";

  const ddx =
    !include || include.ddx ? `# DDx\n\n${ddxToText(results.ddx)}\n\n` : "";

  const mipsNotes =
    (!include || include.mips_notes) && results.mips_notes.length > 0
      ? `# MIPS\n\n${results.mips_notes}\n\n`
      : "";

  const mdm =
    results.mdm.length > 0 && (!include || include.mdm)
      ? `# Medical Decision Making\n\n${mdmToText(results.mdm)}\n\n`
      : "";

  let cdt = "";
  if (
    !include ||
    include.cdt_heart ||
    include.cdt_nihss ||
    include.cdt_pecarn
  ) {
    let commonCdt = "";
    let recursiveCdt;
    if (cdtResults) {
      const { calculatorOptions, pointResults, scoreRanges } = cdtResults;

      for (const calculator of [
        CDTSections.HEART,
        CDTSections.NIHSS,
      ]) {
        commonCdt += CDTToText({
          calculator,
          calculatorOptions: calculatorOptions[calculator],
          pointResults: pointResults[calculator],
          scoreRanges: scoreRanges[calculator],
        });
      }
    }
    if (cdtRecursiveResults) {
      recursiveCdt = RecursiveCDTToText(cdtRecursiveResults);
    }
    cdt =
      commonCdt || recursiveCdt ? "# CDT\n\n" + commonCdt + recursiveCdt : "";
  }

  return summary + followup + ddx + mipsNotes + cdt + mdm;
};