// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import notesReducer from './slices/notesSlice';
import customPhraseReducer from './slices/customPhraseSlice';
import customNotesReducer from './slices/customNotes';
import noteDetailsReducer from './slices/noteDetialsSlice';
import clinicalToolReducer from './slices/clinicalToolSlice';
const store = configureStore({
  reducer: {
    notes: notesReducer,
    customPhrases: customPhraseReducer,
    noteTemplates: customNotesReducer,
    noteDetails: noteDetailsReducer,
    clinicalTool: clinicalToolReducer
  },
});

export default store;
