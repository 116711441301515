import React from "react";

const navigation = {
  main: [
    { name: "About", href: "https://drh.ai" },
    { name: "Privacy", href: "https://drh.ai/legal/privacy" },
    { name: "Terms of Service", href: "https://drh.ai/legal/tos" },
  ],
};

export default function Footer() {
  return (
    <footer className="absolute bottom-0 w-full bg-primary-900">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-8 sm:py-8 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-white hover:text-gray-200"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-10 text-center text-xs leading-5 text-white">
          &copy; {new Date().getFullYear()} Dr. Haydar, LLC. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}
