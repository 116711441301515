import React, { useEffect, useRef, useState, useContext } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import useAutosizeTextArea from "./hooks/useAutoSizeTextarea";

import { DataContext } from "../dataContext/DataContext";

const Item = ({ entity: { code, title } }) => <div>{`${code}: ${title}`}</div>;

const Loading = () => <div>Loading</div>;

export default function AutocompleteTextArea({
  initialValue,
  //options,
  placeholder,
  onUpdate,
}) {
  const rtaRef = useRef(null);
  const [textAreaElem, setTextAreaElem] = useState(null);
  const [textarea, setTextarea] = useState(initialValue);

  useAutosizeTextArea(textAreaElem, textarea);

  const { getCustomPhrases, macros } = useContext(DataContext);

  useEffect(() => {
    getCustomPhrases();
  }, []);

  const options = macros;

  const convertPlaceholdersToText = (text) => {
    return text.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
  };

  return (
    <ReactTextareaAutocomplete
      placeholder={placeholder}
      loadingComponent={Loading}
      className="w-full rounded-lg border-[1px] border-solid border-gray-300 bg-white p-4 outline-none focus:border-primary-300 focus:ring-0"
      ref={rtaRef}
      innerRef={(elem) => setTextAreaElem(elem)}
      value={convertPlaceholdersToText(textarea)}
      movePopupAsYouType={true}
      onChange={(event) => {
        setTextarea(event.target?.value);
        onUpdate(event.target?.value);
      }}
      minChar={0}
      maxLength={10000}
      trigger={{
        "*": {
          dataProvider: (token) =>
            options
              .filter((o) =>
                o.code.toLowerCase().startsWith(token.toLowerCase())
              )
              .slice(0, 20),
          component: Item,
          output: (item) => item.content,
        },
      }}
    />
  );
}
