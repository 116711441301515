import React from "react";
import { useUser } from "@clerk/clerk-react";
import { toTitleCase } from "../../utils/lib";
import CopyButton from "../CopyButton";
import DownloadButtons from "./DownloadButtons";

export default function NoteHeader({
  note,
  isReady,
  results,
  copiableContent,
  isBlocked,
}) {
  const { user } = useUser();

  return (
    <section className="flex justify-between">
      <div className="flex items-start gap-8">
        <div>
          <h1 className="text-2xl font-medium">
            {note.patient && note.patient.identifier
              ? note.patient.identifier
              : "No identifier"}{" "}
            &middot;{" "}
            {note.locationType && note.location
              ? `${toTitleCase(note.locationType || "")} #${note.location}`
              : "No location"}{" "}
          </h1>
        </div>
        {isReady && results ? (
          <CopyButton content={copiableContent} disabled={isBlocked}>
            Copy All
          </CopyButton>
        ) : null}
      </div>
      {user?.publicMetadata.isSuperAdmin && note.noteSlug ? (
        <DownloadButtons uri={note.noteSlug} noteId={note.id} />
      ) : null}
    </section>
  );
}
