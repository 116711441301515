import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PrimitiveAtom, atom, useAtom } from "jotai";
import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/Layout";
import { twMerge } from "tailwind-merge";

import { DataContext } from "../dataContext/DataContext";

const macroAtom = {
  code: "",
  title: "",
  content: "",
};

const macrostemp = [
  { id: 1, code: "abc123", title: "Example Macro 1", content: "Content 1" },
  { id: 2, code: "def456", title: "Example Macro 2", content: "Content 2" },
];

export default function Macros() {
  const [isEditing, setIsEditing] = useState(false);
  const [macroData, setMacroData] = useState(macroAtom);
  const [existingMacroId, setExistingMacroId] = useState(null);
  const [inputValue, setInputValue] = useState(""); // New state for input value

  const { getCustomPhrases, macros, setMacros, createNewPhrase } =
    useContext(DataContext);

  // console.log("NEW MACRO", macroData);

  useEffect(() => {
    getCustomPhrases();
  }, [isEditing]);

  const handleCreateNewMacro = () => {
    setIsEditing(true);
    setMacroData({
      code: "",
      title: "",
      content: "",
    });
    setExistingMacroId(null);
  };

  const handleSave = () => {
    setIsEditing(false);
    existingMacroId === null
      ? createNewPhrase(macroData)
      : updateExistingPhrase(macroData);
  };

  const updateExistingPhrase = () => {};

  const clickMacroHandler = (m) => () => {
    setMacroData(m);
    setIsEditing(true);
    setExistingMacroId(m.id);
  };

  return (
    <Layout>
      <article className="my-4 px-8">
        <header className="flex items-center justify-between">
          <h1 className="text-3xl font-medium">Custom Phrases</h1>
          <button
            className="rounded-md bg-primary-500 px-4 py-2 font-medium text-white hover:bg-primary-600"
            onClick={handleCreateNewMacro}
          >
            <PlusIcon className="-mt-1 inline w-5" /> New custom phrase
          </button>
        </header>
        <div className="grid h-[60vh] grid-cols-[1fr_2fr] gap-8 px-12 py-4">
          {macros.length > 0 || isEditing ? (
            <ul className="h-full space-y-4 overflow-y-scroll rounded-md bg-gray-100 p-4">
              {isEditing && !existingMacroId ? (
                <li
                  className={twMerge(
                    "flex cursor-pointer items-center justify-between rounded-sm bg-gray-200 px-3 py-2 transition-colors duration-200 ease-in-out"
                  )}
                >
                  {macroData.title
                    ? `*${macroData.code || "code"} - ${
                        macroData.title || "My New Custom Phrase"
                      }`
                    : "My new Custom Phrase"}
                </li>
              ) : null}
              {macros.map((m) => {
                const isMacroSelected = existingMacroId === m.id;

                return (
                  <li
                    key={m.code}
                    className={twMerge(
                      "flex cursor-pointer items-center justify-between rounded-sm px-3 py-2 transition-colors duration-200 ease-in-out hover:bg-gray-200",
                      isMacroSelected && "bg-gray-200"
                    )}
                    onClick={clickMacroHandler(m)}
                  >
                    <span>
                      *{isMacroSelected ? macroData.code : m.code} -{" "}
                      {isMacroSelected ? macroData.title : m.title}
                    </span>
                    {isMacroSelected ? (
                      <TrashIcon
                        className="h-6 w-6 rounded-md p-1 text-red-600 hover:bg-gray-300"
                        // onClick={() => deleteMacro(m.id)}
                      />
                    ) : null}
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="flex h-full flex-col items-center justify-center space-y-4 rounded-md bg-gray-100 p-4">
              <p className="text-center">You have no custom phrases yet.</p>
              <button
                className="rounded-md bg-primary-600 px-4 py-2 font-medium text-white"
                onClick={handleCreateNewMacro}
              >
                <PlusIcon className="-mt-1 inline w-5" /> New custom phrase
              </button>
            </div>
          )}
          {isEditing ? (
            <div className="flex h-full flex-col items-center justify-between gap-4 rounded-md bg-gray-100 p-4">
              <div className="grid w-full grid-cols-[1fr_2fr] gap-2">
                <Input
                  //atom={macroAtom}
                  prop="code"
                  placeholder="Code"
                  value={inputValue}
                  onChange={(e) =>
                    setMacroData({ ...macroData, code: e.target.value })
                  }
                />
                <Input
                  //atom={macroAtom}
                  prop="title"
                  placeholder="Title"
                  value={inputValue}
                  onChange={(e) =>
                    setMacroData({ ...macroData, title: e.target.value })
                  }
                />
              </div>
              <Input
                //atom={macroAtom}
                prop="content"
                placeholder="Input here what you want to see whenever you use this custom phrase"
                className="wrap flex-grow"
                textarea
                value={inputValue}
                onChange={(e) =>
                  setMacroData({ ...macroData, content: e.target.value })
                }
              />
              <button
                className="rounded-md bg-primary-500 px-12 py-2 font-medium text-white hover:bg-primary-600"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center rounded-md bg-gray-100">
              <p className="w-2/3 text-center text-sm">
                You can create your own shortcuts to quickly add repetitive or
                frequently used information in your note. You can quickly copy
                them in the summary and MDM.
              </p>
            </div>
          )}
        </div>
      </article>
    </Layout>
  );
}

const Input = ({
  atom,
  prop,
  placeholder,
  value,
  onChange,
  className,
  textarea,
}) => {
  const [localValue, setLocalValue] = useState(value);

  const Component = textarea ? "textarea" : "input";

  return (
    <Component
      value={localValue}
      onChange={(e) => {
        setLocalValue(e.target.value);
        onChange(e);
      }}
      className={twMerge(
        "resizable w-full resize-none rounded-md border border-gray-300 px-3 py-2",
        className
      )}
      placeholder={placeholder}
    />
  );
};
