import React from 'react';
import Grid from '@mui/material/Grid2';
import { SignUp as SignUpComponent} from '@clerk/clerk-react';
import { Stack, Typography } from '@mui/material';

const SignUp = () => {
  return (
    <Grid sx={{ bgcolor: 'white'}} container component="main">
      <Grid display="flex" size={{ lg: 6, md: 6, sx: 12, xs: 12}} height="100vh" justifyContent="center" alignItems="center">
        <SignUpComponent
         signInUrl='/'
         fallbackRedirectUrl="/all-notes"
         signInFallbackRedirectUrl="/all-notes"
        />
      </Grid>
      <Grid sx={{
        backgroundImage: `url('/images/drh new front img.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} size={{ lg: 6, md: 6, sx: 0, xs: 0}} height="100vh">
        {/* <Stack direction="column" justifyContent="center" height="100vh" alignItems="center" display="flex">
          <img src="/images/drh new logo.jpeg" width="100%"  />
        </Stack> */}
        <Stack gap={3} sx={{ background: 'linear-gradient(to bottom, transparent, #2E6FF3)' }} width="100%" height="100%" direction="column-reverse">
        <Typography textAlign="center" color='white' marginBottom='30px'>
          Download the mobile app at <a style={{ color: 'white', fontStyle: 'normal'}} href='https:www.downloadtime.ai' target='_blank'>www.downloadtime.ai</a>
          </Typography>
          <Typography textAlign="center" variant='h3' color='white'>
          {`Automated AI Notes`}
          </Typography>
          <Typography textAlign="center" variant='h3' color='white'>
          {`Reclaim your TIME`}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SignUp