import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import { getnoteTemplatesPostingData, sections } from '../utils';
import { Box, Typography } from '@mui/material';
import CheckboxGroup from './ControlOptionsGroup';
import { useDispatch, useSelector } from 'react-redux';
import { addNewOption, setNoteTemplateOnReorder, toggleOption } from '../slices/customNotes';
const Sections = ({isPosting, setEdited}) => {
  const dispatch = useDispatch();
  const noteTemplates = useSelector(state => state.noteTemplates)
  console.log(noteTemplates);
  return (
    <Grid container spacing={3}>
      {
        Object.entries(noteTemplates).map(([key, values]) => (
          <Grid size={{lg: 4, md: 4, sm: 6, xs: 12}} key={key} display="flex" flexDirection="column" justifyContent="space-between" >
            <Box component="div" sx={{height: {lg: '100px', md: '150px', sm: '150px', xs: '200px'}}}>
            <Typography fontSize="32px" fontWeight={700} fontFamily="sans-serif" color='#202224'>
              {values.title}
            </Typography>
            <Typography fontSize="16px" fontWeight={400} fontFamily="sans-serif" color='#202224'>
              {values.content}
            </Typography>
            </Box>
            <CheckboxGroup
            setEdited={setEdited}
            handleReorder={(value)=>{
              dispatch(setNoteTemplateOnReorder({key, value}))
              setEdited(true);
            }}
            disabled={isPosting}
            onAddNewOption={(option) =>{
              dispatch(addNewOption({key, option}))
              setEdited(true);
            }}
            addnewOption={values.canAddNew}
            onChange={(value, option, index)=>{
              dispatch(toggleOption({
                key,
                option,
                value,
                index
              }))
              setEdited(true);
            }}
            options={values.options}
            controlType='switch'
            />
          </Grid>
        ))
      }
    </Grid>
  )
}

export default Sections